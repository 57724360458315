import {
  EEmptyStateOperationName,
} from '../../../TextProvider';
import * as React from 'react';
import {
  EmptyStateContainer,
  EmptyStateWrapper
} from '../../../components/empty-State/EmptyStateWrapper';
import { CreateRecipeButton } from './CreateRecipeButton';
import { Alignment } from "@kounta/chameleon";
import { PRIMARY_BUTTON } from '../../../typings/Chameleon';

interface IProps {
  isProductsFullyLoaded: boolean;
  setShouldPopupProductsList: () => void;
}

export const RecipesListEmptyState = React.memo(
  (
    { isProductsFullyLoaded, setShouldPopupProductsList }: IProps
  ): JSX.Element => {
    return (
      <EmptyStateContainer>
        <Alignment vertical={'center'} horizontal={'center'}>
          <EmptyStateWrapper
            pageName={EEmptyStateOperationName.productsList}
            footerElement={
              <CreateRecipeButton
                hideYn={false}
                buttonStyle={PRIMARY_BUTTON}
                isProductsFullyLoaded={isProductsFullyLoaded}
                onButtonClick={setShouldPopupProductsList}
              />
            }
          />
        </Alignment>
      </EmptyStateContainer>
    );
  }
);
