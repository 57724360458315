import { FieldArray, FormikProps } from 'formik';
import {
  EFormikIngredientsFieldName,
  IApiRecipe, IFormikBatch, IFormikBatchIngredient
} from '../../../typings/Interface';
import { CustomBatchSection } from './CustomBatchSection';
import { handleCustomBatchToggle } from '../../../formik/batch/HandleCustomBatchToggle';
import { handleExpectedYieldQtyChange } from '../../../formik/batch/HandleExpectedYieldQtyChange';
import * as React from 'react';
import { IBatchFieldArrayProps } from '../../../typings/Formik';
import { ReadOnlyIngredientsTable } from './ReadOnlyIngredientsTable';
import { Group } from '@kounta/chameleon';
import { LoadingIngredients } from '../../../components/loading/LoadingIngredients';

interface IProps {
  values: IFormikBatch,
  setFieldValue: FormikProps<IFormikBatch>['setFieldValue'],
  benchmarkBatchIngs: IFormikBatchIngredient[],
  errors: FormikProps<IFormikBatch>['errors'],
  isCustomBatchSectionReadOnly: boolean,
  selectedRecipe: false | IApiRecipe,
  existingFormikBatch: IFormikBatch | false,
  isProductsFullyLoaded: boolean,
}

export const BatchIngredientSection = React.memo((
  {
    values,
    setFieldValue,
    benchmarkBatchIngs,
    errors,
    isCustomBatchSectionReadOnly,
    selectedRecipe,
    existingFormikBatch,
    isProductsFullyLoaded,
  }: IProps
): JSX.Element => {
  if (!isProductsFullyLoaded) {
    return (<LoadingIngredients/>);
  }
  return (
    <Group spacing={'24px'}>
      <FieldArray
        name={EFormikIngredientsFieldName.batchIngredients}
      >
        {renderDefaultIngredients}
      </FieldArray>
      <CustomBatchSection
        selectedRecipe={selectedRecipe}
        isCustomBatchYield={values.isCustomYield}
        batchFormExpectedYield={values.expectedYield}

        onCustomBatchToggle={handleCustomBatchToggle(
          setFieldValue,
          (selectedRecipe as unknown as IApiRecipe),
          benchmarkBatchIngs)
        }
        onExpectedYieldQtyChange={handleExpectedYieldQtyChange(
          setFieldValue,
          selectedRecipe,
          benchmarkBatchIngs,
          existingFormikBatch)
        }
        errors={errors}
        isCustomBatchSectionReadOnly={isCustomBatchSectionReadOnly}
      />
    </Group>
  );
});

const renderDefaultIngredients = ({ form }: IBatchFieldArrayProps): JSX.Element => {
  return (
    <ReadOnlyIngredientsTable
      batchIngredients={form.values.batchIngredients}
    />
  )
};
