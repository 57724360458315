import moment, { Moment } from 'moment';
import { IApiBatch } from '../../typings/Interface';
import {
  ETableSortingDirection,
} from '../../typings/Chameleon';
import { MIN_SEARCH_LENGTH } from '../../TextProvider';
import { isBatchCompleted } from '../filter/FilterUtil';
import { SelectOptionType, TableSortDirectionType } from '@kounta/chameleon';

export interface IDateRange {
  startingDate: Moment;
  endingDate: Moment;
}

export enum EUnitOfTime {
  day = 'day',
  days = 'days',
  months = 'months',
}

export enum EDateOptionType {
  pastMonth = 'pastMonth',
  pastSevenDays = 'pastSevenDays',
  yesterday = 'yesterday',
  today = 'today',
}

export interface IDateRangeOption extends SelectOptionType {
  id: EDateOptionType;
  name: string;
  numOfDaysBeforeGivenDate: number;
  numOfDaysToGivenDate: number;
  unitOfTime: EUnitOfTime;
}

export interface IDateRangeOptionInfo {
  [name: string]: IDateRangeOption;
}

export interface ISortDateFunc {
  (batch1: IApiBatch, batch2: IApiBatch): number;
}

export const dateRangeOptions: IDateRangeOptionInfo = {
  [EDateOptionType.pastMonth]: {
    id: EDateOptionType.pastMonth,
    name: 'Past month',
    numOfDaysBeforeGivenDate: 30,
    numOfDaysToGivenDate: 0,
    unitOfTime: EUnitOfTime.days,
  },
  [EDateOptionType.pastSevenDays]: {
    id: EDateOptionType.pastSevenDays,
    name: 'Past 7 days',
    numOfDaysBeforeGivenDate: 7,
    numOfDaysToGivenDate: 0,
    unitOfTime: EUnitOfTime.days,
  },
  [EDateOptionType.yesterday]: {
    id: EDateOptionType.yesterday,
    name: 'Yesterday',
    numOfDaysBeforeGivenDate: 1,
    numOfDaysToGivenDate: 1,
    unitOfTime: EUnitOfTime.days,
  },
  [EDateOptionType.today]: {
    id: EDateOptionType.today,
    name: 'Today',
    numOfDaysBeforeGivenDate: 0,
    numOfDaysToGivenDate: 0,
    unitOfTime: EUnitOfTime.days,
  },
};

// 2019-03-07T00:00:00.000Z
export const startOfDay = (dt: Moment) =>
  dt.startOf(EUnitOfTime.day).utc(false);

// 2019-03-07T23:59:59.999Z
export const endOfDay = (dt: Moment) => dt.endOf(EUnitOfTime.day).utc(false);

/**
 * Calculate date range from the pre-defined date range type.
 * In the IDateRangeOption interface,
 * it defines the `numOfDaysBeforeGivenDate`, `numOfDaysToGivenDate` and
 * `unitOfTime` the function below will follow below rule to calculate the
 * starting day and ending day. please refer to unit test cases for detail.
 */
export const getFilterDateRange = (
  {
    numOfDaysBeforeGivenDate,
    numOfDaysToGivenDate,
    unitOfTime,
  }: IDateRangeOption,
  timeRightNow: Moment
): IDateRange => ({
  startingDate: startOfDay(
    moment(timeRightNow).subtract(numOfDaysBeforeGivenDate, unitOfTime)
  ),
  endingDate: endOfDay(
    moment(timeRightNow).subtract(numOfDaysToGivenDate, unitOfTime)
  ),
});

export const filterBatchByCompletedTime = (dateRange: IDateRange) => (
  batch: IApiBatch
): boolean => {
  const completedMoment = moment(batch.completedTime as string);
  return (
    completedMoment.isSameOrAfter(dateRange.startingDate) &&
    completedMoment.isSameOrBefore(dateRange.endingDate)
  );
};

export const sortCompletedBatchByTime = (
  sortDirection: TableSortDirectionType
): ISortDateFunc => (batch1: IApiBatch, batch2: IApiBatch): number => {
  const completedTime1 = moment(batch1.completedTime as string);
  const completedTime2 = moment(batch2.completedTime as string);
  /**
   * If compareFunction(a, b) returns less than 0, sort a to an index lower than b (i.e. a comes first).
   * If compareFunction(a, b) returns 0, leave a and b unchanged with respect to each other, but sorted with respect to all different elements.
   * If compareFunction(a, b) returns greater than 0, sort b to an index lower than a (i.e. b comes first).
   */
  if (completedTime1.isSame(completedTime2)) {
    return 0;
  }
  if (completedTime1.isBefore(completedTime2)) {
    return (sortDirection  === ETableSortingDirection.ascending) ? -1 : 1;
  }
  return (sortDirection === ETableSortingDirection.ascending) ? 1 : -1;
};

export const filterAndSortCompletedBatches = (
  batches: IApiBatch[],
  dateSortingDirection: TableSortDirectionType,
  dateRangeType: EDateOptionType,
  searchText: string = '',
  timeRightNow: Moment = moment()
): IApiBatch[] => {
  let result = batches
    .filter(isBatchCompleted)
    .filter(
      filterBatchByCompletedTime(
        getFilterDateRange(dateRangeOptions[dateRangeType], timeRightNow)
      )
    )
    .sort(sortCompletedBatchByTime(dateSortingDirection));

  if (searchText.trim().length >= MIN_SEARCH_LENGTH) {
    result = result.filter(filterBySearchText(searchText));
  }
  return result;
};

export const filterBySearchText = (searchText: string) => (
  batch: IApiBatch
): boolean =>
  batch.productName.toLowerCase().indexOf(searchText.toLowerCase().trim()) > -1;

