import { EProductInventoryType, EUom, IApiProduct } from "../../typings/Interface";

export const createDefaultProduct = (name = ''): IApiProduct => {
  return {
    id: '',
    name: name,
    isBuy: false,
    isSell: false,
    costPrice: 0,
    inventoryType: EProductInventoryType.purchase,
    averageCostPrice: 0,
    unitPrice: 0,
    image: '',
    uom: EUom.unit,
    measure: 1,
  }
};
