import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  Caption,
  Paragraph,
} from '@kounta/chameleon';
import { NotesPrint } from '../../../components/notes/NotesPrint';
import { IApiRecipe } from '../../../typings/Interface';
import { AccordionParagraphContainer } from '../../../css/Styled';
import { EReadOnlyNotesPlaceHolder } from '../../../TextProvider';

interface IProps {
  selectedRecipe: IApiRecipe | false;
}

const AccordionBaseHeading = (<Paragraph>Instructions</Paragraph>);

export const RecipeInstruction = React.memo(
  ({ selectedRecipe }: IProps
  ): JSX.Element | null => {
    if (selectedRecipe === false) {
      return null;
    }
    return (
      <React.Fragment>
        <Accordion showBorder>
          <AccordionItem
            header={
              <div>
                {AccordionBaseHeading}
                <Caption>See all steps</Caption>
              </div>
            }
            headerOpened={AccordionBaseHeading}
          >
            <AccordionParagraphContainer>
              <NotesPrint
                note={selectedRecipe.note}
                placeHolderText={EReadOnlyNotesPlaceHolder.forRecipe}
              />
            </AccordionParagraphContainer>
          </AccordionItem>
        </Accordion>
      </React.Fragment>
    )
  }
);
