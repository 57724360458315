import {
  EReduxActionType,
  IActionLoadBillingPlan,
} from '../actions';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';

export const initialBillingPlan = null;

export default function billingPlanReducer(
  previousState: IApiBillingPlan | null = initialBillingPlan,
  action: IActionLoadBillingPlan,
): IApiBillingPlan | null {

  switch (action.type) {
    case EReduxActionType.LOAD_BILLING_PLAN:
      return action.billingPlan;
    default:
      return previousState;
  }
}
