import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { ELoadingTitle } from '../TextProvider';
import { EIllustrationName } from './Chameleon';
import Big from 'big.js';
import { EUomDisplay } from "../uom/Display";
import { GraphQLError } from "graphql/error/GraphQLError";

// Put all shared interface here.
// Each classes' props and state does not needs to be here,
// unless they are also shared in different files.

// use a type alias, but it wouldn't provide any compile time checks.
// Just a hint to the developer.
export type Uuid = string;

// Basic types for page initial:
/**
 * Cognito Object type example:
 *
 * {
 * "IdentityId": "us-west-2:cac3bb96-1c13-45a8-838b-f94904fe8c52",
 * "Token":
 *   "eyJraWQiOiJ1cy13ZXN0LTIxIiwidHlwIjoiSldTIiwiYWxnIjoiUlM1MTIifQ.eyJzdWIiOiJ1cy13ZXN0LTI6Y2FjM2JiOTYtMWMxMy00NWE4LTgzOGItZjk0OTA0ZmU4YzUyIiwiYXVkIjoidXMtd2VzdC0yOmZmZmIyZDZhLTQyYTUtNDFkOS1iYzMzLTBjZDYyMWM5NTIzZSIsImFtciI6WyJhdXRoZW50aWNhdGVkIiwibG9naW4ucHJvZHVjZS5rb3VudGEuY29tIiwibG9naW4ucHJvZHVjZS5rb3VudGEuY29tOnVzLXdlc3QtMjpmZmZiMmQ2YS00MmE1LTQxZDktYmMzMy0wY2Q2MjFjOTUyM2U6c3RhZmZVdWlkXzIwMjAwMDBiLTAwMDAtMjAwMC04MDAwLTAwMDAwMDAwMDA1OV9zaXRlVXVpZF8yMDIwMDAwNi0wMDAwLTIwMDAtODAwMC0wMDAwMDAwMDAwNGJfY29tcGFueVV1aWRfMjAxMDAwMDEtMDAwMC0yMDAwLTgwMDAtMDAwMDAwMDAwMDM3X2VuZCJdLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRlbnRpdHkuYW1hem9uYXdzLmNvbSIsImV4cCI6MTU0ODExOTAwNiwiaWF0IjoxNTQ4MDMyNjA2fQ.GIiTyVCiXWFUJtrnWA3rHmWu2HicFzPMv6dNE_DXrbDsXUL5r1orSRTySqbkptZ-Z-aF1Svn0EzYBqssXi_R7WqwmKPQ1Cq_3dDTMM3ffF9k7juqjhMKzyIlXTEiq-nJZ-fuQpdiBC5Gml4_6AuSrhJq-GyixdPsdeL30qGCyUF0q-pzT6Nneoqi1q9wk-jIStdWcU5r1GjTAUCQ0h-gkp8FKwEXdv8LfPqqa65GCerQirRQdxH9znP_ggWXvhCXnGTlAoGUNk8lPIFAD7VEsEury0_plSrl8XMdz9zgHEso4kz8mUrL3k7VdIUVfVY6Lnr95d0lUaEgwJmKRZWT_g",
 * "@metadata":
 *   {
 *     "statusCode": 200,
 *     "effectiveUri": "https://cognito-identity.us-west-2.amazonaws.com",
 *     "headers":
 *      {
 *       "date": "Mon,+21+Jan+2019+01:03:26+GMT",
 *       "content-type": "application/x-amz-json-1.1",
 *       "content-length": "1098",
 *       "connection": "keep-alive",
 *       "x-amzn-requestid": "5bc0bff8-1d18-11e9-8c52-eb99f94de4af"
 *     },
 *     "transferStats":
 *       {
 *         "http":
 *           [[]]
 *       }
 *   }
 * };
 */
export interface ICognitoTokenObject {
  readonly IdentityId: string;
  readonly Token: string;
  readonly '@metadata': {
    readonly statusCode: number;
    readonly effectiveUri: string;
    readonly headers: {
      readonly date: string;
      readonly 'content-type': string;
      readonly 'content-length': string;
      readonly connection: string;
      readonly 'x-amzn-requestid': Uuid;
    };
    readonly transferStats: {
      readonly http: [[]];
    };
  };
}

export interface ICookieStrings {
  cognitoObj: string;
}

export interface IAuthResult {
  authSucceed: boolean;
}

export interface ISiteInfo {
  readonly siteUuid: Uuid;
  readonly siteNumericId: number;
  readonly companyUuid: Uuid;
  readonly companyNumericId: number;
  readonly companyName: string;
  readonly siteName: string;
  readonly siteHasImageUrl: boolean;
  readonly siteImageUrl: string;
}

export interface IApiSubscribedSiteInfo {
  siteUuid: Uuid;
  siteName: string;
}

export interface ILastCompletedBatchInfo {
  batchUuid: Uuid;
  productName: string;
  actualYield: number;
  uom: EUom;
}

// All the available GraphQL query names:
export enum ESubscriptionQueryNames {
  updatedBatchBySite = 'updatedBatchBySite',
  updatedRecipeByCompany = 'updatedRecipeByCompany',
  updatedProductByCompany = 'updatedProductByCompany',
  subscribeBillingPlanBySite = 'subscribeBillingPlanBySite',
}

export enum EQueryNames {
  // Batches
  createBatch = 'createBatch',
  deleteBatch = 'deleteBatch',
  startBatch = 'startBatch',
  completeBatch = 'completeBatch',
  updateBatch = 'updateBatch',
  getBatches = 'getBatches',

  // Recipes
  getRecipesWithPaging = 'getRecipesWithPaging',
  getRecipeByProduct = 'getRecipeByProduct',
  saveRecipe = 'saveRecipe',
  deleteRecipe = 'deleteRecipe',

  // Products
  getProducts = 'getProducts',
  getAppSwitcherConfiguration = 'getAppSwitcherConfiguration',
  updateProductThroughApi = 'updateProductThroughApi',
  createProduct = 'createProductByBackOfficeApi',

  // Produce subscribed sites
  getProduceSites = 'getProduceSites',
  getSite = 'getSite',

  // Billing Plan:
  getBillingPlan = 'getBillingPlan',

  // Batch allowance:
  getRemainingBatchAllowance = 'getRemainingBatchAllowance',

  // Get Staff QL
  getStaff = 'getStaff',
}

// AppSync interfaces:
/**
 * https://docs.aws.amazon.com/appsync/latest/devguide/troubleshooting-and-common-mistakes.html
 *
 * "errors": [
 *  {
 *     "path": null,
 *     "locations": [
 *       {
 *         "line": 38,
 *         "column": 5,
 *         "sourceName": null
 *       }
 *     ],
 *     "message":
 *      "Validation error of type FieldUndefined: Field 'consumption_type' in
 * type 'recipe' is undefined @ 'getRecipes/consumption_type'"
 *   },
 *
 *
 *
 * `error`:
 * https://docs.aws.amazon.com/appsync/latest/devguide/troubleshooting-and-common-mistakes.html
 *
 * `loading`: not documented by AWS, but is a generic graphql response:
 * https://www.apollographql.com/docs/react/essentials/queries.html
 *
 * `data`: if query succeed, will contain the object with queryName as the key
 */

// TypeScript define array like `object[]` or `Array<object>`:
// https://www.typescriptlang.org/docs/handbook/basic-types.html
//
// However, below is using something not `officially supported`:
// "frontend/produce-webpage/node_modules/@aws-amplify/api/lib/types/index.d.ts"
// errors?: [object];
// Not sure if it is a bug? We have to follow it otherwise TS throw exception.
export interface IAppSyncBaseResponse extends GraphQLResult {
  data?: any;
  errors?: [GraphQLError];
  loading?: boolean;
}

export interface IAppSyncError extends IAppSyncBaseResponse {
  data: null;
  errors: [GraphQLError];
}

/**
 * Recipe related interfaces:
 */
export enum EConsumptionType {
  default = 0,
  premade = 1,
  madeToOrder = 2,
}

interface IAbstractRecipe {
  uuid: Uuid;
  consumptionType: EConsumptionType;
  expectedYield: number | string;
  recipeIngredients: IAbstractIngredient[];
  productUuid: Uuid;
  note: string;

  uom: EUom;
}

export interface ISaveRecipe extends IFormikRecipe {
  // unit cost is non-null if consumptionType === made to order
  unitCost: Big | null;
}

export interface IFormikRecipe extends IAbstractRecipe {
  expectedYield: string;
  recipeIngredients: IFormikIngredient[];
}

export interface IReduxDraftRecipe extends IAbstractRecipe {
  expectedYield: number;
  recipeIngredients: IRecipeIngredientsArgs[];
}

export interface IApiRecipe extends ICommonFields, IReduxDraftRecipe {
  companyUuid: Uuid;
  consumptionType: EConsumptionType;
  expectedYield: number;
  recipeIngredients: IApiRecipeIngredient[];
  note: string;
  siteUuid: Uuid;
}

interface IAbstractIngredient {
  componentQty: number | string;
  productUuid: Uuid;
  uom: EUom;
}

// Only Useful since Formik is using string for QTY, check
// DoesRecipeHaveUnsavedChanges.ts
export interface IFormikIngredient extends IAbstractIngredient {
  componentQty: string;
}

// Recipe Ingredients:
// Request object that GraphQl api will accepts when creating recipe.
// When creating, we don't provide ICommonFields:
export interface IRecipeIngredientsArgs extends IAbstractIngredient {
  componentQty: number;
}

// Response object from GraphQl, it includes ICommonFields:
export interface IApiRecipeIngredient
  extends ICommonFields,
    IRecipeIngredientsArgs {
  recipeUuid: Uuid;
}

/**
 * Batch related interfaces:
 */
export enum EBatchStatus {
  planned = 0,
  inProgress = 1,
  completed = 2,
}

export enum EBatchFormHeaderTitle {
  create = 'Create a batch',
  view = 'View batch',
  completeSingleBatch = 'Batch history',
}

export enum EBatchFormButtonText {
  save = 'Save batch',
  start = 'Start batch',
  cancel = 'Cancel batch',
  finish = 'Finish batch',
}

/**
 * Although Frontend will provide below value, but backend will not use it.
 * Backend already has its own logic to determine the value of this field.
 * Backend calculate this value by comparing existing batch and new batch.
 *
 * This field is provided by frontend IFormikBatch, since in ReactJs, the
 * FormikBatch not only create form submit, but also representing the form
 * initial context.
 *
 * While representing, we need to know if this batch ingredient
 * should show up as `custom ingredient` or `original ingredient`.
 */
export enum EBatchIngredientAdditionalFlag {
  // `original ingredient`
  origin = 0,
  // additional: custom ingredient
  additional = 1,
  // the ingredients before this change
  legacy = 2,
}

interface IAbstractBatchIngredient extends IAbstractIngredient {
  productName: string;
  componentQty: string | number;
  additionalIngredientFlag: EBatchIngredientAdditionalFlag;
}

// Only Useful since Formik is using string for QTY,
// check DoesBatchHaveUnsavedChanges.ts
export interface IFormikBatchIngredient extends IAbstractBatchIngredient {
  componentQty: string;
}

export interface IBatchIngredientsArgs extends IAbstractBatchIngredient {
  componentQty: number;
}

export interface IApiBatchIngredient
  extends ICommonFields,
    IBatchIngredientsArgs {
  batchUuid: Uuid;
  totalCost: number | null;
  unitCost: number | null;
}

export interface IApiBatch extends ICommonFields, IReduxDraftBatch {
  readonly companyUuid: Uuid;

  // Note: `siteUuid` is needed to trigger the Subscription updatedBatchBySite().
  readonly siteUuid: Uuid;
  readonly expectedYield: number;

  readonly completedTime: string | null;
  readonly startedTime: string | null;

  readonly batchIngredients: IApiBatchIngredient[];
  readonly totalCost: number | null;
  readonly unitCost: number | null;
  readonly isCustomYield: boolean;
}

interface IAbstractBatch {
  uuid: Uuid;
  recipeUuid: Uuid;
  productName: string;
  status: EBatchStatus;
  expectedYield: number;
  actualYield: number | null;
  plannedTime: string | null;
  note: string;

  batchIngredients: IAbstractBatchIngredient[];

  uom: EUom;
  isCustomYield: boolean;
}

// Only Useful since Formik is using string for Ingredients QTY,
// check DoesBatchHaveUnsavedChanges.ts
export interface IFormikBatch extends IAbstractBatch {
  batchIngredients: IFormikBatchIngredient[];
}

export interface IReduxDraftBatch extends IAbstractBatch {
  batchIngredients: IBatchIngredientsArgs[];
}

// This interface has to inline with the Chameleon `AdvancedSelect` component's props:
// https://chameleon.dev.kounta.com/3.41.6-7df3f2d/index.html#/Forms?id=advancedselect
export interface IUomDropDownOption {
  // The `display` text in the dropdown option:
  label: EUomDisplay;
  // The `value` and also `key` for the dropdown option:
  value: EUom;
}

// GraphQl results: Product
export enum EUom {
  ml = 'ml',
  l = 'l',
  g = 'g',
  kg = 'kg',
  unit = 'unit',
}

export enum EUomEditorType {
  create = 'create',
  update = 'update',
}

export enum EProductInventoryType {
  madeHere = 0,
  purchase = 1,
}

export enum ERouteBatchComponentType {
  CREATING = 0,
  INCOMPLETE,
}

export interface IUomEditorTextProvider {
  create: IUomEditorTextProviderContent;
  update: IUomEditorTextProviderContent;
}

export interface IUomEditorTextProviderContent {
  actionType: EUomEditorType;
  body: string;
  header: string;
  button: string;
}

export interface IApiProduct {
  readonly id: Uuid;
  readonly name: string;
  readonly isBuy: boolean;
  readonly isSell: boolean;
  readonly costPrice: number;
  readonly inventoryType: EProductInventoryType;
  readonly averageCostPrice: number;
  readonly unitPrice: number;
  readonly image: string;

  uom: EUom;
  measure: number;
}

export interface IProfitAndSellPrice {
  grossProfit: string | undefined;
  sellPrice: string | undefined;
}

export interface IRecipeCosts extends IProfitAndSellPrice {
  unitCost: Big;
  totalCost: Big | undefined;
}

// Below are the properties only for operation tracking purpose.
interface IOperatorTrackingFields {
  readonly updatedStaffUuid: Uuid;
  readonly updatedTime: string;
  readonly createdTime: string;
}

// This is to inline with `backend/appsync/schema/schema.graphql` -> CommonFields
interface ICommonFields extends IOperatorTrackingFields {
  // productUuid is not readonly because Ingredient can switch between products:
  productUuid: Uuid;
  // Redux draftRecipe can write uuid:
  uuid: Uuid;

  uom: EUom;
}

export interface IAlertDetail {
  topic: string;
  alertType: string;
  alertTime: number;
  message: string;
}

export interface IApiHandlerResult<T> {
  succeed: boolean;
  responseObj: T;
  errors: any;
  errorMsg: string;
}

export enum ESnackBarType {
  success = 'success',
  error = 'error',
}

export enum EModalType {
  SNACK_BAR = 'snack_bar',
  SHEET = 'sheet',
}

export enum EFormikIngredientsFieldName {
  recipeIngredients = 'recipeIngredients',
  batchIngredients = 'batchIngredients',
}

export interface IHandleModalParams {
  shouldShowModalYn?: boolean;
  snackBarMessage?: string;
  snackBarType?: ESnackBarType;
  completedBatchInfo?: ILastCompletedBatchInfo;
  modalType: EModalType;
}

export interface ILayoutUiControlFuncs {
  // UI controls for SnackBar and Modal:
  handleModal: (params: IHandleModalParams) => void;
  hideSnackBar: () => void;
  setLoading: (shouldShowLoading: boolean, loadingText?: ELoadingTitle) => void;
}

export interface IEmptyStateOption {
  heading: string;
  subheading: string;
  isUrl: boolean;
  imageUrl?: string;
  illustrationName?: EIllustrationName;
}

export interface IProduceLeftNavControl {
  showNav: () => void;
}

export enum EProducePermissionCode {
  ManagePrep = 'ManagePrep',
  ManageRecipes = 'ManageRecipes',
}

export interface IGraphQlSubscriptionFunction {
  (
    companyOrSiteUuid: Uuid,
    subscriptionCallBacks: () => Promise<void>
  ): ZenObservable.Subscription
}
