import React from 'react';
import {
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
} from "@kounta/chameleon";
import {
  EBatchIngredientAdditionalFlag,
  IFormikBatchIngredient
} from '../../../typings/Interface';
import { DEFAULT_INGREDIENTS_SECTION } from '../TextProvider';
import Uom from '../../../uom/Uom';
import { initialDraftFormikBatch } from '../../../formik/batch/InitialDraftBatch';

interface IProps {
  batchIngredients: IFormikBatchIngredient[];
}

const headers: TableHeaderType[] = [
  {
    id: '1',
    name: DEFAULT_INGREDIENTS_SECTION.ingredient.label,
    sortable: false,
  },
  {
    id: '2',
    name: DEFAULT_INGREDIENTS_SECTION.qty.label,
    sortable: false,
  },
  {
    id: '3',
    name: DEFAULT_INGREDIENTS_SECTION.unit.label,
    sortable: false,
  },
];

export const ReadOnlyIngredientsTable = React.memo(
  ({ batchIngredients }: IProps) => {
    let ings = batchIngredients;

    if (ings === initialDraftFormikBatch.batchIngredients) {
      ings = [
        {
          productName: DEFAULT_INGREDIENTS_SECTION.ingredient.placeHolder,
          componentQty: DEFAULT_INGREDIENTS_SECTION.qty.placeHolder,
          uom: DEFAULT_INGREDIENTS_SECTION.unit.placeHolder,
          productUuid: '',

          // For now, user can ONLY add EBatchIngredientAdditionalFlag.origin:
          additionalIngredientFlag: EBatchIngredientAdditionalFlag.origin,
        }
      ];
    }

    const ingredientRows = ings.map(
      (ing: IFormikBatchIngredient, index: number): TableRowType => ({
        id: index,
        content:
          [
            {
              id: '1',
              value: ing.productName,
            },
            {
              id: '2',
              value: ing.componentQty,
            },
            {
              id: '3',
              value: new Uom(ing.uom).displayFromUom,
            },
          ]
      })
    );

    return (
      <React.Fragment>
        <Spacer spacing={'medium'}/>
        <Table
          headers={headers}
          rows={ingredientRows}
          showHover={false}
          showRowIndicator={false}
          autoWidth={false}
        />
      </React.Fragment>
    )
  }
);
