import moment from 'moment';
import { EBatchStatus, IApiBatch, IApiProduct } from '../../typings/Interface';
import { isProductDeleted } from '../product/GetProductByUuidFromList';

const generateCompareFunction = (
  batchStatus: EBatchStatus
): ((batch1: IApiBatch, batch2: IApiBatch) => number) => {
  return (batch1: IApiBatch, batch2: IApiBatch): number => {
    if (
      batchStatus === EBatchStatus.inProgress &&
      batch1.startedTime &&
      batch2.startedTime
    ) {
      // PRO-135: In Progress segment of production list is sorted by
      // started_time descending i.e. most recently started batch appears
      // first (at the top)
      return moment
        .utc(batch2.startedTime)
        .diff(moment.utc(batch1.startedTime));
    } else if (
      batchStatus === EBatchStatus.planned &&
      batch1.plannedTime &&
      batch2.plannedTime
    ) {
      // PRO-135: Planned segment of production list is sorted by planned_time
      // ascending i.e. today’s batches followed by tomorrow’s batches etc
      // (if they exist) appears first (at the top)
      return moment
        .utc(batch1.plannedTime)
        .diff(moment.utc(batch2.plannedTime));
    }
    return 0;
  };
};

// This function do 3 things:
// 1. `Planned` batches need to be checked for deleted products. (PRO-276);
// 2. Return the batches that match the given batchStatus;
// 3. Sorting result by time;
export const getValidBatchesByStatusAndSortByTime = (
  batches: IApiBatch[],
  batchStatus: EBatchStatus,
  products: IApiProduct[]
): IApiBatch[] => {
  const filteredBatches = batches.filter(
    (batch: IApiBatch): boolean => {
      if (batchStatus === EBatchStatus.planned) {
        // PRO-276: Only `Planned` batches need to be checked for deleted products.
        return (
          batch.status === batchStatus &&
          !isProductDeleted(batch.productUuid, products)
        );
      }
      return batch.status === batchStatus;
    }
  );

  return filteredBatches.sort(generateCompareFunction(batchStatus));
};
