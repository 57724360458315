import { EUom, IApiProduct, Uuid } from '../../typings/Interface';
import { EMPTY_INGREDIENT_PRODUCT_UUID } from '../../formik/recipe/InitialDraftRecipe';

export const getProductByUuidFromList = (
  selectedProductUuid: Uuid,
  products: IApiProduct[],
): IApiProduct | false => {
  const matchedProduct = products.find(
    (item: IApiProduct): boolean => item.id === selectedProductUuid
  );
  if (matchedProduct === undefined) {
    return false;
  }
  return matchedProduct;
};

export const getProductUomByUuidFromList = (
  selectedProductUuid: Uuid,
  products: IApiProduct[]
): EUom | false => {
  const matchingProduct = getProductByUuidFromList(
    selectedProductUuid,
    products
  );
  if (matchingProduct === false) {
    return false;
  }
  return matchingProduct.uom;
};

export const getProductNameByUuidFromList = (
  selectedProductUuid: Uuid,
  products: IApiProduct[]
): string => {
  const matchingProduct = getProductByUuidFromList(
    selectedProductUuid,
    products
  );
  if (matchingProduct === false) {
    return '';
  }
  return matchingProduct.name;
};

export const getProductByNameFromList = (
  selectedProductName: string,
  products: IApiProduct[]
): IApiProduct | false => {
  const matchedProduct = products.find(
    (item: IApiProduct): boolean => item.name === selectedProductName
  );
  if (matchedProduct) {
    return matchedProduct;
  }
  return false;
};

export const isProductDeleted = (
  productUuid: Uuid,
  products: IApiProduct[]
): boolean => {
  const product = getProductByUuidFromList(productUuid, products);
  return checkIsSingleProductDeleted(productUuid, product);
};

export const checkIsSingleProductDeleted = (
  productUuid: Uuid,
  product: IApiProduct | false
): boolean =>
  !product &&
  // we exclude the EMPTY_INGREDIENT_PRODUCT_UUID while checking:
  productUuid !== EMPTY_INGREDIENT_PRODUCT_UUID;
