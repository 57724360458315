import { startBatch } from '../../graphql/BatchQL';
import { ESnackBarMessage } from '../../TextProvider';
import {
  EBatchStatus,
  EModalType,
  ESnackBarType,
  IApiBatch,
  IFormikBatch,
  ILayoutUiControlFuncs,
} from '../../typings/Interface';

export const startBatchAction = (
  existingBatch: IFormikBatch | false,
  handleModal: ILayoutUiControlFuncs['handleModal'],
  updateExistingBatch: (existingBatch: IApiBatch) => void,
  handleBatchStatusChange: (productionType: { id: EBatchStatus }) => void
) => {
  return async (): Promise<void> => {
    if (existingBatch === false) {
      throw new Error(`Cannot start a batch doesn't exist`);
    }

    const startResult = await startBatch(existingBatch.uuid);

    if (startResult.succeed) {
      updateExistingBatch(startResult.responseObj);
      handleModal({
        snackBarMessage: ESnackBarMessage.START_BATCH_SUCCEED,
        snackBarType: ESnackBarType.success,
        modalType: EModalType.SNACK_BAR,
      });
      handleBatchStatusChange({ id: EBatchStatus.planned });
    }
    // else {
    //   // Todo: show error flash message
    // }
  };
};
