import {
  EQueryNames,
  ESubscriptionQueryNames,
  IApiBatch,
  IApiHandlerResult,
  IApiRecipe,
  IAppSyncBaseResponse,
} from '../typings/Interface';
import InternalError, { InternalErrorCode } from '../error/InternalError';
import { awsGraphQlClient } from './AwsGraphQlClient';
import { redirectToBackOfficeProduceLoginPage } from '../utils/url/UrlRedirect';
import Observable from 'zen-observable-ts';
import { GraphQLResult } from "@aws-amplify/api-graphql/src/types/index";

const FAILED_RESPONSE: IApiHandlerResult<null> = {
  succeed: false,
  responseObj: null,
  errors: null,
  errorMsg: 'default error',
};

export const graphQlApiHandler = async <T>(
  fullQueryString: string,
  queryName: EQueryNames,
  variables?: {},
): Promise<IApiHandlerResult<T>> => {
  /**
   * Attention: Never do this:
   * const result = FAILED_RESPONSE;
   * Because it will mutate the original object FAILED_RESPONSE
   *
   * ES6 offers two shallow copy mechanisms: Object.assign() and the spread
   * operator:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
   *
   * Further read:
   * https://stackoverflow.com/questions/19448646/js-object-copy-by-value-vs-copy-by-reference
   */
  const result: IApiHandlerResult<any> = { ...FAILED_RESPONSE } as IApiHandlerResult<null>;

  let appSyncResponseObject: IAppSyncBaseResponse = {};

  try {
    // By calling any graphql api, we automatically activate
    // `currentCredentials()`, it will trigger the `refreshTokenHandler` if the
    // credentials expired.
    appSyncResponseObject =
      (await awsGraphQlClient(fullQueryString, variables)) as IAppSyncBaseResponse;
  } catch (e: any) {
    if (e && e?.errors && e?.errors[0] && e?.errors[0]?.message) {
      /**
       * ERR_INTERNET_DISCONNECTED is also captured here with below error obj:
       * e.errors === [{"message":"Network Error"}]
       */
      result.errorMsg = `${e?.errors[0]?.message}. Total number of errors: ${
        e?.errors?.length
      }`;
      result.errors = e.errors;
    } else {
      result.errorMsg = 'Unexpected error';
    }
    return result;
  }

  if (appSyncResponseObject.errors) {
    result.errors = appSyncResponseObject.errors;
    if (appSyncResponseObject.errors[0]) {
      result.errorMsg = appSyncResponseObject.errors[0].message;
    }
    return result;
  }

  if (appSyncResponseObject.data && appSyncResponseObject.data[queryName]) {
    result.succeed = true;
    result.responseObj = appSyncResponseObject.data[queryName];
    result.errorMsg = '';
  } else {
    result.succeed = false;
    result.errorMsg = 'api response data is null or undefined';
  }
  return result as IApiHandlerResult<T>;
};

export const subscriptionErrorHandler = () => {
  redirectToBackOfficeProduceLoginPage();
};

/**
 * For Type issue regarding `Observable`:
 * https://github.com/aws-amplify/amplify-js/issues/5741#issuecomment-626876793
 */
export const graphQlSubscriptionHandler = (
  fullQueryString: string,
  queryName: ESubscriptionQueryNames,
  subscriptionCallBacks: () => Promise<void>
): ZenObservable.Subscription => {
  let observable;

  try {
    // `frontend/produce-webpage/node_modules/@aws-amplify/api/src/API.ts`
    // --> graphql() has the return type Promise<GraphQLResult> | Observable<object>.
    observable = (awsGraphQlClient(fullQueryString));
  } catch (e) {
    throw new InternalError(
      `Subscription ${queryName} errored out: ${e}`,
      InternalErrorCode.GraphqlSubscriptionFailed
    );
  }

  // Understanding `Observable`:
  // https://aws-amplify.github.io/docs/js/api#subscriptions
  // https://www.learnrxjs.io/concepts/rxjs-primer.html
  // https://medium.com/@benlesh/learning-observable-by-building-observable-d5da57405d871
  // https://coryrylan.com/blog/javascript-promises-versus-rxjs-observables
  // https://x-team.com/blog/rxjs-observables/
  const subscription = (observable as Observable<object>).subscribe({
    // on successful emissions:
    next: async (apiResponse: IApiBatch | IApiRecipe) => {
      await subscriptionCallBacks();
    },
    // on errors: just redirectToBackoffice for re-login:
    error: (errorResponse: GraphQLResult) => {
      // console.log(`Subscription Error: ${queryName}`);

      if (errorResponse &&
        errorResponse.errors &&
        errorResponse.errors[0] &&
        errorResponse.errors[0].message
      ) {
        // eslint-disable-next-line no-console
        console.log(errorResponse.errors[0].message);
      }

      subscriptionErrorHandler();
    },

    // called once on completion
    // complete: () => console.log('complete!')
  });
  return subscription;
  // clean up with unsubscribe
  // Stop receiving data updates from the subscription
  // subscription.unsubscribe();
};
