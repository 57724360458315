import styled from 'styled-components';

/** @component */
export const PageContent = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    max-width: 768px;
  }
`;
