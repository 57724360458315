import {Alignment, AppHeader, Group, Search, SelectOptionType, Spacer} from "@kounta/chameleon";
import React, {useState} from "react";
import {IRouteHistoryPushFunction} from "../../typings/ReactRouter";
import {IApiProduct} from "../../typings/Interface";
import {
  CREATE_RECIPE_HEADER,
  EProductInventoryTypeOrAll,
  ERecipesListSearchEntity,
  PRODUCT_FILTER_OPTIONS
} from "../../TextProvider";
import ContainerMaxWidth from "../../containers/ContainerMaxWidth";
import {ProductsTable} from "./components/ProductsTable";
import {
  filterProducts,
  productMatchingInventoryType,
  productMatchingSearchText
} from "../../utils/recipe/FilterProducts";
import {goToSingleRecipePageWithQuery} from "../../utils/recipe/RecipesQueryParam";
import {AppHeaderLeftContent} from "../../components/app-header-bar/AppHeaderLeftContent";
import {FilterText} from "./Style";
import {Filter} from "./components/Filter";
import {EProductInventoryType} from "../../graphql/ProductQL";

interface IProps {
    routeHistoryPush: IRouteHistoryPushFunction;
    productsWithOutRecipe: IApiProduct[];
    goToRecipesListPageWithQuery: () => void;
  }
  
  interface ISearchTextStatus {
    displayText: string,
    searchText: string,
  }


  export const CreateRecipe = React.memo((
    {
      goToRecipesListPageWithQuery,
      routeHistoryPush,
      productsWithOutRecipe,
    }: IProps
  ): JSX.Element => {
    const BEGIN_SEARCH = 3;
    const [searchInputs, setSearchInputs] = useState<ISearchTextStatus>({
      displayText: '',
      searchText: '',
    });
    const [selectedSearchDropdown, setSelectedSearchDropdown] = useState<EProductInventoryTypeOrAll>('All');

    const handleProductFilterSelect = (selectedOption: SelectOptionType) => {
      if (Number(selectedOption.id) === EProductInventoryType.purchase) {
        setSelectedSearchDropdown(EProductInventoryType.purchase);
      } else if (Number(selectedOption.id) === EProductInventoryType.madeHere) {
        setSelectedSearchDropdown(EProductInventoryType.madeHere);
      } else {
        setSelectedSearchDropdown('All')
      }
    }

    const handleClearSearch = () => {
      setSearchInputs({
        displayText: '',
        searchText: '',
      })
    };
  
    const handleSearch = (text: string) => {
      if (text.length >= BEGIN_SEARCH) {
        setSearchInputs({
          displayText: text,
          searchText: text,
        });
        return;
      }
      setSearchInputs({
        displayText: text,
        searchText: '',
      });
    };
  
    const conformProductsMatchSearchText = filterProducts(
      productsWithOutRecipe,
      productMatchingSearchText(searchInputs.searchText),
      productMatchingInventoryType(selectedSearchDropdown)
    )
  
    return (
      <>
        <AppHeader
          title={CREATE_RECIPE_HEADER}
          leftContent={
            <AppHeaderLeftContent
              goBackHandler={goToRecipesListPageWithQuery}
            />
          }
        />
        <ContainerMaxWidth>
          <Group horizontal full spacing="24px">
            <Alignment vertical="top" horizontal="left">
              <Search
                onChange={handleSearch}
                searchText={searchInputs.displayText}
                onClear={handleClearSearch}
              />
            </Alignment>
            <Alignment vertical="top" horizontal="right">
              <FilterText>Filter by</FilterText>
              <Filter
                filterOptions={PRODUCT_FILTER_OPTIONS}
                currentFilterCriteria={selectedSearchDropdown.toString()}
                handleFilterSelect={handleProductFilterSelect}
              />
            </Alignment>
            <Alignment vertical="top" horizontal="left"/>
          </Group>
          <Spacer/>
          <ProductsTable
            conformProducts={conformProductsMatchSearchText}
            goToSingleRecipePageWithQueryByProductUuid={goToSingleRecipePageWithQuery(
              routeHistoryPush,
              null,
              null,
              ERecipesListSearchEntity.recipes,
            )}
          />
        </ContainerMaxWidth>
      </>
    )
  })

CreateRecipe.displayName = 'createRecipe';