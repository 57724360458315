import { EUom, IUomDropDownOption } from '../typings/Interface';

export enum EUomDisplay {
  ml = 'mL',
  l = 'L',
  g = 'g',
  kg = 'kg',
  unit = 'Units',
}

export const displayDropdownOptionsSet = (
  uomList: EUom[]
): IUomDropDownOption[] =>
  uomList.map(
    (uom: EUom): IUomDropDownOption => ({
      label: EUomDisplay[uom],
      value: uom,
    })
  );
