import React from 'react';
import {
  Heading3,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
} from "@kounta/chameleon";
import { BATCH_DETAIL } from '../TextProvider';
import { DateRender } from '../../../components/date-time/DateRender';
import { longDateFormat } from '../../../TextProvider';
import { SectionPaddingLeft } from '../../../css/Styled';

interface IProps {
  plannedDate: string | null;
  productionDate: string | null;
}

const headers: TableHeaderType[] = [
  {
    id: '1',
    name: BATCH_DETAIL.datesSection.labels.plannedDate,
    sortable: false,
  },
  {
    id: '2',
    name: BATCH_DETAIL.datesSection.labels.productionDate,
    sortable: false,
  },
];

export const DatesSection = React.memo((props: IProps) => {
    const rows: TableRowType[] = [
      {
        id: '1',
        content:
          [
            {
              id: '1',
              value: <DateRender
                dateStr={props.plannedDate}
                dtFormat={longDateFormat}
              />
            },
            {
              id: '2',
              value: <DateRender
                dateStr={props.productionDate}
              />
            },
          ]
      },
    ];
    return (
      <React.Fragment>
        <SectionPaddingLeft>
          <Heading3>{BATCH_DETAIL.datesSection.title}</Heading3>
        </SectionPaddingLeft>
        <Spacer spacing={'medium'}/>
        <Table
          headers={headers}
          rows={rows}
          showHover={false}
          showRowIndicator={false}
        />
      </React.Fragment>
    )
  }
);
