import { EUom } from '../typings/Interface';
import InternalError, { InternalErrorCode } from '../error/InternalError';

// This file is the same as `backend/appsync/src/uom/uom.ts`.
// Currently we haven't decide how we can share files between frontend & backend

export enum EMass {
  g = EUom.g,
  kg = EUom.kg,
}

export enum EVolume {
  ml = EUom.ml,
  l = EUom.l,
}

export function acceptableUnits(fromUom: EUom | null = null): EUom[] {
  if (fromUom === null) {
    return Object.values(EUom);
  }
  if (fromUom === EUom.unit) {
    return [EUom.unit];
  }
  if (Object.values(EMass).includes(fromUom)) {
    // https://kounta.atlassian.net/browse/PRO-186
    // `unit` is always an acceptable uom:
    return (Object.values(EMass) as EUom[]).concat([EUom.unit]);
  }
  if (Object.values(EVolume).includes(fromUom)) {
    return (Object.values(EVolume) as EUom[]).concat([EUom.unit]);
  }
  throw new InternalError('fromUom not supported', InternalErrorCode.UomError);
}

export enum EUomCategory {
  UNIT = 'units',
  VOLUME = 'volume',
  WEIGHT = 'weight',
}

export const EUomOptionsByCategory: {[key: string]: EUom[] } = {
  [EUomCategory.UNIT]: [EUom.unit],
  [EUomCategory.VOLUME]: Object.values(EVolume) as EUom[],
  [EUomCategory.WEIGHT]: Object.values(EMass) as EUom[],
};
