import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { handleUomChange } from '../../formik/SetFieldValueImperatively';
import Uom from '../../uom/Uom';
import { UOM_TOOLTIP_TARGET_CLASS } from '../../TextProvider';
import { UomDropDown } from '../uom/UomDropDown';
import { IApiProduct, IUomDropDownOption } from "../../typings/Interface";

interface IProps {
  fieldNamePrefix: string;
  arrayIndex: number;
  product: IApiProduct | false;
}

export default React.memo((props: IProps): JSX.Element => {
  
  const renderUomDropdown = ({ field, form }: FieldProps): JSX.Element => {
    const defaultValue: IUomDropDownOption = {
      label: new Uom(field.value).displayFromUom,
      value: field.value,
    };
    
    const onChange = handleUomChange(form, field);
  
    /**
     * Notice: this key={defaultValue.value} is unique in current row (TableCell in chameleon)
     *
     * we add this because of when we update current DropDown component with new props,
     * the AdvancedSelect's defaultOption still using the old value.
     * which mean after re-render it is not updated the default-option in AdvanceSelect.
     * and the #key prop is using for force it to create a new component.
     *
     * the happen is occurring when we create new Product in Auto-complete.
     * follow ticket: https://kounta.atlassian.net/browse/PRO-522
     */
    return (
      <UomDropDown
        key={defaultValue.value}
        defaultOption={defaultValue}
        changeFormHandler={onChange}
        className={UOM_TOOLTIP_TARGET_CLASS}
        product={props.product}
      />
    );
  };
  
  return (
    <Field name={`${props.fieldNamePrefix}.${props.arrayIndex}.uom`}>
      {renderUomDropdown}
    </Field>
  )
});
