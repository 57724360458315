import {
  IFormikBatchIngredient
} from '../typings/Interface';
import { Big } from 'big.js';

const MAX_DECIMAL_PLACES = 4;

// Only prepare new Batch Ingredients' Qty, not handling actual update:
export const updateBatchIngredientsQtyByCustomYield = (
  benchmarkFormikBatchYield: number,
  benchmarkFormikBatchIngs: IFormikBatchIngredient[],
  customYield: number,
): IFormikBatchIngredient[] => {

  const benchmarkBatchYield = (new Big(benchmarkFormikBatchYield));

  const ratio = benchmarkBatchYield.div(customYield);
  return benchmarkFormikBatchIngs.map(
    (e: IFormikBatchIngredient): IFormikBatchIngredient => {
      const withTrailingZeros = (new Big(e.componentQty)).div(ratio).toFixed(MAX_DECIMAL_PLACES);
      const removeTrailingZeros = parseFloat(withTrailingZeros);

      /**
       * Attention!
       * Don't mutate the `e`, this is wrong: const newIng = e;
       *
       * Only clone the `e` using es6 spreading operation:
       */
      const newIng = { ...e };
      newIng.componentQty = removeTrailingZeros.toString();
      return newIng;
    });
};
