import * as React from 'react';
import { Button } from '@kounta/chameleon';
import { ERecipeFormButtonText } from '../../../TextProvider';

interface IProps {
  disableSaveYn: boolean;
}

// Notes: The `onClick` callback from <Button> is not used.
// We are setting this button type to `submit`, so <Formik> `onSubmit` will be
// triggered automatically when this button is clicked.
// Please check 'RecipeForm.tsx'.
export const SaveRecipeButton = React.memo(
  ({ disableSaveYn }: IProps): JSX.Element => {
    return (
      <Button
        slim={true}
        primary={true}
        disabled={disableSaveYn}
        type={'submit'}
      >
        {ERecipeFormButtonText.save}
      </Button>
    )
  }
);
