import {
  E3rdPartyLinks,
  EBackofficeLinks,
  ERouterUrlPath,
} from '../../TextProvider';
import { BACKOFFICE_HOST } from '../../config/release';
import { Path } from 'history';
import { Uuid } from '../../typings/Interface';
import { redirectToUrl } from './Window';

export const redirectTo3rdPartyPage = (thirdPartyPage: E3rdPartyLinks): string => {
  return redirectToUrl(thirdPartyPage);
};

export const redirectToBakOfficePage = (
  backofficePage: EBackofficeLinks,
  suffix: string = ''
): string => {
  return redirectToUrl(`${BACKOFFICE_HOST}${backofficePage}${suffix}`);
};

export const redirectToBackOfficeProduceLoginPage = (): string => {
  return redirectToBakOfficePage(EBackofficeLinks.assignCognitoPage);
};

export const redirectToInventoryDash = (): void => {
  redirectToBakOfficePage(EBackofficeLinks.inventoryDash);
};

export const redirectToBackOfficeProducePermissionDeniedPage = (): string => {
  return redirectToBakOfficePage(EBackofficeLinks.producePermissionDeniedPage);
};

export const redirectToBackOfficeProducePlanPage = (): string => {
  return redirectToBakOfficePage(EBackofficeLinks.producePlanPage);
};

export const redirectToBackOfficeProductsPage = (): string => {
  return redirectToBakOfficePage(EBackofficeLinks.productPage);
};

export const redirectToFeedbackForum = (): string => {
  return redirectTo3rdPartyPage(E3rdPartyLinks.produceFeedbackPage);
};

export const pageReload = (): void => {
  window.location.reload();
};

export const redirectToSingleCompletedBatch = (
  routerHistoryPush: (path: Path) => void,
  batchUuid: Uuid,
): Function => {
  return () => {
    routerHistoryPush(
      `${ERouterUrlPath.completedSingleBatch}${batchUuid}`
    );
  }
};
