import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Group, Loading, Modal, Portal } from '@kounta/chameleon';
import {
  EBatchStatus,
  IApiBatch,
  IApiProduct,
  IApiRecipe,
  IFormikBatch,
  IFormikBatchIngredient,
  ILayoutUiControlFuncs,
} from '../../typings/Interface';
import { NOTE_TEXT, NotesInput } from '../../components/notes/NotesInput';
import { ELoadingTitle, INVENTORY_DASH_ORIGIN } from '../../TextProvider';
import { initialDraftFormikBatch } from '../../formik/batch/InitialDraftBatch';
import { ActualYieldInputModal } from './single-view/ActualYieldInputModal';
import { Form, Formik } from 'formik';
import { BatchSchema } from '../../validation/BatchSchema';
import BatchAppHeaderBar
  from '../../components/app-header-bar/BatchAppHeaderBar';
import {
  PageContainer,
  PageContent,
  PageLayout,
} from '../../components/page-container';
import BatchMainProductName from './single-view/BatchMainProductName';
import { IBatchFormikProps } from '../../typings/Formik';
import { FULL_HEIGHT } from '../../css/Styled';
import { RecipeInstruction } from './single-view/RecipeInstruction';
import getRecipeByRecipeUuid from '../../utils/recipe/GetRecipeByRecipeUuid';
import { convertRecipeIngsToBatchIngs } from '../../formik/batch/ConvertRecipeToBatch';
import { BatchIngredientSection } from './single-view/BatchIngredientSection';
import { handleCompleteBatchActionButton } from '../../utils/batch/CompleteBatchAction';
import { RemainingBatchAllowance } from '../../components/cards/RemainingBatchAllowance';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { isBatchAllowanceExhausted } from '../../utils/plan-restriction/IsBatchAllowanceExhausted';
import { redirectToInventoryDash } from "../../utils/url/UrlRedirect";
import { IBatchesParams } from "../../utils/batch/BatchesQueryParams";

interface IProps extends IBatchesParams{
  /**
   * This `existingBatch` always shows the last saved (unless `false`) batch.
   * A successfully `saveBatch` or `createBatch` event will update this field.
   * We can use this as the base line when doing custom batch calculation.
   */
  existingBatch: IFormikBatch | false;

  recipes: IApiRecipe[];
  products: IApiProduct[];
  updateExistingBatch: (existingBatch: IApiBatch) => void;
  handleBatchStatusChange: (productionType: { id: EBatchStatus }) => void;

  // UI controls for SnackBar:
  handleModal: ILayoutUiControlFuncs['handleModal'];
  setLoading: ILayoutUiControlFuncs['setLoading'];

  routeComponentProps: RouteComponentProps;

  // Redux flag:
  isProductsFullyLoaded: boolean;

  // Batch Restriction based on the site plan
  remainingBatchAllowance: number;
  billingPlan: IApiBillingPlan;

  headerButtonText: string;
}

interface IState {
  shouldShowOtherModalYn: boolean;
  modalOverlay: boolean;
  modalTitle: string;
  modalContent: JSX.Element | string | null;
  modalFooter: JSX.Element | null;

  // Show actualYield input modal:
  shouldShowActualYieldModal: boolean;
}

export default class BatchForm extends React.Component<IProps, IState> {
  readonly state: IState = {
    // other modals that are not `ActualYieldInput` Modal
    shouldShowOtherModalYn: false,
    modalOverlay: false,
    modalTitle: '',
    modalContent: null,
    modalFooter: null,

    // Show actualYield input modal:
    shouldShowActualYieldModal: false,
  };

  public render() {
    // 1. When existingBatch === false, it should load initialDraft
    // 2. Otherwise, just use existingBatch. The deleted products have already
    //    been removed in upper level.
    const currentBatch: IFormikBatch = this.props.existingBatch || initialDraftFormikBatch;

    return (
      <Formik
        initialValues={currentBatch}
        enableReinitialize={true}
        validationSchema={BatchSchema}
        onSubmit={this.emptyFunction}
        component={this.singleBatchForm}
      />
    );
  }

  private singleBatchForm = (
    {
      setFieldValue,
      values,
      resetForm,
      validateForm,
      submitForm,
      errors,

      // TODO: Using `dirty` to check if values are
      // not deeply equal from initial values: dirty,
    }: IBatchFormikProps): JSX.Element => {

    // if `create batch`, `selectedRecipe` will be `false`, until user select
    // a recipe from `main product name dropdown`.
    const selectedRecipe: false | IApiRecipe = getRecipeByRecipeUuid(
      values.recipeUuid,
      this.props.recipes,
    );

    let benchmarkBatchIngs: IFormikBatchIngredient[] = [];
    if (selectedRecipe !== false) {
      // Two cases will come here:
      // 1. Viewing existing batch;
      // 2. Creating a batch, and already selected a Recipe;
      // NOTE: this BatchIngs is converted directly from the selected Recipe.
      // It is NOT using customised batch ings.
      benchmarkBatchIngs = convertRecipeIngsToBatchIngs(
        selectedRecipe,
        this.props.products,
      );
    }

    let isCustomBatchSectionReadOnly = true;
    if ((values !== initialDraftFormikBatch) &&
      (this.props.existingBatch === false ||
        this.props.existingBatch.status === EBatchStatus.planned)
    ) {
      // Only `create a new batch` or `update planned batch` allow modifying
      // Custom batch yield
      isCustomBatchSectionReadOnly = false;
    }

    if (!this.props.existingBatch && !this.props.isProductsFullyLoaded) {
      // this.props.existingBatch === false means it is `create batch`, and
      // user hasn't select any recipe yet.
      return (
        <Loading
          isLoading
          title={ELoadingTitle.loading}
        />
      );
    }

    const batchAllowanceExhausted = isBatchAllowanceExhausted(
      this.props.billingPlan.produce.newPlanId,
        this.props.remainingBatchAllowance,
    );
    
    let goBackFunc = this.props.routeComponentProps.history.goBack;
    
    if (this.props.originFrom === INVENTORY_DASH_ORIGIN) {
      goBackFunc = redirectToInventoryDash;
    }


    return (
      <Form style={FULL_HEIGHT}>
        <PageLayout>
          <BatchAppHeaderBar
            existingBatch={this.props.existingBatch}
            updateExistingBatch={this.props.updateExistingBatch}
            handleBatchStatusChange={this.props.handleBatchStatusChange}
            handleModal={this.props.handleModal}
            getToggleActualYieldModalFunction={
              this.getToggleActualYieldModalFunction
            }
            formValues={values}
            validateForm={validateForm}
            submitForm={submitForm}
            renderModal={this.renderModal}
            goBack={goBackFunc}
            hideModal={this.hideModal}
            setLoading={this.props.setLoading}
            isBatchAllowanceExhausted={batchAllowanceExhausted}
            goBackButtonText={this.props.headerButtonText}
          />
          <PageContainer>
            <PageContent>
              <RemainingBatchAllowance
                billingPlan={this.props.billingPlan}
                remainingBatchAllowance={this.props.remainingBatchAllowance}
              />
              <Group spacing="24px" className={'form-content'}>
                <BatchMainProductName
                  products={this.props.products}
                  recipes={this.props.recipes}
                  existingBatch={this.props.existingBatch}
                  resetForm={resetForm}
                  values={values}
                  isSearchable={!batchAllowanceExhausted}
                />
                <BatchIngredientSection
                  values={values}
                  setFieldValue={setFieldValue}
                  benchmarkBatchIngs={benchmarkBatchIngs}
                  errors={errors}
                  isCustomBatchSectionReadOnly={isCustomBatchSectionReadOnly}
                  selectedRecipe={selectedRecipe}
                  existingFormikBatch={this.props.existingBatch}
                  isProductsFullyLoaded={this.props.isProductsFullyLoaded}
                />
                <RecipeInstruction selectedRecipe={selectedRecipe}/>

                <NotesInput
                  label={NOTE_TEXT.batch.label}
                  fieldName={'note'}
                  placeHolder={NOTE_TEXT.batch.placeHolder}
                  readOnly={batchAllowanceExhausted}
                />

                {this.state.shouldShowActualYieldModal && (
                  <ActualYieldInputModal
                    handleCompleteBatchActionButton={
                      handleCompleteBatchActionButton(
                        values,
                        this.getToggleActualYieldModalFunction,
                        this.props.handleBatchStatusChange,
                        this.props.handleModal,
                        this.props.routeComponentProps.history.goBack,
                      )
                    }
                    hideFinishBatchYieldModal={this.getToggleActualYieldModalFunction(
                      false
                    )}
                    uom={values.uom}
                    initialValue={values.actualYield ?? 0}
                  />
                )}
                {this.state.shouldShowOtherModalYn && (
                  <Portal>
                    <Modal
                      overlay={this.state.modalOverlay}
                      title={this.state.modalTitle}
                      footer={this.state.modalFooter}
                      actionClose={this.hideModal}
                    >
                      {this.state.modalContent}
                    </Modal>
                  </Portal>
                )}
              </Group>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </Form>
    );
  };

  // This is a workaround, since Formik cannot omit the `onSubmit` prop
  private emptyFunction = async (values: IFormikBatch): Promise<boolean> => {
    return false;
  };

  // Return a Function so that it can be passed as a eventHandler.
  // see:
  // hideFinishBatchYieldModal={this.getToggleActualYieldModalFunction(false)}
  private getToggleActualYieldModalFunction = (
    shouldShowActualYieldModal: boolean
  ) => {
    return () => {
      this.setState({
        shouldShowActualYieldModal,
      });
    };
  };

  private renderModal = (
    overlay: boolean,
    title: string,
    content: JSX.Element | string | null,
    footer: JSX.Element | null
  ) => {
    this.setState({
      shouldShowOtherModalYn: true,
      modalOverlay: overlay,
      modalTitle: title,
      modalContent: content,
      modalFooter: footer,
    });
  };

  private hideModal = () => {
    this.setState({
      shouldShowOtherModalYn: false,
    });
  };
}
