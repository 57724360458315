import {
  EReduxActionType,
  IActionSetRemainingBatchAllowance
} from '../actions';

export const initialAllowance = false;

export default function batchAllowanceReducer(
  previousState: false | number = initialAllowance,
  action: IActionSetRemainingBatchAllowance,
): false | number {

  switch (action.type) {
    case EReduxActionType.SET_REMAINING_BATCH_ALLOWANCE:
      return action.remainingBatchAllowance;
    default:
      return previousState;
  }
}
