import * as React from 'react';
import { Alignment, Segment, SegmentOptionType } from '@kounta/chameleon';
import { EBatchStatus } from '../../../typings/Interface';

interface IProps {
  segmentBatchStatus: EBatchStatus;
  handleBatchStatusChange: (productionType: { id: EBatchStatus }) => void;
}

const ProductionStatusSegment = (props: IProps) => {
  const statusList: SegmentOptionType[] = [
    { id: EBatchStatus.inProgress, name: 'In progress' },
    { id: EBatchStatus.planned, name: 'Planned' },
  ];

  return (
    <React.Fragment>
      <Alignment horizontal={'center'}>
        <Segment
          options={statusList}
          value={props.segmentBatchStatus}
          onChange={props.handleBatchStatusChange}
        />
      </Alignment>
    </React.Fragment>
  );
};

export default ProductionStatusSegment;
