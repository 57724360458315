import { AdvancedSelectOptionType } from '@kounta/chameleon';

export const findOptionByValue = (
  value: string,
  haystack: AdvancedSelectOptionType[]
): AdvancedSelectOptionType | undefined => {
  return haystack.find((e) => {
    return e.value === value;
  });
}
