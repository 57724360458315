import React, { useState } from 'react';
import { AppHeader, Modal } from '@kounta/chameleon';
import { IFormikRecipe } from '../../typings/Interface';
import { doesRecipeHaveUnsavedChanges } from '../../validation/DoesRecipeHaveUnsavedChanges';
import {
  ERecipeAppHeaderTitle,
  UNSAVED_CHANGES_PROMPT_MESSAGE,
} from '../../TextProvider';
import { AppHeaderLeftContent } from './AppHeaderLeftContent';
import { SaveRecipeButton } from '../../pages/recipes/components/SaveRecipeButton';
import { modalFooter } from '../../pages/recipes/components/GetLeftButtonModal';
import {
  EModalActionResult,
  EModalTitle,
  IModalGoBackHandler,
  IModalActionHandler,
} from '../../typings/Modal';

interface IProps {
  existingRecipe: IFormikRecipe | false;
  draftRecipe: IFormikRecipe;
  recipeDeleted: boolean;
  modalActionHandler: IModalActionHandler;
  goBackHandler: IModalGoBackHandler;
  shouldHideRightContent: boolean;
}

export const RecipeAppHeaderBar = React.memo(
  ({
     existingRecipe,
     draftRecipe,
     recipeDeleted,
     modalActionHandler,
     goBackHandler,
     shouldHideRightContent,
   }: IProps): JSX.Element => {
    const [showGoBackModal, setShowGoBackModal] = useState(false);

    return (
      <AppHeader
        title={generateAppHeaderTitle()}
        leftContent={
          <AppHeaderLeftContent
            goBackHandler={goBackButtonHandler}
            goBackModal={getGoBackModal()}
            showGoBackModal={showGoBackModal}
          />
        }
        rightContent={
          generateTopRightSaveRecipeButton(
            shouldHideRightContent,
            existingRecipe,
            draftRecipe,
          )
        }
      />
    );

    function generateAppHeaderTitle(): ERecipeAppHeaderTitle {
      return existingRecipe === false
        ? ERecipeAppHeaderTitle.create
        : ERecipeAppHeaderTitle.update;
    }

    function getGoBackModal(): JSX.Element {
      return (
        <Modal
          title={EModalTitle.unSavedChanges}
          content={UNSAVED_CHANGES_PROMPT_MESSAGE}
          footer={modalFooter({
            confirmHandler: confirmActionHandler,
            cancelHandler: cancelActionHandler,
          })}
          actionClose={closeActionHandler}
          overlay
        />
      );
    }

    function confirmActionHandler(): void {
      setShowGoBackModal(false);
      modalActionHandler(EModalActionResult.yes);
    }

    function cancelActionHandler(): void {
      setShowGoBackModal(false);
      modalActionHandler(EModalActionResult.cancel);
    }

    function closeActionHandler(): void {
      cancelActionHandler();
    }

    function goBackButtonHandler(): void {
      const hasUnsavedChangesYn = doesRecipeHaveUnsavedChanges(
        existingRecipe,
        draftRecipe
      );
      // if has un saved changes and it is not for deletion
      // then show modal
      if (hasUnsavedChangesYn && !recipeDeleted) {
        setShowGoBackModal(true);
      } else {
        goBackHandler();
      }
    }
  }
);

// We don't transfer this
// https://reactjs.org/docs/react-api.html#reactmemo
const generateTopRightSaveRecipeButton = (
  shouldHideRightContent: boolean,
  existingRecipe: IFormikRecipe | false,
  draftRecipe: IFormikRecipe,
): JSX.Element | null => {
  if (shouldHideRightContent) {
    return null
  }

  const hasUnsavedChangesYn = doesRecipeHaveUnsavedChanges(
    existingRecipe,
    draftRecipe
  );

  // as discussed with Narelle, we only `disable` the button, when the form
  // has unsaved changes.
  // We don't `disable` the button for validation errors:
  return (
    <SaveRecipeButton
      disableSaveYn={!hasUnsavedChangesYn}
    />
  );
}

