import {
  EConsumptionType,
  IApiProduct,
  IApiRecipe,
  IApiRecipeIngredient,
  IFormikIngredient,
} from "../../typings/Interface";
import { stockPortionCalculation } from "../stock-movement/StockPortionCalculation";
import Big from "big.js";
import { getProductByUuidFromList } from "../product/GetProductByUuidFromList";
import getRecipeFromRecipesListByProduct from "../recipe/GetRecipeFromRecipesListByProduct";
import { BIG_ZERO } from '../number-format/BigNumberHandler';
import { isProductMadeHere } from '../filter/FilterUtil';

const DEFAULT_QUANTITY = new Big(1);

/**
 *  cache is using to store the cost of product;
 *  let's say if a product A using another recipe product B,
 *  we will calculate product B's cost and store into cache,
 *  when some other ingredient product C using B product, then we can return, B
 *  directly from cache.
 *
 *  also, if we have circle reference, it will also store in cache, so if meet
 * this circle reference, we do not need go search it again.
 *
 *  note: cache is only alive in current calculate loop, that's mean, every
 * time when main IngredientsFormFields render finished, cache will be
 * removed.
 */
export const GetProductCostFromCollection = (
  ingredient: IFormikIngredient,
  productsCollection: IApiProduct[],
  recipes: IApiRecipe[],
  checkedList: string[] = [],
): Big => {
  const productId = ingredient.productUuid;
  const ingQuantity = ingredient.componentQty || BIG_ZERO;

  checkedList.push(productId);
  let product = getProductByUuidFromList(ingredient.productUuid,
    productsCollection);

  if (!product) {
    return BIG_ZERO;
  }

  const portion = stockPortionCalculation(product, ingredient.uom,
    DEFAULT_QUANTITY);
  const averageCost = product.averageCostPrice || 0;
  if (averageCost !== 0) {
    const averageCostPrice = portion.mul(product.averageCostPrice);
    return averageCostPrice.mul(ingQuantity);
  }

  const costPrice = portion.mul(product.costPrice);
  if (!isProductMadeHere(product)) {
    return costPrice.mul(ingQuantity);
  }

  const recipe = getRecipeFromRecipesListByProduct(productId, recipes);

  if (!recipe || recipe.recipeIngredients.length === 0) {
    return costPrice.mul(ingQuantity);
  }

  const recipeIngredients = filterNonReferenceIngredients(
    recipe.recipeIngredients, checkedList);

  let result: Big = recipeIngredients.reduce(
    (final: Big, current: IApiRecipeIngredient): Big => {
      const fIngredient: IFormikIngredient = {
        ...current,
        componentQty: current.componentQty.toString(),
      };

      const tempResult = GetProductCostFromCollection(
        fIngredient,
        productsCollection,
        recipes,
        checkedList,
      );

      return final.add(tempResult);
    }, BIG_ZERO
  );

  const oriRecipeExpectYield = recipe.expectedYield || 0;
  let recipeExpectYield = new Big(oriRecipeExpectYield);

  // in case recipeExpectYield is null or zero;
  // cos recipeExpectYield can be set as 0 in frontend before it save.
  if (recipeExpectYield.eq(0)) {
    recipeExpectYield = new Big(1);
  }

  const recipePortion = recipe.consumptionType === EConsumptionType.madeToOrder ?
    recipeExpectYield :
    stockPortionCalculation(product, recipe.uom, recipeExpectYield);

  const ingredientPortion = portion.mul(ingQuantity);
  return result.div(recipePortion).mul(ingredientPortion);
};

const filterNonReferenceIngredients =
  (
    ingredients: IApiRecipeIngredient[],
    exists: string[]
  ): IApiRecipeIngredient[] => {
    return ingredients.filter((value) => {
      return !exists.includes(value.productUuid);
    });
  };
