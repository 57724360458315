import React, { SyntheticEvent } from 'react';
import {
  ETableSortingDirection,
  ITableRowClickCallback,
  ITableSortingCallback,
} from '../../typings/Chameleon';
import { printQtyWithUom } from '../../utils/batch/PrintQtyWithUom';
import {
  Table,
  TableHeaderType,
  TableSortDirectionType,
} from '@kounta/chameleon';
import Moment from 'react-moment';
import { longDateFormat } from '../../TextProvider';
import { IApiBatch } from '../../typings/Interface';

enum EHistoryHeader {
  completedBatches = 'Completed batches',
  actualYield = 'Actual yield',
  productionDate = 'Production date',
}

export enum EHeaderId {
  completedBatches = 1,
  actualYield,
  productionDate,
}

function getCompletedBatchHeaderField(): TableHeaderType {
  return {
    id: EHeaderId.completedBatches,
    name: EHistoryHeader.completedBatches,
    width: '60%',
  };
}

function getActualYieldHeaderField(): TableHeaderType {
  return {
    id: EHeaderId.actualYield,
    name: EHistoryHeader.actualYield,
    width: '15%',
  };
}

function getCompletedTimeHeaderField(): TableHeaderType {
  return {
    id: EHeaderId.productionDate,
    name: EHistoryHeader.productionDate,
    align: 'right',
    sortable: true,
    sortDirection: ETableSortingDirection.descending,
    width: '25%',
  };
}

function getHistoryHeaders(): TableHeaderType[] {
  return [
    getCompletedBatchHeaderField(),
    getActualYieldHeaderField(),
    getCompletedTimeHeaderField(),
  ];
}

function getProductNameRowColumn(batch: IApiBatch, index: number) {
  return {
    id: index,
    value: batch.productName,
  };
}

function getActualYieldRowColumn(batch: IApiBatch, index: number) {
  return {
    id: index,
    value: printQtyWithUom(
      // completed batch always has actualYield
      batch.actualYield as number,
      batch.uom
    ),
  };
}

function getCompletedTimeRowColumn(batch: IApiBatch, index: number) {
  return {
    id: index,
    value: (
      /* completed batch always has completedTime*/
      <Moment format={longDateFormat}>{batch.completedTime as string}</Moment>
    ),
  };
}

function getHistoryContents(batches: IApiBatch[]) {
  return batches.map((batch: IApiBatch) => ({
    // Note: don NOT change this uuid,
    // as it will be as a input parameter
    // in table rowClick callback function.
    id: batch.uuid,
    content: [
      getProductNameRowColumn(batch, 1),
      getActualYieldRowColumn(batch, 2),
      getCompletedTimeRowColumn(batch, 3),
    ],
  }));
}

interface IProps {
  completedBatches: IApiBatch[];
  handleSortDirectionChange: ITableSortingCallback;
  handleSingleBatchClick: ITableRowClickCallback;
}

export const handleSort = (handleSortDirectionChange: ITableSortingCallback) => {
  // to follow chameleon types, headerId needs to be `string | number`, however,
  // in Produce it is definitely a number. Hence use type guard:
  return (headerId: string | number, direction: TableSortDirectionType) => {
    if (typeof headerId === 'number') {
      handleSortDirectionChange(direction);
    } else {
      throw new Error(
        `Expected headerId to be typeof number, got ${typeof headerId}`);
    }
  };
}

export const handleRowClick = (handleSingleBatchClick: ITableRowClickCallback) => {
  return (e: SyntheticEvent, rowId: string | number) => {
    if (typeof rowId === 'string') {
      handleSingleBatchClick(rowId);
    } else {
      throw new Error(
        `Expected rowId to be typeof string, got ${typeof rowId}`);
    }
  }
}

export const HistoryList = React.memo(({
  completedBatches,
  handleSortDirectionChange,
  handleSingleBatchClick,
}: IProps): JSX.Element => {
  return (
    <Table
      showRowClick
      showRowIndicator={false}
      headers={getHistoryHeaders()}
      rows={getHistoryContents(completedBatches)}
      onSort={handleSort(handleSortDirectionChange)}
      onRowClick={handleRowClick(handleSingleBatchClick)}
    />
  );
});
