import React from 'react';
import { Group, Button } from '@kounta/chameleon';
import { EButtonType } from '../../../typings/Chameleon';
import { EModalButtonText } from '../../../typings/Modal';

interface IProps {
  confirmMessage?: string;
  cancelMessage?: string;
  confirmHandler: () => void;
  cancelHandler: () => void;
}

export const modalFooter = ({
  confirmMessage = EModalButtonText.yes,
  cancelMessage = EModalButtonText.cancel,
  confirmHandler,
  cancelHandler,
}: IProps): JSX.Element => (
  <Group horizontal full even>
    <Button
      type={EButtonType.button}
      onClick={confirmHandler}
      fullWidth
      primary
    >
      {confirmMessage}
    </Button>
    <Button
      type={EButtonType.button}
      onClick={cancelHandler}
      fullWidth
      secondary
    >
      {cancelMessage}
    </Button>
  </Group>
);
