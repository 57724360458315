import React from 'react';
import { Paragraph } from '@kounta/chameleon';
import { EReadOnlyNotesPlaceHolder } from '../../TextProvider';

interface IProps {
  note: string;
  placeHolderText: EReadOnlyNotesPlaceHolder;
}

// Pure Functional components!
// https://logrocket.com/blog/pure-functional-components/
// https://blog.bitsrc.io/improve-performance-in-react-functional-components-using-react-memo-b2e80c11e15a?gi=94b3239d0e8d
export const NotesPrint = React.memo(
  ({
     note,
     placeHolderText,
   }: IProps): JSX.Element => {
    const notesText = (note === '') ? placeHolderText : note;

    /**
     * batch.note is saved like firstline<br>secondline<br>
     *   thus we need to use html rather than text
     *   to show batch.note in paragraph.
     */
    return (
      <Paragraph
        dangerouslySetInnerHTML={{ __html: notesText }}
      />
    );
  }
);
