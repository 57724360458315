import * as React from 'react';
import {
  EBatchFormButtonText,
  EBatchStatus,
  EModalType,
  ESnackBarType,
  EUom,
  ILayoutUiControlFuncs,
  Uuid,
} from '../../../typings/Interface';
import { startBatch } from '../../../graphql/BatchQL';
import { ESnackBarMessage } from '../../../TextProvider';
import styled from 'styled-components';
import LoadingButton from '../../../components/loading/LoadingButton';

interface IProps {
  segmentBatchStatus: EBatchStatus;
  batchUuid: Uuid;
  batchUom: EUom;
  batchActualYield: number;

  // UI controls for SnackBar and Modal:
  handleModal: ILayoutUiControlFuncs['handleModal'];
  showFinishBatchYieldModal: (uuid: Uuid, batchUom: EUom, batchActualYield: number) => void;
}

const ButtonContainer = styled.div`
  width: 148px;
`;

export const BatchAction = (props: IProps) => {
  const batchActionHandler = () =>
    onBatchActionClick(
      props.batchUuid,
      props.segmentBatchStatus,
      props.showFinishBatchYieldModal,
      props.handleModal,
      props.batchUom,
      props.batchActualYield,
    );

  const onBatchActionClick = (
    batchUuid: Uuid,
    batchStatus: EBatchStatus,
    showFinishBatchYieldModal: (uuid: Uuid, batchUom: EUom, batchActualYield: number) => void,
    handleModal: ILayoutUiControlFuncs['handleModal'],
    batchUom: EUom,
    batchActualYield: number,
  ) => {
    return async () => {
      if (batchStatus === EBatchStatus.planned) {
        const startResult = await startBatch(batchUuid);
        if (startResult.succeed) {
          handleModal({
            snackBarMessage: ESnackBarMessage.START_BATCH_SUCCEED,
            snackBarType: ESnackBarType.success,
            modalType: EModalType.SNACK_BAR,
          });
        }
        /**
         * else
         * {
         *  // TODO: show error message here
         * }
         */
      } else {
        showFinishBatchYieldModal(batchUuid, batchUom, batchActualYield);
      }
    };
  };

  const getButtonText = (): string =>
    props.segmentBatchStatus === EBatchStatus.planned
      ? EBatchFormButtonText.start
      : EBatchFormButtonText.finish;

  return (
    <ButtonContainer>
      <LoadingButton
        fullWidth={true}
        slim={true}
        secondary={true}
        onClick={batchActionHandler()}
        buttonText={getButtonText()}
      />
    </ButtonContainer>
  );
};
