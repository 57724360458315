import React from 'react';
import {
  HelpPanel,
  HelpPanelItemType,
  IconChat,
  IconPhone,
} from "@kounta/chameleon";
import { ISiteInfo } from "../../typings/Interface";
import { pageReload } from "../../utils/url/UrlRedirect";
import { showIntercom } from "../../third-party-script-loader/intercomController";
import { getRelatedArticlesByPathName } from './GetRelatedArticlesByPathName';
import { IReduxStaff } from '../../redux/reducers/StaffReducer';

interface IProps {
  staff: IReduxStaff;
  siteInfo: ISiteInfo;
  handleClose: () => void;
  routerLocationPathName: string;
}

const SUPPORT_URL = 'https://support.kounta.com/hc/en-us/search?utf8=%E2%9C%93&query=';
const supportPhoneNo = '+61 2 8599 2247';

export const handleSearch = (query: string): string => {
  const supportUrl = `${SUPPORT_URL}${encodeURI(query)}`;
  window.open(supportUrl);
  return supportUrl;
};

export default React.memo((
  { staff, siteInfo, handleClose, routerLocationPathName }: IProps
): JSX.Element => {
  const popupIntercom = (): void => {
    showIntercom();
    handleClose();
  };

  const supportItemList: HelpPanelItemType[] = [
    {
      id: 1,
      value: 'Talk to the support team',
      icon: <IconChat />,
      action: popupIntercom,
    },
    {
      id: 2,
      value: `Call us at ${supportPhoneNo}`,
      href: `tel:${supportPhoneNo}`,
      icon: <IconPhone />,
    },
  ];

  const supportInfo: HelpPanelItemType[] = [
    {
      id: 1,
      value: `Company ID: ${siteInfo.companyNumericId}`,
    },
    {
      id: 2,
      value: `Site ID: ${siteInfo.siteNumericId}`,
    },
  ];

  const troubleShootingItems: HelpPanelItemType[] = [
    {
      id: 1,
      name: 'Refresh this page', action: pageReload,
    }
  ];

  return (
    <HelpPanel
      showEmailSupport={false}
      relatedItems={getRelatedArticlesByPathName(routerLocationPathName)}
      troubleShootingItems={troubleShootingItems}
      supportItems={supportItemList}
      supportInfo={supportInfo}
      currentUser={staff}
      onSearch={handleSearch}
      onClose={handleClose}
    />
  )
});
