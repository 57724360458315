import React, { useState } from "react";
import {
  Alert,
  Box,
  Caption,
  Group,
  Label,
  Modal,
  Paragraph,
  Portal,
  Segment,
  TextInput,
} from "@kounta/chameleon";
import {
  EUom,
  EUomEditorType,
  IApiProduct,
  IUomDropDownOption,
  IUomEditorTextProviderContent
} from "../../typings/Interface";
import { findUnitCategoryByUom } from "../../uom/FindUnitCategoryByUom";
import { EditorAlertDiv, EditorElement } from "../../css/Styled";
import { EUomDisplay } from "../../uom/Display";
import EditorUomDropDown from './EditorUomDropDown';
import { EUomCategory } from '../../uom/AcceptableUnits';
import { isValidNumber } from '../../validation/Validator';
import UomEditorFooter from "./UomEditorFooter";

interface IUomEditorProps {
  product: IApiProduct;
  closeHandler: () => void;
  saveButtonHandler: (
    product: IApiProduct,
  ) => void;
  adaptor: (product: IApiProduct, productInfos: any) => Promise<IApiProduct>;
  uomProvider: IUomEditorTextProviderContent;
}

interface IEUomCategory {
  id: EUomCategory;
}

export interface IProductUomInfo {
  uom: EUom;
  measure: string;
  category: EUomCategory;
  name: string;
  isUnitValid: boolean;
  isNameValid: boolean;
}

const UNIT_MEASURES = [
  { id: EUomCategory.UNIT, name: 'In units' },
  { id: EUomCategory.VOLUME, name: 'By volume' },
  { id: EUomCategory.WEIGHT, name: 'By weight' },
];

const MAX_NAME_LENGTH = 255;
/**
 * https://www.regextester.com/106421
 */
const EMOJIS_REGX = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
const ERROR_ALERT_MESSAGE = 'Sorry, we couldn’t save your product settings. Please try again in a few minutes.';

export const validateName = (value: string) => {
  if (value === undefined || value === '') {
    return false;
  }
  if (value.length > MAX_NAME_LENGTH) {
    return false;
  }
  if (EMOJIS_REGX.test(value)) {
    return false;
  }
  return true;
};

export const isAbleToSave = (
  productUomInfo: IProductUomInfo,
  product: IApiProduct,
  editorType = EUomEditorType.update
): boolean => {
  /**
   * case if in editor, the editing productUomInfo are equal to current product
   * info, then should not able them update, cos nothing changed.
   */

  const productUomState = product.uom === productUomInfo.uom;
  const productMeasureState = product.measure.toString() ===
    productUomInfo.measure;
  const productNameNotChanged = editorType === EUomEditorType.update ?
    product.name === productUomInfo.name :
    product.name === '';
  const isOriginalProductInfo = productUomState && productMeasureState &&
    productNameNotChanged;
  return productUomInfo.isUnitValid && productUomInfo.isNameValid &&
    !isOriginalProductInfo;
};

export default React.memo((props: IUomEditorProps): JSX.Element | null => {

  const [productUomInfo, setProductUomInfo] = useState<IProductUomInfo>({
    uom: props.product.uom,
    measure: props.product.measure.toString(),
    category: findUnitCategoryByUom(props.product.uom),
    name: props.product.name,
    isUnitValid: true,
    isNameValid: true,
  });

  const [footerLoading, setFooterLoading] = useState(false);

  const [errorAlertState, setErrorAlertState] = useState(false);

  const handleUnitCategoryChange = (option: IEUomCategory): void => {
    if (option.id === productUomInfo.category) {
      return;
    }

    const product = props.product as IApiProduct;
    const productCategory = findUnitCategoryByUom(product.uom);

    let currentDefaultUom = EUom.unit;

    if (EUomCategory.VOLUME === option.id) {
      currentDefaultUom =
        (productCategory === option.id) ? product.uom : EUom.ml;
    }

    if (EUomCategory.WEIGHT === option.id) {
      currentDefaultUom =
        (productCategory === option.id) ? product.uom : EUom.g;
    }

    setProductUomInfo({
      ...productUomInfo,
      uom: currentDefaultUom,
      category: option.id,
    });
  };

  const handleUnitChange = (option: IUomDropDownOption) => {
    setProductUomInfo({
      ...productUomInfo,
      uom: option.value,
    });
  };

  const handleMeasureChange = (value: string) => {
    const isValid = isValidNumber(value);
    setProductUomInfo({
      ...productUomInfo,
      measure: value,
      isUnitValid: isValid
    });
  };

  const handleProductNameChange = (value: string) => {
    const isValid = validateName(value);
    setProductUomInfo({
      ...productUomInfo,
      name: value,
      isNameValid: isValid,
    });
  };

  /**
   * when update product, if category is Unit, then measure must be 1.
   * behavior match with back-office.
   */
  const saveUomInfos = async (): Promise<void> => {
    if (!isAbleToSave(productUomInfo, props.product as IApiProduct,
      props.uomProvider.actionType)) {
      return;
    }
    setFooterLoading(true);
    try {
      const product = await props.adaptor(props.product as IApiProduct,
        productUomInfo);
      props.saveButtonHandler(product);
      props.closeHandler();
    } catch (e) {
      setFooterLoading(false);
      setErrorAlertState(true);
    }
  };

  const showDropDownList = (): JSX.Element | null => {
    if (productUomInfo.category === EUomCategory.UNIT) {
      return null;
    }
    const product = props.product as IApiProduct;
    return (
      <EditorUomDropDown
        uomCategory={productUomInfo.category}
        errorState={!productUomInfo.isUnitValid}
        productUom={product.uom}
        handleUnitChange={handleUnitChange}
        handleMeasureChange={handleMeasureChange}
        measure={productUomInfo.measure}
      />
    );
  };

  const captionMeasureText = productUomInfo.category === EUomCategory.UNIT ?
    `This means that each ${props.product.name} product is measured in units` :
    `This means that each ${props.product.name} product is ${productUomInfo.measure} ${EUomDisplay[productUomInfo.uom]}`;

  const handleAlertClose = () => {
    setErrorAlertState(false);
  };

  return (
    <Portal>
      <Modal
        title={props.uomProvider.header}
        footer={
          <UomEditorFooter
            buttonContent={props.uomProvider.button}
            saveUomInfos={saveUomInfos}
            isAbleToSave={isAbleToSave(productUomInfo,
              props.product as IApiProduct, props.uomProvider.actionType)}
            loading={footerLoading}
          />
        }
        actionClose={props.closeHandler}
      >
        <Group center>
          <EditorElement>
            <Paragraph>
              {props.uomProvider.body}
            </Paragraph>
          </EditorElement>

          <EditorElement>
            <TextInput
              label={'Product Name'}
              placeholder={'default'}
              readOnly={props.uomProvider.actionType === EUomEditorType.update}
              initialValue={props.product.name}
              error={!productUomInfo.isNameValid}
              onChange={handleProductNameChange}
            />
          </EditorElement>
          <EditorElement>
            <Label>
              Measured
            </Label>
            <Segment
              fullWidth
              options={UNIT_MEASURES}
              defaultValue={productUomInfo.category}
              onChange={handleUnitCategoryChange}
              value={productUomInfo.category}
            />
          </EditorElement>
          <EditorElement>
            {
              showDropDownList()
            }
          </EditorElement>
          <EditorElement>
            <Box callout showBorder={false} spacing={"medium"}>
              <Caption>
                {captionMeasureText}
              </Caption>
            </Box>
          </EditorElement>
        </Group>
        {errorAlertState ?
        <Portal>
          <EditorAlertDiv>
            <Alert
              alertType={'Error'}
              alertMessage={ERROR_ALERT_MESSAGE}
              autoClose={true}
              onClose={handleAlertClose}
            />
          </EditorAlertDiv>
        </Portal> : null}
      </Modal>
    </Portal>
  );
});
