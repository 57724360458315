import * as React from 'react';
import {
  EmptyStateContainer,
  EmptyStateWrapper,
} from '../../../components/empty-State/EmptyStateWrapper';
import { Alignment } from '@kounta/chameleon';
import { EEmptyStateOperationName } from '../../../TextProvider';

export const SearchResultEmpty = React.memo((): JSX.Element => (
  <EmptyStateContainer>
    <Alignment vertical={'center'} horizontal={'center'}>
      <EmptyStateWrapper pageName={EEmptyStateOperationName.productsSearch} />
    </Alignment>
  </EmptyStateContainer>
));
