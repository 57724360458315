import { AdvancedSelect, NumberInput } from "@kounta/chameleon";
import React from "react";
import {
  EUom,
  IUomDropDownOption
} from "../../typings/Interface";
import { displayDropdownOptionsSet } from "../../uom/Display";
import { EUomCategory, EUomOptionsByCategory } from '../../uom/AcceptableUnits';

interface IUomDropDownList {
  uomCategory: EUomCategory;
  measure: string;
  productUom: EUom;
  errorState: boolean;
  handleUnitChange: (option: IUomDropDownOption) => void;
  handleMeasureChange: (value: string) => void;
}

export default React.memo((props: IUomDropDownList): JSX.Element => {
  const options = displayDropdownOptionsSet(
    EUomOptionsByCategory[props.uomCategory]);

  const defaultUom = options.find((obj) => obj.value === props.productUom) || options[0];
  
  return (
    <NumberInput
      maxWidth={'100%'}
      label={'Default Size'}
      value={props.measure}
      onChange={props.handleMeasureChange}
      error={props.errorState}
      connectedElementWidth={'130px'}
      connectedRight={
        <AdvancedSelect
          key={defaultUom.value}
          options={options}
          onChange={props.handleUnitChange}
          defaultValue={defaultUom}
          isConnectedRight={true}
        />
      }
    />
  );
})
