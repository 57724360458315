import { DropDownWrapper } from '../../css/Styled';
import * as React from 'react';
import { EDIT_IN_BACKOFFICE_OPTION_VALUE } from "../../uom/EditInBackOffice";
import { useState } from "react";
import { IApiProduct, IUomDropDownOption } from "../../typings/Interface";
import UomSelector from "./UomSelector";
import { AdvancedSelectOptionType } from '@kounta/chameleon';

interface IProps {
  defaultOption: IUomDropDownOption;
  changeFormHandler: (
    selectedOptionObject: AdvancedSelectOptionType
  ) => void;
  product: IApiProduct | false;
  className?: string;
}

export const UomDropDown = React.memo((
  {
    defaultOption,
    changeFormHandler,
    product,
    className,
  }: IProps
  ): JSX.Element => {

    const [dropDownModalState, setDropDownModalState] = useState(false);

    const closeModalHandle = () => {
      setDropDownModalState(false);
    };

    const onDropDownValueChange = (option: AdvancedSelectOptionType) => {
      changeFormHandler(option);
      if (option.value === EDIT_IN_BACKOFFICE_OPTION_VALUE && !dropDownModalState){
        setDropDownModalState(true);
      }
    };

    return (
      <DropDownWrapper className={className? '' : className}>
        <UomSelector
          defaultOption={defaultOption}
          onDropDownChange={onDropDownValueChange}
          product={product}
          closeModalHandle={closeModalHandle}
          modalState={dropDownModalState}
        />
      </DropDownWrapper>
    );
  }
);
