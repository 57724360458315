import { IReduxStaff } from '../../redux/reducers/StaffReducer';
import { IStaff } from '../../graphql/StaffQL';
import { determineEditPermissions } from './DetermineEditPermissions';
import moment from 'moment';

export const translateApiStaff = (staff: IStaff): IReduxStaff => {
  return {
    ...determineEditPermissions(staff),
    email: staff.primaryEmailAddress,
    image: staff.image,
    initials: getStaffInitials(staff),
    name: `${staff.firstName} ${staff.lastName}`,
    phone: staff.phone,

    personTrackingId: staff.personTrackingId,
    personCreatedAt: moment(staff.personCreatedAt).unix().toString(),
    intercomHash: staff.intercomHash,
    staffTrackingId: staff.staffTrackingId,
  };
}

export const getStaffInitials = (staff: IStaff): string => {
  return `${staff.firstName.substring(0, 1).toUpperCase()}${staff.lastName.substring(0, 1).toUpperCase()}`;
}
