import { IApiProduct, IFormikIngredient, Uuid } from '../../typings/Interface';

/**
 * This function will remove below products from the `productsList` array:
 * 1. the product that has a product_id in ingredients list.
 * 2. the product that has a uuid of `mainProductUuid`
 *
 * So the return array will exclude `mainProduct` and `ingredients` products.
 */
export const getProductsListExcludeMainProductAndIngredientProduct = (
  productsList: IApiProduct[],
  ingredients: IFormikIngredient[],
  mainProductUuid: Uuid
): IApiProduct[] => {
  const ingredientsProductUuids: Uuid[] = ingredients.map(
    (ing: IFormikIngredient) => ing.productUuid
  );

  return productsList.filter(
    (product: IApiProduct) =>
      !ingredientsProductUuids.includes(product.id) &&
      product.id !== mainProductUuid
  );
};
