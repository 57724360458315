import { EUom } from '../../typings/Interface';

const QTY_PLACEHOLDER = '00';

export const DEFAULT_INGREDIENTS_SECTION = {
  ingredient: {
    label: 'DEFAULT INGREDIENTS',
    placeHolder: 'Search for a product above to see ingredients listed here',
  },
  qty: {
    label: 'QTY',
    placeHolder: QTY_PLACEHOLDER,
  },
  unit: {
    label: '',
    placeHolder: EUom.unit,
  },
};

export const CUSTOM_BATCH_SECTION = {
  buttons: {
    originalRecipe: 'Original recipe',
    customBatch: 'Custom batch',
  },
  batchMakes: {
    default: {
      label: 'Original recipe makes',
      placeHolderQty: QTY_PLACEHOLDER,
      placeHolderUnit: EUom.unit,
    },
    origin: {
      label: 'Original recipe makes',
    },
    custom: {
      label: 'Batch makes',
    },
  },
};
