import {
  Alignment,
  Group,
  Label,
  SegmentOptionType,
} from '@kounta/chameleon';
import React from 'react';
import styled from 'styled-components';
import { IApiRecipe, IFormikBatch } from '../../../typings/Interface';
import { CUSTOM_BATCH_SECTION } from '../TextProvider';
import { BatchMakes } from './BatchMakes';
import { CustomBatchToggle } from './CustomBatchToggle';
import { FormikErrors } from 'formik';

interface IProps {
  // If selectedRecipe is false, it means user did not select the main Product
  // yet.
  selectedRecipe: IApiRecipe | false;
  batchFormExpectedYield: number;
  isCustomBatchYield: boolean;

  onCustomBatchToggle: (selectedOption: SegmentOptionType) => void;
  onExpectedYieldQtyChange: (numberInString: string) => void;

  errors: FormikErrors<IFormikBatch>;
  isCustomBatchSectionReadOnly: boolean;
}

const RightAlignGroup = styled(Group)`
  margin-right: 0;
`;

export const CustomBatchSection = React.memo((
  {
    selectedRecipe,
    batchFormExpectedYield,
    isCustomBatchYield,

    onCustomBatchToggle,
    onExpectedYieldQtyChange,
    errors,
    isCustomBatchSectionReadOnly,
  }: IProps
  ): JSX.Element => {

    return (
      <Group horizontal full>
        <CustomBatchToggle
          isCustomBatchYield={isCustomBatchYield}
          onChange={onCustomBatchToggle}
          disabled={isCustomBatchSectionReadOnly}
        />
        <Alignment horizontal={'right'}>
          <RightAlignGroup horizontal>
            <Label>
              {isCustomBatchYield
                ? CUSTOM_BATCH_SECTION.batchMakes.custom.label
                : CUSTOM_BATCH_SECTION.batchMakes.origin.label}
            </Label>
            <Alignment horizontal={'right'}>
              <BatchMakes
                isCustomBatchYield={isCustomBatchYield}
                isCustomBatchSectionReadOnly={isCustomBatchSectionReadOnly}
                batchFormExpectedYield={batchFormExpectedYield}
                yieldUom={(selectedRecipe === false)
                  ? CUSTOM_BATCH_SECTION.batchMakes.default.placeHolderUnit
                  : selectedRecipe.uom}

                onBatchExpectedYieldChange={onExpectedYieldQtyChange}
                errors={errors}
              />
            </Alignment>
          </RightAlignGroup>
        </Alignment>
      </Group>
    );
  }
);
