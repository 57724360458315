import API, { graphqlOperation } from '@aws-amplify/api';

/**
 * "variables?: {}" is copied from AWS sdk:
 * frontend/produce-webpage/node_modules/@aws-amplify/api/src/API.ts
 *
 * Difference between `object` and `{}`:
 * https://stackoverflow.com/questions/49464634/difference-between-object-and-in-typescript
 *
 * Return type:
 * https://github.com/aws-amplify/amplify-js/issues/4257
 */
export const awsGraphQlClient = (queryString: string, variables?: {}) => (
  // `frontend/produce-webpage/node_modules/@aws-amplify/api/src/API.ts`
  // --> graphql() has the return type Promise<GraphQLResult> | Observable<object>.
  API.graphql(graphqlOperation(queryString, variables))
);
