import React from 'react';
import { Heading3, Spacer } from "@kounta/chameleon";
import { SectionPaddingLeft } from '../../../css/Styled';
import { NotesPrint } from '../../../components/notes/NotesPrint';
import { EReadOnlyNotesPlaceHolder } from '../../../TextProvider';

interface IProps {
  title: string;
  note: string;
  placeHolder: EReadOnlyNotesPlaceHolder;
}

export const NotesSection = React.memo(
  ({ title, note, placeHolder }: IProps) => (
    <React.Fragment>
      <SectionPaddingLeft>
        <Heading3>{title}</Heading3>
      </SectionPaddingLeft>
      <Spacer spacing={'medium'}/>
      <SectionPaddingLeft>
        <NotesPrint
          note={note}
          placeHolderText={placeHolder}
        />
      </SectionPaddingLeft>
    </React.Fragment>
  )
);
