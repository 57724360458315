import { EBatchStatus, IApiBatch, IFormikBatch } from '../../typings/Interface';
import { updateBatch } from '../../graphql/BatchQL';

export const updateBatchAction = (
  formValue: IFormikBatch,
  updateExistingBatch: (existingBatch: IApiBatch) => void
) => {
  return async (): Promise<void> => {
    // const draftBatch = this.props.draftBatch;
    //
    if (
      formValue.status !== EBatchStatus.planned &&
      formValue.status !== EBatchStatus.inProgress
    ) {
      throw new Error(`Invalid Batch update`);
    }

    const updateResult = await updateBatch(
      formValue.uuid,
      formValue.note,
      formValue.status,
      formValue.batchIngredients,
      formValue.uom,
      formValue.isCustomYield,
      formValue.expectedYield,
      formValue.plannedTime
    );

    if (updateResult.succeed) {
      updateExistingBatch(updateResult.responseObj);
    } else {
      // Todo: show error flash message
    }
  };
};
