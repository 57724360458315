import * as React from 'react';
import {
  Group,
  Label,
  Segment,
  SegmentOptionType,
  Paragraph
} from '@kounta/chameleon';
import {
  ERecipeFormLabel,
  PRODUCT_COMSUMPTION_TYPES,
} from '../../../TextProvider';
import {
  EConsumptionType,
  IApiProduct,
  IFormikRecipe,
} from '../../../typings/Interface';
import { Field, FieldProps } from 'formik';
import { ExpectedYield } from './ExpectedYield';
import { findNameById, IOptionIdName } from '../../../utils/chameleon/FindOption';

interface IProps {
  products: IApiProduct[];
  draftEntity: IFormikRecipe;
  hasEditRecipePermission: boolean;
}

export const InventoryConsumed = React.memo(
  ({ products, draftEntity, hasEditRecipePermission }: IProps) => {
    return (
      <Group horizontal spacing={'24px'}>
        <div>
          <Label>{ERecipeFormLabel.recipeType}</Label>
          {hasEditRecipePermission && (
            <Field name={'consumptionType'}>
              {renderConsumptionType}
            </Field>
          )}
          {!hasEditRecipePermission && (
            <Paragraph>
              {
                findNameById(
                  PRODUCT_COMSUMPTION_TYPES as IOptionIdName[],
                  draftEntity.consumptionType,
                )
              }
            </Paragraph>
          )}
        </div>
        { draftEntity.consumptionType !== EConsumptionType.madeToOrder && (
          <ExpectedYield
            hasEditRecipePermission={hasEditRecipePermission}
            products={products}
            draftEntity={draftEntity}
          />
        )}
      </Group>
    );

    function renderConsumptionType({ field, form }: FieldProps): JSX.Element {
      return (
        <Segment
          value={draftEntity.consumptionType}
          options={PRODUCT_COMSUMPTION_TYPES}
          onChange={handleConsumptionTypeChange(form, field)}
        />
      );
    }
  }
);

const handleConsumptionTypeChange = (
  form: FieldProps['form'],
  field: FieldProps['field'],
) => {
  return (obj: SegmentOptionType) => {
    const consumptionType = (obj.id as unknown) as EConsumptionType;
    form.setFieldValue(field.name, consumptionType);
  };
};
