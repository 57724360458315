export enum InternalErrorCode {
  UnknownError = 500,
  UomError = 600,
  ReduxProductMissingFromProductsList = 700,
  GraphqlSubscriptionFailed = 800,
}

export default class InternalError extends Error {
  public statusCode: number;
  constructor(
    message: string,
    statusCode: number = InternalErrorCode.UnknownError
  ) {
    // https://github.com/facebook/jest/issues/7394
    // https://github.com/facebook/create-react-app/issues/5868
    // https://github.com/istanbuljs/istanbuljs/issues/237
    super(message);
    this.statusCode = statusCode;
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InternalError);
    }
  }
}
