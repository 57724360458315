import * as React from 'react';
import styled from 'styled-components';
import { Caption, Group, Paragraph } from '@kounta/chameleon';
import { relativeTimeStamp } from '../../../components/date-time/RelativeTimeStamp';
import { EBatchStatus } from '../../../typings/Interface';
import Moment from 'react-moment';

interface IItemType {
  name: string;
  timestamp: string | null;
  segmentBatchStatus: EBatchStatus;
}

export const ItemWithTimeStamp = (props: IItemType): JSX.Element => {
  const classNameByBatchStatus =
    props.segmentBatchStatus === EBatchStatus.planned ? 'planned' : 'progress';
  const timestampPrefix =
    props.segmentBatchStatus === EBatchStatus.planned
      ? 'Planned for'
      : 'Started';

  // IF Batch status is planned just show Planned for and Date with month,
  // else use the same logic as recipes from the helper function
  const timestamp =
    props.segmentBatchStatus === EBatchStatus.planned
      ? props.timestamp && (
          <Moment format="D MMM YYYY">{props.timestamp}</Moment>
        )
      : props.timestamp && relativeTimeStamp(props.timestamp);

  return (
    <ItemWithTimeStampContainer>
      <Group horizontal={true}>
        <StyledStatusTag className={classNameByBatchStatus} />
        <Group spacing="2px">
          <Paragraph>{props.name}</Paragraph>
          <Caption>
            {timestampPrefix} {timestamp}
          </Caption>
        </Group>
      </Group>
    </ItemWithTimeStampContainer>
  );
};

const ItemWithTimeStampContainer = styled.div`
  margin: 10px 0;
  cursor: pointer;
`;

export const StyledStatusTag = styled.div`
  border-radius: 16px;
  width: 4px;
  height: 56px;
  &.progress {
    background: #4e8c48;
  }
  &.planned {
    background: #757575;
  }
`;
