import * as React from 'react';
import {
  IApiProduct,
  IApiRecipe,
  IFormikRecipe,
  ILayoutUiControlFuncs,
  Uuid,
} from '../../typings/Interface';
import { convertApiRecipeToFormikRecipe } from '../../validation/DoesRecipeHaveUnsavedChanges';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { isLitePlan } from '../../utils/plan-restriction/RestrictedPlans';
import { RecipeForm } from './RecipeForm';
import { IOnAddOrReplaceList } from '../../redux/typings/Interface';

interface IProps {
  // From Redux store:
  products: IApiProduct[];
  recipes: IApiRecipe[];
  isProductsFullyLoaded: boolean;
  billingPlan: IApiBillingPlan;

  setLoading: ILayoutUiControlFuncs['setLoading'];
  handleModal: ILayoutUiControlFuncs['handleModal'];

  onLoadingProducts: IOnAddOrReplaceList<IApiProduct>;

  productUuidFromUrl: Uuid;
  goToRecipesListPageWithQuery: () => void;

  formikRecipeByUrlProductUuid: false | IFormikRecipe;
  hasEditRecipePermission: boolean;
}

interface IState {
  // It can only from Produce DB (existing recipe), or false ===> no existing
  // recipe in the produce DB.
  existingRecipe: IFormikRecipe | false;
}

export default class Recipe extends React.Component<IProps, IState> {
  readonly state: IState = {
    existingRecipe: this.props.formikRecipeByUrlProductUuid,
  };

  public render() {
    return (
      <RecipeForm
        goBackToRecipesListPage={this.props.goToRecipesListPageWithQuery}
        products={this.props.products}
        isProductsFullyLoaded={this.props.isProductsFullyLoaded}
        productUuidFromUrl={this.props.productUuidFromUrl}
        existingRecipe={this.state.existingRecipe}
        updateExistingRecipe={this.updateExistingRecipe}
        setLoading={this.props.setLoading}
        handleModal={this.props.handleModal}

        onLoadingProducts={this.props.onLoadingProducts}

        // Need all recipes to work out the recipe cost
        recipes={this.props.recipes}
        // Site plan restriction:
        isLitePlan={isLitePlan(this.props.billingPlan)}
        hasEditRecipePermission={this.props.hasEditRecipePermission}
      />
    );
  }

  private updateExistingRecipe = (existingRecipe: IApiRecipe | false): void => {
    this.setState({
      existingRecipe: existingRecipe
        ? convertApiRecipeToFormikRecipe(existingRecipe)
        : false,
    });
  };
}
