import React from "react";
import { Button, Group } from "@kounta/chameleon";

interface IFooterProps {
  saveUomInfos: () => Promise<void>;
  isAbleToSave: boolean;
  loading: boolean;
  buttonContent: string;
}

export default React.memo((props: IFooterProps) => {
  return (
    <Group horizontal full>
      <Button
        fullWidth
        primary
        onClick={props.saveUomInfos}
        disabled={!props.isAbleToSave}
        type={'button'}
        showLoading={props.loading}
      >
        {props.buttonContent}
      </Button>
    </Group>
  );
});
