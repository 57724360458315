import { IApiBatch, Uuid } from '../../typings/Interface';

export const getBatchFromListByUuid = (
  targetUuid: Uuid,
  batches: IApiBatch[]
): IApiBatch | false => {
  const matchedArray = batches.filter(
    (item: IApiBatch): boolean => item.uuid === targetUuid
  );
  if (matchedArray.length === 1) {
    return matchedArray[0];
  } else {
    return false;
  }
};
