import {
  EProducePermissionCode,
  IApiHandlerResult,
} from '../../typings/Interface';
import { IStaff, IStaffPermission } from '../../graphql/StaffQL';

/**
 * Produce permission are below:
 *
 * const managePrepPermission: IStaffPermission = {
 *   code: EProducePermission.ManagePrep,
 *   name: "Manage Prep",
 *   domain: "Back Office",
 * };
 *
 * const managePrepPermission: IStaffPermission = {
 *   code: EProducePermission.ManageRecipes,
 *   name: "Manage Recipes",
 *   domain: "Back Office",
 * };
 */
export const hasProducePermission = (staffApiResponse: IApiHandlerResult<IStaff>): boolean => {
  if(!staffApiResponse.succeed || !staffApiResponse.responseObj){
    return false;
  }
  if(staffApiResponse.responseObj.isAdmin){
    return true;
  }
  const producePermissions = staffApiResponse.responseObj.permissions.find((e: IStaffPermission) => {
    return Object.values(EProducePermissionCode).includes(e.code as EProducePermissionCode);
  });
  return producePermissions !== undefined;
}
