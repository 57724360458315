import * as React from 'react';
import { NotesSection } from '../../completed-batches/components/NotesSection';
import { NOTE_TEXT, NotesInput } from '../../../components/notes/NotesInput';
import { EReadOnlyNotesPlaceHolder } from '../../../TextProvider';

interface IProps {
  hasEditRecipePermission: boolean;
  isLitePlan: boolean;
  note: string;
}

export const RecipeNote = React.memo(
  (
    { hasEditRecipePermission, isLitePlan, note }: IProps
  ): JSX.Element | null => {

    if (isLitePlan) {
      return null;
    }

    const sectionTitle = NOTE_TEXT.recipe.label;
    if (!hasEditRecipePermission) {
      return (
        <NotesSection
          title={sectionTitle}
          note={note}
          placeHolder={EReadOnlyNotesPlaceHolder.forRecipe}
        />
      );
    }
    return (
      <NotesInput
        label={sectionTitle}
        fieldName={'note'}
        placeHolder={NOTE_TEXT.recipe.placeHolder}
        readOnly={false}
      />
    );
  }
);
