import { DatePicker, Group } from '@kounta/chameleon';
import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { EBatchButtonText } from '../../../TextProvider';
import moment from 'moment';
import LoadingButton from '../../../components/loading/LoadingButton';

interface IProps {
  updatePlanDateAndReturnToListView: () => Promise<void>;
}

export const BatchDataPickerModal = (props: IProps): JSX.Element => {
  return (
    <Group full={true}>
      <Field name={'plannedTime'}>{renderDatePicker}</Field>
      <LoadingButton
        fullWidth={true}
        primary={true}
        onClick={props.updatePlanDateAndReturnToListView}
        buttonText={EBatchButtonText.plan}
      />
    </Group>
  );
};

const renderDatePicker = ({ form, field }: FieldProps): JSX.Element => (
  <DatePicker
    onDateChange={handleDatePickerChange(form, field)}
    date={new Date()}
    disabledDays={[{ before: new Date() }]}
  />
);

const handleDatePickerChange = (
  form: FieldProps['form'],
  field: FieldProps['field']
) => {
  return (plannedTime: Date): void => {
    const selectedPlannedTime = moment(plannedTime).toISOString();
    form.setFieldValue(field.name, selectedPlannedTime);
  };
};
