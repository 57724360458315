import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  ContextSelector,
  Group,
  Loading,
  Paragraph,
} from '@kounta/chameleon';
import { IApiSubscribedSiteInfo } from '../../typings/Interface';
import { getSubscribedProduceSites } from '../../graphql/SitesQL';
import { ELoadingTitle } from '../../TextProvider';

interface IProps {
  onSelect: (selectedSite: IApiSubscribedSiteInfo) => void;
  onClose: () => void;
}

export const SiteList = React.memo(({ onClose, onSelect }: IProps) => {
  const [sites, setSites] = useState<IApiSubscribedSiteInfo[]>([]);
  useEffect(() => {
    if (sites.length === 0) {
      const fetchData = async () => {
        const sites = await getSubscribedProduceSites();
        if (sites.succeed) {
          setSites(sites.responseObj as IApiSubscribedSiteInfo[]);
        }
      };
      fetchData();
    }
  });
  return (
    <ContextSelector fullscreen showFooter onClose={onClose}>
      <Loading
        isLoading={sites.length === 0}
        title={ELoadingTitle.retrievingSites}
      />
      <Group>
        {sites.map((site: IApiSubscribedSiteInfo) => (
          <Card
            key={site.siteUuid}
            hidePadding
            onClick={siteSelectHandler(site)}
          >
            <Box>
              <Paragraph>{site.siteName}</Paragraph>
            </Box>
          </Card>
        ))}
      </Group>
    </ContextSelector>
  );

  function siteSelectHandler(site: IApiSubscribedSiteInfo) {
    return () => onSelect(site);
  }
});
