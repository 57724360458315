import React from 'react';
import { Select } from '@kounta/chameleon';
import { ISelectUpdateOptionCallback } from '../../typings/Chameleon';
import styled from 'styled-components';
import {
  EDateOptionType,
  dateRangeOptions,
  IDateRangeOption,
} from '../../utils/date-time/DateTimeUtil';

const daysOptions: IDateRangeOption[] = [
  { ...dateRangeOptions.pastMonth },
  { ...dateRangeOptions.pastSevenDays },
  { ...dateRangeOptions.yesterday },
  { ...dateRangeOptions.today },
];

const StyledSelect = styled.div`
  width: 156px;
`;

interface IProps {
  handleDataRangeChange: ISelectUpdateOptionCallback;
  selectedOption: EDateOptionType;
}

function selectDateRangeDropdown({
  handleDataRangeChange,
  selectedOption,
}: IProps): JSX.Element {
  return (
    <StyledSelect>
      <Select
        options={daysOptions}
        defaultValue={selectedOption}
        updateSelectedOption={handleDataRangeChange}
      />
    </StyledSelect>
  );
}

export const SelectDateRangeDropdown = React.memo(selectDateRangeDropdown);
