import {
  IApiProduct,
  IFormikBatch,
  IFormikIngredient,
  IFormikRecipe,
} from '../typings/Interface';
import { isProductDeleted } from '../utils/product/GetProductByUuidFromList';
import produce from 'immer';

export const removeDeletedProductsFromIngredients = <
  T extends IFormikIngredient
>(
  ings: T[],
  products: IApiProduct[]
): T[] => {
  // The filter() method creates a new array with all elements that PASS
  // the test implemented by the provided function.
  return ings.filter((ing: T) => {
    // Remove the ingredient if getProductByUuidFromList() return false, which
    // means the product of this ingredient is already deleted.
    // NOTE: isProductDeleted() also exclude EMPTY_INGREDIENT_PRODUCT_UUID
    return !isProductDeleted(ing.productUuid, products);
  });
};

export const convertRecipeToHaveValidIngredients = (
  originalRecipe: IFormikRecipe,
  products: IApiProduct[]
): IFormikRecipe => {
  // PRO-276: Hide a recipe/recipe ingredient when the product has been deleted from BO
  const ingredientsWithUndeletedProduct = removeDeletedProductsFromIngredients(
    originalRecipe.recipeIngredients,
    products
  );

  // Using `immer` to make sure we are not mutating the original object:
  return produce(originalRecipe, draft => {
    draft.recipeIngredients = ingredientsWithUndeletedProduct;
  });
};

export const convertBatchToHaveValidIngredients = (
  originalBatch: IFormikBatch,
  products: IApiProduct[]
): IFormikBatch => {
  // PRO-276: Hide a recipe/recipe ingredient when the product has been deleted from BO
  const ingredientsWithUndeletedProduct = removeDeletedProductsFromIngredients(
    originalBatch.batchIngredients,
    products
  );

  // Using `immer` to make sure we are not mutating the original object:
  return produce(originalBatch, draft => {
    draft.batchIngredients = ingredientsWithUndeletedProduct;
  });
};
