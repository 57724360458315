import { EReduxActionType, IActionLoadSite } from '../actions';
import { ISiteInfo } from '../../typings/Interface';

export const EMPTY_SITE_INFO: ISiteInfo = {
  siteUuid: '',
  siteNumericId: 0,
  companyUuid: '',
  companyNumericId: 0,
  companyName: '',
  siteName: '',
  siteHasImageUrl: false,
  siteImageUrl: '',
};

export const initialSite = EMPTY_SITE_INFO;

export default function siteReducer(
  previousState: ISiteInfo = initialSite,
  action: IActionLoadSite,
): ISiteInfo {
  switch (action.type) {
    case EReduxActionType.LOAD_SITE:
      return action.siteInfo;
    default:
      return previousState;
  }
}
