import React from 'react';
import {
  Alignment,
  Card,
  Illustration,
  Link,
  Paragraph,
  Spacer
} from "@kounta/chameleon";
import { redirectToBackOfficeProducePlanPage } from '../../utils/url/UrlRedirect';

export const UPGRADE_TEXT_PART_1 =
  'Get access to your costs and profit margins for all your recipes with an';
export const UPGRADE_TEXT_PART_2 = 'Advanced Recipes plan';

export const RecipeCostUpgrade = React.memo((): JSX.Element => {
    return (
      <React.Fragment>
        <Card
          elementOnLeft={false}
        >
          <Alignment vertical={'center'} horizontal={'center'}>

            <div>
              <Illustration illustrationName="recipe-small"/>
            </div>
            <div>
              <Paragraph>
                &nbsp; &nbsp; {UPGRADE_TEXT_PART_1} &nbsp;
                <Link
                  primary
                  onClick={redirectToBackOfficeProducePlanPage}
                >
                  {UPGRADE_TEXT_PART_2}
                </Link>

              </Paragraph>
            </div>
          </Alignment>
        </Card>
        <Spacer/>
      </React.Fragment>
    );
  }
);
