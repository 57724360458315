import {
  EBatchIngredientAdditionalFlag,
  IApiProduct,
  IApiRecipe,
  IApiRecipeIngredient,
  IFormikBatch,
  IFormikBatchIngredient
} from '../../typings/Interface';
import { getProductNameByUuidFromList } from '../../utils/product/GetProductByUuidFromList';
import { initialDraftFormikBatch } from './InitialDraftBatch';
import { removeDeletedProductsFromIngredients } from '../RemoveDeletedProductsFromIngredients';

export const convertRecipeIngsToBatchIngs = (
  selectedRecipe: IApiRecipe,
  products: IApiProduct[]
): IFormikBatchIngredient[] => {
  const result = selectedRecipe.recipeIngredients.map(
    (ing: IApiRecipeIngredient): IFormikBatchIngredient => ({
      productUuid: ing.productUuid,
      productName: getProductNameByUuidFromList(
        ing.productUuid,
        products
      ),
      componentQty: ing.componentQty.toString(),
      uom: ing.uom,

      // When converting recipeIng, always use EBatchIngredientAdditionalFlag.origin
      additionalIngredientFlag: EBatchIngredientAdditionalFlag.origin,
    })
  );
  return removeDeletedProductsFromIngredients(result, products);
};

export const convertRecipeToBatch = (
  selectedRecipe: IApiRecipe,
  products: IApiProduct[],
  mainProductName: string,
): IFormikBatch => {
  const newBatchIngredients = convertRecipeIngsToBatchIngs(
    selectedRecipe,
    products,
  );

  return {
    uom: selectedRecipe.uom,
    expectedYield: selectedRecipe.expectedYield,
    recipeUuid: selectedRecipe.uuid,
    productName: mainProductName,
    batchIngredients: newBatchIngredients,
    // Recipe Note filed is clashed with Batch note filed, need reset:
    note: '',

    // Below fields still come from default `initialDraftFormikBatch`
    // `uuid, status, actualYield, plannedTime`
    uuid: initialDraftFormikBatch.uuid,
    status: initialDraftFormikBatch.status,
    actualYield: initialDraftFormikBatch.actualYield,
    plannedTime: initialDraftFormikBatch.plannedTime,

    isCustomYield: initialDraftFormikBatch.isCustomYield,
  };
};
