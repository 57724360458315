import {
  EConsumptionType, EUom,
  IApiProduct, IApiRecipe,
  IFormikIngredient,
  IFormikRecipe, Uuid,
} from '../../typings/Interface';
import {
  GetProductCostFromCollection
} from './GetProductCostFromCollection';
import {
  stockPortionCalculation
} from '../stock-movement/StockPortionCalculation';
import { IRecipeCosts } from "../../typings/Interface";
import Big from "big.js";
import { calculateGrossProfit } from "./CalculateSellPriceAndGrossProfit";
import { getProductByUuidFromList } from '../product/GetProductByUuidFromList';
import {
  BIG_ZERO, SHOW_PRECISION,
  ZERO_STRING_DISPLAY
} from '../number-format/BigNumberHandler';

export const calculateCosts = (
  shouldBlurPrice: boolean,
  currentEditingRecipe: IFormikRecipe | false,
  products: IApiProduct[],
  recipes: IApiRecipe[],

  mainProductUuid: Uuid,
  // the `mainProductSellPrice` can come from currentEditingRecipe, or passed in
  // by user input:
  mainProductSellPrice: string,
): IRecipeCosts => {
  // Early exit No.1: Skip calculation if shouldBlurPrice (when plan is Lite):
  if (shouldBlurPrice) {
    const defaultCosts: IRecipeCosts = {
      unitCost: BIG_ZERO,
      totalCost: BIG_ZERO,
      grossProfit: ZERO_STRING_DISPLAY,
      sellPrice: ZERO_STRING_DISPLAY,
    };
    return defaultCosts;
  }

  const mainProduct = getProductByUuidFromList(mainProductUuid, products);
  if (!mainProduct) {
    throw Error('product cannot found');
  }

  if (currentEditingRecipe === false ||
    currentEditingRecipe.recipeIngredients.length === 0
  ) {
    // Early exit No.2: if the editing Recipe is false, or not entering any
    // ingredients yet, the price is simply decided by the mainProduct:
    let grossProfit = undefined;
    let sellPrice = undefined;
    if (mainProduct.isSell) {
      grossProfit =
        calculateGrossProfit(mainProductSellPrice, BIG_ZERO);
      const mainProductSellPriceBig = new Big(mainProductSellPrice);
      sellPrice = mainProductSellPriceBig.toFixed(SHOW_PRECISION);
    }
    return {
      unitCost: BIG_ZERO,
      totalCost: BIG_ZERO,
      grossProfit,
      sellPrice,
    };
  }

  // all other cases need to deal with Ingredients:
  let totalCost: Big | undefined = BIG_ZERO;
  let unitCost = BIG_ZERO;
  let grossProfit = undefined;
  let sellPrice = undefined;

  currentEditingRecipe.recipeIngredients.forEach(
    (ing: IFormikIngredient) => {
      const quantity = GetProductCostFromCollection(
        ing,
        products,
        recipes,
      );
      totalCost = (totalCost as Big).add(quantity);
    }
  );

  const recipeType = currentEditingRecipe.consumptionType;
  let bigMainExpectYield = currentEditingRecipe.expectedYield ? new Big(
    currentEditingRecipe.expectedYield) : BIG_ZERO;

  // if recipeType is made to order
  // then expect yield should as 1;
  if (recipeType === EConsumptionType.madeToOrder) {
    bigMainExpectYield = new Big(1);
  }

  if (!bigMainExpectYield.eq(0)) {
    /**
     * Follow ticket https://kounta.atlassian.net/browse/PRO-447
     * default UOM for madeToOrder is Unit.
     */
    const currentUom = currentEditingRecipe.consumptionType === EConsumptionType.madeToOrder ?
      EUom.unit : currentEditingRecipe.uom;

    const mainPortion = stockPortionCalculation(
      mainProduct,
      currentUom,
      bigMainExpectYield,
    );
    unitCost = totalCost.div(mainPortion);
  }

  if (mainProduct.isSell) {
    grossProfit = calculateGrossProfit(mainProductSellPrice, unitCost);
    const mainProductSellPriceBig = new Big(mainProductSellPrice);
    sellPrice = mainProductSellPriceBig.toFixed(SHOW_PRECISION);
  }

  if (recipeType === EConsumptionType.madeToOrder) {
    totalCost = undefined;
  }

  return {
    unitCost,
    totalCost,
    grossProfit,
    sellPrice,
  };
};
