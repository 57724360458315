import { Guide } from '@kounta/chameleon';
import React from 'react';
import { IChameleonTooltipStep } from '../../typings/Chameleon';

interface IProps {
  steps: IChameleonTooltipStep[];
  callback: (data: any) => void;
}
export const ToolTipWrapper = React.memo(
  (props: IProps): JSX.Element => {
    return <Guide steps={props.steps} callback={props.callback} />;
  }
);
