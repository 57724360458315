import { createStore, Store } from 'redux';
import reducersCombined from './reducers';

export const storeCreator = (devToolEnabled: Boolean): Store => {
  let store: Store;
  if (devToolEnabled) {
    // Using Redux chrome debug tool:
    // http://extension.remotedev.io/
    store = createStore(
      reducersCombined,
      // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
        trace: true,
      })
    );
    // Below is for console debugging:
    // Log the initial state
    // console.log(store.getState());
    //
    // Every time the state changes, log it
    // store.subscribe(() =>
    //   console.log(store.getState())
    // );
  } else {
    store = createStore(reducersCombined);
  }
  return store
};
