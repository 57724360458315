import {
  EReduxActionType,
  IActionLoadStaff,
} from '../actions';
import { HelpPanelUserInfoType } from '@kounta/chameleon/dist/components/HelpPanel/types';

export interface IReduxStaff extends HelpPanelUserInfoType{
  hasEditRecipePermission: boolean;
  hasEditBatchPermission: boolean;

  email: string;
  image: string;

  // Those are for third party integration, eg: Intercom, churnZeroScript, segmentScript:
  staffTrackingId: number;
  personTrackingId: number;
  personCreatedAt: string;
  intercomHash: string;
}

export const initialStaff: IReduxStaff = {
  hasEditRecipePermission: false,
  hasEditBatchPermission: false,

  email: '',
  image: '',
  name: '',
  phone: '',

  // Those are for third party integration, eg: Intercom, churnZeroScript, segmentScript:
  staffTrackingId: 0,
  personTrackingId: 0,
  personCreatedAt: '',
  intercomHash: '',
};

export default function staffReducer(
  previousState: IReduxStaff = initialStaff,
  action: IActionLoadStaff,
): IReduxStaff {
  switch (action.type) {
    case EReduxActionType.LOAD_STAFF:
      return action.staff;
    default:
      return previousState;
  }
}
