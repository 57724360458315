import { object, string, number, array, mixed, boolean } from 'yup';
import {
  max12integer4decimal,
  MAX_NUMBER_OF_LETTERS_FOR_NOTE,
} from './Validator';
import { getIngredientValidationRules } from './RecipeSchema';
import { EBatchStatus } from '../typings/Interface';

const PRODUCT_NAME_MIN_LENGTH = 2;

export const testActualYield = (num: number | null | undefined): boolean => {
  if (num === null) {
    return true;
  }
  
  if (num === undefined) {
    return false;
  }
  
  return max12integer4decimal(num.toString()) && num > 0;
};

export const BatchSchema = object().shape({
  batchIngredients: array()
    .of(
      object().shape({
        ...getIngredientValidationRules(false),
        productName: string()
          .min(
            PRODUCT_NAME_MIN_LENGTH,
            `batchIngredient's productName too short`
          )
          .required(`batchIngredient's productName cannot be empty`),
      })
    )
    .required('Must have Ingredients') // these constraints are shown if and
    // only if inner constraints are
    // satisfied
    .min(1, 'Minimum of 1 Ingredients'),
  uuid: string(),
  recipeUuid: string(),
  productName: string()
    .min(PRODUCT_NAME_MIN_LENGTH, `batch's productName too short`)
    .required(`batch's productName cannot be empty`),
  status: number().oneOf((Object.values(EBatchStatus)) as number[]),
  isCustomYield: boolean(),
  expectedYield: number()
    .nullable(false)
    .test(
      'invalid expectedYield value',
      'Invalid expectedYield value',
      testActualYield
    ),
  // `actualYield` is nullable if the batch is in-progress
  actualYield: number()
    .nullable(true) // these constraints take precedence
    .test(
      'invalid ActualYield value',
      'Invalid ActualYield value',
      testActualYield
    ),
  plannedTime: mixed(),
  note: string()
    .nullable(true)
    .max(
      MAX_NUMBER_OF_LETTERS_FOR_NOTE,
      `Max ${MAX_NUMBER_OF_LETTERS_FOR_NOTE} letters`
    ),
});
