import {
  IPaginationResponse,
  IGraphQlPagingRequestFunction,
  IGraphQlRequestArgument,
} from '../../typings/Pagination';
import { IApiHandlerResult } from '../../typings/Interface';
import { restPagesHandler } from './AddRemainingPagesIntoRedux';

export async function replaceReduxListByPagingResponse<T>(
  graphQlRequest: IGraphQlPagingRequestFunction<T, IGraphQlRequestArgument>,
  firstPagingResponse: IApiHandlerResult<IPaginationResponse<T>>,
  reduxListReducerFunction: (list: T[]) => void,
): Promise<boolean> {
  let firstPageList: T[] = [];
  if (!firstPagingResponse.succeed) {
    return false;
  } else {
    firstPageList = firstPagingResponse.responseObj.edges;
  }

  return restPagesHandler(
    graphQlRequest,
    firstPagingResponse.responseObj.pageInfo,
    reduxListReducerFunction,
    firstPageList,
  );
}
