import { refreshTokenHandler } from '../auth/RefreshTokenHandler';

// ****** Functions to generate third-party-scripts:
// https://developers.google.com/tag-manager/quickstart
const getGtmHeadScript = (gtmKey: string): string =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmKey}');`;
const getGtmBodyHtml = (gtmKey: string): string =>
  `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmKey}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
const getSegmentHeadScript = (segmentKey: string): string =>
  `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";analytics.load("${segmentKey}");}}();`;

interface IThirdPartyScripts {
  gtmHeadScript: string;
  gtmBodyHtml: string;
  segmentHeadScript: string;
}

// ******* TEST env:
// 1. For AWS configuration:
const TEST_AWS_REGION = 'us-west-2';
const TEST_ID_POOL = 'us-west-2:fffb2d6a-42a5-41d9-bc33-0cd621c9523e';
export const TEST_AWS_CONFIG = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity
    // Pool ID
    identityPoolId: TEST_ID_POOL,

    // REQUIRED - Amazon Cognito Region
    region: TEST_AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources
    // or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is
    // provided) domain: '.yourdomain.com', // OPTIONAL - Cookie path path:
    // '/', // OPTIONAL - Cookie expiration in days expires: 365, // OPTIONAL -
    // Cookie secure flag secure: true },

    // OPTIONAL - customized storage object
    // storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is
    // 'USER_SRP_AUTH' authenticationFlowType: 'USER_PASSWORD_AUTH'

    // This refreshHandlers will be triggered when both the AWS credentials and the user token have expired.
    // And the whole process has to kick off with a request for the credentials:
    refreshHandlers: {
      developer: refreshTokenHandler,
    },
  },
  // https://aws-amplify.github.io/docs/js/analytics#manual-setup
  Analytics: {
    // disable Analytics, since we are not using them anyway.
    disabled: true,
  },
  aws_appsync_authenticationType: 'AWS_IAM',

  // The `dev` branch AppSync endpoint:
  aws_appsync_graphqlEndpoint:
    'https://zsbltjwkrvaddn4f4sllepc4xi.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: TEST_AWS_REGION,

  // aws_appsync_authenticationType: 'OPENID_CONNECT',
  // Before calling API.graphql(...) is required to do
  // Auth.federatedSignIn(...) check authentication guide for details.
};

// 2. For backoffice URL link:
export const TEST_BACKOFFICE_HOST = 'https://latest.test.kounta.com';

// 3. For Google tag manager:
const TEST_GTM_KEY = 'GTM-N8XJF76';
const TEST_GTM_HEAD_SCRIPT = getGtmHeadScript(TEST_GTM_KEY);
const TEST_GTM_BODY_HTML = getGtmBodyHtml(TEST_GTM_KEY);

// 4: For Segment:
const TEST_SEGMENT_KEY = 'vqAdj9Lf0FVmUCsMqgwwsTxs9sLHTcmp';
const TEST_SEGMENT_HEAD_SCRIPT = getSegmentHeadScript(TEST_SEGMENT_KEY);

export const TEST_THIRD_PARTY_SCRIPTS: IThirdPartyScripts = {
  gtmHeadScript: TEST_GTM_HEAD_SCRIPT,
  gtmBodyHtml: TEST_GTM_BODY_HTML,
  segmentHeadScript: TEST_SEGMENT_HEAD_SCRIPT,
};

// 5: Redux DevTool:
// https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f
export const TEST_ENABLE_REDUX_DEVTOOLS = true;

// 6: intercom
export const TEST_INTERCOM_APPID = "631xk4uu";

// ******** Production env:
const LIVE_AWS_REGION = 'ap-northeast-1';
const LIVE_ID_POOL = 'ap-northeast-1:bf320208-7f7e-4e14-9171-7c8a0a7a1c37';
export const LIVE_AWS_CONFIG = {
  ...TEST_AWS_CONFIG,
  aws_appsync_region: LIVE_AWS_REGION,
  Auth: {
    ...TEST_AWS_CONFIG.Auth,
    identityPoolId: LIVE_ID_POOL,
    region: LIVE_AWS_REGION,
  },
};

export const LIVE_BACKOFFICE_HOST = 'https://my.kounta.com';

const LIVE_GTM_KEY = 'GTM-53V89M';
const LIVE_SEGMENT_KEY = 'gNhXmPuNf9QLx3DykhK9aiEUCDmykoTa';
const LIVE_GTM_HEAD_SCRIPT = getGtmHeadScript(LIVE_GTM_KEY);
const LIVE_GTM_BODY_HTML = getGtmBodyHtml(LIVE_GTM_KEY);
const LIVE_SEGMENT_HEAD_SCRIPT = getSegmentHeadScript(LIVE_SEGMENT_KEY);

export const LIVE_THIRD_PARTY_SCRIPTS: IThirdPartyScripts = {
  gtmHeadScript: LIVE_GTM_HEAD_SCRIPT,
  gtmBodyHtml: LIVE_GTM_BODY_HTML,
  segmentHeadScript: LIVE_SEGMENT_HEAD_SCRIPT,
};

// Redux DevTool:
// https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f
export const LIVE_ENABLE_REDUX_DEVTOOLS = false;

// intercom
export const LIVE_INTERCOM_APPID = "186c9738a6c42e53dac79ae4ee12e616bc31a213";
