import {
  ERecipesListSearchEntity,
  RECIPES_LIST_SEARCH_LENGTH_THRESHOLD,
} from '../../../TextProvider';
import * as React from 'react';
import { Paragraph, Spacer } from '@kounta/chameleon';

const SHARED = 'Results for recipes ';
const CONNECT = {
  [ERecipesListSearchEntity.recipes]: 'named ',
  [ERecipesListSearchEntity.ingredients]: 'with ingredient ',
};

export const generateHelpTextPrefix =
  (selectedSearchDropdown: ERecipesListSearchEntity): string => {
    return `${SHARED}${CONNECT[selectedSearchDropdown]}`;
  }

interface IProps {
  dropDown: ERecipesListSearchEntity;
  searchText: string;
}

export const SearchRecipesHelpText = React.memo(
  (
    { dropDown, searchText }: IProps
  ): JSX.Element | null => {
    if (searchText.length < RECIPES_LIST_SEARCH_LENGTH_THRESHOLD) {
      return null;
    }
    return (
      <>
        <Spacer spacing="medium" />
        <Paragraph>
          {generateHelpTextPrefix(dropDown)}
          <b>"{searchText}"</b>
        </Paragraph>
      </>
    );
  }
);
