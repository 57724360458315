import * as React from 'react';
import { BoldDiv } from "../css/Styled";
import { AdvancedSelectOptionType } from '@kounta/chameleon';

export const EDIT_IN_BACKOFFICE_OPTION_VALUE = 'SPECIAL_EDIT_BK_UOM';
export const REDIRECT_LINK_TEXT = 'Edit Default';

// Adding extra `Edit in backoffice` to UOM dropDown
export const getEditInBackOfficeOption = (): AdvancedSelectOptionType => {
  return {
    label: <BoldDiv>{REDIRECT_LINK_TEXT}</BoldDiv>,
    value: EDIT_IN_BACKOFFICE_OPTION_VALUE,
  };
};
