import { ILayoutUiControlFuncs, Uuid } from '../../typings/Interface';
import { ELoadingTitle } from '../../TextProvider';

// Attention: Below is only returning a function, you will need to attach `()`
// to execute it.
//
// The reason we return only a function is because we need to use
// it as `onClick` call back.  Check:
// frontend/produce-webpage/src/components/loading-button/LoadingButton.tsx
//
// We also execute it programmatically, check:
// frontend/produce-webpage/src/containers/Layout.tsx
/**
 * This function is designed for loading purpose.
 * The use cases is set the loading state to true
 * before calling loading function
 * and set the loading state to false when loading function finished.
 */
export const loadingHandler = (
  loadingFunc: () => Promise<any>,
  showLoadingFunc: ILayoutUiControlFuncs['setLoading'],
  loadingMessage: ELoadingTitle = ELoadingTitle.loading
) => async () => {
  showLoadingFunc(true, loadingMessage);
  try {
    await loadingFunc();
  } finally {
    showLoadingFunc(false);
  }
};

export interface IGraphQlSubscriptionFunction {
  (
    companyOrSiteUuid: Uuid,
    subscriptionCallBacks: () => Promise<void>
  ): ZenObservable.Subscription
}

export interface IloadingHandlerWithReturnValueFunction {
  (
    loadingFunc: () => Promise<any>,
    showLoadingFunc: ILayoutUiControlFuncs['setLoading'],
    loadingMessage?: ELoadingTitle,
  ): (() => Promise<any>)
}

export const loadingHandlerWithReturnValue: IloadingHandlerWithReturnValueFunction = (
  loadingFunc: () => Promise<any>,
  showLoadingFunc: ILayoutUiControlFuncs['setLoading'],
  loadingMessage: ELoadingTitle = ELoadingTitle.loading,
) => async (): Promise<any> => {
  showLoadingFunc(true, loadingMessage);
  try {
    let result = null;
    await loadingFunc().then(values => {
      result = values;
    });
    return result;
  } finally {
    showLoadingFunc(false);
  }
};
