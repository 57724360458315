export const loadThirdPartyLib = (tagName: string, script: string) => {
  const element = document.getElementsByTagName(tagName)[0];
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.innerText = script;
  element.prepend(scriptElement);
};

export const loadThirdPartyLibToHead = (script: string) =>
  loadThirdPartyLib('head', script);

export const loadThirdPartyHtml = (tagName: string, html: string) => {
  const element = document.getElementsByTagName(tagName)[0];
  const scriptElement = document.createElement('noscript');
  element.prepend(scriptElement);
  element.insertAdjacentHTML('afterbegin', html);
};
export const loadThirdPartyHtmlToBody = (html: string) =>
  loadThirdPartyHtml('body', html);
