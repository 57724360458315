import {
  EConsumptionType,
  EQueryNames,
  ESubscriptionQueryNames,
  EUom,
  IApiHandlerResult,
  IApiRecipe,
  IFormikIngredient,
  IGraphQlSubscriptionFunction, ISaveRecipe,
  Uuid,
} from '../typings/Interface';
import {
  capitalizeFirstLetter,
  purifyIngredientsQuery,
  replaceLineBreakToBr,
} from './QueryStringPurify';
import {
  RECIPE_FIELDS,
  RECIPES_PAGING,
  SUBSCRIPTION_FIELDS,
} from './CommonFields';
import { graphQlApiHandler, graphQlSubscriptionHandler } from './Handler';
import {
  IPaginationEncodedRequest,
  IPaginationResponse,
  PAGINATION_LIMIT,
  IGraphQlPagingRequestFunction,
  IGraphQlRequestArgument,
} from '../typings/Pagination';

// 1. Get Recipes:
export const getRecipesQuery = (): string => {
  return `query ${capitalizeFirstLetter(EQueryNames.getRecipesWithPaging)}(
     $paginationRequest: PaginationRequestInput!) {
     ${EQueryNames.getRecipesWithPaging} (
        paginationRequest: $paginationRequest
     ){
       ${RECIPES_PAGING}
     }
  }`;
}

export const getRecipes: IGraphQlPagingRequestFunction<IApiRecipe, IGraphQlRequestArgument> = async (
  {
    base64EncodedCursor,
  }
): Promise<IApiHandlerResult<IPaginationResponse<IApiRecipe>>> => {
  const paginationRequest: IPaginationEncodedRequest = {
    limit: PAGINATION_LIMIT,
  };

  if (base64EncodedCursor) {
    paginationRequest.cursor = base64EncodedCursor;
  }

  const getRecipesQl = getRecipesQuery();

  const variable = { paginationRequest };
  return await graphQlApiHandler<IPaginationResponse<IApiRecipe>>(
    getRecipesQl,
    EQueryNames.getRecipesWithPaging,
    variable,
  );
}

// 2. Save Recipe:
export const saveRecipeQuery = (
  productUuid: Uuid,
  expectedYield: string,
  consumptionType: EConsumptionType,
  note: string,
  recipeIngredients: IFormikIngredient[],
  uom: EUom,
  recipeUuid: Uuid = '',
  // Unit cost should be non-null if recipe is made to order
  unitCost: number | null = null,
): string => {
  const recipeIngredientsQuery = purifyIngredientsQuery(recipeIngredients);

  // `expectedYield` is 0 if `consumptionType !== premade`
  let convertYieldToFloat: number = 0;
  if (consumptionType === EConsumptionType.premade) {
    convertYieldToFloat = parseFloat(expectedYield);
  }

  if (recipeUuid === '') {
    // 1. This is create:
    return `mutation ${capitalizeFirstLetter(EQueryNames.saveRecipe)} {
      ${EQueryNames.saveRecipe}(
        productUuid: "${productUuid}",
        note: "${replaceLineBreakToBr(note)}",
        consumptionType: ${consumptionType},
        expectedYield: ${convertYieldToFloat},
        recipeIngredients: ${recipeIngredientsQuery},
        uom: ${uom},
        unitCost: ${unitCost}
      ){
        ${RECIPE_FIELDS}
      }
    }`;
  }
  // 2. This is updated:
  return `mutation ${capitalizeFirstLetter(EQueryNames.saveRecipe)} {
    ${EQueryNames.saveRecipe}(
      uuid: "${recipeUuid}",
      productUuid: "${productUuid}",
      note: "${replaceLineBreakToBr(note)}",
      consumptionType: ${consumptionType},
      expectedYield: ${convertYieldToFloat},
      recipeIngredients: ${recipeIngredientsQuery},
      uom: ${uom},
      unitCost: ${unitCost}
    ){
      ${RECIPE_FIELDS}
    }
  }`;
};

export async function saveRecipe(
  recipeToSave: ISaveRecipe
): Promise<IApiHandlerResult<IApiRecipe>> {
  const unitCost = recipeToSave.unitCost === null ? null : recipeToSave.unitCost.toNumber();

  const createRecipeQl = saveRecipeQuery(
    recipeToSave.productUuid,
    recipeToSave.expectedYield,
    recipeToSave.consumptionType,
    recipeToSave.note,
    recipeToSave.recipeIngredients,
    recipeToSave.uom,
    recipeToSave.uuid,
    unitCost
  );

  return await graphQlApiHandler<IApiRecipe>(createRecipeQl, EQueryNames.saveRecipe);
}

// 3. delete Recipe:
export const deleteRecipeMutation = (recipeUuid: string): string =>
  `mutation ${capitalizeFirstLetter(EQueryNames.deleteRecipe)} {
    ${EQueryNames.deleteRecipe}(
      recipeUuid: "${recipeUuid}",
    ){
      ${RECIPE_FIELDS}
    }
  }
  `;

export async function deleteRecipe(
  recipeUuid: string
): Promise<IApiHandlerResult<IApiRecipe>> {
  const deleteRecipeQl = deleteRecipeMutation(recipeUuid);

  return await graphQlApiHandler<IApiRecipe>(deleteRecipeQl, EQueryNames.deleteRecipe);
}

// 4. getRecipeByProductUuid:
export const getRecipeByProductUuidQuery = (productUuid: Uuid): string =>
  `query ${capitalizeFirstLetter(EQueryNames.getRecipeByProduct)} {
     ${EQueryNames.getRecipeByProduct}(
       productUuid: "${productUuid}"
     ) {
       ${RECIPE_FIELDS}
     }
  }`;

export async function getRecipeByProductUuid(
  productUuid: Uuid
): Promise<IApiHandlerResult<IApiRecipe>> {
  const getRecipeByProductQl = getRecipeByProductUuidQuery(productUuid);

  return await graphQlApiHandler<IApiRecipe>(
    getRecipeByProductQl,
    EQueryNames.getRecipeByProduct
  );
}

export const recipeSubscriptionQuery = (companyUuid: Uuid): string =>
  `subscription ${ESubscriptionQueryNames.updatedRecipeByCompany} {
    ${
    ESubscriptionQueryNames.updatedRecipeByCompany
  }(companyUuid: "${companyUuid}") {
      ${SUBSCRIPTION_FIELDS}
    }
  }`;

export const recipeSubscription: IGraphQlSubscriptionFunction = (
  companyUuid: Uuid,
  subscriptionCallBacks: () => Promise<void>
): ZenObservable.Subscription => {
  return graphQlSubscriptionHandler(
    recipeSubscriptionQuery(companyUuid),
    ESubscriptionQueryNames.updatedRecipeByCompany,
    subscriptionCallBacks,
  );
};
