import {
  IApiBatch,
  IApiBatchIngredient,
  IFormikBatch,
  IFormikBatchIngredient,
} from '../typings/Interface';
import { initialDraftFormikBatch } from '../formik/batch/InitialDraftBatch';
import { isNotesSame } from '../graphql/QueryStringPurify';

// TODO:
// Consider using Formik `dirty` to check if values are not deeply equal from initial values
// https://jaredpalmer.com/formik/docs/api/formik#dirty-boolean

export function doesBatchHaveUnsavedChanges(
  existingBatch: IFormikBatch | false,
  currentDraftBatch: IFormikBatch
): boolean {
  if (currentDraftBatch === initialDraftFormikBatch) {
    // using initialDraft means there are no changes from user input:
    return false;
  }
  if (existingBatch === false) {
    // Create Batch, that means no existing recipe, there are draft for sure:
    return true;
  }

  if (
    existingBatch.batchIngredients.length !==
    currentDraftBatch.batchIngredients.length
  ) {
    return true;
  }
  for (let i: number = 0; i < existingBatch.batchIngredients.length; i++) {
    if (
      !isSameIngredient(
        existingBatch.batchIngredients[i],
        currentDraftBatch.batchIngredients[i]
      )
    ) {
      return true;
    }
  }

  // At the moment, user cannot change the `uom` of a batch, but in the future,
  // we might allow them. Here we compare all of them.
  const fieldRemains =
    currentDraftBatch.productName === existingBatch.productName &&
    isNotesSame(currentDraftBatch.note, existingBatch.note) &&
    currentDraftBatch.plannedTime === existingBatch.plannedTime &&
    currentDraftBatch.recipeUuid === existingBatch.recipeUuid &&
    currentDraftBatch.uom === existingBatch.uom &&
    currentDraftBatch.isCustomYield === existingBatch.isCustomYield &&
    currentDraftBatch.expectedYield === existingBatch.expectedYield;
  return !fieldRemains;
}

export const convertApiBatchToFormikBatch = (
  existingBatch: IApiBatch
): IFormikBatch => {
  const newIngs: IFormikBatchIngredient[] = existingBatch.batchIngredients.map(
    (ing: IApiBatchIngredient): IFormikBatchIngredient => ({
      componentQty: ing.componentQty.toString(),
      productUuid: ing.productUuid,
      productName: ing.productName,
      uom: ing.uom,
      additionalIngredientFlag: ing.additionalIngredientFlag,
    })
  );
  return {
    ...existingBatch,
    batchIngredients: newIngs,
  };
};

const isSameIngredient = (
  ingredientA: IFormikBatchIngredient,
  ingredientB: IFormikBatchIngredient
): boolean => {
  return (
    ingredientA.productUuid === ingredientB.productUuid &&
    ingredientA.componentQty === ingredientB.componentQty &&
    ingredientA.productName === ingredientB.productName &&
    ingredientA.uom === ingredientB.uom &&
    ingredientA.additionalIngredientFlag === ingredientB.additionalIngredientFlag
  );
};
