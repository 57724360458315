import { IBatchFormikProps } from '../typings/Formik';

export const formValidationFunction = (
  validateFormFunction: IBatchFormikProps['validateForm'],
  submitForm: IBatchFormikProps['submitForm'],
  callBack: () => void
) => {
  return async () => {
    await submitForm();
    const validateResult = await validateFormFunction();

    if (Object.keys(validateResult).length === 0) {
      // console.log('validation passed!');
      await callBack();
    } else {
      // We haven't decide what we do if validation failed. (error message?)
      // console.log(validateResult);
      // console.log('validation error out! ');
    }
  };
};
