import { getRecipes } from '../graphql/RecipeQL';
import { EProductInventoryType, getProducts } from '../graphql/ProductQL';
import { getBatches } from '../graphql/BatchQL';
import {
  EModalType,
  ESnackBarType,
  IApiBatch,
  IApiHandlerResult,
  IApiProduct,
  IApiRecipe,
  IHandleModalParams,
  ILayoutUiControlFuncs,
} from '../typings/Interface';
import {
  IAddProductsByInventory,
  IFetchAllInOne,
  IOnAddOrReplaceList,
  IOnLoadBillingPlan,
  IOnSetProductsFullyLoaded,
  IOnSetRecipesFullyLoaded,
  IOnSetRemainingBatchAllowance,
  IReplaceBatchesList,
  IUpdateBillingPlanFunction,
  IUpdateListFunction,
} from './typings/Interface';
import { getBillingPlan } from '../graphql/BillingPlanQL';
import { getRemainingBatchAllowance } from '../graphql/BatchAllowanceQL';
import {
  IPaginationEncodedFeedback,
  IPaginationResponse,
} from '../typings/Pagination';
import { replaceReduxListByPagingResponse } from '../utils/pagination/ReplaceReduxListByPagingResponse';
import { add1stPageIntoRedux } from '../utils/pagination/Add1stPageIntoRedux';
import { addRemainingPagesIntoRedux } from '../utils/pagination/AddRemainingPagesIntoRedux';

export const UPDATE_RECIPE_LIST_FAILED_MESSAGE = 'update RecipesList failed';
export const UPDATE_BATCH_LIST_FAILED_MESSAGE = 'update BatchesList failed';
export const UPDATE_PRODUCTS_LIST_FAILED_MESSAGE = 'update ProductsList failed';
export const LOAD_BILLING_AND_ALLOWANCE_FAILED_MESSAGE = 'load BillingPlan or batch allowance failed';

export const ERROR_SNACKBAR_PARAMS_FOR_RECIPES: IHandleModalParams = {
  snackBarMessage: UPDATE_RECIPE_LIST_FAILED_MESSAGE,
  snackBarType: ESnackBarType.error,
  modalType: EModalType.SNACK_BAR,
};

export const ERROR_SNACKBAR_PARAMS_FOR_BATCHES: IHandleModalParams = {
  snackBarMessage: UPDATE_BATCH_LIST_FAILED_MESSAGE,
  snackBarType: ESnackBarType.error,
  modalType: EModalType.SNACK_BAR,
};

export const ERROR_SNACKBAR_PARAMS_FOR_BILLING_ALLOWANCE: IHandleModalParams = {
  snackBarMessage: LOAD_BILLING_AND_ALLOWANCE_FAILED_MESSAGE,
  snackBarType: ESnackBarType.error,
  modalType: EModalType.SNACK_BAR,
};

export const ERROR_SNACKBAR_PARAMS_FOR_PRODUCTS: IHandleModalParams = {
  snackBarMessage: UPDATE_PRODUCTS_LIST_FAILED_MESSAGE,
  snackBarType: ESnackBarType.error,
  modalType: EModalType.SNACK_BAR,
};

export const add1stFetchRecipesIntoRedux: IUpdateListFunction<IApiRecipe, false | IPaginationEncodedFeedback> = async (
  onAddPartialRecipes: IOnAddOrReplaceList<IApiRecipe>,
  handleModal: ILayoutUiControlFuncs['handleModal'],
): Promise<false | IPaginationEncodedFeedback> => {
  const paginationResponse = await add1stPageIntoRedux<IApiRecipe>(
    getRecipes,
    onAddPartialRecipes,
  ) as IPaginationResponse<IApiRecipe>;
  if (!paginationResponse.pageInfo) {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_RECIPES);
    return false;
  }
  return paginationResponse.pageInfo;
}

export const add1stFetchBatchesIntoRedux: IUpdateListFunction<IApiBatch, false | IPaginationEncodedFeedback> = async (
  onAddPartialBatches: IOnAddOrReplaceList<IApiBatch>,
  handleModal: ILayoutUiControlFuncs['handleModal'],
): Promise<false | IPaginationEncodedFeedback> => {
  const paginationResponse = await add1stPageIntoRedux<IApiBatch>(
    getBatches,
    onAddPartialBatches,
  ) as IPaginationResponse<IApiBatch>;
  if (!paginationResponse.pageInfo) {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_BATCHES);
    return false;
  }
  return paginationResponse.pageInfo;
}

/**
 * Lazy load subsequent Recipes After First Fetch.
 */
export const addSubsequentRecipesAfterFirstFetch = async (
  firstFetchPageInfo: IPaginationEncodedFeedback,
  onAddPartialRecipes: IOnAddOrReplaceList<IApiRecipe>,
  handleModal: ILayoutUiControlFuncs['handleModal'],
  onSetRecipesFullyLoaded: IOnSetRecipesFullyLoaded,
): Promise<boolean> => {
  const isSuccessful = await addRemainingPagesIntoRedux<IApiRecipe>(
    getRecipes,
    firstFetchPageInfo,
    onAddPartialRecipes,
  );
  if (!isSuccessful) {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_RECIPES);
    return false;
  }
  onSetRecipesFullyLoaded(true);
  return true;
}

/**
 * Lazy load subsequent Batches After First Fetch.
 */
export const addSubsequentBatchesAfterFirstFetch = async (
  firstFetchPageInfo: IPaginationEncodedFeedback,
  onAddPartialBatches: IOnAddOrReplaceList<IApiBatch>,
  handleModal: ILayoutUiControlFuncs['handleModal'],
): Promise<boolean> => {
  const isSuccessful = await addRemainingPagesIntoRedux<IApiBatch>(
    getBatches,
    firstFetchPageInfo,
    onAddPartialBatches
  );
  if (!isSuccessful) {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_BATCHES);
    return false;
  }
  return true;
}

export const replaceRecipesList: IUpdateListFunction<IApiRecipe, boolean> = async (
  onLoadingRecipes: IOnAddOrReplaceList<IApiRecipe>,
  handleModal: ILayoutUiControlFuncs['handleModal']
): Promise<boolean> => {
  // 1. First initial fetch, with no `cursor`:
  let currentResponse = await getRecipes({});
  const isSuccessful = await replaceReduxListByPagingResponse<IApiRecipe>(
    getRecipes,
    currentResponse,
    onLoadingRecipes,
  );

  if (!isSuccessful) {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_RECIPES);
    return false;
  }
  return true;
};

export const replaceBatchesList: IReplaceBatchesList = async (
  onLoadingBatches: IOnAddOrReplaceList<IApiBatch>,
  onSetRemainingBatchAllowance: IOnSetRemainingBatchAllowance,
  handleModal: ILayoutUiControlFuncs['handleModal'],
): Promise<boolean> => {

  // 1. First initial fetch, with no `cursor`:
  let currentResponse = await getBatches({});
  const isSuccessful = await replaceReduxListByPagingResponse<IApiBatch>(
    getBatches,
    currentResponse,
    onLoadingBatches,
  );

  if (!isSuccessful) {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_BATCHES);
    return false;
  }
  // Utilise the existing batch event subscriptions.
  // If the site is Lite or Advance Recipe, and batch changes event
  // triggered, we calculate batch allowance again.
  const batchAllowance = await getRemainingBatchAllowance();
  if (batchAllowance.succeed) {
    onSetRemainingBatchAllowance(
      batchAllowance.responseObj.remainingBatchAllowance);
    return true;
  } else {
    handleModal(ERROR_SNACKBAR_PARAMS_FOR_BATCHES);
    return false;
  }
};

export const addProductsByInventoryType: IAddProductsByInventory = async (
  replaceOrAddProducts: IOnAddOrReplaceList<IApiProduct>,
  handleModal: ILayoutUiControlFuncs['handleModal'],
  inventoryType?: EProductInventoryType,
): Promise<boolean> => {
  // make sure clone the object, instead of mutate it:
  let errorParams = { ...ERROR_SNACKBAR_PARAMS_FOR_PRODUCTS };
  let currentResponse: IApiHandlerResult<IPaginationResponse<IApiProduct>>;

  // 1. First initial fetch, with no `cursor`:
  if (inventoryType === undefined) {
    currentResponse = await getProducts({});
    errorParams.snackBarMessage += ' for all inventory types';
  } else {
    currentResponse = await getProducts({
      inventoryType,
    });
    errorParams.snackBarMessage +=
      (inventoryType === EProductInventoryType.madeHere)
        ? ' for made here products'
        : ' for purchased products';
  }

  const isSuccessful = await replaceReduxListByPagingResponse<IApiProduct>(
    getProducts,
    currentResponse,
    replaceOrAddProducts,
  );

  if (!isSuccessful) {
    handleModal(errorParams);
    return false;
  }
  return true;
};

// We don't change `isProductsFullyLoaded` flag when we fetch `MadeHere`
// products. Because `madeHere` products fetched first, then `purchased`
// products. `isProductsFullyLoaded` is set to true only after fetching
// `purchased` products.
export const loadMadeHereProductsList: IUpdateListFunction<IApiProduct, boolean> = async (
  onReplaceWholeProductsList: IOnAddOrReplaceList<IApiProduct>,
  handleModal: ILayoutUiControlFuncs['handleModal']
): Promise<boolean> => {
  return addProductsByInventoryType(
    onReplaceWholeProductsList,
    handleModal,
    EProductInventoryType.madeHere,
  );
};

// Lazy load other Products (Purchase)
export const loadPurchaseProductsList = async (
  onAddPartialProducts: IOnAddOrReplaceList<IApiProduct>,
  onSetProductsFullyLoaded: IOnSetProductsFullyLoaded,
  handleModal: ILayoutUiControlFuncs['handleModal']
): Promise<boolean> => {
  const result = await addProductsByInventoryType(
    onAddPartialProducts,
    handleModal,
    EProductInventoryType.purchase,
  );

  if (result) {
    onSetProductsFullyLoaded(true);
    return true;
  }
  return false;
};

export const updateBillingPlan: IUpdateBillingPlanFunction = async (
  onLoadBillingPlan: IOnLoadBillingPlan,
  onSetRemainingBatchAllowance: IOnSetRemainingBatchAllowance,
  handleModal: ILayoutUiControlFuncs['handleModal']
): Promise<boolean> => {
  const billingPlan = await getBillingPlan();
  if (billingPlan.succeed) {
    onLoadBillingPlan(billingPlan.responseObj);

    // Get Batch Allowance from API endpoint
    // 1. Every time Billing Plan event triggered,
    //    Fetch Allowance from Produce AppSync api.
    // It does not need `Batches` info, the result is from backend so always
    // accurate.
    const batchAllowance = await getRemainingBatchAllowance();
    if (batchAllowance.succeed) {
      onSetRemainingBatchAllowance(
        batchAllowance.responseObj.remainingBatchAllowance);
      return true;
    }
  }
  handleModal(ERROR_SNACKBAR_PARAMS_FOR_BILLING_ALLOWANCE);
  return false;
};

/**
 * We only load the most essential data that required in the landing page:
 * eg:
 *  1. the first pagination of products/recipes/batches.
 *  2. site billing plan info.
 * The rest of the data, like remaining pagination of the
 * products/recipes/batches, can be lazy loaded afterward.
 */
export const initialFetchOfProductsRecipesBatchesAndPlanRestrictions: IFetchAllInOne = (
  // Below 4 functions are graphQL requests:
  add1stFetchRecipesIntoRedux: IUpdateListFunction<IApiRecipe, false | IPaginationEncodedFeedback>,
  add1stFetchBatchesIntoRedux: IUpdateListFunction<IApiBatch, false | IPaginationEncodedFeedback>,
  updateBillingPlan: IUpdateBillingPlanFunction,
  loadMadeHereProductsList: IUpdateListFunction<IApiProduct, boolean>,

  // Below 5 functions are Redux reducer actions:
  onAddPartialRecipes: IOnAddOrReplaceList<IApiRecipe>,
  onAddPartialBatches: IOnAddOrReplaceList<IApiBatch>,
  onReplaceWholeProductsList: IOnAddOrReplaceList<IApiProduct>,
  onLoadBillingPlan: IOnLoadBillingPlan,
  onSetRemainingBatchAllowance: IOnSetRemainingBatchAllowance,
  // Below is UI function to show error message, if one or more of the requests errors out:
  handleModal: ILayoutUiControlFuncs['handleModal'],
): (() => Promise<(boolean | IPaginationEncodedFeedback)[]>) => {
  // Without `() => func(arg1, arg2)`, the function will be executed
  // immediately. In order to use `Promise.all()` to concurrently requests all
  // Promise, we wrap all the requests into a `() => func(arg1, arg2)` format,
  // and only
  const listOfOperations: (() => Promise<boolean | IPaginationEncodedFeedback>)[] =
    [
      (() => add1stFetchRecipesIntoRedux(onAddPartialRecipes, handleModal)),
      (() => add1stFetchBatchesIntoRedux(onAddPartialBatches, handleModal)),
      (() => updateBillingPlan(
          onLoadBillingPlan,
          onSetRemainingBatchAllowance,
          handleModal
        )
      ),
      // Fetch all madeHere products. After that we can safely assure
      // `recipes` --> `main product` always has a match with `products` list:
      (() => loadMadeHereProductsList(onReplaceWholeProductsList, handleModal)),
    ];

  /**
   * The order of the promises is maintained. The first promise in the array
   * will get resolved to the first element of the output array,
   * the second promise will be a second element in the output array and so on.
   * https://www.freecodecamp.org/news/promise-all-in-javascript-with-example-6c8c5aea3e32/
   */
  const promiseAllFunction = () =>
    Promise.all(listOfOperations.map(
      (promiseFunction: (() => Promise<boolean | IPaginationEncodedFeedback>)) => {
        // In the below line, two things happen.
        // 1. We are calling the async function. So at this point
        //    the async function has started and enters the 'pending' state.
        // 2. We are pushing the pending promise to an array (using `map()`).
        return promiseFunction();
      }
    ));
  return promiseAllFunction;
};
