import { longDateTimeFormat } from '../../TextProvider';
import Moment from 'react-moment';
import React from 'react';

const defaultDate = '-';

interface IProps {
  dateStr: string | null;
  dtFormat?: string;
}

// Pure Functional components!
// https://logrocket.com/blog/pure-functional-components/
// https://blog.bitsrc.io/improve-performance-in-react-functional-components-using-react-memo-b2e80c11e15a?gi=94b3239d0e8d
export const DateRender = React.memo(
  ({
     dateStr,
     dtFormat = longDateTimeFormat
   }: IProps): JSX.Element => {
    return dateStr ? (
      <Moment format={dtFormat}>{dateStr}</Moment>
    ) : (
      <label>{defaultDate}</label>
    );
  }
);
