import {
  EBatchStatus,
  EProductInventoryType,
  IApiBatch,
  IApiProduct,
} from '../../typings/Interface';

export const isBatchCompleted = (batch: IApiBatch): boolean =>
  batch.status === EBatchStatus.completed;

// Currently, the BO has the duplicate attributes(isBuy and inventoryType) to decide if the product is
// made here or not. When BO decides to deprecate one attribute, this function
// needs to be changed at the same time.
//
// Attention: This `inventoryType` is on the `Products` level, from Backoffice
// API response.
// Meanwhile we have Produce `Recipes` `EConsumptionType`.
// They are completely different, don't mixed up.
export const isProductMadeHere = (product: IApiProduct): boolean => {
  return (
    product.isBuy === false &&
    product.inventoryType === EProductInventoryType.madeHere
  );
};
