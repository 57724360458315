import { IApiProduct } from '../../typings/Interface';
import { getProductByNameFromList } from '../../utils/product/GetProductByUuidFromList';
import { initialDraftFormikBatch } from './InitialDraftBatch';
import { IFormikResetForm } from '../../typings/Formik';
import { AdvancedSelectOptionType } from '@kounta/chameleon';

export const handleMainProductNameOnBlur = (
  resetForm: IFormikResetForm,
  products: IApiProduct[],
) => {
  return (option: AdvancedSelectOptionType | null) => {
    const optionValue = option ? (option.label as string) : '';
    if (!getProductByNameFromList(optionValue, products)) {
      resetForm({ values: initialDraftFormikBatch});
    }
  };
};
