import { HelpPanelItemType, IconArticle } from "@kounta/chameleon";
import { REACT_ROUTER_URL_LIST } from '../../TextProvider';
import React from 'react';
import { findMatchingUrlPath } from '../../utils/url/FindMatchingUrlPath';

const relatedItemGeneral: HelpPanelItemType = {
  id: 1,
  value: 'Getting around Lightspeed Produce',
  icon: <IconArticle/>,
  action: () => {
    window.open('https://support.kounta.com/hc/en-us/articles/360001215535-Getting-Around-Kounta-Produce');
  },
};

export const relatedItemsListForRecipe: HelpPanelItemType[] = [
  relatedItemGeneral,
  {
    id: 2,
    value: 'Creating recipes',
    icon: <IconArticle/>,
    action: () => {
      window.open('https://support.kounta.com/hc/en-us/articles/360001215595-Creating-Recipes-Made-to-Order-Batches');
    },
  },
  {
    id: 3,
    value: 'Managing recipe costs',
    icon: <IconArticle/>,
    action: () => {
      window.open('https://support.kounta.com/hc/en-us/articles/360001290655-Managing-your-recipe-cost-profit');
    },
  },
  {
    id: 4,
    value: 'Managing user settings',
    icon: <IconArticle/>,
    action: () => {
      window.open('https://support.kounta.com/hc/en-us/articles/203387944-Managing-User-Settings');
    },
  },
];

export const relatedItemsListForBatch: HelpPanelItemType[] = [
  relatedItemGeneral,
  {
    id: 2,
    value: 'Managing prep',
    icon: <IconArticle/>,
    action: () => {
      window.open('https://support.kounta.com/hc/en-us/articles/360001215996-Schedule-and-track-prep');
    },
  }
];

const RELATED_ITEMS = {
  [REACT_ROUTER_URL_LIST.default]: relatedItemsListForRecipe,
  [REACT_ROUTER_URL_LIST.recipes]: relatedItemsListForRecipe,
  [REACT_ROUTER_URL_LIST.singleRecipe]: relatedItemsListForRecipe,
  [REACT_ROUTER_URL_LIST.createBatch]: relatedItemsListForBatch,
  [REACT_ROUTER_URL_LIST.incompleteBatches]: relatedItemsListForBatch,
  [REACT_ROUTER_URL_LIST.completedBatches]: relatedItemsListForBatch,
  [REACT_ROUTER_URL_LIST.incompleteSingleBatch]: relatedItemsListForBatch,
  [REACT_ROUTER_URL_LIST.completedSingleBatch]: relatedItemsListForBatch,
};

export const getRelatedArticlesByPathName = (pathName: string): HelpPanelItemType[] => {
  const matchedUrlPath = findMatchingUrlPath(pathName);
  return RELATED_ITEMS[matchedUrlPath];
};
