import {
  EButtonType,
} from '../../typings/Chameleon';
import { getProductsListExcludeMainProductAndIngredientProduct } from '../../utils/product/GetProductsListExcludeMainProductAndIngredientProduct';
import {
  getProductByUuidFromList,
} from '../../utils/product/GetProductByUuidFromList';
import {
  EConsumptionType,
  EFormikIngredientsFieldName,
  IApiProduct,
  IApiRecipe,
  IFormikIngredient,
  IFormikRecipe,
} from '../../typings/Interface';
import ProductNameRow from './ProductNameRow';
import * as React from 'react';
import UomRow from './UomRow';
import { QtyRow } from './QtyRow';
import { CostRow } from './CostRow';
import { GetProductCostFromCollection } from '../../utils/recipe-cost/GetProductCostFromCollection';
import {
  IconButton,
  IconDelete,
  TableCellType,
  TableRowType,
} from "@kounta/chameleon";
import { BIG_ZERO } from '../../utils/number-format/BigNumberHandler';
import { IOnAddOrReplaceList } from '../../redux/typings/Interface';

export const generateRecipeIngRow = (
  products: IApiProduct[],
  recipes: IApiRecipe[],
  currentEditingRecipe: IFormikRecipe,
  isLitePlan: boolean,
  shouldProductNameAutoFocus: boolean,
  shouldShowUomDropDownRedirectToBackOffice: boolean,
  removeIngredient: (arrayIndex: number) => () => void,
  enableToolTip: () => void,
  renderTooltip: (arrayIndex: number) => JSX.Element | null,
  showSnackBarCallback: () => void,
  onLoadingProducts: IOnAddOrReplaceList<IApiProduct>,
): TableRowType[] => {

  // User cannot select main product as ingredient.
  // User cannot select existing Ingredient as new ingredient again.
  const productCandidatesForIngredientInput = getProductsListExcludeMainProductAndIngredientProduct(
    products,
    currentEditingRecipe.recipeIngredients,
    currentEditingRecipe.productUuid
  );

  const mainProduct = getProductByUuidFromList(currentEditingRecipe.productUuid,
    products);

  if (!mainProduct) {
    throw Error('recipe product cannot found');
  }
  
  const addNewProductCallback = (product: IApiProduct) => {
    /**
     * To avoid:
     * TypeError: Cannot add property 38, object is not extensible at Array.push
     * https://stackoverflow.com/questions/52492093/typeerror-cannot-add-property-1-object-is-not-extensible-at-array-push-anon
     */
    const newProducts = [...products, product] as IApiProduct[];
    onLoadingProducts(newProducts);
  };
  
  const result = currentEditingRecipe.recipeIngredients.map(
    (ing: IFormikIngredient, arrayIndex: number): TableRowType => {
      const dropDownProduct = getProductByUuidFromList(ing.productUuid, products) as IApiProduct;

      const nameRow: JSX.Element = (
        <ProductNameRow
          fieldNamePrefix={EFormikIngredientsFieldName.recipeIngredients}
          arrayIndex={arrayIndex}
          products={productCandidatesForIngredientInput}
          selectedProductName={dropDownProduct.name}
          enableToolTip={enableToolTip}
          shouldProductNameAutoFocus={shouldProductNameAutoFocus}
          showSnackBar={showSnackBarCallback}
          addNewProductCallback={addNewProductCallback}
        />
      );

      const uomDropDownRow: JSX.Element = (
        <React.Fragment>
          {renderTooltip(arrayIndex)}
          <UomRow
            fieldNamePrefix={EFormikIngredientsFieldName.recipeIngredients}
            arrayIndex={arrayIndex}
            product={dropDownProduct}
          />
        </React.Fragment>
      );

      /**
       * Dealing with QTY
       * Only `madeToOrder` recipes are allowed to have negative qty:
       */
      const qtyRow: JSX.Element = (
        <QtyRow
          fieldNamePrefix={EFormikIngredientsFieldName.recipeIngredients}
          arrayIndex={arrayIndex}
          allowNegative={
            currentEditingRecipe.consumptionType ===
            EConsumptionType.madeToOrder
          }
        />
      );

      const nameAndQtyRows: TableCellType[] = [
        {
          id: 1,
          value: nameRow,
        },
        {
          id: 2,
          value: qtyRow,
        },
        {
          id: 3,
          value: uomDropDownRow,
        },
      ];

      let costRow: JSX.Element = (
        <CostRow
          quantity={BIG_ZERO}
          shouldHidePrice={true}
        />
      );
      if (!isLitePlan) {
        const quantity = GetProductCostFromCollection(
          ing,
          products,
          recipes,
        );

        costRow = (
          <CostRow
            quantity={quantity}
            shouldHidePrice={false}
          />
        );
      }

      nameAndQtyRows.push({
        id: 4,
        value: costRow,
      });

      const removeIngredientRow = {
        id: 5,
        value: (
          <IconButton
            type={EButtonType.button}
            onClick={removeIngredient(arrayIndex)}
          >
            <IconDelete width={20} height={20}/>
          </IconButton>
        ),
      };

      if (currentEditingRecipe.recipeIngredients.length > 1) {
        nameAndQtyRows.push(removeIngredientRow);
      }

      // the `key` is a combination of both arrayIndex and
      // productUuid. This gives react certain stability when
      // re-rendering occurs.
      // It considered `initial` case and `update` case.
      return {
        id: `${arrayIndex}_${ing.productUuid}`,
        content: nameAndQtyRows,
      };
    }
  );
  return result;
};


