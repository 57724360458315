import React from "react";
import { CostFieldDiv, CostLabel } from "../../css/Styled";
import { Alignment, Group, NumberInput } from "@kounta/chameleon";
import { calculateGrossProfit } from "../../utils/recipe-cost/CalculateSellPriceAndGrossProfit";
import Big from 'big.js'
import { CURRENCY_SYMBOL, sellPriceLabelProvider } from '../../TextProvider';
import { getNumberInputPropsForReadonlyAndBlur } from './GetNumberInputPropsForReadonlyAndBlur';

// Used for Heap tracking: https://kounta.atlassian.net/browse/PRO-490
const SELL_PRICE_DOM_ID = 'sell_price_input';

export interface ISellPriceSectionProps {
  originalSellPrice: string;
  sellPrice: string;
  unitCost: Big;
  setGrossProfitAndSellPrice: (
    sellPrice: string,
    grossProfit: string
  ) => void;
  shouldBlurPrice: boolean;
  readOnly: boolean;
}

export const SellPriceSection = React.memo((
  props: ISellPriceSectionProps
): JSX.Element => {
  /**
   * When user delete the whole input, the value will be empty string ``,
   * it will not be `undefined` or `null`.
   */
  const onSellPriceChange = (
    userInputSellPrice: string,
    isValidate: boolean
  ): void => {
    if (!isValidate) {
      return;
    }
    const grossProfit = calculateGrossProfit(userInputSellPrice,
      props.unitCost);
    props.setGrossProfitAndSellPrice(
      userInputSellPrice,
      grossProfit,
    );
  };

  return (
    <Alignment horizontal={'right'} vertical={'center'}>
      <Group horizontal align={'right'}>
        <CostLabel align={'right'}>
          {sellPriceLabelProvider(
            new Big(props.originalSellPrice).eq(new Big(props.sellPrice))
          )}
        </CostLabel>
        <CostFieldDiv>
          <NumberInput
            id={SELL_PRICE_DOM_ID}
            prefix={CURRENCY_SYMBOL}
            value={props.sellPrice}
            align={'right'}
            onChange={onSellPriceChange}
            maxFractionLength={2}
            minFractionLength={0}
            {...getNumberInputPropsForReadonlyAndBlur(
              props.readOnly,
              props.shouldBlurPrice,
            )}
          />
        </CostFieldDiv>
      </Group>
    </Alignment>
  )
});
