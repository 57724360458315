import {
  EQueryNames,
  IApiHandlerResult,
  IApiSubscribedSiteInfo,
  ISiteInfo,
} from '../typings/Interface';
import { graphQlApiHandler } from './Handler';
import { capitalizeFirstLetter } from './QueryStringPurify';

export const getSitesQuery = (): string => {
  return `
      query ${capitalizeFirstLetter(EQueryNames.getProduceSites)} {
        ${EQueryNames.getProduceSites} {
          siteUuid
          siteName
        }
      }
  `;
};

export async function getSubscribedProduceSites(): Promise<IApiHandlerResult<IApiSubscribedSiteInfo[]>> {
  const getSitesQl = getSitesQuery();
  return await graphQlApiHandler<IApiSubscribedSiteInfo[]>(
    getSitesQl,
    EQueryNames.getProduceSites
  );
}

export const getSiteInfoQuery = (): string => {
  return `
      query ${capitalizeFirstLetter(EQueryNames.getSite)} {
        ${EQueryNames.getSite} {
          siteUuid
          siteName
      
          siteNumericId
          companyUuid
          companyNumericId
          companyName
          siteHasImageUrl
          siteImageUrl
        }
      }
  `;
};

export async function getSiteInfo(): Promise<IApiHandlerResult<ISiteInfo>> {
  const getSitesQl = getSiteInfoQuery();
  return await graphQlApiHandler<ISiteInfo>(
    getSitesQl,
    EQueryNames.getSite
  );
}
