import React from 'react';
import { Box, Loading } from "@kounta/chameleon";

const LOADING_INGREDIENTS_TITLE = 'Loading ingredients';

export const LoadingIngredients = React.memo(() => (
  <Box>
    <Loading
      isLoading={true}
      title={LOADING_INGREDIENTS_TITLE}
      overlay={false}
      usedOnDarkBackground={false}
    />
  </Box>
));
