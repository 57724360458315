import { IApiProduct } from '../../typings/Interface';
import { IAutoSuggestOptions } from '../../typings/Chameleon';

export const getProductNamesForAutoComplete = (
  products: IApiProduct[]
): IAutoSuggestOptions[] => {
  return products.map(
    (product: IApiProduct): IAutoSuggestOptions => {
      return {
        value: product.name,
      };
    }
  );
};
