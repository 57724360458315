import React from 'react';
import { Group, IconButton, IconMenu, TitleHeader } from "@kounta/chameleon";
import Media from "react-media";
import styled from "styled-components";
import { IProduceHeader, ProduceHeader } from "./ProduceHeader";
import { MEDIA_QUERY_BREAK_POINT } from '../../css/Styled';

interface IProps extends IProduceHeader{
  showNavYn: () => void;
}

const ProduceTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  padding: 0px 0px 0px 16px;
  margin: 0;
`;

export const ProduceTitleHeader = React.memo((props: IProps): JSX.Element => {

  const navHeader = (
    <Group horizontal spacing={'0px'}>
      <IconButton size={'small'} onClick={props.showNavYn}>
        <IconMenu width={24} height={24} />
      </IconButton>
      <ProduceTitle>{props.title}</ProduceTitle>
    </Group>
  );

  return (
    <Media query={MEDIA_QUERY_BREAK_POINT}>
      {
        (matches: boolean): JSX.Element => {
          if (matches) {
            return <ProduceHeader
              title={navHeader}
              content={props.content}
            />
          } else {
            return <TitleHeader
              title={props.title}
              content={props.content}
            />
          }
        }
      }
    </Media>
  );
});
