import * as React from 'react';
import { getCognitoTokenFromCookie } from '../persister/Cookie';
import { Main } from './Main';
import { redirectToBackOfficeProduceLoginPage } from '../utils/url/UrlRedirect';

/**
 * This is the root wrapper
 */
export default function CookieDealer() {
  // Cookie is eventually `String`, to make sure the child component does not
  // run useEffect after every rendering when cookie doesn't change, we need to
  // make sure we are passing cookie as `String` instead of `Object`.  Using
  // `JSON.parse(cognitoObj)` will always create a new object, and will force
  // the Child component (Main.tsx) always trigger a `useEffect`, even we
  // already passed the second argument [userInfo, siteInfo, cognitoObj] in its
  // useEffect function.
  const cognitoObj = getCognitoTokenFromCookie();

  if (cognitoObj === null) {
    redirectToBackOfficeProduceLoginPage();
    return null;
  }

  return (
    <Main
      cognitoObj={cognitoObj}
    />
  );
}
