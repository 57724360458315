import * as React from 'react';
import { Button } from '@kounta/chameleon';
import { loadingHandler } from '../../utils/loader/LoadingHandler';
import { EButtonType, IButtonProps } from '../../typings/Chameleon';

interface IState {
  isLoading: boolean;
}

const defaultProps: IButtonProps = {
  onClick: async () => {},
  fullWidth: false,
  primary: false,
  slim: false,
  secondary: false,
  disabled: false,
  buttonText: '',
};

export default class LoadingButton extends React.Component<
  IButtonProps,
  IState
> {
  static defaultProps: IButtonProps = defaultProps;
  readonly state: IState = {
    isLoading: false,
  };

  setLoading = (isLoading: boolean) => {
    this.setState({
      isLoading,
    });
  };

  render = () => (
    <Button
      type={EButtonType.button}
      {...this.props}
      showLoading={this.state.isLoading}
      onClick={loadingHandler(this.props.onClick, this.setLoading)}
    >
      {this.props.buttonText}
    </Button>
  );
}
