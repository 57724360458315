import React from "react";
import styled from "styled-components";
import { Title } from "@kounta/chameleon";

export interface IProduceHeader {
  title: string | JSX.Element;
  content?: JSX.Element;
}

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  height: 56px;
`;

export const ProduceHeader = React.memo((props: IProduceHeader): JSX.Element => {
  return (
    <TopBar>
      <Title>{props.title}</Title>
      {props.content}
    </TopBar>
  );
});
