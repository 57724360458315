import { Portal, AppSwitcher, Loading } from '@kounta/chameleon';
import * as React from 'react';
import {
  IChameleonAppSwitcherConfiguration,
  IChameleonKountaAppSetting,
} from '../../typings/Chameleon';
import { useEffect, useState } from 'react';
import { getAppSwitcherConfiguration } from '../../graphql/AppSwitcherConfigurationQL';

interface IProps {
  siteName: string;
  siteHasImageUrl: boolean;
  siteImageUrl: string;
  onCloseCallback: () => void;
}

const KOUNTA_APP_SETTING_DEFAULT: IChameleonKountaAppSetting = {
  hasSubscription: false,
  name: '',
  productUrl: '',
  tryUrl: '',
};

export const defaultAppSwitcherConfiguration: IChameleonAppSwitcherConfiguration = {
  insights: {
    ...KOUNTA_APP_SETTING_DEFAULT,
    name: 'Insights',
  },
  kounta: {
    ...KOUNTA_APP_SETTING_DEFAULT,
    name: 'Back Office',
  },
  pos: {
    ...KOUNTA_APP_SETTING_DEFAULT,
    name: 'POS',
  },
  produce: {
    ...KOUNTA_APP_SETTING_DEFAULT,
    name: 'Produce',
  },
  purchase: {
    ...KOUNTA_APP_SETTING_DEFAULT,
    name: 'Purchase',
  },
};

export const HTTP_SCHEME = 'https://';
export const prependHttps = (
  appSwitcherConfiguration: IChameleonAppSwitcherConfiguration
) => {
  // BO should fix the result to include the url(Unfortunately, I did this)
  // In case BO fixed it, we won't break
  let appSetting: IChameleonKountaAppSetting;
  for (appSetting of Object.values(appSwitcherConfiguration)) {
    if (!appSetting.productUrl.includes(HTTP_SCHEME)) {
      appSetting.productUrl = `${HTTP_SCHEME}${appSetting.productUrl}`;
    }
    if (!appSetting.tryUrl.includes(HTTP_SCHEME)) {
      appSetting.tryUrl = `${HTTP_SCHEME}${appSetting.tryUrl}`;
    }
  }
  return appSwitcherConfiguration;
};

export const ProduceAppSwitcher = (props: IProps): JSX.Element => {
  const [appSwitcherConfiguration, setAppSwitcherConfiguration] = useState<
    IChameleonAppSwitcherConfiguration
  >(defaultAppSwitcherConfiguration);

  const fetchAppSwitcherConfiguration = async () => {
    const result = await getAppSwitcherConfiguration();
    if (result.succeed) {
      result.responseObj.produce.selected = true;
      setAppSwitcherConfiguration(prependHttps(result.responseObj));
    }
  };

  useEffect(() => {
    fetchAppSwitcherConfiguration();
  }, [props.siteName]);

  return (
    <Portal>
      {appSwitcherConfiguration !== defaultAppSwitcherConfiguration ? (
        <AppSwitcher
          siteName={props.siteName}
          siteImageUrl={props.siteHasImageUrl? props.siteImageUrl:''}
          products={appSwitcherConfiguration}
          onClose={props.onCloseCallback}
        />
      ) : (
        <Loading overlay />
      )}
    </Portal>
  );
};
