import {
  EQueryNames,
  ESubscriptionQueryNames,
  EUom,
  IApiHandlerResult,
  IApiProduct, IGraphQlSubscriptionFunction,
  Uuid,
} from '../typings/Interface';
import { graphQlApiHandler, graphQlSubscriptionHandler } from './Handler';
import { PRODUCT_FIELDS, PRODUCT_PAGING } from './CommonFields';
import { capitalizeFirstLetter } from './QueryStringPurify';
import {
  IGraphQlGetProductsArgument,
  IGraphQlPagingRequestFunction,
  IPaginationEncodedRequest,
  IPaginationResponse,
} from '../typings/Pagination';

/**
 * https://kounta.atlassian.net/browse/PRO-579
 * The default global `PAGINATION_LIMIT` from `frontend/produce-webpage/src/typings/Pagination.ts`
 * is too large for backoffice API. It fails with `timeout` error.
 * So here we use a specific smaller page size of 700.
 */
export const PRODUCT_PAGINATION_LIMIT = 700;

export enum EProductInventoryType {
  madeHere = 0,
  purchase = 1,
}

export const getProductsQuery = (): string => {
  return `query ${capitalizeFirstLetter(EQueryNames.getProducts)}(
       $inventoryType: Int
       $paginationRequest: PaginationRequestInput!
       $productUuids: [String]){
       ${EQueryNames.getProducts}(
          inventoryType: $inventoryType,
          paginationRequest: $paginationRequest,
          productUuids: $productUuids
       ) {
         ${PRODUCT_PAGING}
       }
    }`;
};

export const getProducts: IGraphQlPagingRequestFunction<IApiProduct, IGraphQlGetProductsArgument> = (
  {
    base64EncodedCursor,
    productUuids,
    inventoryType,
  }:
    IGraphQlGetProductsArgument
): Promise<IApiHandlerResult<IPaginationResponse<IApiProduct>>> => {
  let limit: number = PRODUCT_PAGINATION_LIMIT;
  if (productUuids && productUuids.length > 0) {
    // when productUuids are provided, the limit number is the same as the array
    // length:
    limit = productUuids.length;
  }

  const paginationRequest: IPaginationEncodedRequest = {
    limit,
  };

  if (base64EncodedCursor) {
    // In the backend, (backend/appsync/src/pagination/GetProductsWithPaging.ts)
    // getProductsHandler will ignore the `limit`, if the `cursor` is provided.
    paginationRequest.cursor = base64EncodedCursor;
  }
  const getProductsQl = getProductsQuery();
  const variable = {
    paginationRequest,
    productUuids,
    inventoryType,
  };
  return graphQlApiHandler<IPaginationResponse<IApiProduct>>(
    getProductsQl,
    EQueryNames.getProducts,
    variable,
  );
}

export const PRODUCT_SUBSCRIPTION_FIELDS = `
  companyUuid
  id
  name
`;

export const productSubscriptionQuery = (companyUuid: Uuid): string =>
  `subscription ${ESubscriptionQueryNames.updatedProductByCompany} {
    ${
      ESubscriptionQueryNames.updatedProductByCompany
    }(companyUuid: "${companyUuid}") {
      ${PRODUCT_SUBSCRIPTION_FIELDS}
    }
  }`;

export const productSubscription: IGraphQlSubscriptionFunction = (
  companyUuid: Uuid,
  subscriptionCallBacks: () => Promise<void>
): ZenObservable.Subscription => {
  return graphQlSubscriptionHandler(
    productSubscriptionQuery(companyUuid),
    ESubscriptionQueryNames.updatedProductByCompany,
    subscriptionCallBacks,
  );
};

export const updateProductQuery = (product: IApiProduct, uom: EUom, measure: number): string => {
  return `mutation ${capitalizeFirstLetter(EQueryNames.updateProductThroughApi)}{
    ${EQueryNames.updateProductThroughApi}(
      id: "${product.id}",
      uom: "${uom}",
      measure: ${measure},
    ){
      name
      unit_of_measure
      measure
    }
  }`;
};

export const updateProduct = async (
  product: IApiProduct,
  uom: EUom,
  measure: number,
): Promise<IApiHandlerResult<IApiProduct>> => {
  const query = updateProductQuery(product, uom, measure);
  return graphQlApiHandler<IApiProduct>(query, EQueryNames.updateProductThroughApi);
};

export const createProductQuery = (
  uom: EUom,
  measure: number,
  name: string,
): string => {
  return `mutation ${capitalizeFirstLetter(EQueryNames.createProduct)}{
    ${EQueryNames.createProduct}(
      uom: "${uom}",
      measure: ${measure},
      name: "${name}"
    ){
      ${PRODUCT_FIELDS}
    }
  }`;
};

export const createProduct = (
  uom: EUom,
  measure: number,
  name: string,
): Promise<IApiHandlerResult<IApiProduct>> => {
  const query = createProductQuery(uom, measure, name);
  return graphQlApiHandler<IApiProduct>(query, EQueryNames.createProduct);
};

