import React from 'react';
import { Select, SelectOptionType } from '@kounta/chameleon';
import styled from 'styled-components';

const StyledSelect = styled.div`
  width: 260px;
`;

interface IProps {
  currentFilterCriteria: string;
  handleFilterSelect: (selectedOption: SelectOptionType) => void;
  filterOptions: SelectOptionType[];
}

export const Filter = React.memo((
  {
    currentFilterCriteria,
    handleFilterSelect,
    filterOptions,
  }: IProps
): JSX.Element => {
  return (
    <StyledSelect>
      <Select
        options={filterOptions}
        defaultValue={currentFilterCriteria}
        updateSelectedOption={handleFilterSelect}
      />
    </StyledSelect>
  );
});
