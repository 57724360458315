import React, { SyntheticEvent } from 'react';
import {
  Avatar,
  getInitials,
  Table,
  TableRowType,
  Button
} from "@kounta/chameleon";
import { IApiProduct, Uuid } from '../../../typings/Interface';
import { AvatarContainer } from '../Style';
import { SearchResultEmpty } from './SearchResultEmpty';
import {
  PRODUCTS_LIST_TABLE_HEADER_OPTIONS,
} from '../../../TextProvider';
import { Scrollable } from '../../../css/Styled';

interface IProps {
  conformProducts: IApiProduct[];
  goToSingleRecipePageWithQueryByProductUuid: (productUuid: Uuid) => void;
}

export const LINK_TEXT = 'Create Recipe';

export const renderEachProductRow = (
  singleProduct: IApiProduct,
  handleCreateRecipeOnSingleProduct: (productUuid: Uuid) => () => void,
): TableRowType => {
  let updatedDateOrCreateRecipe = (
    <Button
      slim
      fullWidth
      secondary
      primary={false}
      onClick={handleCreateRecipeOnSingleProduct(singleProduct.id)}
    >
      {LINK_TEXT}
    </Button>
  );

  return {
    id: singleProduct.id,
    content: [
      {
        id: 0,
        value: (
          <AvatarContainer>
            <Avatar
              data-testid={'avatarImgBackground'}
              initials={getInitials(singleProduct.name)
                .replace(/[^a-zA-Z ]/g, '')}
              imageUrl={singleProduct.image}
              square={true}
            />
          </AvatarContainer>
        ),
      },
      { id: 1, value: singleProduct.name },
      { id: 2, value: updatedDateOrCreateRecipe },
    ],
  };
};

export const ProductsTable = React.memo((
  {
    conformProducts,
    goToSingleRecipePageWithQueryByProductUuid,
  }: IProps
): JSX.Element => {
  if (conformProducts.length === 0) {
    // Early return if we know the conformProducts is none:
    return (
      <SearchResultEmpty/>
    );
  }
  const handleCreateRecipeOnSingleProduct = (productUuid: Uuid) => {
    return () => {
      goToSingleRecipePageWithQueryByProductUuid(productUuid);
    };
  };

  // Only need to build/calculate the table row when necessary:
  const tableRows = conformProducts.map(
    (singleProduct: IApiProduct) => renderEachProductRow(
      singleProduct,
      handleCreateRecipeOnSingleProduct,
    )
  );

  const handleTableRowClick = (
    e: SyntheticEvent,
    productUuid: Uuid | number
  ) => {
    if (typeof productUuid === 'string'){
      goToSingleRecipePageWithQueryByProductUuid(productUuid);
      e.preventDefault();
    }
    else throw new Error(`Expected productUuid to be typeof string, got ${typeof productUuid}`);
  };

  return (
    <Scrollable>
      <Table
        headers={PRODUCTS_LIST_TABLE_HEADER_OPTIONS}
        rows={tableRows}
        onRowClick={handleTableRowClick}
        showRowIndicator={false}
        showRowClick
      />
    </Scrollable>
  );
});
