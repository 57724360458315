import { IApiRecipe, Uuid } from '../../typings/Interface';
import { initialDraftFormikBatch } from '../../formik/batch/InitialDraftBatch';

export default function getRecipeByRecipeUuid(
  recipeUuid: Uuid,
  recipes: IApiRecipe[] | null
): false | IApiRecipe {
  if (recipes === null || recipes.length === 0) {
    return false;
  }
  if (recipeUuid === initialDraftFormikBatch.recipeUuid) {
    return false;
  }
  const filteredRecipes = recipes.find(
    (item: IApiRecipe) => item.uuid === recipeUuid
  );
  if (filteredRecipes === undefined) {
    return false;
  }
  return filteredRecipes;
}
