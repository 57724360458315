import {
  loadThirdPartyHtmlToBody,
  loadThirdPartyLibToHead,
} from './ScriptLoader';
import { THIRD_PARTY_SCRIPTS } from '../config/release';
import {
  getChurnZeroScript,
  getGTMDataLayerFromCookie,
  getSegmentUserInfoFromCookie,
} from '../persister/Cookie';
import { ISiteInfo } from '../typings/Interface';
import { initIntercom } from "./intercomController";
import { initialStaff, IReduxStaff } from '../redux/reducers/StaffReducer';

export interface ILoadAllThirdPartyFunction {
  (
    staff: IReduxStaff,
    site: ISiteInfo,
  ): boolean
}

export const loadAllThirdParty: ILoadAllThirdPartyFunction = (
  staff: IReduxStaff,
  site: ISiteInfo,
): boolean => {
  loadThirdPartyLibToHead(THIRD_PARTY_SCRIPTS.gtmHeadScript);
  loadThirdPartyHtmlToBody(THIRD_PARTY_SCRIPTS.gtmBodyHtml);
  loadThirdPartyLibToHead(THIRD_PARTY_SCRIPTS.segmentHeadScript);

  if (
    !staff ||
    !staff.personTrackingId ||
    (staff.personTrackingId === initialStaff.personTrackingId)
  ) {
    return false;
  }
  loadThirdPartyLibToHead(getGTMDataLayerFromCookie(staff, site));
  loadThirdPartyLibToHead(getChurnZeroScript(staff, site));
  loadThirdPartyLibToHead(getSegmentUserInfoFromCookie(staff));
  initIntercom(staff);
  return true;
};
