import * as React from 'react';
import { SyntheticEvent, useState } from 'react';
import {
  ALERT_LIST,
  ELoadingTitle,
  ERecipeFormButtonText,
  ESnackBarMessage,
} from '../../TextProvider';
import {
  Group,
  Heading2,
  Link,
  Paragraph,
  Spacer,
} from '@kounta/chameleon';
import { InventoryConsumed } from './components/InventoryConsumed';
import {
  EConsumptionType,
  EFormikIngredientsFieldName,
  EModalType,
  ESnackBarType,
  IApiProduct,
  IApiRecipe,
  IFormikRecipe,
  ILayoutUiControlFuncs, ISaveRecipe,
  Uuid,
} from '../../typings/Interface';
import {
  deleteRecipe,
  saveRecipe,
} from '../../graphql/RecipeQL';
import {
  renderDeletePromptModal,
  IDeleteAsync,
} from './components/DeleteRecipeModal';
import { RecipeFormContainer } from './Style';
import { AlertWrapper } from '../../components/alert/AlertWrapper';
import { initialDraftFormikRecipe } from '../../formik/recipe/InitialDraftRecipe';
import {
  getProductByUuidFromList,
  getProductNameByUuidFromList,
} from '../../utils/product/GetProductByUuidFromList';
import { FieldArray, Form, Formik } from 'formik';
import { RecipeSchema } from '../../validation/RecipeSchema';
import RecipeIngredients
  from '../../components/ingredients-section/RecipeIngredients';
import { doesRecipeHaveUnsavedChanges } from '../../validation/DoesRecipeHaveUnsavedChanges';
import { loadingHandler } from '../../utils/loader/LoadingHandler';
import {
  IRecipeFieldArrayProps,
  IRecipeFormikProps,
} from '../../typings/Formik';
import { RecipeAppHeaderBar } from '../../components/app-header-bar/RecipeAppHeaderBar';
import {
  PageContainer,
  PageContent,
  PageLayout,
} from '../../components/page-container';
import { EModalActionResult } from '../../typings/Modal';
import { convertRecipeToHaveValidIngredients } from '../../formik/RemoveDeletedProductsFromIngredients';
import { LoadingIngredients } from '../../components/loading/LoadingIngredients';
import { RecipeCostUpgrade } from '../../components/cards/RecipeCostUpgrade';
import { RecipeNote } from './components/RecipeNote';
import { IOnAddOrReplaceList } from '../../redux/typings/Interface';
import { calculateCosts } from "../../utils/recipe-cost/CalculateCosts";

interface IProps {
  products: IApiProduct[];
  recipes: IApiRecipe[];
  isProductsFullyLoaded: boolean;

  productUuidFromUrl: Uuid;
  existingRecipe: IFormikRecipe | false;
  updateExistingRecipe: (existingRecipe: IApiRecipe | false) => void;

  setLoading: ILayoutUiControlFuncs['setLoading'];
  handleModal: ILayoutUiControlFuncs['handleModal'];

  onLoadingProducts: IOnAddOrReplaceList<IApiProduct>;

  goBackToRecipesListPage: () => void;

  isLitePlan: boolean;
  hasEditRecipePermission: boolean;
}

interface IPromptState {
  shouldShowAlertYn: boolean;
  alertTopic: string;
  showDeletePrompt: boolean;
  alertMessage: string;
}

export const RecipeForm = React.memo((
  {
    products,
    recipes,
    isProductsFullyLoaded,
    productUuidFromUrl,
    existingRecipe,
    updateExistingRecipe,
    setLoading,
    handleModal,
    goBackToRecipesListPage,
    isLitePlan,
    hasEditRecipePermission,
    onLoadingProducts,
  }: IProps): JSX.Element => {
    /**
     * ------ Start of initial rendering setting:
     */
    const mainProduct = getProductByUuidFromList(
      productUuidFromUrl,
      products,
    );
    
    const [snackBarState, setSnackBarState] = useState(false);

    if (mainProduct === false) {
      // Usually, users click individual recipe item from `recipeList`,
      // this way, `mainProduct` won't be false, cause only fetched products
      // will show up in the list. However, user can also bookmark a recipe and
      // access that single recipe using Product UUID. In that case, it is
      // possible the product is not fetched yet due to lazy loading. The last
      // case hasn't been considered seriously, we will need UX ideas on this
      // case.
      throw new Error(`No product matches this uuid:${productUuidFromUrl}`);
    }

    const [prompt, setPrompt] = useState<IPromptState>({
      showDeletePrompt: false,
      shouldShowAlertYn: false,
      alertTopic: '',
      alertMessage: '',
    });



    const singleRecipeForm = ({ values }: IRecipeFormikProps): JSX.Element => (
      <Form>
        <PageLayout>
          <RecipeAppHeaderBar
            existingRecipe={existingRecipe}
            draftRecipe={values}
            recipeDeleted={prompt.showDeletePrompt}
            modalActionHandler={unSavedChangesModalHandler(goBackToRecipesListPage)}
            goBackHandler={goBackToRecipesListPage}
            shouldHideRightContent={!hasEditRecipePermission}
          />
          <PageContainer>
            <PageContent>
              {isLitePlan && (
                <RecipeCostUpgrade/>
              )}
              <Group spacing={'24px'}>
                <div>
                  <Heading2>
                    {getProductNameByUuidFromList(
                      values.productUuid,
                      products
                    )}
                  </Heading2>
                  { hasEditRecipePermission && (
                    <Paragraph>
                      Please fill in all the details to save the recipe.
                    </Paragraph>
                  )}
                </div>
                <InventoryConsumed
                  key={values.productUuid}
                  products={products}
                  draftEntity={values}
                  hasEditRecipePermission={hasEditRecipePermission}
                />
                <FieldArray
                  name={EFormikIngredientsFieldName.recipeIngredients}
                >
                  {renderIngredientsFields}
                </FieldArray>
                <RecipeNote
                  hasEditRecipePermission={hasEditRecipePermission}
                  isLitePlan={isLitePlan}
                  note={values.note}
                />
                {renderAlert()}
                {existingRecipe && hasEditRecipePermission && (
                  <React.Fragment>
                    <Spacer spacing="large" />
                    <Link
                      destructive={true}
                      onClick={openDeleteRecipeModal}
                    >
                      {ERecipeFormButtonText.delete}
                    </Link>
                  </React.Fragment>
                )}
                {prompt.showDeletePrompt &&
                renderDeletePromptModal(
                  values.uuid,
                  closeDeleteRecipeModal,
                  handleDeleteAsynchronously,
                )}
              </Group>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </Form>
    );

    // more details:
    // https://jaredpalmer.com/formik/docs/api/fieldarray#fieldarray-render-methods
    const renderIngredientsFields = (
      {
        push,
        remove,
        form,
      }: IRecipeFieldArrayProps
    ): JSX.Element => {
      if (!isProductsFullyLoaded) {
        return (<LoadingIngredients/>);
      } else {
        // The above `else` is essential, without it, sometime the JS trying to
        // pre-execute some of codes below, and throwing `products not in the
        // list` error.
        return (
          <RecipeIngredients
            pushNewIngredientRowIntoFormikFieldArray={push}
            removeIngredientRowFromFormikFieldArray={remove}
            shouldShowUomDropDownRedirectToBackOffice
            shouldProductNameAutoFocus
            products={products}

            onLoadingProducts={onLoadingProducts}

            // Plan restriction
            isLitePlan={isLitePlan}
            recipes={recipes}
            currentEditingRecipe={form.values}

            mainProduct={mainProduct}
            hasEditRecipePermission={hasEditRecipePermission}
            
            showSnackBar={snackBarState}
            snackBarHandler={setSnackBarState}
          />
        );
      }
    };

    const handelFormikFormSubmit = async (values: IFormikRecipe) => {
      const costs = calculateCosts(
        false,
        values,
        products,
        recipes,
        values.productUuid,
        mainProduct.unitPrice.toString(),
      )

      // Don't worry, this function only be called when the validation of
      // the form has passed.
      if (doesRecipeHaveUnsavedChanges(existingRecipe, values)) {
        await loadingHandler(
          saveRecipeApi({...values, unitCost: costs.unitCost }),
          setLoading,
          ELoadingTitle.saveRecipe
        )();
      }
    };

    const getInitialFormValues = (): IFormikRecipe => {
      // 1. Existing recipe:
      let recipeWithIngredients: IFormikRecipe | false = existingRecipe;

      if (recipeWithIngredients === false) {
        // 2. New recipe:
        initialDraftFormikRecipe.productUuid = productUuidFromUrl;
        initialDraftFormikRecipe.uom = mainProduct.uom;

        recipeWithIngredients = initialDraftFormikRecipe;
      }

      return convertRecipeToHaveValidIngredients(
        recipeWithIngredients,
        products
      );
    };

    const renderAlert = (): JSX.Element | null =>
      prompt.shouldShowAlertYn ? (
        <AlertWrapper
          alertTopic={prompt.alertTopic}
          onCloseCallBack={handleCloseAlert}
          alertMessage={prompt.alertMessage}
        />
      ) : null;

    const handleCloseAlert = () => {
      setPrompt(prompt => ({
          ...prompt,
          shouldShowAlertYn: false,
        })
      );
    };

    const saveRecipeApi = (draftRecipe: ISaveRecipe) => async () => {
      const recipeUuid = draftRecipe.uuid;

      const saveObject = {
        productUuid: draftRecipe.productUuid,
        expectedYield: draftRecipe.expectedYield,
        consumptionType: draftRecipe.consumptionType,
        recipeIngredients: draftRecipe.recipeIngredients,
        note: draftRecipe.note,
        uom: draftRecipe.uom,
        uuid: recipeUuid,
        unitCost: draftRecipe.consumptionType === EConsumptionType.madeToOrder ? draftRecipe.unitCost : null
      };

      // call Api to Save the recipe:
      const saveRecipeResponse = await saveRecipe(saveObject);

      if (saveRecipeResponse.succeed) {
        handleModal({
          snackBarMessage: ESnackBarMessage.SAVE_RECIPE_SUCCEED,
          snackBarType: ESnackBarType.success,
          modalType: EModalType.SNACK_BAR,
        });
        updateExistingRecipe(saveRecipeResponse.responseObj);
      } else {
        let alertMessage = ALERT_LIST.saveRecipeError.message;
        if(saveRecipeResponse.errors[0].errorType === 'CircularRecipe') {
          alertMessage = 'Circular recipe detected. Remove ingredients containing this recipe to continue.'
        }
        setPrompt(prompt => ({
            ...prompt,
            shouldShowAlertYn: true,
            alertTopic: ALERT_LIST.saveRecipeError.topic,
            alertMessage,
          })
        );
      }
    };

    const openDeleteRecipeModal = (e: SyntheticEvent) => {
      e.preventDefault();
      setPrompt(prompt => ({
          ...prompt,
          showDeletePrompt: true,
        })
      );
    };

    const closeDeleteRecipeModal = (e?: SyntheticEvent) => {
      e?.preventDefault();
      setPrompt(prompt => ({
          ...prompt,
          showDeletePrompt: false,
        })
      );
    };

    const handleDeleteAsynchronously: IDeleteAsync = (toBeDeleteRecipeUuid: Uuid) => {
      return async () => {
        const deleteResponse = await deleteRecipe(toBeDeleteRecipeUuid);

        if (deleteResponse.succeed) {
          handleModal({
            snackBarMessage: ESnackBarMessage.DELETE_RECIPE_SUCCEED,
            modalType: EModalType.SNACK_BAR,
          });
          goBackToRecipesListPage();
        } else {
          setPrompt(prompt => ({
              ...prompt,
              showDeletePrompt: false,
              shouldShowAlertYn: true,
              alertTopic: ALERT_LIST.deleteRecipeError.topic,
              alertMessage: ALERT_LIST.deleteRecipeError.message,
            })
          );
        }
      };
    };

    return (
      <RecipeFormContainer>
        <Formik
          initialValues={getInitialFormValues()}
          enableReinitialize={true}
          validationSchema={RecipeSchema}
          onSubmit={handelFormikFormSubmit}
          component={singleRecipeForm}
        />
      </RecipeFormContainer>
    );
  }
);

export const unSavedChangesModalHandler = (goBackToRecipesListPage: () => void) => {
  return (actionResult: EModalActionResult) => {
    if (actionResult === EModalActionResult.yes) {
      goBackToRecipesListPage();
    }
  };
}
