import {
  EQueryNames, IApiHandlerResult,
} from '../typings/Interface';
import { graphQlApiHandler } from './Handler';
import { capitalizeFirstLetter } from './QueryStringPurify';

export interface IApiBatchAllowance {
  remainingBatchAllowance: number;
}

export const getRemainingBatchAllowanceQuery = (): string =>
  `query ${capitalizeFirstLetter(EQueryNames.getRemainingBatchAllowance)} {
     ${EQueryNames.getRemainingBatchAllowance} {
        remainingBatchAllowance
     }
  }`;

export async function getRemainingBatchAllowance(): Promise<IApiHandlerResult<IApiBatchAllowance>> {
  const getRemainingBatchAllowanceQl = getRemainingBatchAllowanceQuery();

  return await graphQlApiHandler<IApiBatchAllowance>(
    getRemainingBatchAllowanceQl,
    EQueryNames.getRemainingBatchAllowance,
  );
}
