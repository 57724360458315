import { IApiBatch } from '../../typings/Interface';
import {
  IActionLoadBatches,
  EReduxActionType,
  IActionAddPartialBatches,
} from '../actions';
import produce from 'immer';

export const initialBatches = null;

export default function batchesReducer(
  previousState: IApiBatch[] | null = initialBatches,
  action: IActionLoadBatches | IActionAddPartialBatches
): IApiBatch[] | null {
  return produce(previousState, (draft: IApiBatch[] | null) => {
    switch (action.type) {
      case EReduxActionType.LOAD_BATCHES:
        return action.batches;
      case EReduxActionType.ADD_PARTIAL_BATCHES:
        if (draft === initialBatches) {
          // null is not iterable, cannot use `...draft`;
          return action.batches;
        }
        return [
          ...draft,
          ...action.batches,
        ];
      default:
        return previousState;
    }
  });
}
