import React from "react";
import { NumberInput } from "@kounta/chameleon";
import Big from 'big.js';
import { CostFieldDiv } from '../../css/Styled';
import {
  SHOW_PRECISION,
  ZERO_STRING_DISPLAY
} from '../../utils/number-format/BigNumberHandler';
import { CURRENCY_SYMBOL } from '../../TextProvider';

interface ICostRowProps {
  quantity: Big,
  shouldHidePrice: boolean,
}

export const CostRow = React.memo((props: ICostRowProps) => {
  return (
    <CostFieldDiv>
      {props.shouldHidePrice && (
        <NumberInput
          prefix={CURRENCY_SYMBOL}
          align="right"
          value={ZERO_STRING_DISPLAY}
          readOnly
          filter="blur"
        />
      )}
      {!props.shouldHidePrice && (
        <NumberInput
          prefix={CURRENCY_SYMBOL}
          align={'right'}
          readOnly
          value={props.quantity.toFixed(SHOW_PRECISION)}
        />
      )}
    </CostFieldDiv>
  )
});

