import React from 'react';
import { redirectToBackOfficeProducePlanPage } from '../../utils/url/UrlRedirect';
import { Alignment, Badge, Card, Link, Paragraph, Spacer } from "@kounta/chameleon";
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { BATCH_RESTRICTED_PRODUCE_PLANS } from '../../utils/plan-restriction/RestrictedPlans';
import { RemainBadge } from "../../css/Styled";

interface IProps {
  remainingBatchAllowance: number;
  billingPlan: IApiBillingPlan;
}

export const generateBatchText = (remainingBatchAllowance: number): string => {
  if (remainingBatchAllowance === 1) {
    return `${remainingBatchAllowance} batch`;
  }
  return `${remainingBatchAllowance} batches`;
};

export const RemainingBatchAllowance = React.memo((
  { remainingBatchAllowance, billingPlan }: IProps
  ): JSX.Element | null => {
    if (BATCH_RESTRICTED_PRODUCE_PLANS.includes(billingPlan.produce.newPlanId)) {
      return (
        <React.Fragment>
          <Card
            elementOnLeft={false}
          >
            <Alignment vertical={'center'} horizontal={'center'}>
              <RemainBadge>
                <Badge type={'callout'} showBorder={false}>{remainingBatchAllowance}</Badge>
              </RemainBadge>
              <Paragraph>
                You have {generateBatchText(remainingBatchAllowance)} remaining on
                your {billingPlan.produce.newPlan} plan.&nbsp;&nbsp;
                <Link
                  primary
                  onClick={redirectToBackOfficeProducePlanPage}
                >
                  Upgrade to Advanced Prep
                </Link>
                &nbsp;for unlimited batches.
              </Paragraph>
            </Alignment>
          </Card>
          <Spacer/>
        </React.Fragment>
      );
    }
    return null;
  }
);
