export const COMMON_FIELDS = `
  uuid
  productUuid
  uom
`;

export const INGREDIENT_FIELDS = `
  componentQty
  recipeUuid
  ${COMMON_FIELDS}
`;

export const BATCH_INGREDIENT_FIELDS = `
  componentQty
  batchUuid
  productName
  ${COMMON_FIELDS}
  totalCost
  unitCost
  additionalIngredientFlag
`;

const RECIPE_FIELDS_PART = `
  companyUuid
  expectedYield
`;

export const RECIPE_FIELDS = `
  ${RECIPE_FIELDS_PART}
  ${COMMON_FIELDS}
  updatedTime
  note
  consumptionType
  unitCost
  recipeIngredients{${INGREDIENT_FIELDS}}
`;

export const RECIPES_PAGING = `
  edges{${RECIPE_FIELDS}}
  pageInfo{
    newCursor
    hasNextPage
  }
`;

// Make sure the fields are inline with the actual interface (eg: IApiBatch).
// Every time the GraphQL schema updated, it needs to reflect in here:
// Note: `siteUuid` is needed to trigger the Subscription updatedBatchBySite().
export const BATCH_FIELDS = `
    ${RECIPE_FIELDS_PART}
    ${COMMON_FIELDS}
    siteUuid
    
    updatedTime
    createdTime
    
    batchIngredients{${BATCH_INGREDIENT_FIELDS}}
    recipeUuid
    actualYield
    status
    note
    completedTime
    productName
    plannedTime
    startedTime
    totalCost
    unitCost
    
    isCustomYield
`;

export const BATCHES_PAGING = `
  edges{${BATCH_FIELDS}}
  pageInfo{
    newCursor
    hasNextPage
  }
`;

// Products API fields:
export const PRODUCT_FIELDS = `
  id
  name
  uom
  measure
  isBuy
  isSell
  costPrice
  unitPrice
  inventoryType
  averageCostPrice
  image
`;
export const PRODUCT_PAGING = `
  edges{${PRODUCT_FIELDS}}
  pageInfo{
    newCursor
    hasNextPage
  }
`;

/**
 * Currently, we only use subscription response to trigger a refresh of lists (
 * another API call).
 * We don't utilise the actual subscription response object.
 * So here we keep the fields at minimal.
 */
export const SUBSCRIPTION_FIELDS = `
  uuid
  companyUuid
  siteUuid
`;

const KOUNTA_APP_SETTING_FIELDS = `name
  hasSubscription
  productUrl
  tryUrl`;

export const APPSWICHERCONFIGURATION_FIELDS = `
  kounta {
    ${KOUNTA_APP_SETTING_FIELDS}
  }
  pos {
    ${KOUNTA_APP_SETTING_FIELDS}
  }
  insights {
    ${KOUNTA_APP_SETTING_FIELDS}
  }
  purchase {
    ${KOUNTA_APP_SETTING_FIELDS}
  }
  produce {
    ${KOUNTA_APP_SETTING_FIELDS}
  }
`;

export const BILLING_PLAN_FIELD = `
  siteUuid
`;
