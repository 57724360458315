import {
  IGraphQlPagingRequestFunction,
  IGraphQlRequestArgument,
  IPaginationResponse,
} from '../../typings/Pagination';
import { IOnAddOrReplaceList } from '../../redux/typings/Interface';

export const add1stPageIntoRedux = async<T> (
  graphQlRequest: IGraphQlPagingRequestFunction<T, IGraphQlRequestArgument>,
  onAddPartialList: IOnAddOrReplaceList<T>,
): Promise<false | IPaginationResponse<T>> => {
  // First initial fetch, with no `cursor`:
  let currentResponse = await graphQlRequest({});
  if (!currentResponse.succeed) {
    return false;
  } else {
    onAddPartialList(currentResponse.responseObj.edges as T[]);
    return currentResponse.responseObj;
  }
};
