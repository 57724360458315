import {
  ICognitoTokenObject,
  ISiteInfo,
} from '../typings/Interface';
import { IReduxStaff } from '../redux/reducers/StaffReducer';

export enum ECookieKey {
  cognitoObj = 'cognitoObj',
  isUomTooltipDisplayed = 'isUomTooltipDisplayed',
}

export enum ECookieValue {
  domain = '.kounta.com',
  path = '/',
}
// https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript#25490531
function getCookieValue(a: ECookieKey): any {
  const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

// https://stackoverflow.com/questions/17010119/decodeuri-decodes-space-as-symbol
// do the convert before decodeURIComponent to avoid converting the actual '+' to space
// Also Note: When is a space in a URL encoded to `+` and when it is encoded to `%20`
// https://stackoverflow.com/questions/1634271/url-encoding-the-space-character-or-20
export function decodeAddSymbolToSpace(str: string): string {
  return str.replace(/\+/g, ' ');
}
// Notes:
// Cookie is eventually `String`, to make sure the child component does not run
// useEffect after every rendering when cookie doesn't change, we need to make
// sure we are passing cookie as `String` instead of `Object`.  Using
// `JSON.parse(cognitoObj)` will always create a new object, and will force the
// Child component (Main.tsx) always trigger a `useEffect`, even we already
// passed the second argument [userInfo, siteInfo, cognitoObj] in its useEffect
// function.
export function getCognitoTokenFromCookie(): string | null {
  const cognitoObjRaw = getCookieValue(ECookieKey.cognitoObj);
  if (cognitoObjRaw === '') {
    return null;
  }
  return decodeURIComponent(cognitoObjRaw);
}

export const getCookieObjFromString = (
  cookieString: string
): ISiteInfo | ICognitoTokenObject => {
  return JSON.parse(cookieString);
};

// https://stackoverflow.com/questions/10593013/delete-cookie-by-name
export const deleteCookie = (domain: string, path: string) => (
  name: string
) => {
  document.cookie = `${name}=; domain=${domain};Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'`;
};

export const deleteAllCookies = (domain: string, path: string) => {
  const deleteDomainCookie = deleteCookie(domain, path);
  deleteDomainCookie(ECookieKey.cognitoObj);
};

export const getGTMDataLayerFromCookie = (
  staff: IReduxStaff,
  siteInfoObj: ISiteInfo,
): string => {
  const dataLayer = [
    {
      personId: staff.personTrackingId,
      siteId: siteInfoObj.siteNumericId,
      companyId: siteInfoObj.companyNumericId,
      email: staff.email,
    },
  ];
  return `dataLayer=${JSON.stringify(dataLayer)};`;
};

export const getChurnZeroScript = (
  staff: IReduxStaff,
  siteInfoObj: ISiteInfo,
) => {
  const personId = staff.personTrackingId;
  const siteId = siteInfoObj.siteNumericId;
  return `var ChurnZero = ChurnZero || [];(function() {var cz = document.createElement('script'); cz.type = 'text/javascript';cz.async = true;cz.src = 'https://analytics.churnzero.net/churnzero.js';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(cz, s);})();ChurnZero.push(['setAppKey', 'c_iIz_8vb0WkXfKNbePYFx5-razrsgsd0C_8ot8Q23Q']);ChurnZero.push(['setContact', '${siteId}', '${personId}']);ChurnZero.push(['setModule', 'Produce']);`;
};

export const getSegmentUserInfoFromCookie = (staff: IReduxStaff) => {
  if (!staff.personCreatedAt) {
    return '';
  }
  return `analytics.identify('${staff.personTrackingId}', {name: '${
    staff.name
  }',email: '${staff.email}',createdAt: '${
    staff.personCreatedAt
  }'});`;
};

export const isUomTooltipNeverDisplayed = () => {
  return getCookieValue(ECookieKey.isUomTooltipDisplayed) === '';
};

export const markUomTooltipDisplayed = () => {
  document.cookie = `${ECookieKey.isUomTooltipDisplayed}=${
    ECookieKey.isUomTooltipDisplayed
  }`;
};
