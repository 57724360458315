import { FieldProps } from 'formik';
import { EDIT_IN_BACKOFFICE_OPTION_VALUE } from '../uom/EditInBackOffice';
import { IUomDropDownOption } from "../typings/Interface";
import { AdvancedSelectOptionType } from '@kounta/chameleon';

/**
 * This is a call back function, it returns a function after `setFieldValue()`
 */
export const setFieldValueImperatively = (
  form: FieldProps['form'],
  field: FieldProps['field']
) => {
  return setFormikValueByFieldString(form, field.name);
};

/**
 * This is a call back function, it returns a function after `setFieldValue()`
 */
export const setFormikValueByFieldString = (
  form: FieldProps['form'],
  fieldName: string
) => {
  return (value: string | number) => {
    form.setFieldValue(fieldName, value);
  };
};

export const handleUomChange = (
  form: FieldProps['form'],
  field: FieldProps['field']
) => (
  selectedOptionObject: IUomDropDownOption | AdvancedSelectOptionType
) => {
  // Exclude special `EDIT_IN_BACKOFFICE_OPTION`, that case will immediately
  // redirect to back office.
  if (selectedOptionObject.value !== EDIT_IN_BACKOFFICE_OPTION_VALUE) {
    form.setFieldValue(field.name, selectedOptionObject.value);
  }
};
