import {
  LIVE_AWS_CONFIG,
  LIVE_BACKOFFICE_HOST,
  LIVE_ENABLE_REDUX_DEVTOOLS,
  LIVE_INTERCOM_APPID,
  LIVE_THIRD_PARTY_SCRIPTS,
} from './SharedConfig';

export const AWS_CONFIG = {
  ...LIVE_AWS_CONFIG,
  aws_appsync_graphqlEndpoint:
    'https://rpyhvylvvfekxphlfvlurgfyu4.appsync-api.ap-northeast-1.amazonaws.com/graphql',
};

export const BACKOFFICE_HOST = LIVE_BACKOFFICE_HOST;
export const THIRD_PARTY_SCRIPTS = LIVE_THIRD_PARTY_SCRIPTS;
export const ENABLE_REDUX_DEVTOOLS = LIVE_ENABLE_REDUX_DEVTOOLS;
export const INTERCOM_APPID = LIVE_INTERCOM_APPID;
