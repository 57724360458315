import { FieldProps } from 'formik';
import { getFormikFieldError } from '../../formik/FormikField';
import { NumberInput } from '@kounta/chameleon';
import { INGREDIENT_QTY_PLACEHOLDER } from '../../TextProvider';
import { setFieldValueImperatively } from '../../formik/SetFieldValueImperatively';
import * as React from 'react';
import { NumberInputWrapper } from '../../css/Styled';

export const renderNumberInput = (allowNegative: boolean) =>
  ({ field, form }: FieldProps): JSX.Element => {
    const errorObj = getFormikFieldError(form, field);

    // When the `value` is 0 or `0`, instead of showing 0, we show placeholder
    // `0.0`. This is specially for Recipe Expected Yield initial value (when
    // create a recipe). In Chameleon <NumberInput>, if value==='', it will
    // show placeholder instead
    const value = (parseFloat(field.value) === 0) ? '' : field.value;

    // As a requirement, the `add ingredient` need to have placeholder
    // (gray-out text) instead of initialValue;
    // So we use initialValue= '' (default value) when it is field.value=0
    // @kounta/chameleon/src/components/NumberInput/NumberInput.js
    return (
      <NumberInputWrapper>
        <NumberInput
          label={''}
          placeholder={INGREDIENT_QTY_PLACEHOLDER}
          value={value}

          onChange={setFieldValueImperatively(form, field)}
          name={field.name}
          allowNegative={allowNegative}
          error={errorObj.errorYn}
          errorMessagesAllowed={false}
        />
      </NumberInputWrapper>
    );
  };
