import { connect } from 'react-redux';
import {
  IApiBatch,
  IApiProduct,
  IApiRecipe,
  ISiteInfo,
} from '../typings/Interface';
import {
  addPartialProducts,
  addPartialRecipes,
  addPartialBatches,
  IReduxStore,
  loadBatches,
  loadBillingPlan,
  loadProducts,
  loadRecipes,
  IReduxDispatchFunction,
  setProductsFullyLoaded,
  setRecipesFullyLoaded,
  setRemainingBatchAllowance,
  loadStaff,
  loadSite,
} from '../redux/actions';
import { Layout } from './Layout';
import { IReduxFunctions } from '../redux/typings/Interface';
import { IApiBillingPlan } from '../graphql/BillingPlanQL';
import { IReduxStaff } from '../redux/reducers/StaffReducer';

/**
 * API
 * [mapStateToProps(state, [ownProps]): stateProps] (Function):
 * Part of the Redux `connect()` Arguments.
 *
 * 1. If this argument is specified, the new component will subscribe to
 *    Redux store updates.
 *    The mapStateToProps function's first argument is the entire Redux store’s
 *    state and it returns an object to be passed as `props`.
 *    This means that any time the store is updated, mapStateToProps will
 *    be called. (And the subscribed component's props will be updated.)
 *
 * 2. The results of mapStateToProps must be a plain JS object, which will be
 *    merged into the component’s props.
 *
 * 3. If you don't want to subscribe to store updates, pass `null` or
 * `undefined` in place of mapStateToProps.
 *
 * 4. If your mapStateToProps function is declared as taking two parameters,
 *    it will be called with the store `state` as the first parameter and
 *    the `props` passed to the connected component as the second parameter,
 *    and will also be re-invoked whenever the connected component receives
 *    `new props` as determined by shallow equality comparisons.
 *    (The second parameter is normally referred to as `ownProps`.)
 *    eg:
 *    const mapStateToProps = (state: State, ownProps: OwnProps) => {
 *       return {
 *              active: state.visibilityFilter === ownProps.filter
 *       };
 *    };
 */
const mapStateToProps = (state: IReduxStore): IReduxStore => state;

/**
 * API
 * [mapDispatchToProps(dispatch, [ownProps]): dispatchProps] (Object or
 * Function):
 * 1. If an object is passed, each function inside it, is assumed to be a Redux
 *    action creator.
 *    An object with the same function names, but with every action creator
 *    wrapped into a dispatch call so they may be invoked directly, will be
 *    merged into the component’s props.
 *
 * 2. If a function is passed, it will be given dispatch as the first
 * parameter.
 *    It’s up to you to return an object that somehow uses dispatch to
 *    bind action creators in your own way.
 *    (Tip: you may use the bindActionCreators() helper from Redux.)
 *
 * 3. If your mapDispatchToProps function is declared as taking two parameters,
 *    it will be called with `dispatch` as the first parameter and the `props`
 *    passed to the connected component as the second parameter, and will be
 *    re-invoked whenever the connected component receives new props.
 *
 * 4. If you do not supply your own mapDispatchToProps function or object
 *    full of action creators, the default mapDispatchToProps implementation
 *    just injects dispatch into your component’s props.
 *
 * More discussion: https://gist.github.com/heygrady/c6c17fc7cbdd978f93a746056f618552
 * TODO: Jingbo potentially can reuse the Redux IActionXYZ interfaces here,
 *      need to have a try when free. (https://repl.it/@heygrady/dispatchProps)
 */
export const mapDispatchToProps = (
  dispatch: IReduxDispatchFunction
): IReduxFunctions => ({
  // Lists
  onLoadingProducts: (products: IApiProduct[]): void => {
    dispatch(loadProducts(products));
  },
  onLoadingBatches: (batches: IApiBatch[]): void => {
    dispatch(loadBatches(batches));
  },
  onLoadingRecipes: (recipes: IApiRecipe[]): void => {
    dispatch(loadRecipes(recipes));
  },
  onAddPartialProducts: (products: IApiProduct[]): void => {
    dispatch(addPartialProducts(products));
  },
  onAddPartialRecipes: (recipes: IApiRecipe[]): void => {
    dispatch(addPartialRecipes(recipes));
  },
  onAddPartialBatches: (batches: IApiBatch[]): void => {
    dispatch(addPartialBatches(batches));
  },

  // Others:
  onSetProductsFullyLoaded: (isProductsFullyLoaded: boolean): void => {
    dispatch(setProductsFullyLoaded(isProductsFullyLoaded));
  },
  onSetRecipesFullyLoaded: (isRecipesFullyLoaded: boolean): void => {
    dispatch(setRecipesFullyLoaded(isRecipesFullyLoaded));
  },
  onSetRemainingBatchAllowance: (remainingBatchAllowance: number): void => {
    dispatch(setRemainingBatchAllowance(remainingBatchAllowance));
  },
  onLoadBillingPlan: (billingPlan: IApiBillingPlan): void => {
    dispatch(loadBillingPlan(billingPlan));
  },

  onLoadStaff: (staff: IReduxStaff): void => {
    dispatch(loadStaff(staff));
  },

  onLoadSiteInfo: (siteInfo: ISiteInfo): void => {
    dispatch(loadSite(siteInfo));
  },
});

/**
 * connect([mapStateToProps], [mapDispatchToProps], [mergeProps], [options]):
 * Connects a React component to a Redux store.
 *
 * connect() function returns:
 * A higher-order React component class that passes state and action creators
 * into your component derived from the supplied arguments.
 * It does not modify the component class passed to it;     instead,
 * it returns a new, connected component class for you to use.
 *
 * Here it returns `Main` component, it injects `State` as `Props` into
 * `Main` component.
 *
 * More details about Redux API:
 * https://github.com/reactjs/react-redux/blob/master/docs/api.md
 *
 * PS: Some props: `Products, Recipes, Batches` is automatically passed
 * down to the child, without help of mapDispatchToProps() or mapStateToProps().
 */
const ReduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);

export default ReduxConnector;
