import { INTERCOM_APPID } from "../config/release";
import { IReduxStaff } from '../redux/reducers/StaffReducer';

/**
 * this file using Intercom Third party library
 * it will append an Intercom function into Window Object,
 * so the code and library in this is may not be reachable by compiler.
 * document: https://developers.intercom.com/installing-intercom/docs/basic-javascript
 */
export const initIntercom = (staff: IReduxStaff): void => {
  let intercomFun = getIntercom();
  /**
   * if Intercom initialized already,
   * then we don't have to install Intercom Lib again.
   */
  if (intercomFun) {
    return;
  }

  // @ts-ignore unreachable exception
  (function(APP_ID){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];if(!x){return;};x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})(INTERCOM_APPID);

  // Reassign if initialized just now:
  intercomFun = getIntercom();
  bootIntercom(intercomFun, staff);
};

export const bootIntercom = (intercomFun: Function, staff: IReduxStaff): boolean => {
  if (!intercomFun){
    return false;
  }

  const currentTime = (new Date()).getTime();

  intercomFun('boot', {
    app_id: INTERCOM_APPID,
    email: staff.email,
    created_at: currentTime,
    name: staff.name,
    user_id: staff.staffTrackingId,
    user_hash: staff.intercomHash,
    alignment: 'left',
    hide_default_launcher: true,
  });
  return true;
};


export const showIntercom = () => {
  const intercom = getIntercom();
  if (intercom) {
    // @ts-ignore
    intercom('show');
  }
};

const getIntercom = (): Function => {
  // @ts-ignore unreachable exception
  return window.Intercom;
};
