import React from 'react';
import { TopLeftBackButton } from '../../pages/recipes/components/TopLeftBackButton';
import { GO_BACK_TEXT } from '../../TextProvider';
import { IModalGoBackHandler } from '../../typings/Modal';

interface IProps {
  goBackHandler: IModalGoBackHandler;
  goBackLabel?: string;
  goBackModal?: JSX.Element | null;
  showGoBackModal?: boolean;
}

/**
 * Goback modal always appears with the Back button,
 * thus added a logic here.
 * The parent component decides to show modal window.
 */
export const AppHeaderLeftContent = React.memo(
  ({
    goBackHandler,
    goBackLabel = GO_BACK_TEXT,
    goBackModal = null,
    showGoBackModal = false,
  }: IProps): JSX.Element => (
    <React.Fragment>
      <TopLeftBackButton
        goBackLabel={goBackLabel}
        goBackHandler={goBackHandler}
      />
      {showGoBackModal && goBackModal}
    </React.Fragment>
  )
);
