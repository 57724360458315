import { always2decimalPlaces } from '../number-format/Always2DecimalPlaces';
import { CURRENCY_SYMBOL } from '../../TextProvider';
import Big from 'big.js';

export const printCost = (price: number | null | Big) => {
  return ((price !== null) && (price !== undefined))
    // Costs is displayed with 2 decimal places:
    // https://kounta.atlassian.net/browse/PRO-370
    ? `${CURRENCY_SYMBOL}${always2decimalPlaces(price)}`
    : '-';
};
