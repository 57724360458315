import styled from 'styled-components';

/** @component */
export const PageContainer = styled.div`
  margin-top: 24px;
  padding: 0 16px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  /* has to be scroll, not auto for smooth scrolling iOS to work */
  overflow-y: scroll;

  .form-content {
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 0 48px;
  }

  /* For padding at the bottom for better scrolling experience */
  &:after {
    content: '';
    display: block;
    height: 80px;
    width: 100%;
  }
`;

/**
 * This class makes sure mobile has smaller/none left&right space than bigger screen.
 */
export const ProductTablePageContainer = styled(PageContainer)`
  max-width: 768px;
  margin: 0 auto;
`;
