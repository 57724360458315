import { ZERO } from '../number-format/BigNumberHandler';
import { IRecipeCosts } from '../../typings/Interface';

/**
 * `updateGrossProfitAndSellPrice()` does NOT accept potential `undefined`
 * values. This function is ONLY used by underlying NumberInput child
 * components, to update the value.
 */
export const updateGrossProfitAndSellPrice = (
  setGrossProfitAndSellPrice: (func: (params: IRecipeCosts) => IRecipeCosts) => void
): ((newSellPrice: string, newGrossProfit: string) => void) => {
  return (newSellPrice: string, newGrossProfit: string) => {
    setGrossProfitAndSellPrice(
      (previousState: IRecipeCosts) => {
        const result: IRecipeCosts = {
          ...previousState,
          sellPrice: (newSellPrice === '')
            ? `${ZERO}`
            : newSellPrice,
          grossProfit: (newGrossProfit === '')
            ? `${ZERO}`
            : newGrossProfit,
        };
        return result;
      }
    );
  }
};
