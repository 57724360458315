import {
  IApiBatch,
  IApiHandlerResult,
  IApiProduct,
  IApiRecipe,
  IGraphQlSubscriptionFunction,
  IHandleModalParams,
  ISiteInfo,
} from '../../typings/Interface';
import { IStaff } from '../../graphql/StaffQL';
import { hasProducePermission } from '../staff/HasProducePermission';
import { IloadingHandlerWithReturnValueFunction } from '../loader/LoadingHandler';
import {
  add1stFetchBatchesIntoRedux,
  add1stFetchRecipesIntoRedux,
  addSubsequentBatchesAfterFirstFetch,
  addSubsequentRecipesAfterFirstFetch,
  loadMadeHereProductsList,
  loadPurchaseProductsList,
} from '../../redux/UpdateProductRecipeBatchLists';
import {
  IAddProductsByInventory,
  IFetchAllInOne,
  IOnAddOrReplaceList,
  IOnLoadBillingPlan,
  IOnLoadSiteInfo,
  IOnLoadStaff,
  IOnSetProductsFullyLoaded,
  IOnSetRecipesFullyLoaded,
  IOnSetRemainingBatchAllowance,
  IReplaceBatchesList,
  IUpdateBillingPlanFunction,
  IUpdateListFunction,
} from '../../redux/typings/Interface';
import { ELoadingTitle } from '../../TextProvider';
import {
  ILoadAllThirdPartyFunction,
} from '../../third-party-script-loader/ScriptLoaderEntry';
import { translateApiStaff } from '../staff/TanslateApiStaff';

export const layoutUseEffectFunction = (
  loadingHandlerWithReturnValue: IloadingHandlerWithReturnValueFunction,
  getSiteInfo: () => Promise<IApiHandlerResult<ISiteInfo>>,
  onLoadSiteInfo: IOnLoadSiteInfo,
  onLoadStaff: IOnLoadStaff,
  onAddPartialRecipes: IOnAddOrReplaceList<IApiRecipe>,
  onAddPartialBatches: IOnAddOrReplaceList<IApiBatch>,
  onAddPartialProducts: IOnAddOrReplaceList<IApiProduct>,
  onLoadingBatches: IOnAddOrReplaceList<IApiBatch>,
  onLoadingRecipes: IOnAddOrReplaceList<IApiRecipe>,
  onLoadingProducts: IOnAddOrReplaceList<IApiProduct>,
  onLoadBillingPlan: IOnLoadBillingPlan,
  onSetRemainingBatchAllowance: IOnSetRemainingBatchAllowance,
  handleModal: (params: IHandleModalParams) => void,
  setLoading: (shouldShowLoading: boolean, loadingText?: ELoadingTitle) => void,
  onSetProductsFullyLoaded: IOnSetProductsFullyLoaded,
  onSetRecipesFullyLoaded: IOnSetRecipesFullyLoaded,
  getStaff: () => Promise<IApiHandlerResult<IStaff>>,
  redirectToBackOfficeProducePermissionDeniedPage: () => string,
  redirectToBackOfficeProduceLoginPage: () => string,
  initialFetchOfProductsRecipesBatchesAndPlanRestrictions: IFetchAllInOne,
  replaceBatchesList: IReplaceBatchesList,
  replaceRecipesList: IUpdateListFunction<IApiRecipe, boolean>,
  addProductsByInventoryType: IAddProductsByInventory,
  updateBillingPlan: IUpdateBillingPlanFunction,
  batchSubscription: IGraphQlSubscriptionFunction,
  recipeSubscription: IGraphQlSubscriptionFunction,
  productSubscription: IGraphQlSubscriptionFunction,
  billingPlanSubscription: IGraphQlSubscriptionFunction,
  loadAllThirdParty: ILoadAllThirdPartyFunction,
) => {
  return (
    () => {
      // We are enforcing es-lint rule here by moving all functions that
      // related to `fetchProductsRecipesBatchesAndStaffForOnce()` inside
      // `useEffect()`; Check
      // https://github.com/facebook/create-react-app/issues/6880


      let batchObservable: ZenObservable.Subscription | null = null;
      let recipeObservable: ZenObservable.Subscription | null = null;
      let productObservable: ZenObservable.Subscription | null = null;
      let billingPlanObservable: ZenObservable.Subscription | null = null;

      /**
       * TODO: Research if we could/should rely on subscriptions only, instead
       * of fetching the lists first and then subscribe to different events.
       * This initial load api calls are becoming more and more complicate.
       */
      const fetchProductsRecipesBatchesAndStaffForOnce = async () => {
        const staffDetail = await getStaff();
        if (!hasProducePermission(staffDetail)) {
          redirectToBackOfficeProducePermissionDeniedPage();
          return;
        }
        const staff = translateApiStaff(staffDetail.responseObj);
        onLoadStaff(staff);

        // The main purpose of this function is to show a `loading spinner`
        // while loading the most basic data from backend.
        // This is to avoid users seeing a blank page.
        // With the `loading spinner`, users are aware the page is loading.
        // We only load the most essential data with `loading spinner`:
        // eg:
        //  1. the first pagination of products/recipes/batches.
        //  2. site billing plan info.
        // The rest of the data, like remaining pagination of the
        // products/recipes/batches, can be lazy loaded, without a loading
        // spinner.
        const resultOfPromiseAll = await loadingHandlerWithReturnValue(
          initialFetchOfProductsRecipesBatchesAndPlanRestrictions(
            add1stFetchRecipesIntoRedux,
            add1stFetchBatchesIntoRedux,
            updateBillingPlan,
            loadMadeHereProductsList,
            // using `onAddPartialProducts`, but only add madeHere products:
            onAddPartialRecipes,
            onAddPartialBatches,
            onLoadingProducts,
            onLoadBillingPlan,
            onSetRemainingBatchAllowance,
            handleModal,
          ),
          setLoading
        )();

        // Load siteInfo:
        const siteInfo = await getSiteInfo();
        const siteInfoObj = siteInfo.responseObj;
        onLoadSiteInfo(siteInfoObj);

        /**
         * The order of the promises is maintained. The first promise in the
         * array will get resolved to the first element of the output array,
         * the second promise will be a second element in the output array and
         * so on.
         * https://www.freecodecamp.org/news/promise-all-in-javascript-with-example-6c8c5aea3e32/
         *
         * Lazy load the rest of the recipes/batches. No `await`, fire and
         * forget:
         */
        addSubsequentRecipesAfterFirstFetch(
          resultOfPromiseAll[0],
          onAddPartialRecipes,
          handleModal,
          onSetRecipesFullyLoaded,
        );
        addSubsequentBatchesAfterFirstFetch(
          resultOfPromiseAll[1],
          onAddPartialBatches,
          handleModal,
        );

        // Lazy load other Products (Purchase). No `await`, fire and forget:
        loadPurchaseProductsList(
          onAddPartialProducts,
          onSetProductsFullyLoaded,
          handleModal,
        );

        // Lazy load 3rd party scripts:
        loadAllThirdParty(staff, siteInfoObj);

        // deal with subscription:
        batchObservable = batchSubscription(
          siteInfoObj.siteUuid,
          batchSubscriptionHandler
        );
        recipeObservable = recipeSubscription(
          siteInfoObj.companyUuid,
          recipeSubscriptionHandler
        );
        productObservable = productSubscription(
          siteInfoObj.companyUuid,
          productSubscriptionHandler
        );

        billingPlanObservable = billingPlanSubscription(
          siteInfoObj.siteUuid,
          billingPlanSubscriptionHandler
        );
      };

      // Below subscriptions has to use arrow function expression,
      // because the subscription call back triggered outside this class. To
      // make sure proper `this` binding, we use arrow function expression.
      const batchSubscriptionHandler = async () => {
        const succeedYn = await replaceBatchesList(
          onLoadingBatches,
          onSetRemainingBatchAllowance,
          handleModal,
        );
        if (!succeedYn) {
          redirectToBackOfficeProduceLoginPage();
        }
      };

      const recipeSubscriptionHandler = async () => {
        const succeedYn = await replaceRecipesList(
          onLoadingRecipes,
          handleModal,
        );
        if (!succeedYn) {
          redirectToBackOfficeProduceLoginPage();
        }
      };

      const productSubscriptionHandler = async () => {

        const succeedYn = await addProductsByInventoryType(
          onLoadingProducts,
          handleModal,
        );
        if (!succeedYn) {
          redirectToBackOfficeProduceLoginPage();
        }
      };

      const billingPlanSubscriptionHandler = async () => {
        const succeedYn = await updateBillingPlan(
          onLoadBillingPlan,
          onSetRemainingBatchAllowance,
          handleModal,
        );
        if (!succeedYn) {
          redirectToBackOfficeProduceLoginPage();
        }
      };

      // Use async in useEffect:
      // https://www.robinwieruch.de/react-hooks-fetch-data/
      // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
      // Below function should run only once, we are using `[userInfo,
      // siteInfo, cognitoObj .... ]` to ensure it won't run in every
      // re-rendering. After initialisation of the application, `recipes` &
      // `batches` will use GraphQL subscriptions to ensure the freshness.
      // `Products` from Backoffice API will still need each container to
      // refresh.
      fetchProductsRecipesBatchesAndStaffForOnce();



      // clean up so that we don’t introduce a memory leak!
      // https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup
      // Specify how to clean up after this effect:
      return () => {
        if (batchObservable != null) {
          batchObservable.unsubscribe();
        }
        if (recipeObservable != null) {
          recipeObservable.unsubscribe();
        }
        if (productObservable != null) {
          productObservable.unsubscribe();
        }
        if (billingPlanObservable != null) {
          billingPlanObservable.unsubscribe();
        }
      };
    });
};
