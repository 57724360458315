import { EUom } from "../typings/Interface";
import InternalError, { InternalErrorCode } from '../error/InternalError';
import { EMass, EUomCategory, EVolume } from './AcceptableUnits';

export const findUnitCategoryByUom = (fromUom: EUom) => {
  if (fromUom === EUom.unit) {
    return EUomCategory.UNIT;
  }
  if (Object.values(EMass).includes(fromUom)) {
    return EUomCategory.WEIGHT;
  }
  if (Object.values(EVolume).includes(fromUom)) {
    return EUomCategory.VOLUME;
  }
  throw new InternalError('cannot find the UomCategory', InternalErrorCode.UomError);
};
