import {
  EBatchStatus,
  EQueryNames,
  ESubscriptionQueryNames,
  EUom,
  IApiBatch,
  IApiHandlerResult,
  IFormikBatchIngredient,
  IGraphQlSubscriptionFunction,
  Uuid,
} from '../typings/Interface';
import {
  capitalizeFirstLetter,
  purifyIngredientsQuery,
  replaceLineBreakToBr,
} from './QueryStringPurify';
import {
  BATCH_FIELDS,
  BATCHES_PAGING,
  SUBSCRIPTION_FIELDS,
} from './CommonFields';
import { graphQlApiHandler, graphQlSubscriptionHandler } from './Handler';
import {
  IPaginationEncodedRequest,
  IPaginationResponse,
  PAGINATION_LIMIT,
  IGraphQlPagingRequestFunction,
  IGraphQlRequestArgument,
} from '../typings/Pagination';

// 1. CreateBatch
export const createBatchMutation = (
  recipeUuid: Uuid,
  note: string | null,
  status: EBatchStatus.planned | EBatchStatus.inProgress,
  batchIngredients: IFormikBatchIngredient[],
  uom: EUom,
  isCustomYield: boolean,
  expectedYield: number,
  plannedTime: string | null = null
): string => {
  let optionalPlannedTime = '';
  if (plannedTime !== null) {
    optionalPlannedTime = `plannedTime: "${plannedTime}",`;
  }

  let optionalNote = '';
  if (note !== null) {
    optionalNote = `note: "${replaceLineBreakToBr(note)}",`;
  }

  const recipeIngredientsQuery = purifyIngredientsQuery(batchIngredients);

  return `mutation ${capitalizeFirstLetter(EQueryNames.createBatch)}(
      $productName: String!
    ){
    ${EQueryNames.createBatch}(
      recipeUuid: "${recipeUuid}",
      productName: $productName,
      ${optionalNote}
      status: ${status},
      ${optionalPlannedTime}
      batchIngredients: ${recipeIngredientsQuery},
      uom: ${uom},
      isCustomYield: ${isCustomYield},
      expectedYield: ${expectedYield}
    ){
      ${BATCH_FIELDS}
    }
  }`;
};

export async function createBatch(
  recipeUuid: Uuid,
  productName: string,
  note: string | null,
  status: EBatchStatus.planned | EBatchStatus.inProgress,
  batchIngredients: IFormikBatchIngredient[],
  uom: EUom,
  isCustomYield: boolean,
  expectedYield: number,
  plannedTime: string | null = null
): Promise<IApiHandlerResult<IApiBatch>> {
  const createBatchQl = createBatchMutation(
    recipeUuid,
    note,
    status,
    batchIngredients,
    uom,
    isCustomYield,
    expectedYield,
    plannedTime
  );
  const variable = { productName };
  return await graphQlApiHandler<IApiBatch>(createBatchQl, EQueryNames.createBatch, variable);
}

// 2. DeleteBatch
export const deleteBatchMutation = (batchUuid: Uuid): string =>
  `mutation ${capitalizeFirstLetter(EQueryNames.deleteBatch)}{
    ${EQueryNames.deleteBatch}(
      uuid: "${batchUuid}",
    ){
      ${BATCH_FIELDS}
    }
  }`;

export async function deleteBatch(batchUuid: Uuid): Promise<IApiHandlerResult<IApiBatch>> {
  const deleteBatchQl = deleteBatchMutation(batchUuid);

  return await graphQlApiHandler<IApiBatch>(deleteBatchQl, EQueryNames.deleteBatch);
}

// 3. startBatch
export const startBatchMutation = (batchUuid: Uuid): string =>
  `mutation ${capitalizeFirstLetter(EQueryNames.startBatch)}{
    ${EQueryNames.startBatch}(
      uuid: "${batchUuid}",
    ){
      ${BATCH_FIELDS}
    }
  }`;

export async function startBatch(batchUuid: Uuid): Promise<IApiHandlerResult<IApiBatch>> {
  const startBatchQl = startBatchMutation(batchUuid);

  return await graphQlApiHandler<IApiBatch>(startBatchQl, EQueryNames.startBatch);
}

// 4. completeBatch
export const completeBatchMutation = (batchUuid: Uuid, actualYield: number): string =>
  `mutation ${capitalizeFirstLetter(EQueryNames.completeBatch)}{
    ${EQueryNames.completeBatch}(
      uuid: "${batchUuid}",
      actualYield: ${actualYield},
    ){
      ${BATCH_FIELDS}
    }
  }`;

export async function completeBatch(
  batchUuid: Uuid,
  actualYield: number
): Promise<IApiHandlerResult<IApiBatch>> {
  const completeBatchQl = completeBatchMutation(batchUuid, actualYield);

  return await graphQlApiHandler<IApiBatch>(completeBatchQl, EQueryNames.completeBatch);
}

// 5. updateBatch
export const updateBatchMutation = (
  batchUuid: Uuid,
  note: string | null,
  status: EBatchStatus.planned | EBatchStatus.inProgress,
  batchIngredients: IFormikBatchIngredient[],
  uom: EUom,
  isCustomYield: boolean,
  expectedYield: number,
  plannedTime: string | null = null
): string => {
  let optionalPlannedTime = '';
  if (plannedTime !== null) {
    optionalPlannedTime = `plannedTime: "${plannedTime}",`;
  }

  let optionalNote = '';
  if (note !== null) {
    optionalNote = `note: "${replaceLineBreakToBr(note)}",`;
  }

  const batchIngredientsQuery = purifyIngredientsQuery(batchIngredients);

  return `mutation ${capitalizeFirstLetter(EQueryNames.updateBatch)}{
    ${EQueryNames.updateBatch}(
      uuid: "${batchUuid}",
      ${optionalNote}
      status: ${status},
      ${optionalPlannedTime}
      batchIngredients: ${batchIngredientsQuery},
      uom: ${uom},
      isCustomYield: ${isCustomYield},
      expectedYield: ${expectedYield}
    ){
      ${BATCH_FIELDS}
    }
  }`;
};

export async function updateBatch(
  batchUuid: Uuid,
  note: string | null,
  status: EBatchStatus.planned | EBatchStatus.inProgress,
  batchIngredients: IFormikBatchIngredient[],
  uom: EUom,
  isCustomYield: boolean,
  expectedYield: number,
  plannedTime: string | null = null
): Promise<IApiHandlerResult<IApiBatch>> {
  const updateBatchQl = updateBatchMutation(
    batchUuid,
    note,
    status,
    batchIngredients,
    uom,
    isCustomYield,
    expectedYield,
    plannedTime
  );

  return await graphQlApiHandler<IApiBatch>(updateBatchQl, EQueryNames.updateBatch);
}

// 6. get batches
export const getBatchesQuery = (): string => {
  return `query ${capitalizeFirstLetter(EQueryNames.getBatches)}(
      $paginationRequest: PaginationRequestInput!){
      ${EQueryNames.getBatches}(
        paginationRequest: $paginationRequest
      ){
        ${BATCHES_PAGING}
      }
    }`;
};

export const getBatches: IGraphQlPagingRequestFunction<IApiBatch, IGraphQlRequestArgument> = async (
  {
    base64EncodedCursor,
  }
): Promise<IApiHandlerResult<IPaginationResponse<IApiBatch>>> => {
  const paginationRequest: IPaginationEncodedRequest = {
    limit: PAGINATION_LIMIT,
  };

  if (base64EncodedCursor) {
    paginationRequest.cursor = base64EncodedCursor;
  }

  const getBatchesQl = getBatchesQuery();
  const variable = { paginationRequest };
  return await graphQlApiHandler<IPaginationResponse<IApiBatch>>(
    getBatchesQl,
    EQueryNames.getBatches,
    variable,
  );
}

export const batchSubscriptionQuery = (siteUuid: Uuid): string =>
  `subscription ${ESubscriptionQueryNames.updatedBatchBySite} {
    ${ESubscriptionQueryNames.updatedBatchBySite}(siteUuid: "${siteUuid}") {
      ${SUBSCRIPTION_FIELDS}
    }
  }`;

export const batchSubscription: IGraphQlSubscriptionFunction = (
  siteUuid: Uuid,
  subscriptionCallBacks: () => Promise<void>
): ZenObservable.Subscription => {
  return graphQlSubscriptionHandler(
    batchSubscriptionQuery(siteUuid),
    ESubscriptionQueryNames.updatedBatchBySite,
    subscriptionCallBacks,
  );
};
