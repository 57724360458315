import {
  Alignment,
  Badge,
  Box,
  Group,
  Heading3,
  Heading6,
  IconTick,
  Label,
  Link,
  Paragraph,
  Sheet,
  TextTruncate,
} from '@kounta/chameleon';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EUom, Uuid } from '../../typings/Interface';
import { printQtyWithUom } from '../../utils/batch/PrintQtyWithUom';
import { redirectToSingleCompletedBatch } from '../../utils/url/UrlRedirect';

// setTimeout unit: milliseconds
const DEFAULT_DURATION = 10 * 1000;
export const ALERT_MESSAGE =
  'The ingredients used in the batch have been consumed from stock levels';

interface IFinishBatchSheetProps extends RouteComponentProps {
  batchUuid: Uuid;
  productName: string;
  actualYield: number;
  uom: EUom;
  hideSheet: () => void;
}

const CompletedBatchSheet = React.memo((props: IFinishBatchSheetProps) => {
  return (
    <div>
      <Sheet
        onClose={props.hideSheet}
        autoClose
        autoCloseDuration={DEFAULT_DURATION}
        width="378px"
      >
        <Group center even horizontalWidth="-webkit-fill-available">
          <Badge status="success" circle>
            <IconTick height={16} width={16} />
          </Badge>
          <Heading3 bold>Batch completed</Heading3>
          <Paragraph align={'center'}>{ALERT_MESSAGE}</Paragraph>
          <Group center spacing="4px">
            <Heading6>Stock Created</Heading6>
            <Box callout showBorder={false} spacing="medium">
              <Group horizontal horizontalWidth="-webkit-fill-available">
                <Alignment horizontal="left">
                  <Label align="left">
                    {props.productName}
                  </Label>
                </Alignment>
                <Alignment horizontal="right">
                  <Label align="right">
                    <TextTruncate>
                      {printQtyWithUom(
                        props.actualYield,
                        props.uom,
                      )}
                    </TextTruncate>
                  </Label>
                </Alignment>
              </Group>
            </Box>
          </Group>
          <Link
            primary
            onClick={
              redirectToSingleCompletedBatch(props.history.push, props.batchUuid)
            }
          >More info
          </Link>
        </Group>
      </Sheet>
    </div>
  );
});

export default withRouter(CompletedBatchSheet);
