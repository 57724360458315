import { IApiBillingPlan } from '../../graphql/BillingPlanQL';

// This is a direct copy from BackOffice `protected/models/BillingPlan.php`
export enum EProducePlanIds {
  PLAN_PRODUCE_LITE = 28,
  PLAN_PRODUCE_BASIC = 29,
  PLAN_PRODUCE_STANDARD = 30,
  PLAN_PRODUCE_USA_BASIC = 31,
  PLAN_PRODUCE_USA_STANDARD = 32,
  PLAN_PRODUCE_INTERNAL = 41,
  PLAN_PRODUCE_PILOT = 65,
  PLAN_PRODUCE_SPONSORED = 66,
  PLAN_PRODUCE_PARTNER = 67,

  PLAN_PRODUCE_ADVANCED_RECIPES = 73,
  PLAN_PRODUCE_ADVANCED_RECIPES_USA = 74,
  PLAN_PRODUCE_ADVANCED_PREP = 75,
  PLAN_PRODUCE_ADVANCED_PREP_USA = 76,

  // https://jira.atlightspeed.net/browse/LSH-7220
  // https://github.com/kounta/kounta/pull/10887/files#diff-b44df7c226910f0e37faf3bd1b8ec0c26ad6b36cb2b2ac0b7f71b9a3b5e28948
  PLAN_PRODUCE_PRO_PRODUCE = 89,
}

// Only below produce plans will have limitation on batch operations.
// Other produce plans will have full batch access:
export const BATCH_RESTRICTED_PRODUCE_PLANS = [
  // https://kounta.atlassian.net/browse/PRO-398
  // https://kounta.atlassian.net/browse/PRO-399
  EProducePlanIds.PLAN_PRODUCE_LITE,
  EProducePlanIds.PLAN_PRODUCE_ADVANCED_RECIPES,
  EProducePlanIds.PLAN_PRODUCE_ADVANCED_RECIPES_USA,
];

export const isLitePlan = (billingPlan: IApiBillingPlan): boolean => {
  if (!billingPlan.produce || !billingPlan.produce.newPlanId) {
    // If a produce plan cannot be found, then it is treated as LitePlan.
    // This situation should not happen, a site without Produce subscription
    // should not be allowed to go into Produce website.
    // So here is just a fallback and avoid application crash:
    return true;
  }
  return billingPlan.produce.newPlanId === EProducePlanIds.PLAN_PRODUCE_LITE;
};
