import { REACT_ROUTER_URL_LIST } from '../../TextProvider';
import { matchPath } from "react-router-dom";

/**
 * expected result:
 * [
 *    [ 'incompleteSingleBatch', '/incompletebatch/:batchUuid' ],
 *    [ 'completedSingleBatch', '/completedbatch/:batchUuid' ],
 *    [ 'createBatch', '/production/create-batch' ],
 *    [ 'singleRecipe', '/recipe/:productId' ],
 *    [ 'incompleteBatches', '/production' ],
 *    [ 'recipes', '/recipes' ],
 *    [ 'completedBatches', '/history' ],
 *    [ 'default', '/' ]
 * ]
 */
export const orderRouterUrlPathByLengthDescending = (): (string[])[] => {
  const sortable = Object.entries(REACT_ROUTER_URL_LIST);

  sortable.sort(function(previous, next) {
    // make sure the longest Path string is on the top of the list.
    // Order by length DESC:
    return next[1].length - previous[1].length;
  });

  return sortable;
}

export const findMatchingUrlPath = (pathName: string): string => {
  const hayStack = orderRouterUrlPathByLengthDescending();
  const firstMath = hayStack.find((e: string[]): boolean => {
    const verifyMatch = matchPath(pathName, {
      path: e[1],
      exact: true,
      strict: true
    });
    return verifyMatch !== null;

  });

  if (firstMath === undefined) {
    throw new Error('Invalid URL, cannot find a match.');
  }

  return firstMath[1];
};

