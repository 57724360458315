import * as React from 'react';
import {
  Button,
  Group,
  Portal,
  SnackBar,
  Table,
  TableHeaderType,
  TableRowType,
} from '@kounta/chameleon';
import {
  IApiProduct,
  IApiRecipe,
  IFormikRecipe,
} from '../../typings/Interface';
import {
  EButtonType,
  EToolTipStatusName,
  IChameleonTooltipCallbackDataType,
} from '../../typings/Chameleon';
import { emptyRecipeIngredient, } from '../../formik/recipe/InitialDraftRecipe';
import { ArrayHelpers } from 'formik';
import { markUomTooltipDisplayed } from '../../persister/Cookie';
import { UOM_TOOLTIP } from '../../TextProvider';
import { ToolTipWrapper } from '../tooltip/TooltipWrapper';
import { TotalCost } from "../total-cost/TotalCost";
import { generateRecipeIngRow } from './GenerateRecipeIngRow';
import { generateReadOnlyRecipeIngRow } from './GenerateReadOnlyRecipeIngRow';
import { IOnAddOrReplaceList } from '../../redux/typings/Interface';

interface IProps {
  // Redux stores:
  products: IApiProduct[];
  recipes: IApiRecipe[];

  pushNewIngredientRowIntoFormikFieldArray: ArrayHelpers['push'];
  removeIngredientRowFromFormikFieldArray: ArrayHelpers['remove'];

  shouldShowUomDropDownRedirectToBackOffice: boolean;
  shouldProductNameAutoFocus: boolean;

  onLoadingProducts: IOnAddOrReplaceList<IApiProduct>;

  // Plan restriction
  isLitePlan: boolean;
  currentEditingRecipe: IFormikRecipe;

  mainProduct: IApiProduct;
  hasEditRecipePermission: boolean;
  
  showSnackBar: boolean;
  snackBarHandler: (status: boolean) => void
}

interface IState {
  isTooltipEnabled: boolean;
}

const createProductSnackBarMessage = 'Product added successfully';

export default class RecipeIngredients extends React.Component<IProps, IState> {
  readonly state: IState = {
    isTooltipEnabled: false,
  };

  public render() {
    let ingredientsHeader: TableHeaderType[] = [];
    let rows: TableRowType[] = [];
    if(this.props.hasEditRecipePermission) {
      rows = generateRecipeIngRow(
        // passing Props:
        this.props.products,
        this.props.recipes,
        this.props.currentEditingRecipe,
        this.props.isLitePlan,
        this.props.shouldProductNameAutoFocus,
        this.props.shouldShowUomDropDownRedirectToBackOffice,
        // below is class functions:
        this.removeIngredient,
        this.enableToolTip,
        this.renderTooltip,
        this.showSnackBar,
        this.props.onLoadingProducts,
      );
      ingredientsHeader = ingredientsHeaderWithWritePermission;
    } else {
      ingredientsHeader = ingredientsHeaderWithReadOnlyPermission;
      rows = generateReadOnlyRecipeIngRow(
        this.props.products,
        this.props.recipes,
        this.props.currentEditingRecipe,
        this.props.isLitePlan,
      );
    }

    return (
      <React.Fragment>
        <Group>
          <Table
            showIndent={false}
            headers={ingredientsHeader}
            rows={rows}
            showRowIndicator={false}
            showRowLines={!this.props.hasEditRecipePermission}
            showHover={false}
            spacingBetweenCells="small"
            spacingBetweenRows="small"
          />
          { this.props.hasEditRecipePermission && (
            <Group horizontal={true}>
              <Button
                slim={true}
                secondary={true}
                onClick={this.addingIng}
                type={EButtonType.button}
              >
                Add ingredient
              </Button>
            </Group>
          )}
          <TotalCost
            mainProduct={this.props.mainProduct}
            readOnly={!this.props.hasEditRecipePermission}
            shouldBlurPrice={this.props.isLitePlan}
            currentEditingRecipe={this.props.currentEditingRecipe}
            products={this.props.products}
            recipes={this.props.recipes}
            shouldAddTopDivider={this.props.hasEditRecipePermission}
          />
        </Group>
        {
          this.props.showSnackBar &&
          <Portal>
              <SnackBar
                  message={createProductSnackBarMessage}
                  autoHide={true}
                  type={'success'}
                  onClose={this.removeSnackBar}
              />
          </Portal>
        }
      </React.Fragment>
    );
  }

  private enableToolTip = () => {
    this.setState({
      ...this.state,
      isTooltipEnabled: true,
    });
  };

  private addingIng = () => {
    this.props.pushNewIngredientRowIntoFormikFieldArray(emptyRecipeIngredient);
  };

  private showSnackBar = () => {
    this.props.snackBarHandler(true);
  }

  private removeSnackBar = () => {
    this.props.snackBarHandler(false);
  }

  private closeTooltipCallback = (data: IChameleonTooltipCallbackDataType) => {
    if (data.status === EToolTipStatusName.finished) {
      markUomTooltipDisplayed();
    }
  };

  private renderTooltip = (arrayIndex: number): JSX.Element | null => {
    if (arrayIndex === 0 && this.state.isTooltipEnabled) {
      return (
        <ToolTipWrapper
          steps={UOM_TOOLTIP}
          callback={this.closeTooltipCallback}
        />
      );
    }
    return null;
  };

  private removeIngredient = (
    arrayIndex: number,
  ) => () => {
    this.props.removeIngredientRowFromFormikFieldArray(arrayIndex);
  };
}

export const UOM_DROP_DOWN_WIDTH = '100px';

const ingredientsHeaderWithReadOnlyPermission: TableHeaderType[] = [
  {
    id: 1,
    name: 'Ingredients',
  },
  {
    id: 2,
    name: 'Qty',
    width: '100px',
    align: 'left',
  },
  {
    id: 3,
    name: 'COST',
    width: '20px',
    align: 'right',
  },
  {
    id: 4,
    name: ' ',
    width: '46px',
    align: 'right',
  },
];

const ingredientsHeaderWithWritePermission: TableHeaderType[] = [
  ingredientsHeaderWithReadOnlyPermission[0],
  ingredientsHeaderWithReadOnlyPermission[1],
  {
    id: 3,
    name: ' ',
    width: UOM_DROP_DOWN_WIDTH,
    align: 'right',
  },
  {
    ...ingredientsHeaderWithReadOnlyPermission[2],
    id: 4,
  },
  {
    id: 5,
    name: ' ',
    width: '64px',
    align: 'right',
  },
];

