import Big from 'big.js'
import {
  PERCENTAGE_RATE,
  roundToPrecision, SHOW_PRECISION,
  ZERO, ZERO_STRING_DISPLAY
} from '../number-format/BigNumberHandler';

export const calculateGrossProfit = (
  sellPrice: string,
  unitCost: Big,
): string => {
  const oriSellPrice = (sellPrice === '')? ZERO : sellPrice;
  const sellPriceBig = new Big(oriSellPrice);
  const unitCostBig = roundToPrecision(unitCost);
  const grossProfit = sellPriceBig.eq(ZERO) ?
    sellPriceBig :
    sellPriceBig.minus(unitCostBig).div(sellPrice).mul(PERCENTAGE_RATE);

  return grossProfit.toFixed(SHOW_PRECISION);
};

export const calculateSellPriceByProfit = (
  grossProfit: string,
  unitCost: Big,
): string => {
  const oriProfit = (grossProfit === '')? ZERO : grossProfit;
  const grossProfitBig = new Big(oriProfit);
  const one = new Big(1);
  const unitCostBig = roundToPrecision(unitCost);
  if (grossProfitBig.eq(ZERO)) {
    return ZERO_STRING_DISPLAY;
  }
  const grossProfitInFloat = grossProfitBig.div(PERCENTAGE_RATE);
  const result = unitCostBig.div(one.minus(grossProfitInFloat));
  return result.toFixed(SHOW_PRECISION);
};
