import {
  EReduxActionType,
  IActionSetProductsFullyLoaded, IActionSetRecipesFullyLoaded,
} from '../actions';

export const initialFlag = false;

export function isProductsFullyLoadedReducer(
  previousState: boolean = initialFlag,
  action: IActionSetProductsFullyLoaded,
): boolean {

  switch (action.type) {
    case EReduxActionType.SET_PRODUCTS_FULLY_LOADED:
      return action.isProductsFullyLoaded;
    default:
      return previousState;
  }
}

export function isRecipesFullyLoadedReducer(
  previousState: boolean = initialFlag,
  action: IActionSetRecipesFullyLoaded,
): boolean {

  switch (action.type) {
    case EReduxActionType.SET_RECIPES_FULLY_LOADED:
      return action.isRecipesFullyLoaded;
    default:
      return previousState;
  }
}
