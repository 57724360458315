import { EProducePermissionCode } from '../../typings/Interface';
import { IStaff, IStaffPermission } from '../../graphql/StaffQL';

interface IReduxStaffPartial {
  hasEditRecipePermission: boolean;
  hasEditBatchPermission: boolean;
}

export const determineEditPermissions = (staff: IStaff): IReduxStaffPartial => {
  if (staff.isAdmin) {
    return {
      hasEditRecipePermission: true,
      hasEditBatchPermission: true,
    };
  }
  const editRecipePermission = staff.permissions.find((e: IStaffPermission) => {
    return e.code === EProducePermissionCode.ManageRecipes;
  });
  const editBatchPermission = staff.permissions.find((e: IStaffPermission) => {
    return e.code === EProducePermissionCode.ManagePrep;
  });
  return {
    hasEditRecipePermission: (editRecipePermission !== undefined),
    hasEditBatchPermission: (editBatchPermission !== undefined),
  };
}
