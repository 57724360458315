import React from 'react';
import {
  Heading3,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
} from "@kounta/chameleon";
import { BATCH_DETAIL } from '../TextProvider';
import { printQtyWithUom } from '../../../utils/batch/PrintQtyWithUom';
import { IApiBatch } from '../../../typings/Interface';
import { printCost } from '../../../utils/batch/PrintCost';
import { SectionPaddingLeft } from '../../../css/Styled';

interface IProps {
  batch: IApiBatch
}

const headers: TableHeaderType[] = [
  {
    id: '1',
    name: BATCH_DETAIL.costSection.labels.expectedYield,
    sortable: false,
  },
  {
    id: '2',
    name: BATCH_DETAIL.costSection.labels.actualYield,
    sortable: false,
  },
  {
    id: '3',
    name: BATCH_DETAIL.costSection.labels.batchCost,
    sortable: false,
  },
  {
    id: '4',
    name: BATCH_DETAIL.costSection.labels.unitCost,
    sortable: false,
  },
];

export const CostsSection = React.memo(({ batch }: IProps) => {
    const rows: TableRowType[] = [
      {
        id: '1',
        content:
          [
            {
              id: '1',
              value: printQtyWithUom(batch.expectedYield, batch.uom)
            },
            {
              id: '2',
              value: printQtyWithUom(batch.actualYield as number, batch.uom)
            },
            {
              id: '3',
              value: printCost(batch.totalCost)
            },
            {
              id: '4',
              value: printCost(batch.unitCost)
            },
          ]
      },
    ];

    return (
      <React.Fragment>
        <SectionPaddingLeft>
          <Heading3>{BATCH_DETAIL.costSection.title}</Heading3>
        </SectionPaddingLeft>
        <Spacer spacing={'medium'}/>
        <Table
          headers={headers}
          rows={rows}
          showHover={false}
          showRowIndicator={false}
        />
      </React.Fragment>
    )
  }
);
