import {
  getProductNameByUuidFromList,
} from '../../utils/product/GetProductByUuidFromList';
import {
  IApiProduct,
  IApiRecipe,
  IFormikIngredient,
  IFormikRecipe,
} from '../../typings/Interface';
import { GetProductCostFromCollection } from '../../utils/recipe-cost/GetProductCostFromCollection';
import {
  TableCellType,
  TableRowType,
} from "@kounta/chameleon";
import { printQtyWithUom } from '../../utils/batch/PrintQtyWithUom';
import { printCost } from '../../utils/batch/PrintCost';

const LITE_PLAN_COST_MASK = '-';
export const generateReadOnlyRecipeIngRow = (
  products: IApiProduct[],
  recipes: IApiRecipe[],
  currentEditingRecipe: IFormikRecipe,
  shouldHidePrice: boolean,
): TableRowType[] => {
  const result = currentEditingRecipe.recipeIngredients.map(
    (ing: IFormikIngredient, arrayIndex: number): TableRowType => {
      const productName = getProductNameByUuidFromList(
        ing.productUuid,
        products,
      );

      const nameAndQtyRows: TableCellType[] = [
        {
          id: 1,
          value: productName,
        },
        {
          id: 2,
          value: printQtyWithUom(ing.componentQty, ing.uom),
        },
      ];

      let costValue = LITE_PLAN_COST_MASK;
      if (!shouldHidePrice) {
        const quantity = GetProductCostFromCollection(
          ing,
          products,
          recipes,
        );
        costValue = printCost(quantity);
      }

      nameAndQtyRows.push({
        id: 3,
        value: costValue,
      });

      // the `key` is a combination of both arrayIndex and
      // productUuid. This gives react certain stability when
      // re-rendering occurs.
      // It considered `initial` case and `update` case.
      return {
        id: `${arrayIndex}_${ing.productUuid}`,
        content: nameAndQtyRows,
      };
    }
  );
  return result;
};


