import {
  EConsumptionType,
  IApiRecipe,
  IApiRecipeIngredient,
  IFormikIngredient,
  IFormikRecipe,
} from '../typings/Interface';
import { initialDraftFormikRecipe } from '../formik/recipe/InitialDraftRecipe';
import { isNotesSame } from '../graphql/QueryStringPurify';

// TODO:
// Consider using Formik `dirty` to check if values are not deeply equal from initial values
// https://jaredpalmer.com/formik/docs/api/formik#dirty-boolean

export const doesRecipeHaveUnsavedChanges = (
  existingRecipe: IFormikRecipe | false,
  draftRecipe: IFormikRecipe
): boolean => {
  if (draftRecipe === initialDraftFormikRecipe) {
    // using initialDraft means there are no changes from user input:
    return false;
  }
  // create recipe but no user input
  if (
    existingRecipe === false &&
    (draftRecipe.expectedYield === initialDraftFormikRecipe.expectedYield ||
      !draftRecipe.expectedYield) &&
    draftRecipe.recipeIngredients.length ===
      initialDraftFormikRecipe.recipeIngredients.length &&
    draftRecipe.recipeIngredients[0].productUuid ===
      initialDraftFormikRecipe.recipeIngredients[0].productUuid &&
    draftRecipe.uom === initialDraftFormikRecipe.uom
  ) {
    return false;
  }
  if (existingRecipe === false) {
    // No existing recipe, that means Create Recipe, it is draft for sure:
    return true;
  }

  if (
    existingRecipe.recipeIngredients.length !==
    draftRecipe.recipeIngredients.length
  ) {
    return true;
  }
  for (let i: number = 0; i < existingRecipe.recipeIngredients.length; i++) {
    if (
      !isSameIngredient(
        existingRecipe.recipeIngredients[i],
        draftRecipe.recipeIngredients[i]
      )
    ) {
      return true;
    }
  }

  let recipeFieldRemains =
    (draftRecipe.consumptionType === existingRecipe.consumptionType) &&
    (draftRecipe.uom === existingRecipe.uom) &&
    (isNotesSame(draftRecipe.note, existingRecipe.note));

  if(draftRecipe.consumptionType === EConsumptionType.premade){
    // If it is `made to order`, we don't care about the changes on 'expectedYield',
    // if it is `Premade`, We need to care about the changes on 'expectedYield':
    recipeFieldRemains = recipeFieldRemains && (draftRecipe.expectedYield === existingRecipe.expectedYield);
  }

  // If Recipe from Api is same as DraftRecipe, then nothing has changed:
  return !recipeFieldRemains;
};

const extractFormikRecipeIngredient = (
  existingRecipe: IApiRecipe
): IFormikIngredient[] =>
  existingRecipe.recipeIngredients.map(
    (ing: IApiRecipeIngredient): IFormikIngredient => ({
      componentQty: ing.componentQty.toString(),
      productUuid: ing.productUuid,
      uom: ing.uom,
    })
  );

export const convertApiRecipeToFormikRecipe = (
  existingRecipe: IApiRecipe
): IFormikRecipe => {
  const newIngs = extractFormikRecipeIngredient(existingRecipe);
  return {
    ...existingRecipe,
    expectedYield: existingRecipe.expectedYield.toString(),
    recipeIngredients: newIngs,
  };
};

const isSameIngredient = (
  ingredientA: IFormikIngredient,
  ingredientB: IFormikIngredient
): boolean => {
  return (
    ingredientA.productUuid === ingredientB.productUuid &&
    ingredientA.componentQty === ingredientB.componentQty &&
    ingredientA.uom === ingredientB.uom
  );
};
