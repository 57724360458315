/**
 * NOTES:
 * 1. Only Actions will be called from Reactjs Components, eg: Wrapper.js
 * 2. Reducers are not called directly from Reactjs Components.
 *    Reducers only tells the Redux how to generate `nextState` from
 *    `previousState`;
 *
 * Reducers specify how the application's state changes in response to actions
 * sent to the store.
 *
 * Remember that actions only describe the fact that something happened,
 * but don't describe how the application's state changes.
 *
 * a reducer is a pure function. It only computes the next state. It should be
 * completely predictable: calling it with the same inputs many times should
 * produce the same outputs.
 *
 * Things you should never do inside a reducer:
 * 1. Mutate its arguments;
 * 2. Perform side effects like API calls and routing transitions;
 * 3. Call non-pure functions, e.g. Date.now() or Math.random()
 * These above should happen before an action is dispatched.
 */

import { combineReducers } from 'redux';
import productsReducer from './ProductsReducer';
import recipesReducer from './RecipesReducer';
import batchesReducer from './BatchesReducer';
import { isProductsFullyLoadedReducer, isRecipesFullyLoadedReducer } from './FlagReducer';
import billingPlanReducer from './BillingPlanReducer';
import batchAllowanceReducer from './BatchAllowanceReducer';
import staffReducer from './StaffReducer';
import siteReducer from './SiteReducer';

const reducersCombined = combineReducers({
  products: productsReducer,
  recipes: recipesReducer,
  batches: batchesReducer,
  isProductsFullyLoaded: isProductsFullyLoadedReducer,
  isRecipesFullyLoaded: isRecipesFullyLoadedReducer,
  billingPlan: billingPlanReducer,
  remainingBatchAllowance: batchAllowanceReducer,
  staff: staffReducer,
  siteInfo: siteReducer,
});

export default reducersCombined;
