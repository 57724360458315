import { Field } from 'formik';
import * as React from 'react';
import { EFormikIngredientsFieldName } from '../../typings/Interface';
import { renderNumberInput } from '../number-input/RenderNumberInput';

interface IProps {
  fieldNamePrefix: EFormikIngredientsFieldName;
  arrayIndex: number;
  allowNegative: boolean;
}

export const QtyRow = React.memo(
  ({ fieldNamePrefix, arrayIndex, allowNegative }: IProps): JSX.Element => {
    return (
      <Field
        name={`${fieldNamePrefix}.${
          arrayIndex
          }.componentQty`}
      >
        {renderNumberInput(allowNegative)}
      </Field>
    );
  }
);
