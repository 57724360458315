import { redirectToBackOfficeProduceLoginPage } from '../utils/url/UrlRedirect';

// https://aws-amplify.github.io/docs/js/authentication#token-refresh
// https://github.com/aws-amplify/amplify-js/issues/2529
// https://github.com/aws-amplify/amplify-js/pull/665
// When AWS 'cognito token'/Credentials expired, instead of implementing an actual
// cognito token, we just redirect user back to BackOffice.  In the future, we
// can build an API kind of endpoint in backoffice to accept the refreshToken
// request, and return a new token. That backoffice endpoint should still
// require Login session. This way Login is always taken cared by backoffice,
// not Produce.

/**
 * In order to fully understand the workflow, it helps to note the following:
 *
 *  1, Depending on what functions are called, AWS credentials are usually loaded lazily.
 *      As a result, you'll want to make sure they are actually loaded when inspecting them
 *      (e.g., via making an an API call or by calling Auth.currentCredentials()).
 *  2, The expiration of AWS credentials isn't checked until the credentials are needed.
 *  3, The expiration of tokens used to retrieve credentials aren't checked until the tokens are needed.
 *      In effect this occurs when AWS credentials have expired and need to be refreshed.
 *
 * As a result the refresh handler won't be triggered under normal circumstances
 *  unless both the AWS credentials and the user token have expired.
 *  And the whole process has to kick off with a request for the credentials
 *  (either via an API call or a call to currentCredentials()).
 */
export const refreshTokenHandler = async (): Promise<null> => {
  /**
   * ATTENTION!!
   * To avoid `circular dependence` issue while doing es6 `import`, we should
   * not use `REDIRECT_BACKOFFICE_HOST = release.ts-> BACKOFFICE_HOST`
   * from './TextProvider.ts',
   * Otherwise, there will be below circular dependence:
   *
   *    UrlRedirect.ts              -- Import REDIRECT_BACKOFFICE_HOST from -->
   *    TextProvider.ts             -- Import BACKOFFICE_HOST          from -->
   *    './config/SharedConfig.ts'  -- Import refreshTokenHandler()    from -->
   *    'RefreshTokenHandler.ts'    -- Import redirectToBackOffice()   from -->
   *     UrlRedirect.ts
   *
   *
   * There is some tool we can use to detect `circular dependence`:
   * https://github.com/sproutsocial/analyze-es6-modules
   * https://github.com/aackerman/circular-dependency-plugin
   *
   * More reads:
   * https://railsware.com/blog/how-to-analyze-circular-dependencies-in-es6/
   * https://github.com/facebook/create-react-app/issues/2034
   * https://github.com/whmountains/create-react-app-circular-deps-poc
   */
  redirectToBackOfficeProduceLoginPage();

  return null;
};
