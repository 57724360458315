import { IApiRecipe, Uuid } from '../../typings/Interface';

export default function getRecipeFromRecipesListByProduct(
  productUuid: Uuid,
  recipes: IApiRecipe[] | null
): false | IApiRecipe {
  if (recipes === null) {
    return false;
  }
  const filteredRecipes = recipes.find(
    (item: IApiRecipe) => item.productUuid === productUuid
  );
  if (filteredRecipes === undefined) {
    return false;
  }
  return filteredRecipes;
}
