import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Button, Group, Modal, Portal } from '@kounta/chameleon';
import {
  DELETE_RECIPE_MODAL_MESSAGE,
  ERecipeFormButtonText
} from '../../../TextProvider';
import LoadingButton from '../../../components/loading/LoadingButton';
import { Uuid } from '../../../typings/Interface';
import { EModalTitle } from '../../../typings/Modal';

export const modalFooterButtons = (
  cancelAndCloseModal: (e?: SyntheticEvent) => void,
  mainAction: () => Promise<void>
): JSX.Element => {
  return (
    <Group full horizontal even>
      <LoadingButton
        fullWidth
        primary
        onClick={mainAction}
        buttonText={ERecipeFormButtonText.delete}
      />
      <Button fullWidth secondary onClick={cancelAndCloseModal}>
        Cancel
      </Button>
    </Group>
  );
};

export interface ICloseModalCallBack {
  (e?: SyntheticEvent): void
}

export interface IDeleteAsync {
  (toBeDeleteRecipeUuid: Uuid): (() => Promise<void>)
}

export const renderDeletePromptModal = (
  recipeUuid: Uuid,
  closeDeleteRecipeModal: ICloseModalCallBack,
  handleDeleteAsynchronously: IDeleteAsync,
) => {
  return (
    <Portal>
      <Modal
        title={EModalTitle.delete}
        content={DELETE_RECIPE_MODAL_MESSAGE}
        footer={modalFooterButtons(
          closeDeleteRecipeModal,
          handleDeleteAsynchronously(recipeUuid)
        )}
        actionClose={closeDeleteRecipeModal}
      />
    </Portal>
  );
};
