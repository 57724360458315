import React, { useEffect, useState } from "react";
import {
  Alignment,
  Divider,
  Group,
  NumberInput,
  Spacer
} from "@kounta/chameleon";
import {
  CostFieldDiv,
  CostLabel,
  CostList,
  INumberInputProps
} from "../../css/Styled";
import Big from 'big.js';
import { EAlignType, EChameleonFilterType } from '../../typings/Chameleon';
import {
  SHOW_PRECISION,
  ZERO_STRING_DISPLAY,
} from '../../utils/number-format/BigNumberHandler';
import { ProfitSection } from './ProfitSection';
import { SellPriceSection } from './SellPriceSection';
import {
  IApiProduct,
  IApiRecipe,
  IFormikRecipe,
  IRecipeCosts,
} from '../../typings/Interface';
import { updateGrossProfitAndSellPrice } from '../../utils/recipe-cost/UpdateGrossProfitAndSellPriceWithKeys';
import { calculateCosts } from '../../utils/recipe-cost/CalculateCosts';
import { COST_SECTION_LABELS, CURRENCY_SYMBOL } from '../../TextProvider';

interface IProps {
  readOnly: boolean;
  shouldBlurPrice: boolean;
  currentEditingRecipe: IFormikRecipe;
  products: IApiProduct[];
  recipes: IApiRecipe[];
  mainProduct: IApiProduct;
  shouldAddTopDivider: boolean;
}

export const TotalCost = React.memo((
  props: IProps,
  ): JSX.Element => {

    const initialRecipeCosts = calculateCosts(
      props.shouldBlurPrice,
      props.currentEditingRecipe,
      props.products,
      props.recipes,
      props.currentEditingRecipe.productUuid,
      props.mainProduct.unitPrice.toString(),
    );

    /**
     * It is certain when `setGrossProfitAndSellPrice()` being called,
     * the `isOriginalSellPrice` need to be set to `false`.
     * Hence `isOriginalSellPrice` and `costs` is managed in one state.
     */
    const [grossProfitAndSellPrice, setGrossProfitAndSellPrice] =
      useState<IRecipeCosts>({
          grossProfit: initialRecipeCosts.grossProfit,
          sellPrice: initialRecipeCosts.sellPrice,
          unitCost: initialRecipeCosts.unitCost,
          totalCost: initialRecipeCosts.totalCost,
        }
      );

    useEffect(() => {
        const costs = calculateCosts(
          props.shouldBlurPrice,
          props.currentEditingRecipe,
          props.products,
          props.recipes,
          props.currentEditingRecipe.productUuid,
          grossProfitAndSellPrice.sellPrice ||
          props.mainProduct.unitPrice.toString(),
        );

        // We only accept `grossProfit`, `unitCost` and `totalCost` updates
        // caused by the parent (eg: when expected Yield or Ingredients
        // changed). Sell price will not updated by the parent, but by this
        // component only (Except the initial load: eg: when loading an
        // existing Recipe)  Here we use previous State
        // `grossProfitAndSellPrice.sellPrice` to make sure the sellPrice is
        // not changed.
        setGrossProfitAndSellPrice({
          ...costs,
          sellPrice: grossProfitAndSellPrice.sellPrice,
        });
      },

      /**
       * We intentionally only listen to the props changes, we don't wanna
       * create an infinite loop by listen to the state change
       * (`grossProfitAndSellPrice`):
       */
      // eslint-disable-next-line
      [
        props.currentEditingRecipe,
        props.products,
        props.recipes,
      ]
    );

    return (
      <CostList>
        { props.shouldAddTopDivider && (
          <Divider />
        )}
        <Spacer spacing="large" />
        <Group>
          {
            costListGenerator(
              props.shouldBlurPrice,
              COST_SECTION_LABELS.unitCost,
              grossProfitAndSellPrice.unitCost,
            )
          }
          {
            costListGenerator(
              props.shouldBlurPrice,
              COST_SECTION_LABELS.totalCost,
              grossProfitAndSellPrice.totalCost,
            )
          }
          <ProfitSection
            unitCost={grossProfitAndSellPrice.unitCost}
            grossProfit={grossProfitAndSellPrice.grossProfit}
            setGrossProfitAndSellPrice={updateGrossProfitAndSellPrice(
              setGrossProfitAndSellPrice,
            )}
            shouldBlurPrice={props.shouldBlurPrice}
            readOnly={props.readOnly}
          />
          {grossProfitAndSellPrice.sellPrice !== undefined &&
          <SellPriceSection
            originalSellPrice={initialRecipeCosts.sellPrice as string}
            unitCost={grossProfitAndSellPrice.unitCost}
            sellPrice={grossProfitAndSellPrice.sellPrice}
            setGrossProfitAndSellPrice={updateGrossProfitAndSellPrice(
              setGrossProfitAndSellPrice,
            )}
            shouldBlurPrice={props.shouldBlurPrice}
            readOnly={props.readOnly}
          />
          }
        </Group>
        <Spacer spacing="large" />
        { props.shouldAddTopDivider && (
          <Divider />
        )}
      </CostList>
    )
  }
);

export const costListGenerator = (
  shouldBlurPrice: boolean,
  title: string,
  cost: Big | undefined,
): JSX.Element | null => {
  if (cost === undefined) {
    return null;
  }

  const inputProps: INumberInputProps = shouldBlurPrice ?
    { value: ZERO_STRING_DISPLAY, filter: EChameleonFilterType.blur } :
    { value: cost.toFixed(SHOW_PRECISION) };

  return (
    <Alignment horizontal={'right'} vertical={'center'}>
      <Group horizontal align={'right'}>
        <div>
          <CostLabel align={'right'}>{title}</CostLabel>
        </div>
        <div>
          <CostFieldDiv>
            <NumberInput
              prefix={CURRENCY_SYMBOL}
              align={EAlignType.right}
              readOnly={true}
              {...inputProps}
            />
          </CostFieldDiv>
        </div>
      </Group>
    </Alignment>
  );
};

