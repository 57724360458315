import * as React from 'react';
import { Group, Label, Paragraph } from "@kounta/chameleon";
import { ERecipeFormLabel } from '../../../TextProvider';
import { Field, FieldProps } from 'formik';
import { renderNumberInput } from '../../../components/number-input/RenderNumberInput';
import Uom from '../../../uom/Uom';
import { handleUomChange } from '../../../formik/SetFieldValueImperatively';
import {
  IApiProduct,
  IFormikRecipe,
  IUomDropDownOption,
} from '../../../typings/Interface';
import { UomDropDown } from '../../../components/uom/UomDropDown';
import { getProductByUuidFromList } from "../../../utils/product/GetProductByUuidFromList";
import { printQtyWithUom } from '../../../utils/batch/PrintQtyWithUom';

interface IProps {
  products: IApiProduct[];
  draftEntity: IFormikRecipe;
  hasEditRecipePermission: boolean;
}

export const ExpectedYield = React.memo(
  (
    { draftEntity, products, hasEditRecipePermission }: IProps
  ): JSX.Element => {

    return (
      // Cannot use `<React.Fragment>` to replace `<div>` here as it will break the UI.
      <div>
        <Label>{ERecipeFormLabel.expectedYield}</Label>
        {hasEditRecipePermission && (
          <Group horizontal spacing={'8px'}>
            <Field name={'expectedYield'}>
              {renderNumberInput(false)}
            </Field>
            <div className="measurement-unit-selector">
              <Field name={'uom'}>{renderUomDropdown}</Field>
            </div>
          </Group>
        )}
        {!hasEditRecipePermission && (
          <Group horizontal>
            <Paragraph>
              {printQtyWithUom(
                parseFloat(draftEntity.expectedYield),
                draftEntity.uom,
              )}
            </Paragraph>
          </Group>
        )}
      </div>
    );

    function renderUomDropdown({ field, form }: FieldProps): JSX.Element {
      const defaultValue: IUomDropDownOption = {
        label: new Uom(draftEntity.uom).displayFromUom,
        value: draftEntity.uom,
      };

      const onChange = handleUomChange(form, field);

      const dropDownProduct = getProductByUuidFromList(draftEntity.productUuid,
        products);

      if (!dropDownProduct) {
        throw Error(
          `cannot found product uuid: ${draftEntity.productUuid}`);
      }

      return (
        <UomDropDown
          defaultOption={defaultValue}
          changeFormHandler={onChange}
          product={dropDownProduct}
        />
      );
    }
  }
);


