import { EUom, IApiProduct } from "../../typings/Interface";
import { createProduct } from "../../graphql/ProductQL";
import { DEFAULT_MEASURE, IUpdateProductAttributes } from "./UpdateProductApiAdaptor";

export interface ICreateProductAttributes extends IUpdateProductAttributes{
  name: string;
}

export const createProductApiAdaptor = async (product: IApiProduct, productInfos: ICreateProductAttributes):
  Promise<IApiProduct> => {
  const measure = productInfos.uom === EUom.unit
    ? DEFAULT_MEASURE
    : parseFloat(productInfos.measure);
  
  const result = await createProduct(productInfos.uom, measure, productInfos.name);
  
  if (result.succeed) {
    return result.responseObj as IApiProduct;
  }
  
  throw Error(result.errorMsg);
};
