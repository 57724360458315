import { EBatchButtonText } from '../TextProvider';

export const MODAL_LIST = {
  planBatch: {
    title: EBatchButtonText.plan,
    text: `Please select a date you're planning to start
   production on this batch`,
  },
  cancelBatch: {
    title: EBatchButtonText.cancel,
    text: `Are you sure you want to cancel this batch? Once
  cancelled any production data linked to this batch 
  will be deleted. This cannot be undone.`,
  },
  batchActualYield: {
    title: 'Record actual yield',
    text: `Please record the actual yield for this batch to 
  complete production`,
  },
};

export enum EModalTitle {
  unSavedChanges = 'Unsaved changes',
  delete = 'Delete recipe',
}

export enum EModalButtonText {
  cancel = 'Cancel',
  yes = 'Yes',
}

export enum EModalActionResult {
  cancel = 1,
  close,
  yes,
}

export interface IModalActionHandler {
  (option: EModalActionResult): void;
}

export interface IModalGoBackHandler {
  (): void;
}
