import {
  IGraphQlPagingRequestFunction,
  IGraphQlRequestArgument,
  IPaginationEncodedFeedback,
} from '../../typings/Pagination';
import { IOnAddOrReplaceList } from '../../redux/typings/Interface';

export const restPagesHandler = async <T>(
  graphQlRequest: IGraphQlPagingRequestFunction<T, IGraphQlRequestArgument>,
  firstFetchPageInfo: IPaginationEncodedFeedback,
  reduxListReducerFunction: (list: T[]) => void,
  currentList: T[],
): Promise<boolean> => {
  let currentFetchPageInfo = firstFetchPageInfo;

  // Subsequent fetches, with `cursor` from previous response:
  while (currentFetchPageInfo.hasNextPage) {
    const nextResponse = await graphQlRequest(
      {
        base64EncodedCursor: currentFetchPageInfo.newCursor
      });
    if (!nextResponse.succeed) {
      return false;
    }
    currentList = [
      ...currentList,
      ...nextResponse.responseObj.edges as T[]
    ];

    // Refresh the iterator `currentFetchPageInfo`
    currentFetchPageInfo = nextResponse.responseObj.pageInfo;
  }

  // This function will call `reduxListReducerFunction()` at least once.
  reduxListReducerFunction(currentList);
  return true;
}


export const addRemainingPagesIntoRedux = async <T>(
  graphQlRequest: IGraphQlPagingRequestFunction<T, IGraphQlRequestArgument>,
  firstFetchPageInfo: IPaginationEncodedFeedback,
  onAddPartialRecord: IOnAddOrReplaceList<T>,
): Promise<boolean> => {
  if (!firstFetchPageInfo.hasNextPage) {
    // if there is no next page, early exit with `true`:
    return true;
  }

  // When adding remaining list, we keep the existing Redux list, only `onAddPartialRecord()`.
  // When calling `onAddPartialRecord()`, we don't need to provide `current redux list`,
  // hence `currentList` is `[]`.
  return restPagesHandler(
    graphQlRequest,
    firstFetchPageInfo,
    onAddPartialRecord,
    []
  );
}
