import * as React from 'react';
import { TextArea } from '@kounta/chameleon';
import { Field, FieldProps } from 'formik';
import { getFormikFieldError } from '../../formik/FormikField';
import { setFieldValueImperatively } from '../../formik/SetFieldValueImperatively';
import { replaceBrToLineBreak } from '../../graphql/QueryStringPurify';
import { MAX_NUMBER_OF_LETTERS_FOR_NOTE } from '../../validation/Validator';

const DefaultResize = 'none';

export const NOTE_TEXT = {
  batch: {
    label: 'Notes',
    placeHolder: 'Enter any other notes here',
  },
  recipe: {
    label: 'Instructions',
    placeHolder: 'Enter the instructions',
  },
};

interface IProps {
  label: string;
  fieldName: string;
  placeHolder: string;
  readOnly: boolean;
}

export const NotesInput = React.memo((props: IProps) => {
  return (
    <Field name={props.fieldName}>
      {renderTextArea(props.placeHolder, props.label, props.readOnly)}
    </Field>
  );
});

export const renderTextArea = (
  placeHolder: string,
  label: string,
  readOnly: boolean,
) => (
  {
    field,
    form,
  }: FieldProps
): JSX.Element => {
  const errorObj = getFormikFieldError(form, field);
  return (
    <TextArea
      label={label}
      maxLength={MAX_NUMBER_OF_LETTERS_FOR_NOTE}
      resize={DefaultResize}
      placeholder={placeHolder}
      value={replaceBrToLineBreak(field.value || '')}
      onChange={setFieldValueImperatively(form, field)}
      error={errorObj.errorYn}
      readOnly={readOnly}
    />
  );
};
