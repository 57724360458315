import React from "react";
import { Alignment, Group, NumberInput } from "@kounta/chameleon";
import { CostFieldDiv, CostLabel } from "../../css/Styled";
import { calculateSellPriceByProfit } from "../../utils/recipe-cost/CalculateSellPriceAndGrossProfit";
import Big from 'big.js';
import { COST_SECTION_LABELS } from '../../TextProvider';
import { getNumberInputPropsForReadonlyAndBlur } from './GetNumberInputPropsForReadonlyAndBlur';

// Used for Heap tracking: https://kounta.atlassian.net/browse/PRO-490
const GROSS_PROFIT_DOM_ID = 'gross_profit_input';

export interface IProfitSectionProps {
  grossProfit?: string;
  unitCost: Big;
  setGrossProfitAndSellPrice: (
    sellPrice: string,
    grossProfit: string,
  ) => void;
  shouldBlurPrice: boolean;
  readOnly: boolean;
}

export const ProfitSection = React.memo((
  props: IProfitSectionProps
): JSX.Element | null => {

  /**
   * When user delete the whole input, the value will be empty string ``,
   * it will not be `undefined` or `null`.
   */
  const onGrossProfitChange = (
    grossProfitInString: string,
    isValidate: boolean,
  ): void => {
    if (!isValidate) {
      return;
    }
    const sellPrice = calculateSellPriceByProfit(
      grossProfitInString,
      props.unitCost,
    );
    props.setGrossProfitAndSellPrice(
      sellPrice,
      grossProfitInString,
    );
  };

  if (props.grossProfit === undefined) {
    return null;
  }

  return (
    <Alignment horizontal={'right'} vertical={'center'}>
      <Group horizontal align={'right'}>
        <div>
          <CostLabel align={'right'}>
            {COST_SECTION_LABELS.profit}
          </CostLabel>
        </div>
        <CostFieldDiv>
          <NumberInput
            id={GROSS_PROFIT_DOM_ID}
            suffix={'%'}
            value={props.grossProfit}
            align={'right'}
            maxFractionLength={2}
            minFractionLength={0}
            onChange={onGrossProfitChange}
            {...getNumberInputPropsForReadonlyAndBlur(
              props.readOnly,
              props.shouldBlurPrice,
            )}
          />
        </CostFieldDiv>
      </Group>
    </Alignment>
  );
});
