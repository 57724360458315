import { useEffect, useRef } from 'react';

// https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
export const usePrevious =  <T>(propOrState: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = propOrState;
  });
  return ref.current;
};
