import { doesBatchHaveUnsavedChanges } from '../../validation/DoesBatchHaveUnsavedChanges';
import {
  AppHeader,
  Button,
  Group,
  ActionListHeaderActionType,
  IconDelete,
} from '@kounta/chameleon';
import * as React from 'react';
import {
  EBatchFormButtonText,
  EBatchFormHeaderTitle,
  EBatchStatus,
  EModalType,
  ESnackBarType,
  IApiBatch,
  IFormikBatch,
  ILayoutUiControlFuncs,
} from '../../typings/Interface';
import { formValidationFunction } from '../../validation/FormValidationFunction';
import { updateBatchAction } from './UpdateBatchAction';
import { createBatchAction } from './CreateBatchAction';
import { startBatchAction } from './StartBatchAction';
import {
  longDateFormat,
  EBatchButtonText,
  ELoadingTitle,
  ESnackBarMessage,
} from '../../TextProvider';
import { initialDraftFormikBatch } from '../../formik/batch/InitialDraftBatch';
import { createBatch, deleteBatch } from '../../graphql/BatchQL';
import moment from 'moment';
import { goBackToListView } from '../../pages/incomplete-batches/single-view/GoBackToListView';
import { IBatchFormikProps } from '../../typings/Formik';
import { BatchDataPickerModal } from '../../pages/incomplete-batches/single-view/BatchDatePickerModal';
import { loadingHandler } from '../../utils/loader/LoadingHandler';
import { MODAL_LIST } from '../../typings/Modal';

interface IProps {
  existingBatch: IFormikBatch | false;

  updateExistingBatch: (existingBatch: IApiBatch) => void;
  handleBatchStatusChange: (productionType: { id: EBatchStatus }) => void;
  // UI controls for SnackBar:
  handleModal: ILayoutUiControlFuncs['handleModal'];

  getToggleActualYieldModalFunction: (
    shouldShowActualYieldModal: boolean
  ) => () => void;

  formValues: IFormikBatch;
  validateForm: IBatchFormikProps['validateForm'];
  submitForm: IBatchFormikProps['submitForm'];

  renderModal: (
    overlay: boolean,
    title: string,
    content: JSX.Element | string | null,
    footer: JSX.Element | null
  ) => void;

  // This is not a safe solution. Because user can enter the URL, then when
  // `goBack` it will go back to what user typed in before.
  // Ideally, it should use `routerComponentProps.push('/batches')`.
  // However the proper way to do that is still under considering.
  goBack: () => void;
  goBackButtonText?: string;

  hideModal: () => void;

  setLoading: ILayoutUiControlFuncs['setLoading'];
  isBatchAllowanceExhausted: boolean;
}

const sharedButtonProps = {
  primary: true,
  showAsAction: true,
  visible: true,
  enabled: true,
  destructive: false,
};

export default class BatchAppHeaderBar extends React.Component<IProps, any> {
  render = () => (
    <AppHeader
      title={this.getTitle()}
      leftContent={goBackToListView(
        this.getTitle(),
        this.props.goBack,
        this.props.renderModal,
        this.props.hideModal,
        doesBatchHaveUnsavedChanges(
          this.props.existingBatch,
          this.props.formValues
        ),
        this.props.formValues.status,
        this.props.handleBatchStatusChange,
        this.props.goBackButtonText,
      )}
      actions={this.appHeaderButtonMenu()}
    />
  );

  getTitle = (): string => {
    return this.props.formValues.uuid === initialDraftFormikBatch.uuid
      ? EBatchFormHeaderTitle.create
      : EBatchFormHeaderTitle.view;
  };

  private setLoadingTitleWithLoadingHandler = (
    title: ELoadingTitle,
    loadingAction: () => Promise<void>
  ): (() => Promise<void>) =>
    loadingHandler(loadingAction, this.props.setLoading, title);

  private getSaveButton = (): ActionListHeaderActionType => ({
    name: EBatchFormButtonText.save,
    onClick: formValidationFunction(
      this.props.validateForm,
      this.props.submitForm,
      this.setLoadingTitleWithLoadingHandler(
        ELoadingTitle.saveBatch,
        updateBatchAction(this.props.formValues, this.props.updateExistingBatch)
      )
    ),
    ...sharedButtonProps,
  });


  // `start batch` button on the right top corner is controlled by the below function.
  // In technical sense, it is "creating a batch", however UI use `start batch`.
  private getCreateButton = (): ActionListHeaderActionType => ({
    name: EBatchFormButtonText.start,
    onClick: formValidationFunction(
      this.props.validateForm,
      this.props.submitForm,
      this.setLoadingTitleWithLoadingHandler(
        ELoadingTitle.startBatch,
        createBatchAction(
          this.props.formValues,
          this.props.handleModal,
          this.props.updateExistingBatch
        )
      )
    ),
    ...sharedButtonProps,
    enabled: !this.props.isBatchAllowanceExhausted,
  });

  private getStartButton = (): ActionListHeaderActionType => ({
    name: EBatchFormButtonText.start,
    onClick: formValidationFunction(
      this.props.validateForm,
      this.props.submitForm,
      this.setLoadingTitleWithLoadingHandler(
        ELoadingTitle.startBatch,
        startBatchAction(
          this.props.existingBatch,
          this.props.handleModal,
          this.props.updateExistingBatch,
          this.props.handleBatchStatusChange
        )
      )
    ),
    ...sharedButtonProps,
  });

  private getFinishButton = (): ActionListHeaderActionType => ({
    name: EBatchFormButtonText.finish,
    onClick: formValidationFunction(
      this.props.validateForm,
      this.props.submitForm,
      this.props.getToggleActualYieldModalFunction(true)
    ),
    ...sharedButtonProps,
  });

  // --------- Start of Date Picker ---------
  private activateDatePickerModal = () => {
    this.props.renderModal(
      true,
      MODAL_LIST.planBatch.title,
      MODAL_LIST.planBatch.text,
      <BatchDataPickerModal
        updatePlanDateAndReturnToListView={
          this.updatePlanDateAndReturnToListView
        }
      />
    );
  };

  updatePlanDateAndReturnToListView = async () => {
    const formValues = this.props.formValues;

    // According to Milos, we are not allowing user `update` the plannedtime.
    // that will be next sprint.
    // So only `CreateBatch` will have `calendar` popup for now.

    // This Calendar model only appear if the <AppHeader> has the calendar
    // button
    if (formValues.status !== EBatchStatus.planned) {
      throw new Error('This batch is not in planned status.');
    }
    const planResult = await createBatch(
      formValues.recipeUuid,
      formValues.productName,
      formValues.note,
      formValues.status,
      formValues.batchIngredients,
      formValues.uom,
      formValues.isCustomYield,
      formValues.expectedYield,
      formValues.plannedTime
    );

    if (planResult.succeed) {
      this.props.hideModal();
      // Pre-set the batch tab:
      this.props.handleBatchStatusChange({ id: EBatchStatus.planned });

      this.props.goBack();
      this.props.handleModal({
        snackBarMessage: `${
          ESnackBarMessage.PLAN_BATCH_SNACKBAR_MSG_PREFIX
        } ${moment((planResult.responseObj.plannedTime) as string).format(longDateFormat)}`,
        snackBarType: ESnackBarType.success,
        modalType: EModalType.SNACK_BAR,
      });
    }
  };

  private getCalendarButton = (): ActionListHeaderActionType => ({
    name: EBatchButtonText.plan,
    onClick: formValidationFunction(
      this.props.validateForm,
      this.props.submitForm,
      this.activateDatePickerModal
    ),
    ...sharedButtonProps,
    primary: false,
    enabled: !this.props.isBatchAllowanceExhausted,
  });
  // -------- End of Date Picker -----------

  // Using single Modal to show Plan Batch / CancelBatch / and Unsaved changes.
  activateCancelBatchModal = () => {
    this.props.renderModal(
      true,
      MODAL_LIST.cancelBatch.title,
      this.cancelBatchModalContent,
      this.cancelBatchModalFooter
    );
  };

  private getDeleteButton = (): ActionListHeaderActionType => ({
    name: <IconDelete />,
    onClick: this.activateCancelBatchModal,
    ...sharedButtonProps,
    primary: false,
    destructive: true,
  });

  appHeaderButtonMenu = (): ActionListHeaderActionType[] => {
    const formValues = this.props.formValues;
    const hasUnsavedChanges = doesBatchHaveUnsavedChanges(
      this.props.existingBatch,
      formValues
    );

    // 1. For creating a batch
    if (formValues.uuid === initialDraftFormikBatch.uuid) {
      return [this.getCalendarButton(), this.getCreateButton()];
    }

    // 2. For Planned Batch
    if (formValues.status === EBatchStatus.planned) {
      // According to Milos, we are not allowing user `update` the plannedtime
      // that will be next sprint.
      // So only `CreateBatch` will have `calendar` popup for now.
      let primaryButton: ActionListHeaderActionType = this.getSaveButton();
      if (!hasUnsavedChanges) {
        primaryButton = this.getStartButton();
      }

      return [primaryButton, this.getDeleteButton()];
    }

    // 3. For InProgress Batch
    if (formValues.status === EBatchStatus.inProgress) {
      let primaryButton: ActionListHeaderActionType = this.getSaveButton();
      if (!hasUnsavedChanges) {
        primaryButton = this.getFinishButton();
      }

      return [primaryButton, this.getDeleteButton()];
    }

    throw new Error('Whoops, something is not right');
  };

  // -------- Start of Cancel Batch ----------
  private handleCancelBatchActionButton = async () => {
    if (this.props.formValues.uuid === initialDraftFormikBatch.uuid) {
      this.props.handleBatchStatusChange({ id: EBatchStatus.inProgress });
      this.props.goBack();
    }
    this.props.hideModal();
    this.props.setLoading(true);
    const deleteResult = await deleteBatch(this.props.formValues.uuid);
    this.props.setLoading(false);
    if (deleteResult.succeed) {
      this.props.goBack();
      this.props.handleModal({
        snackBarMessage: ESnackBarMessage.CANCEL_BATCH_SUCCEED,
        modalType: EModalType.SNACK_BAR,
      });
    }
    /**
      else {
        // TODO: show error flash message
      }
    */
  };

  // Chameleon error, if using <Paragraph> instead of <React.Fragment>, below
  // issue occurs:
  // https://stackoverflow.com/questions/52272462/warning-validatedomnesting-div-cannot-appear-as-a-descendant-of-p
  private cancelBatchModalContent = (
    <React.Fragment>{MODAL_LIST.cancelBatch.text}</React.Fragment>
  );

  private cancelBatchModalFooter = (
    <Group horizontal={true} full={true} even={true}>
      <Button
        fullWidth
        variant={'destructive'}
        onClick={this.handleCancelBatchActionButton}
      >
        {EBatchFormButtonText.cancel}
      </Button>
    </Group>
  );
  // -------- End of Cancel Batch ----------
}
