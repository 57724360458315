import {
  EBatchIngredientAdditionalFlag,
  EBatchStatus,
  EUom,
  IBatchIngredientsArgs,
  IFormikBatch,
  IFormikBatchIngredient,
  IReduxDraftBatch,
} from '../../typings/Interface';

import { emptyRecipeIngredient } from '../recipe/InitialDraftRecipe';

export const emptyBatchIngredient: IBatchIngredientsArgs = {
  ...emptyRecipeIngredient,
  productName: '',
  additionalIngredientFlag: EBatchIngredientAdditionalFlag.origin,
};

export const formikEmptyBatchIngredient: IFormikBatchIngredient = {
  ...emptyBatchIngredient,
  componentQty: `${emptyBatchIngredient.componentQty}`,
};

export const initialDraftBatch: IReduxDraftBatch = {
  // below two properties are provided by action argument for:
  // DRAFT_BATCH_LOAD_EXISTING_OR_DEFAULT
  recipeUuid: '',
  productName: '',
  // Others:
  uuid: '',
  actualYield: 0,

  // The validation will fail, it expected expectedYield > 0, from:
  // frontend/produce-webpage/src/validation/BatchSchema.ts
  expectedYield: 0,
  status: EBatchStatus.planned,
  plannedTime: null,
  note: '',
  batchIngredients: [emptyBatchIngredient],

  uom: EUom.unit,
  isCustomYield: false,
};

export const initialDraftFormikBatch: IFormikBatch = {
  ...initialDraftBatch,
  actualYield: null,
  batchIngredients: [formikEmptyBatchIngredient],
};
