import * as React from 'react';
import {
  Alignment,
  Container,
  Group,
  Spacer,
  SelectOptionType,
  AdvancedSelectOptionType,
} from '@kounta/chameleon';
import {
  IApiProduct,
  IApiRecipe,
  ILayoutUiControlFuncs,
  IProduceLeftNavControl,
} from '../../typings/Interface';
import {
  EPageHeader,
  ERecipeFilterDropdownIds,
  ERecipesListSearchEntity,
  ERouterUrlPath,
  RECIPE_FILTER_OPTIONS,
  RECIPES_LIST_SEARCH_DROPDOWN,
} from '../../TextProvider';
import { RecipesListEmptyState } from './components/RecipesListEmptyState';
import { Filter } from './components/Filter';
import { RecipesTable } from './components/RecipesTable';
import {
  generateRecipesQueryString,
  goToCreateRecipePageWithQuery,
} from '../../utils/recipe/RecipesQueryParam';
import { IRouteHistoryPushFunction } from '../../typings/ReactRouter';
import { FilterText } from './Style';
import { CreateRecipeButton } from './components/CreateRecipeButton';
import { SLIM_PRIMARY_BUTTON } from '../../typings/Chameleon';
import { ProduceTitleHeader } from "../../components/produce-nav/ProduceTitleHeader";
import DropdownSearch from '@kounta/chameleon/dist/components/DropdownSearch';
import { SearchRecipesHelpText } from './components/SearchRecipesHelpText';
import { findOptionByValue } from '../../utils/chameleon/FindOptionByValue';

interface IState {
  productNameSearchText: string;
  shouldPopupProductsList: boolean;
  selectedSearchDropdown: ERecipesListSearchEntity;
}

interface IProps extends IProduceLeftNavControl {
  currentFilterCriteria: ERecipeFilterDropdownIds;
  currentSearchEntity: ERecipesListSearchEntity;
  products: IApiProduct[];
  recipes: IApiRecipe[];
  routeHistoryPush: IRouteHistoryPushFunction;
  setLoading: ILayoutUiControlFuncs['setLoading'];
  handleModal: ILayoutUiControlFuncs['handleModal'];
  searchTextFromUrl: string | null;
  isProductsFullyLoaded: boolean;
  hasEditRecipePermission: boolean;
}

export const handleFilterSelect = (
  productNameSearchText: string,
  currentSearchEntity: ERecipesListSearchEntity,
  routeHistoryPush: IRouteHistoryPushFunction,
) => {
  return (selectedFilterCriteria: SelectOptionType) => {
    if (Object.values(ERecipeFilterDropdownIds)
      .includes(selectedFilterCriteria.id as ERecipeFilterDropdownIds)) {
      // Building a query string, prepare to use that for redirection.
      // https://github.com/ReactTraining/react-router/issues/1100#issuecomment-336619058
      const queryString = generateRecipesQueryString(
        selectedFilterCriteria.id as ERecipeFilterDropdownIds,
        productNameSearchText,
        currentSearchEntity,
      );

      // This only triggered when the Filter dropdown selection changes, so
      // don't worry about it causing infinite re-rendering loop.  Detail on
      // `history.push()` https://github.com/ReactTraining/history#navigation
      routeHistoryPush({
        pathname: ERouterUrlPath.recipes,
        search: queryString,
      });
    } else {
      throw new Error(
        `Expected selectedFilterCriteria.id to be typeof string, got ${typeof selectedFilterCriteria.id}`);
    }
  };
};

/**
 * TL;DR: Until we enable GraphQl subscription on BackOffice `products`
 *
 * This component has to use traditional React.Component, NOT memo.
 * Because react.memo is using `shallow-compare`.
 *
 * Redux is passing down `products`.
 * When other component calls `onLoadingProducts()`, `products` will change.
 * If using memo, because `products` reference (pointer) changed, it will cause
 * another re-rendering.
 *
 * However if using traditional React.Component, it `deeply compare` new
 * `products` with the old `products`, only re-render if they are `deeply
 * different`. https://reactjs.org/docs/shallow-compare.html
 */
export default class RecipesList extends React.Component<IProps, IState> {
  readonly state: IState = {
    productNameSearchText: this.props.searchTextFromUrl === null
      ? ''
      : this.props.searchTextFromUrl,
    shouldPopupProductsList: false,
    selectedSearchDropdown: this.props.currentSearchEntity,
  };

  // Now we only consider if the recipes are empty. We don't cross-check with
  // products to see if the recipe product is deleted or not.
  // https://kounta.atlassian.net/browse/PRO-465
  //
  // When we are at the `Create Recipe` search list page, we show ALL products
  // without recipes, no matter if it is `madeHere` or `purchase`, because we
  // want to be able to switch a `purchase` product to `madeHere`.
  //
  // PS: the `products` passing to `<RecipesListEmptyState />` will still go
  // through `lazy loading`.
  // So at the first, it is actually `madeHere` products, later on it will be
  // `all products`. We don't need to worry about partial products been shown
  // in the search list, because the `loading button` in
  // `<RecipesListEmptyState>` will appear if `isProductsFullyLoaded` is
  // false.
  public render() {
    return (
      <React.Fragment>
        <ProduceTitleHeader
          title={EPageHeader.recipes}
          showNavYn={this.props.showNav}
          content={
            this.props.hasEditRecipePermission ?
              (
                <CreateRecipeButton
                  hideYn={(this.props.recipes.length === 0)}
                  buttonStyle={SLIM_PRIMARY_BUTTON}
                  isProductsFullyLoaded={this.props.isProductsFullyLoaded}
                  onButtonClick={this.shouldGoToCreateRecipe}
                />
              ) : undefined
          }
        />
        <Container>
          {this.props.recipes.length === 0 && (
            <RecipesListEmptyState
              isProductsFullyLoaded={this.props.isProductsFullyLoaded}
              setShouldPopupProductsList={this.shouldGoToCreateRecipe}
            />
          )
          }
          {this.props.recipes.length !== 0 && (
            <React.Fragment>
              <Group horizontal full>
                <Alignment vertical={'top'} horizontal={'left'}>
                  <DropdownSearch
                    value={this.state.productNameSearchText}
                    placeholderPrefix="Search Produce"
                    onChange={this.handleSearch}
                    onClear={this.handleClearSearch}
                    defaultDropDownOption={
                      findOptionByValue(
                        this.state.selectedSearchDropdown,
                        RECIPES_LIST_SEARCH_DROPDOWN
                      ) as AdvancedSelectOptionType
                    }
                    dropDownOptions={RECIPES_LIST_SEARCH_DROPDOWN}
                    onDropDownChange={this.handleDropDownSelect}
                  />
                </Alignment>
                <Alignment vertical={'top'} horizontal={'right'}>
                  <FilterText>Filter by</FilterText>
                  <Filter
                    filterOptions={RECIPE_FILTER_OPTIONS}
                    currentFilterCriteria={this.props.currentFilterCriteria}
                    handleFilterSelect={handleFilterSelect(
                      this.state.productNameSearchText,
                      this.state.selectedSearchDropdown,
                      this.props.routeHistoryPush,
                    )}
                  />
                </Alignment>
              </Group>
              <SearchRecipesHelpText
                dropDown={this.state.selectedSearchDropdown}
                searchText={this.state.productNameSearchText}
              />
              <Spacer/>
              <RecipesTable
                recipes={this.props.recipes}
                products={this.props.products}
                routeHistoryPush={this.props.routeHistoryPush}

                productNameSearchText={this.state.productNameSearchText}
                currentFilterCriteria={this.props.currentFilterCriteria}
                selectedSearchDropdown={this.state.selectedSearchDropdown}
              />
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    );
  }

  private shouldGoToCreateRecipe = () => {
    if (this.props.isProductsFullyLoaded) {
      goToCreateRecipePageWithQuery(
        this.props.routeHistoryPush,
        this.props.currentFilterCriteria,
        this.state.productNameSearchText,
        this.state.selectedSearchDropdown,
      )()
    }
  };

  private handleClearSearch = () => {
    this.setState({ productNameSearchText: '' });
  };

  private handleSearch = (productNameSearchText: string) => {
    this.setState({ productNameSearchText });
  };

  private handleDropDownSelect = (selectedOption: AdvancedSelectOptionType) => {
    this.setState(
      { selectedSearchDropdown: selectedOption.value as ERecipesListSearchEntity }
    );
  };
}
