import {
  EBatchStatus,
  EModalType,
  ESnackBarType,
  IApiBatch,
  IFormikBatch,
  ILayoutUiControlFuncs,
} from '../../typings/Interface';
import { createBatch } from '../../graphql/BatchQL';
import { ESnackBarMessage } from '../../TextProvider';

export const createBatchAction = (
  formValues: IFormikBatch,
  handleModal: ILayoutUiControlFuncs['handleModal'],
  updateExistingBatch: (existingBatch: IApiBatch) => void
) => {
  return async (): Promise<void> => {
    // We make sure the <AppHeader> only show this `start button` if batch is
    // new.
    // `Start batch` will immediately put batch to `in progress`, skipped the
    // `planned` stage.
    const createResult = await createBatch(
      formValues.recipeUuid,
      formValues.productName,
      formValues.note,

      // put new batch to `in progress`, skipped the `planned` stage.
      EBatchStatus.inProgress,

      formValues.batchIngredients,
      formValues.uom,
      formValues.isCustomYield,
      formValues.expectedYield
    );
    if (createResult.succeed) {
      handleModal({
        snackBarMessage: ESnackBarMessage.START_BATCH_SUCCEED,
        snackBarType: ESnackBarType.success,
        modalType: EModalType.SNACK_BAR,
      });
      updateExistingBatch(createResult.responseObj);
    }
    // else {
    //   // Todo: show your error flash message
    //   // console.log('Create Batch failed');
    // }
  };
};
