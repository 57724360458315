import { RouteComponentProps } from 'react-router';
import {
  ERecipeFilterDropdownIds,
  ERecipesListSearchEntity,
  ERouterQueryParamKey,
  ERouterUrlPath,
} from '../../TextProvider';
import {Uuid} from '../../typings/Interface';
import { IRouteHistoryPushFunction } from '../../typings/ReactRouter';

export const getRecipesQueryParamFromUrl = (
  routeComponentProps: RouteComponentProps,
  invalidQueryRedirectTo: string,
) => {
  const searchParams = new URLSearchParams(routeComponentProps.location.search);
  let searchTextFromUrl = searchParams.get(ERouterQueryParamKey.recipesSearchText);
  let filterCriteria = searchParams.get(ERouterQueryParamKey.recipesFilterCriteria);
  let entity = searchParams.get(ERouterQueryParamKey.recipesSearchEntity);

  if (filterCriteria === null) {
    filterCriteria = ERecipeFilterDropdownIds.All;
  }
  if (entity === null) {
    // Default search entity is `recipes`
    entity = ERecipesListSearchEntity.recipes;
  }

  let isInvalidQuery = false;
  if (
    !Object.values(ERecipeFilterDropdownIds).includes(filterCriteria as ERecipeFilterDropdownIds) ||
    !Object.values(ERecipesListSearchEntity).includes(entity as ERecipesListSearchEntity)
  ) {
    isInvalidQuery = true;
    // If URL has invalid `filterOption`, just force redirect to
    // `invalidQueryRedirectTo` eg: `/recipes?filterCriteria=invalid` will
    // redirect to `/recipes`
    routeComponentProps.history.replace(invalidQueryRedirectTo);

  }
  // After this `if`, we can safely assume `filterCriteria as ERecipeFilterDropdownIds`
  return { searchTextFromUrl, filterCriteria, isInvalidQuery, entity };
};

export const generateRecipesQueryString = (
  currentFilterCriteria: ERecipeFilterDropdownIds | null,
  searchText: string | null,
  currentSearchEntity: ERecipesListSearchEntity,
): string => {
  const query = new URLSearchParams();
  if (currentFilterCriteria !== null) {
    query.set(ERouterQueryParamKey.recipesFilterCriteria,
      currentFilterCriteria);
  }
  if (searchText !== null && searchText !== '') {
    query.set(ERouterQueryParamKey.recipesSearchText, searchText);
  }
  query.set(ERouterQueryParamKey.recipesSearchEntity, currentSearchEntity);

  // Note that toString does not add the ? at the beginning, so make sure you
  // remember to add that if you need it.
  // https://javascriptplayground.com/url-search-params/
  return `?${query.toString()}`;
};

export const goToRecipesListPageWithQuery = (
  routeHistoryPush: (path: { search: string; pathname: ERouterUrlPath }) => void,
  currentFilterCriteria: ERecipeFilterDropdownIds,
  searchTextFromUrl: string | null,
  currentSearchEntity: ERecipesListSearchEntity,
) => {
  const queryString = generateRecipesQueryString(
    currentFilterCriteria,
    searchTextFromUrl,
    currentSearchEntity,
  );

  return () => routeHistoryPush({
    pathname: ERouterUrlPath.recipes,
    search: queryString,
  });
};

export const goToSingleRecipePageWithQuery = (
  routeHistoryPush: IRouteHistoryPushFunction,
  currentFilterCriteria: ERecipeFilterDropdownIds | null,
  searchTextFromUrl: string | null,
  currentSearchEntity: ERecipesListSearchEntity,
) => {
  const queryString = generateRecipesQueryString(
    currentFilterCriteria,
    searchTextFromUrl,
    currentSearchEntity,
  );

  /**
   * Utilise the `search` query parameters to save the state of the
   * `recipesFilterCriteria` and `recipesSearchText`, and transfer them into
   * the single Recipe Form page.
   */
  return (productUuid: Uuid) => routeHistoryPush({
    pathname: `${ERouterUrlPath.singleRecipe}${productUuid}`,
    search: queryString,
  });
};

export const goToCreateRecipePageWithQuery = (
  routeHistoryPush: (path: { search: string; pathname: ERouterUrlPath }) => void,
  currentFilterCriteria: ERecipeFilterDropdownIds,
  searchTextFromUrl: string | null,
  currentSearchEntity: ERecipesListSearchEntity,
) => {
  const queryString = generateRecipesQueryString(
    currentFilterCriteria,
    searchTextFromUrl,
    currentSearchEntity,
  )

  return () => routeHistoryPush({
    pathname: ERouterUrlPath.createRecipe,
    search: queryString,
  })
}