import {
  SelectOptionType,
  TableSortDirectionType,
} from "@kounta/chameleon";

export enum ETableSortingDirection {
  ascending = 'ascending',
  descending = 'descending',
  default = 'default'
}

export interface ISelectUpdateOptionCallback {
  (selectedOption: SelectOptionType): void;
}

export interface ITableSortingCallback {
  (direction: TableSortDirectionType): void;
}

export interface ITableRowClickCallback {
  (rowId: string): void;
}

export interface IAutoSuggestOptions {
  value?: string;
}

// ------ NumberPad ---------
export interface IButtonProps {
  fullWidth?: boolean;
  primary?: boolean;
  slim?: boolean;
  secondary?: boolean;
  buttonText?: string;
  disabled?: boolean;
  onClick: () => Promise<void>;
}

export enum EIllustrationName {
  foodScale = 'food-scale',
  noMatches = 'no-matches',
  reminders = 'reminders',
}

export enum EButtonType {
  submit = 'submit',
  reset = 'reset',
  button = 'button',
}

export interface IChameleonTooltipStep {
  target: string;
  title: string;
  content: string;
  locale?: object;
  disableBeacon: boolean;
  showSkipButton: boolean;
  styles?: object;
}

export interface IChameleonTooltipCallbackDataType {
  action: string;
  controlled: boolean;
  index: number;
  lifecycle: string;
  size: number;
  status: string;
  step: object;
  type: string;
}

export enum EToolTipStatusName {
  finished = 'finished',
}

export interface IChameleonKountaAppSetting {
  name: string;
  hasSubscription: boolean;
  productUrl: string;
  tryUrl: string;
  selected?: boolean;
}

export interface IChameleonAppSwitcherConfiguration {
  kounta: IChameleonKountaAppSetting;
  pos: IChameleonKountaAppSetting;
  insights: IChameleonKountaAppSetting;
  purchase: IChameleonKountaAppSetting;
  produce: IChameleonKountaAppSetting;
}

// @kounta/chameleon/src/components/LeftNav/LeftNav.js
type ContextMenuItemPropsType = {
  id?: string;
  label: string;
  description: string;
  shortLabel: string;
  imageUrl?: string;
  isPerson: boolean;
  onClick: () => void;
};

export type ContextMenuItemType = ContextMenuItemPropsType & {
  value?: string;
};

export interface IMenuItem {
  id?: string;
  label: string;
  value?: string;
  selectedItem?: string;
  useRouter?: boolean;
  onClick: () => void;
  children?: IMenuItem[];
}

export interface ISecondaryMenu {
  contexts: ContextMenuItemType[];
  items: IMenuItem[];
}

export enum EChameleonFilterType {
  blur = 'blur',
}

export enum EAlignType {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface IButtonStyle {
  slim: boolean;
  primary: boolean;
  secondary: boolean;
  destructive: boolean;
}

export const SLIM_PRIMARY_BUTTON: IButtonStyle = {
  slim: true,
  primary: true,
  secondary: false,
  destructive: false,
};

export const PRIMARY_BUTTON: IButtonStyle = {
  slim: false,
  primary: true,
  secondary: false,
  destructive: false,
};
export const SECONDARY_BUTTON: IButtonStyle = {
  slim: false,
  primary: false,
  secondary: true,
  destructive: false,
};
