import { EQueryNames, IApiHandlerResult } from '../typings/Interface';
import { capitalizeFirstLetter } from './QueryStringPurify';
import { APPSWICHERCONFIGURATION_FIELDS } from './CommonFields';
import { graphQlApiHandler } from './Handler';
import {
  IChameleonAppSwitcherConfiguration,
} from '../typings/Chameleon';

export const getAppSwitcherConfigurationQuery = (): string => {
  return `query ${capitalizeFirstLetter(
    EQueryNames.getAppSwitcherConfiguration
  )} {
     ${EQueryNames.getAppSwitcherConfiguration} {
       ${APPSWICHERCONFIGURATION_FIELDS}
     } 
  }`;
};

export async function getAppSwitcherConfiguration(): Promise<IApiHandlerResult<IChameleonAppSwitcherConfiguration>> {
  const getAppSwitcherConfigurationQl = getAppSwitcherConfigurationQuery();
  return graphQlApiHandler<IChameleonAppSwitcherConfiguration>(
    getAppSwitcherConfigurationQl,
    EQueryNames.getAppSwitcherConfiguration
  );
}
