import React from 'react';
import {
  EModalType,
  IHandleModalParams,
  ILastCompletedBatchInfo,
} from '../../typings/Interface';
import { SnackBar } from '@kounta/chameleon';
import CompletedBatchSheet from './CompletedBatchSheet';

const SNACK_BAR_DURATION_IN_MILLISECOND = 5000;

export interface IModalBox extends IHandleModalParams {
  hideModal: () => void;
}

const ModalBox = React.memo((props: IModalBox) => {
  if (props.shouldShowModalYn) {
    if (props.modalType === EModalType.SHEET) {
      return generateSheet(props);
    }
    return generateSnackBar(props);
  }
  return null;
});

const generateSnackBar = (snackBar: IModalBox) => {
  return (
    <SnackBar
      autoHideDuration={SNACK_BAR_DURATION_IN_MILLISECOND}
      message={snackBar.snackBarMessage}
      type={snackBar.snackBarType}
      onClose={snackBar.hideModal}
    />
  );
};

const generateSheet = (props: IModalBox) => {
  const lastBatchInfo = props.completedBatchInfo as ILastCompletedBatchInfo;
  return (
    <CompletedBatchSheet
      batchUuid={lastBatchInfo.batchUuid}
      productName={lastBatchInfo.productName}
      actualYield={lastBatchInfo.actualYield}
      uom={lastBatchInfo.uom}
      hideSheet={props.hideModal}
    />
  );
};

export default ModalBox;
