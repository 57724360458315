import * as React from 'react';
import {
  EMPTY_PRODUCTS_POPUP_BUTTON_TEXT
} from '../../../TextProvider';
import { Button } from "@kounta/chameleon";
import { IButtonStyle } from '../../../typings/Chameleon';
import { useState } from 'react';
import { useEffect } from 'react';
import { usePrevious } from '../../../utils/GetPreviousPropsOrState';

interface IProps {
  hideYn: boolean;
  buttonStyle: IButtonStyle;
  isProductsFullyLoaded: boolean;
  onButtonClick: () => void;
}

export const CreateRecipeButton = React.memo(
  (
    {
      hideYn,
      buttonStyle,
      isProductsFullyLoaded,
      onButtonClick
    }: IProps
  ): JSX.Element | null => {
    const [needLoadingSpin, setNeedLoadingSpin] = useState<boolean>(false);

    // Use previous state, to decide if the search popup is needed or not:
    const prevNeedLoadingSpin = usePrevious(needLoadingSpin);

    useEffect(() => {
      if (prevNeedLoadingSpin) {
        onButtonClick();
        setNeedLoadingSpin(false);
      }
    }, [
      isProductsFullyLoaded,
      needLoadingSpin,
      onButtonClick,
      prevNeedLoadingSpin,
    ]);

    const buttonClickHandler = (): void => {
      if (!isProductsFullyLoaded) {
        setNeedLoadingSpin(true);
      } else {
        onButtonClick();
      }
    };

    if (hideYn) {
      return null;
    }

    return (
      <Button
        slim={buttonStyle.slim}
        primary={buttonStyle.primary}
        secondary={buttonStyle.secondary}
        destructive={buttonStyle.destructive}
        onClick={buttonClickHandler}
        showLoading={needLoadingSpin}
      >
        {EMPTY_PRODUCTS_POPUP_BUTTON_TEXT}
      </Button>
    );
  }
);
