import { IBatchFormikProps } from '../../typings/Formik';
import {
  IApiRecipe,
  IFormikBatch,
  IFormikBatchIngredient
} from '../../typings/Interface';
import { isValidNumber } from '../../validation/Validator';
import { updateBatchIngredientsQtyByCustomYield } from '../UpdateBatchIngredientsQtyByCustomYield';

export const handleExpectedYieldQtyChange = (
  setFieldValue: IBatchFormikProps["setFieldValue"],
  selectedRecipe: IApiRecipe | false,
  benchmarkBatchIngs: IFormikBatchIngredient[],
  existingBatch: IFormikBatch | false,
) => {
  return (numberInString: string) => {
    // There are two `expectedYield` need to consider:
    // 1. values.expectedYield  --- current formik Formbatch expectedYield.
    //      We need this to calculate the ratio and use the ratio to convert
    //      batchIngredients' Qty.
    // 2. numberInString --- new user inputted expectedYield.
    //      We need update `batchMake` to reflect user's input.
    if (isValidNumber(numberInString)) {
      // As long as the `numberInString` is valid, we should update the field
      // to make `values.expectedYield` valid.
      // `updateBatchIngredientsQtyByCustomYield()` depends on it.
      // An invalid `values.expectedYield` will cause '
      // `updateBatchIngredientsQtyByCustomYield()` failed.
      const numberInput = parseFloat(numberInString);
      
      // Do validation when entering (third argument of setFieldValue() ignored)
      // , this way users will receive real-time validation result
      // and see error highlight if validation failed:
      setFieldValue('expectedYield', numberInput);

      let newIngs: IFormikBatchIngredient[];
      // Note: initialBatch form has `expectedYieldQty` disabled, so don't
      // need to worry about initialBatch (no recipe select) case in here:
      if (existingBatch === false) {
        if (selectedRecipe === false) {
          throw new Error(`This is not suppose to happen
             If ExpectedYield is changeable, existingBatch and selectedRecipe
             cannot both be null.`);
        }
        // For case: "existingBatch === false"
        // It is `createBatch`, it is using the recipe to generate the batch.
        newIngs = updateBatchIngredientsQtyByCustomYield(
          selectedRecipe.expectedYield,
          benchmarkBatchIngs,
          numberInput,
        );
      } else {
        // For case: "existingBatch !== false"
        // It is `updateBatch`:
        newIngs = updateBatchIngredientsQtyByCustomYield(
          existingBatch.expectedYield,
          existingBatch.batchIngredients,
          numberInput,
        );
      }

      setFieldValue('batchIngredients', newIngs, false);
    } else {
      // If the expectedYield is null/undefined/NaN/false, maybe user just
      // clear/reset the input to empty. Or maybe user hasn't finished the
      // input yet eg: trying to type in `0.123`, currently only typed in
      // `0.` We still need to setup it, otherwise the form will just hold
      // the previous valid value.
  
      // Do validation when entering (third argument of setFieldValue() ignored)
      // , this way users will receive real-time validation result
      // and see error highlight if validation failed:
      setFieldValue('expectedYield', numberInString);
    }
  };
};
