import {
  EConsumptionType,
  EUom,
  IFormikIngredient,
  IFormikRecipe,
  IRecipeIngredientsArgs,
  IReduxDraftRecipe,
} from '../../typings/Interface';

export const EMPTY_INGREDIENT_PRODUCT_UUID = '';
export const emptyRecipeIngredient: IRecipeIngredientsArgs = {
  productUuid: EMPTY_INGREDIENT_PRODUCT_UUID,
  componentQty: 0,
  uom: EUom.unit,
};

export const formikEmptyRecipeIngredient: IFormikIngredient = {
  ...emptyRecipeIngredient,
  componentQty: `${emptyRecipeIngredient.componentQty}`,
};

export const initialDraftRecipe: IReduxDraftRecipe = {
  uuid: '',
  note: '',
  productUuid: '',
  consumptionType: EConsumptionType.madeToOrder,
  expectedYield: 0,
  recipeIngredients: [emptyRecipeIngredient],
  uom: EUom.unit,
};

export const initialDraftFormikRecipe: IFormikRecipe = {
  ...initialDraftRecipe,
  expectedYield: initialDraftRecipe.expectedYield.toString(),
  recipeIngredients: [formikEmptyRecipeIngredient],
};
