import React, { SyntheticEvent } from 'react';
import { Avatar, getInitials, Table, TableRowType } from "@kounta/chameleon";
import {
  ERecipeFilterDropdownIds,
  ERecipesListSearchEntity,
  RECIPES_LIST_TABLE_HEADER_OPTIONS,
} from '../../../TextProvider';
import { IApiProduct, IApiRecipe, Uuid } from '../../../typings/Interface';
import { relativeTimeStamp } from '../../../components/date-time/RelativeTimeStamp';
import { AvatarContainer } from '../Style';
import { SearchResultEmpty } from './SearchResultEmpty';
import { IRouteHistoryPushFunction } from '../../../typings/ReactRouter';
import { goToSingleRecipePageWithQuery } from '../../../utils/recipe/RecipesQueryParam';
import { searchByRecipesOrIngredients } from '../../../utils/recipe/SearchByRecipesOrIngredients';
import { Scrollable } from '../../../css/Styled';

interface IProps {
  products: IApiProduct[];
  recipes: IApiRecipe[];
  routeHistoryPush: IRouteHistoryPushFunction;
  productNameSearchText: string;
  currentFilterCriteria: ERecipeFilterDropdownIds;
  selectedSearchDropdown: ERecipesListSearchEntity;
}

export const renderEachProductRow = (
  result: TableRowType[],
  singleRecipe: IApiRecipe,
  products: IApiProduct[],
): TableRowType[] => {
  const relatedProductOfThisRecipe = products.find(
    (product: IApiProduct) => singleRecipe.productUuid === product.id
  );

  if (relatedProductOfThisRecipe !== undefined) {
    const updatedTime = singleRecipe.updatedTime;
    const timeAgo = relativeTimeStamp(updatedTime);
    const updatedDateOrCreateRecipe = <div>{timeAgo}</div>;

    result.push({
      id: relatedProductOfThisRecipe.id,
      content: [
        {
          id: 0,
          value: (
            <AvatarContainer>
              <Avatar
                data-testid={'avatarImgBackground'}
                initials={getInitials(relatedProductOfThisRecipe.name)
                  .replace(/[^a-zA-Z ]/g, '')}
                imageUrl={relatedProductOfThisRecipe.image}
                square={true}
              />
            </AvatarContainer>
          ),
        },
        { id: 1, value: relatedProductOfThisRecipe.name },
        { id: 2, value: updatedDateOrCreateRecipe },
      ],
    });
  }
  return result;
};

export const RecipesTable = React.memo((
  {
    products,
    recipes,
    routeHistoryPush,
    productNameSearchText,
    currentFilterCriteria,
    selectedSearchDropdown,
  }: IProps,
): JSX.Element => {
  const conformRecipes = searchByRecipesOrIngredients(
    products,
    recipes,
    productNameSearchText,
    currentFilterCriteria,
    selectedSearchDropdown,
  );

  // Only need to build/calculate the table row when necessary:
  const recipeTableRows = conformRecipes.reduce(
    (result: TableRowType[], singleRecipe: IApiRecipe) => {
      return renderEachProductRow(
        result,
        singleRecipe,
        products,
      );
    },
    []
  );

  const handleTableRowClick = (
    e: SyntheticEvent,
    productUuid: Uuid | number
  ) => {
    if(typeof productUuid === 'string') {
      goToSingleRecipePageWithQuery(
        routeHistoryPush,
        currentFilterCriteria,
        productNameSearchText,
        selectedSearchDropdown,
      )(productUuid);
      e.preventDefault();
    }
    else throw new Error(`Expected productUuid to be typeof string, got ${typeof productUuid}`);

  };

  if (recipeTableRows.length === 0) {
    return (
      <SearchResultEmpty/>
    );
  }
  return (
    <Scrollable>
      <Table
        headers={RECIPES_LIST_TABLE_HEADER_OPTIONS}
        rows={recipeTableRows}
        onRowClick={handleTableRowClick}
        showRowIndicator={false}
        showRowClick
      />
    </Scrollable>
  );
});
