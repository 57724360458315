import {
  EQueryNames,
  ESubscriptionQueryNames, IApiHandlerResult, IGraphQlSubscriptionFunction,
  Uuid
} from '../typings/Interface';
import { BILLING_PLAN_FIELD } from './CommonFields';
import { graphQlApiHandler, graphQlSubscriptionHandler } from './Handler';
import { capitalizeFirstLetter } from './QueryStringPurify';

export interface IApiBillingPlanDetail {
  // below 3 attributes will be string `null` for newly signed up sites.
  // Check schema definition:  backend/appsync/schema/schema.graphql
  oldPlan: string;
  oldPlanId: string;
  endDatetime: string;

  newPlan: string;
  newPlanId: number;
  isPaidPlan: boolean;
  startDatetime: string;
  productType: string;
  productTypeId: number;

  personId: number | null;
  personUuid: Uuid;

  snsTimestamp: string;
}

export interface IApiBillingPlan {
  companyId: number;
  companyUuid: Uuid;

  siteId: number;
  siteUuid: Uuid;

  insights?: IApiBillingPlanDetail;
  pos?: IApiBillingPlanDetail;
  produce: IApiBillingPlanDetail;
  purchase?: IApiBillingPlanDetail;
  supply?: IApiBillingPlanDetail;
}

export const billingPlanItemDetail = `
    oldPlan
    oldPlanId
    endDatetime

    newPlan
    newPlanId
    isPaidPlan
    startDatetime
    productType
    productTypeId

    personId
    personUuid
    
    snsTimestamp
`;

// 1. Get BillingPlan for current site:
export const getBillingPlanQuery = (): string =>
  `query ${capitalizeFirstLetter(EQueryNames.getBillingPlan)} {
     ${EQueryNames.getBillingPlan} {
        companyId
        companyUuid
        siteId
        siteUuid
        
        pos{${billingPlanItemDetail}}
        purchase{${billingPlanItemDetail}}
        produce{${billingPlanItemDetail}}
        insights{${billingPlanItemDetail}}
        supply{${billingPlanItemDetail}}
     }
  }`;

export async function getBillingPlan(): Promise<IApiHandlerResult<IApiBillingPlan>> {
  const getBillingPlanQl = getBillingPlanQuery();

  return await graphQlApiHandler<IApiBillingPlan>(getBillingPlanQl, EQueryNames.getBillingPlan);
}

export const billingPlanSubscriptionQuery = (siteUuid: Uuid): string =>
  `subscription ${ESubscriptionQueryNames.subscribeBillingPlanBySite} {
    ${ESubscriptionQueryNames.subscribeBillingPlanBySite}(siteUuid: "${siteUuid}") {
      ${BILLING_PLAN_FIELD}
    }
  }`;

export const billingPlanSubscription: IGraphQlSubscriptionFunction = (
  siteUuid: Uuid,
  subscriptionCallBacks: () => Promise<void>
): ZenObservable.Subscription => {
  return graphQlSubscriptionHandler(
    billingPlanSubscriptionQuery(siteUuid),
    ESubscriptionQueryNames.subscribeBillingPlanBySite,
    subscriptionCallBacks,
  );
};


