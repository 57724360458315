import { EChameleonFilterType } from '../../typings/Chameleon';

interface IChameleonNumberInputPartialProps {
  readOnly?: boolean;
  filter?: EChameleonFilterType;
}
export const getNumberInputPropsForReadonlyAndBlur = (
  readOnly: boolean,
  shouldBlurPrice: boolean,
): IChameleonNumberInputPartialProps => {
  if (!readOnly && !shouldBlurPrice) {
    return {};
  }

  // `blurInputProps` takes precedence over `readOnly`.
  return shouldBlurPrice ? {
      readOnly: true,
      filter: EChameleonFilterType.blur,
    } :
    {
      readOnly,
    };
}
