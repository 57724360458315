import { FieldProps, getIn } from 'formik';

export const getFormikFieldError = (
  form: FieldProps['form'],
  field: FieldProps['field']
): { errorYn: boolean; errorMsg: string } => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  // Now we don't consider `touch` anymore, because we want to show the error
  // in real-time. Except for the initial state;
  // const touch = getIn(form.touched, field.name);

  // Both touch & error might be undefined.
  // TODO: I found (touch && error) || false will cause the real-time error
  // highlight disabled. Need more time to check out why.
  const hasError = touch && error;

  return {
    errorYn: hasError,
    errorMsg: hasError ? error : '',
  };
};
