import * as React from 'react';
import { Field, FieldProps } from 'formik';
import {
  IApiProduct,
  IApiRecipe,
  IFormikBatch,
} from '../../../typings/Interface';
import { AutoComplete, Group, TextInput } from '@kounta/chameleon';
import {
  PRODUCT_NAME_SEARCH_LABEL,
  PRODUCT_NAME_SEARCH_PLACEHOLDER,
} from '../../../TextProvider';
import { getFormikFieldError } from '../../../formik/FormikField';
import { getPremadeProductNamesForDropDown } from '../../../utils/recipe/GetPremadeProductNamesForDropDown';
import styled from 'styled-components';
import { handleMainProductNameOnChange } from '../../../formik/batch/HandleMainProductNameOnChange';
import { handleMainProductNameOnBlur } from '../../../formik/batch/HandleMainProductNameOnBlur';
import { IFormikResetForm } from '../../../typings/Formik';

interface IProps {
  products: IApiProduct[];
  recipes: IApiRecipe[];

  existingBatch: IFormikBatch | false;

  resetForm: IFormikResetForm;
  values: IFormikBatch;
  isSearchable: boolean;
}

export default class BatchMainProductName extends React.PureComponent<IProps, any> {
  public render = () => (
    <Group spacing="0px">
      <ProductNameSearch>
        <Field name={'productName'}>{this.renderBatchProductName}</Field>
      </ProductNameSearch>
    </Group>
  );

  private renderBatchProductName = ({
    field,
    form,
  }: FieldProps): JSX.Element => {
    if (this.props.existingBatch) {
      return (
        <TextInput
          placeholder={this.props.values.productName}
          value={this.props.values.productName}
          label={PRODUCT_NAME_SEARCH_LABEL}
          readOnly={true}
        />
      );
    }
    const errorObj = getFormikFieldError(form, field);
    return (
      <AutoComplete
        placeHolder={PRODUCT_NAME_SEARCH_PLACEHOLDER}
        itemLabel={PRODUCT_NAME_SEARCH_LABEL}
        itemList={getPremadeProductNamesForDropDown(
          this.props.products,
          this.props.recipes
        )}
        onChange={handleMainProductNameOnChange(
          this.props.resetForm,
          this.props.products,
          this.props.recipes,
        )}
        onBlur={handleMainProductNameOnBlur(
          this.props.resetForm,
          this.props.products,
        )}
        error={errorObj.errorYn}
        autoFocus
        isSearchable={this.props.isSearchable}
        value={this.props.values.productName}
      />
    );
  };

}

const ProductNameSearch = styled.div`
  width: 100%;
  margin: 0;
`;
