import React, { useEffect, useState } from "react";
import UomEditor from "../uom-editor/UomEditor";
import {
  AdvancedSelect,
  AdvancedSelectOptionType,
  Portal,
  SnackBar,
} from "@kounta/chameleon";
import { IApiProduct, IUomDropDownOption } from "../../typings/Interface";
import { emptyBatchIngredient } from "../../formik/batch/InitialDraftBatch";
import Uom from "../../uom/Uom";
import { getEditInBackOfficeOption } from "../../uom/EditInBackOffice";
import { updateProductApiAdaptor } from "../../utils/product/UpdateProductApiAdaptor";
import { EUomDisplay } from "../../uom/Display";
import { UOM_EDITOR_TEXT_PROVIDER } from "../../TextProvider";

interface IUomModalSelectorProps {
  defaultOption: IUomDropDownOption;
  onDropDownChange: (option: AdvancedSelectOptionType) => void;
  product: IApiProduct | false;
  modalState: boolean;
  closeModalHandle: () => void;
}

interface ISelectorState {
  currentOption: IUomDropDownOption;
  options: AdvancedSelectOptionType[];
  product: IApiProduct | false;
  isButtonActive: boolean;
}

export default React.memo((props: IUomModalSelectorProps): JSX.Element => {

  const uom = props.product ? props.product.uom : emptyBatchIngredient.uom;

  const options: AdvancedSelectOptionType[] = new Uom(
    uom).displayDropdownOptionsSet;

  if (props.product) {
    const extraOption: AdvancedSelectOptionType = getEditInBackOfficeOption();
    options.push(extraOption);
  }

  const [selectorState, setSelectorState] = useState<ISelectorState>({
    currentOption: props.defaultOption,
    options: options,
    product: props.product,
    isButtonActive: false,
  });

  useEffect(() => {
      setSelectorState({
        ...selectorState,
        product: props.product,
      })
    },
    // we only care about product change in this useEffect
    // eslint-disable-next-line
    [props.product]);

  const saveButtonHandler = (
    product: IApiProduct,
  ): void => {

    const options = new Uom(product.uom).displayDropdownOptionsSet as AdvancedSelectOptionType[];
    options.push(getEditInBackOfficeOption());
    const currentOption = { value: product.uom, label: EUomDisplay[product.uom] };

    setSelectorState({
      product,
      options,
      currentOption: currentOption,
      isButtonActive: true,
    });

    props.onDropDownChange(currentOption);
  };

  const showModal = (): JSX.Element => {
    return (
      <React.Fragment>
        <AdvancedSelect
          error={false}
          options={selectorState.options}
          defaultValue={selectorState.currentOption}
          isSearchable={false}
          onChange={props.onDropDownChange}
        />
        { selectorState.product &&
          <UomEditor
            product={selectorState.product}
            closeHandler={props.closeModalHandle}
            saveButtonHandler={saveButtonHandler}
            adaptor={updateProductApiAdaptor}
            uomProvider={UOM_EDITOR_TEXT_PROVIDER.update}
          />
        }
      </React.Fragment>
    );
  };

  const hideSnackBar = () => {
    setSelectorState({
      ...selectorState,
      isButtonActive: false,
    })
  };

  const hideModal = (): JSX.Element => {
    return (
      <AdvancedSelect
        error={false}
        options={selectorState.options}
        defaultValue={selectorState.currentOption}
        isSearchable={false}
        onChange={props.onDropDownChange}
      />
    )
  };

  const selectSection = props.modalState ? showModal() : hideModal();
  const productName = props.product ? props.product.name : 'Product';

  return (
    <React.Fragment>
      {selectSection}
      {selectorState.isButtonActive && <Portal>
        <SnackBar
          message={`${productName} updated successfully`}
          autoHide={true}
          type={'success'}
          onClose={hideSnackBar}
        />
      </Portal>}
    </React.Fragment>
  )
});
