import Big from 'big.js';

export const PERCENTAGE_RATE = 100;
export const ZERO = 0;
export const BIG_ZERO = new Big(0);
export const SHOW_PRECISION = 2;
export const ZERO_STRING_DISPLAY = BIG_ZERO.toFixed(SHOW_PRECISION);

export const roundToPrecision = (
  source: Big,
  precision = SHOW_PRECISION,
): Big => {
  return source.round(precision);
};
