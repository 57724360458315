import React, { useState } from 'react';
import {
  Container,
  Group,
  Search,
  Spacer,
  Alignment,
  Button,
  TableSortDirectionType, SelectOptionType,
} from '@kounta/chameleon';
import {
  IApiBatch,
  IProduceLeftNavControl,
  Uuid,
} from '../../typings/Interface';
import { HistoryList } from './HistoryList';
import {
  EBatchButtonText,
  EEmptyStateOperationName,
  EPageHeader,
  ERouterUrlPath,
} from '../../TextProvider';
import { RouteComponentProps } from 'react-router';
import { SelectDateRangeDropdown } from './SelectDateRangeDropdown';
import {
  ETableSortingDirection,
} from '../../typings/Chameleon';
import {
  EDateOptionType,
  filterAndSortCompletedBatches,
} from '../../utils/date-time/DateTimeUtil';
import {
  CenterEmptyStateButton,
  EmptyStateWrapper,
} from '../../components/empty-State/EmptyStateWrapper';
import { isBatchCompleted } from '../../utils/filter/FilterUtil';
import { RemainingBatchAllowance } from '../../components/cards/RemainingBatchAllowance';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { ProduceTitleHeader } from "../../components/produce-nav/ProduceTitleHeader";
import { Scrollable } from '../../css/Styled';

const defaultDateSortingDirection = ETableSortingDirection.descending;
const defaultDateRangeType = EDateOptionType.pastMonth;

let completeBatches: IApiBatch[] = [];

interface IProps extends IProduceLeftNavControl {
  routeComponentProps: RouteComponentProps;
  batches: IApiBatch[];
  remainingBatchAllowance: number;
  billingPlan: IApiBillingPlan;
}

export const History = React.memo((
  {
    routeComponentProps,
    batches,
    billingPlan,
    remainingBatchAllowance,
    showNav,
  }: IProps
): JSX.Element => {
  const [searchText, setSearchText] = useState<string>('');
  const [batchesList, setBatchesList] = useState<IApiBatch[]>(
    getInitialBatchesList(batches)
  );
  const [dateRangeType, setDateRangType] = useState<EDateOptionType>(
    defaultDateRangeType
  );
  const [dateSortingDirection, setDateSortingDirection] = useState<TableSortDirectionType>(
    defaultDateSortingDirection);

  const emptyStateForNoCompletedBatches = (
    <Alignment vertical={'top'} horizontal={'center'}>
      <CenterEmptyStateButton>
        <EmptyStateWrapper pageName={EEmptyStateOperationName.noProductionHistory}/>
        <Button secondary onClick={gotoCreateBatch}>
          {EBatchButtonText.create}
        </Button>
      </CenterEmptyStateButton>
    </Alignment>
  );

  function getInitialBatchesList(batchesFromProps: IApiBatch[]): IApiBatch[] {
    completeBatches = filterAndSortCompletedBatches(
      batchesFromProps,
      defaultDateSortingDirection,
      defaultDateRangeType,
      ''
    );
    return completeBatches;
  }

  const renderSearchAndDateRange = (): JSX.Element => (
    <Group horizontal full>
      <Alignment vertical={'top'} horizontal={'left'}>
        <Search
          searchText={searchText}
          onChange={updateSearchText}
          onClear={updateSearchText}
        />
      </Alignment>
      <Alignment vertical={'top'} horizontal={'right'}>
        <SelectDateRangeDropdown
          handleDataRangeChange={handleDataRangeChange}
          selectedOption={dateRangeType}
        />
      </Alignment>
    </Group>
  );

  return (
    <React.Fragment>
      <ProduceTitleHeader
        title={EPageHeader.completedBatches}
        showNavYn={showNav}
      />
      <Container>
        <RemainingBatchAllowance
          billingPlan={billingPlan}
          remainingBatchAllowance={remainingBatchAllowance}
        />
        {renderSearchAndDateRange()}
        <Spacer/>
        <Scrollable>{renderHistoryList()}</Scrollable>
      </Container>
    </React.Fragment>
  );

  function renderHistoryList(): JSX.Element | null {
    if (!hasCompletedBatches(batches)) {
      return emptyStateForNoCompletedBatches;
    }

    if (batchesList.length > 0) {
      return (
        <HistoryList
          completedBatches={batchesList}
          handleSortDirectionChange={handleSortDirectionChange}
          handleSingleBatchClick={handleSingleBatchClick}
        />
      );
    }
    return <EmptyStateWrapper pageName={EEmptyStateOperationName.historyFilter}/>;
  }

  function gotoCreateBatch() {
    routeComponentProps.history.push(ERouterUrlPath.createBatch);
  }

  function updateSearchText(searchText: string = '') {
    setSearchText(searchText);
    setBatchesList(
      filterAndSortCompletedBatches(
        completeBatches,
        dateSortingDirection,
        dateRangeType,
        searchText
      )
    );
  }

  function handleSortDirectionChange(
    dateSortingDirection: TableSortDirectionType
  ) {
    setDateSortingDirection(dateSortingDirection);
    setBatchesList(
      filterAndSortCompletedBatches(
        completeBatches,
        dateSortingDirection,
        dateRangeType,
        searchText
      )
    );
  }

  function handleSingleBatchClick(batchUuid: Uuid) {
    // Route to /completeBatch/batchUuid
    // like completeBatch/db8e5478-1b25-4343-86f3-4d69a0d71c59
    routeComponentProps.history.push(
      `${ERouterUrlPath.completedSingleBatch}${batchUuid}`
    );
  }

  function handleDataRangeChange(selectedOption: SelectOptionType) {
    setDateRangType(selectedOption.id as EDateOptionType);
    setBatchesList(
      filterAndSortCompletedBatches(
        completeBatches,
        dateSortingDirection,
        selectedOption.id as EDateOptionType,
        searchText
      )
    );
  }

  function hasCompletedBatches(allBatches: IApiBatch[]): boolean {
    return allBatches.filter(isBatchCompleted).length > 0;
  }
});
