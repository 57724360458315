import {
  array,
  mixed,
  number,
  object,
  string,
} from 'yup';
import {
  max12integer4decimal,
  MAX_NUMBER_OF_LETTERS_FOR_NOTE,
  isNumericCompatible,
  isPositiveNumber,
  moreThanMinNum,
  isLessOrEqualThanMaxNum,
} from './Validator';
import { EConsumptionType, EUom } from '../typings/Interface';

export const testValueNotZero = (
  value: string | null | undefined
): boolean => {
  if (value === null || value === undefined) {
    return false;
  }
  return parseFloat(value) !== 0;
};

const getFloatValidationRule = (allowNegative: boolean = false) => {
  if (allowNegative) {
    return string()
      // these constraints take precedence;
      .required('Number is Required')
      .test('numeric compatible', 'input must be a Number', isNumericCompatible)
      .test('zero is invalid', 'number cannot be 0', testValueNotZero)
      .test('number too big', 'Must be less than (16,4)',
        isLessOrEqualThanMaxNum)
      .test('number too small', 'Must be more than (16,4)',
        moreThanMinNum)
      .test('max-4-decimal', 'Max 12 integer and 4 decimal', max12integer4decimal);
  }
  return string()
    // these constraints take precedence;
    .required('Number is Required')
    .test('numeric compatible', 'input must be a Number', isNumericCompatible)
    .test('positive number only', 'Must be more than 0', isPositiveNumber)
    .test('number too big', 'Must be less than (16,4)',
      isLessOrEqualThanMaxNum)
    .test('max-4-decimal', 'Max 12 integer and 4 decimal', max12integer4decimal);
};

export const getIngredientValidationRules = (allowNegative: boolean = false) => {
  return {
    productUuid: string()
      .length(36)
      .required('Required'), // these constraints take precedence
    componentQty: getFloatValidationRule(allowNegative), // these constraints
                                                         // take precedence
    uom: string().required('Required'),
  }
};

export const RecipeSchema = object().shape({
  consumptionType: number()
    .oneOf([EConsumptionType.madeToOrder, EConsumptionType.premade])
    .required('Required'),
  recipeIngredients: array()
    .required('Must have Ingredients')
    // these constraints are shown if and
    // only if inner constraints are satisfied
    .min(1, 'Minimum of 1 Ingredients')
    .when('consumptionType', {
      is: (val: EConsumptionType) => (val === EConsumptionType.premade),
      then: array()
        .of(object().shape(getIngredientValidationRules(false))),
      otherwise: array()
        .of(object().shape(getIngredientValidationRules(true))),
    }),

  uuid: string(),
  productUuid: string()
    .length(36)
    .required('Required'),
  // We relax the `expectedYield` type here to be `string` instead of `number`,
  // just to avoid some uncaught Formik exception.
  // Eventually, the validation rules to restrict the type still applies in
  // `then`. We still make sure `expectedYield` is float type in
  // `getFloatValidationRule`
  expectedYield: string()
    // https://github.com/jquense/yup#mixedwhenkeys-string--arraystring-builder-object--value-schema-schema-schema
    .when('consumptionType', {
      is: (val: EConsumptionType) => (val === EConsumptionType.premade),
      then: getFloatValidationRule(false),
      // `consumptionType` already has `oneOf` checks, so this otherwise below
      // has to be `madeToOrder`:
      //
      // Update:
      //   https://kounta.atlassian.net/browse/PRO-263
      //   We have moved the logic of forcing `made_to_order` have `0`
      // expectedYield to the backend:
      // backend/appsync/src/lambda-entry-point/recipe/SaveRecipeHandler.ts  In
      // Frontend, we decided not to manually reset the `expectedYield` to `0`
      // when it is `made to order`. Because we want the form to remember the
      // temp input value before `save to database`. eg: 1. User has an
      // existing recipe type as `made to order`. 2. User switch this recipe to
      // `in batches/premade`. 3. User input the `expected yield` as `123`. 4.
      // User switch back to `made to order`. 5. Again, user switch to
      // `premade`. 6. The `123` should be preserved. Hence we cannot apply
      // below validation rule, because we allow the preserved `expectedYield`
      // even when the `consumptionType === made to order`: otherwise:
      // number().oneOf([0]),
    }),

  uom: mixed()
    .oneOf(Object.values(EUom))
    .required('Required'),
  note: string()
    .nullable(true)
    .max(
      MAX_NUMBER_OF_LETTERS_FOR_NOTE,
      `Max ${MAX_NUMBER_OF_LETTERS_FOR_NOTE} letters`
    ),
});
