import {
  EQueryNames,
  IApiHandlerResult,
  Uuid,
} from '../typings/Interface';
import { graphQlApiHandler } from './Handler';
import { capitalizeFirstLetter } from './QueryStringPurify';

const STAFF_PERMISSIONS = `
  code
  name
  domain
`;

export const STAFF_FIELD = `
  staffUuid
  firstName
  lastName
  isAdmin
  primaryEmailAddress
  permissions{${STAFF_PERMISSIONS}}
  image
  phone
  staffTrackingId
  personTrackingId
  personCreatedAt
  intercomHash
`;

export interface IStaffPermission {
  code: string;
  name: string;
  domain: string;
}

// This IStaff partially copied from:
// backend/appsync/src/backoffice-api/GetStaff.ts --> IGetStaff
// We removed unlikely used fields, only keep likely to be used fields
export interface IStaff {
  staffUuid: Uuid;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  primaryEmailAddress: string;
  permissions: IStaffPermission[];
  image: string;
  phone: string;

  // Those are for third party integration, eg: Intercom, churnZeroScript, segmentScript:
  staffTrackingId: number;
  personTrackingId: number;
  personCreatedAt: string;
  intercomHash: string;
}

export const getStaffQuery = (): string => {
  return `query ${capitalizeFirstLetter(
    EQueryNames.getStaff
  )} {
     ${EQueryNames.getStaff} {
       ${STAFF_FIELD}
     } 
  }`;
};

export async function getStaff(): Promise<IApiHandlerResult<IStaff>> {
  const getStaffQl = getStaffQuery();
  return graphQlApiHandler(
    getStaffQl,
    EQueryNames.getStaff
  );
}
