import Big from "big.js";
import { EUom, IApiProduct } from "../../typings/Interface";
import { BIG_ZERO } from '../number-format/BigNumberHandler';

/**
 * Stock calculation result
 * 1. stock_portion = ingredient_qty / measure
 * 2. stock_portion = ingredient_qty / 1000 / measure
 * 3. stock_portion = ingredient_qty * 1000 / measure
 * 4. stock_portion = ingredient_qty
 */
export enum ECalculationRule {
  qtyOverMeasure = 1,
  qtyOverMeasureOverOneThousand,
  qtyTimesOneThousandOverMeasure,
  qtyOnly,
}

interface IMappingRule {
  from: EUom,
  to: EUom,
  rule: ECalculationRule,
}

export const calculateFromRule = (
  rule: ECalculationRule,
  quantity: Big,
  measure: Big,
): Big => {
  let result = quantity;
  if (rule === ECalculationRule.qtyOverMeasure) {
    result = quantity.div(measure);
  }
  if (rule === ECalculationRule.qtyOverMeasureOverOneThousand) {
    result = quantity.div(1000).div(measure);
  }

  if (rule === ECalculationRule.qtyTimesOneThousandOverMeasure) {
    result = quantity.mul(1000).div(measure);
  }
  return result;
};

// Refer to notes https://kounta.atlassian.net/browse/PRO-286
// below mapping rule is to describe the converting rule
// between backoffice product uom and produce produce uom
const unitMappingRules: IMappingRule[] = [
  // from unit
  {
    from: EUom.unit,
    to: EUom.unit,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.unit,
    to: EUom.g,
    rule: ECalculationRule.qtyOverMeasureOverOneThousand,
  },
  {
    from: EUom.unit,
    to: EUom.ml,
    rule: ECalculationRule.qtyOverMeasureOverOneThousand,
  },
  {
    from: EUom.unit,
    to: EUom.kg,
    rule: ECalculationRule.qtyOnly,
  },
  {
    from: EUom.unit,
    to: EUom.l,
    rule: ECalculationRule.qtyOnly,
  },
  // from g
  {
    from: EUom.g,
    to: EUom.unit,
    rule: ECalculationRule.qtyOnly,
  },
  {
    from: EUom.g,
    to: EUom.g,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.g,
    to: EUom.ml,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.g,
    to: EUom.kg,
    rule: ECalculationRule.qtyTimesOneThousandOverMeasure,
  },
  {
    from: EUom.g,
    to: EUom.l,
    rule: ECalculationRule.qtyTimesOneThousandOverMeasure,
  },
  // from ml
  {
    from: EUom.ml,
    to: EUom.unit,
    rule: ECalculationRule.qtyOnly,
  },
  {
    from: EUom.ml,
    to: EUom.g,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.ml,
    to: EUom.ml,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.ml,
    to: EUom.kg,
    rule: ECalculationRule.qtyTimesOneThousandOverMeasure,
  },
  {
    from: EUom.ml,
    to: EUom.l,
    rule: ECalculationRule.qtyTimesOneThousandOverMeasure,
  },
// from kg
  {
    from: EUom.kg,
    to: EUom.unit,
    rule: ECalculationRule.qtyOnly,
  },
  {
    from: EUom.kg,
    to: EUom.g,
    rule: ECalculationRule.qtyOverMeasureOverOneThousand,
  },
  {
    from: EUom.kg,
    to: EUom.ml,
    rule: ECalculationRule.qtyOverMeasureOverOneThousand,
  },
  {
    from: EUom.kg,
    to: EUom.kg,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.kg,
    to: EUom.l,
    rule: ECalculationRule.qtyOverMeasure,
  },
  // from l
  {
    from: EUom.l,
    to: EUom.unit,
    rule: ECalculationRule.qtyOnly,
  },
  {
    from: EUom.l,
    to: EUom.g,
    rule: ECalculationRule.qtyOverMeasureOverOneThousand,
  },
  {
    from: EUom.l,
    to: EUom.ml,
    rule: ECalculationRule.qtyOverMeasureOverOneThousand,
  },
  {
    from: EUom.l,
    to: EUom.kg,
    rule: ECalculationRule.qtyOverMeasure,
  },
  {
    from: EUom.l,
    to: EUom.l,
    rule: ECalculationRule.qtyOverMeasure,
  },
];

export const stockPortionCalculation = (
  product: IApiProduct,
  uom: EUom,
  quantity: Big,
): Big => {
  const foundRule = unitMappingRules.find((rule: IMappingRule): boolean =>
    rule.from === product.uom && rule.to === uom
  );

  if (!foundRule) {
    return BIG_ZERO;
  }

  let bigMeasure = new Big(product.measure);

  return calculateFromRule(
    foundRule.rule,
    quantity,
    bigMeasure,
  );
};
