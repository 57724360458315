import styled from 'styled-components';

export const RecipeFormContainer = styled.div`
  .go-back-link {
    text-decoration: none;
  }

  .measurement-unit-selector {
    width: 72px;
  }

  .ingredients-row-quantity,
  .ingredients-row-cost {
    width: 96px;
  }
`;

export const AvatarContainer = styled.div`
  padding: 8px 0;
`;

export const FilterText = styled.div`
  margin-top: 15px;
  margin-right: 10px;
`;

// This style needs to inline with `ProductTablePageContainer` from:
// frontend/produce-webpage/src/components/page-container/PageContainer.tsx
// to make sure the search bar is having the same width with the result table.
export const SearchBarAutoWidth = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding:0 16px;
  @media screen and (min-width: 768px) {
    padding: 0 48px;
  }
`;

export const SearchBarWrapper = styled.div`
  backgroundColor: #fff;
  paddingTop: 16px;
  paddingBottom: 16px;
  width: 100%;
`;
