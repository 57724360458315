import React from 'react';
import { EmptyState, Illustration } from '@kounta/chameleon';
import {
  EEmptyStateOperationName,
  EMPTY_STATE_DESCRIPTION,
} from '../../TextProvider';
import styled from 'styled-components';

interface IProps {
  pageName: EEmptyStateOperationName;
  footerElement?: JSX.Element;
}

export const EmptyStateWrapper = React.memo((props: IProps): JSX.Element => {
  const emptyStateTextProvider = EMPTY_STATE_DESCRIPTION[props.pageName];

  return (
    <EmptyState
      heading={emptyStateTextProvider.heading}
      subheading={emptyStateTextProvider.subheading}
      footer={props.footerElement}
    >
      <Illustration
        illustrationName={
          emptyStateTextProvider.illustrationName
        }
        url={emptyStateTextProvider.imageUrl}
      />
    </EmptyState>
  );
});

/**
 * This container can take the full height of the page
 * minus the header and search bar.
 * in order to make the EmptyStateWrapper in the middle,
 * use the Alignment component inside.Example:
 * <EmptyStateContainer>
 *   <Alignment horizontal={'center'} vertical={'center'}>
 *     <EmptyStateWrapper />
 *   </Alignment>
 * <EmptyStateContainer>
 */
export const EmptyStateContainer = styled.div`
  height: calc(100vh - 88px);
  padding-bottom: 120px;
`;
export const CenterEmptyStateButton = styled.div`
  text-align: center;
  margin-top: 80px;
`;
