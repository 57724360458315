import * as React from 'react';
import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import {
  Alignment, Decimal,
  Table,
  TableHeaderType,
  TableRowType,
} from '@kounta/chameleon';
import { ItemWithTimeStamp } from './RowItemWithTimeStamp';
import { BatchAction } from './BatchAction';
import {
  EBatchStatus,
  EModalType,
  EUom,
  IApiBatch,
  IApiProduct,
  ILayoutUiControlFuncs,
  Uuid,
} from '../../../typings/Interface';
import {
  SECONDARY_BUTTON,
} from '../../../typings/Chameleon';
import { completeBatch } from '../../../graphql/BatchQL';
import { getValidBatchesByStatusAndSortByTime } from '../../../utils/batch/GetValidBatchesByStatusAndSortByTime';
import {
  EEmptyStateOperationName,
  ERouterUrlParamKey,
  ERouterUrlPath,
} from '../../../TextProvider';
import { ActualYieldModal } from './ActualYieldModal';
import { printQtyWithUom } from '../../../utils/batch/PrintQtyWithUom';
import { RouteComponentProps } from 'react-router-dom';
import {
  CenterEmptyStateButton,
  EmptyStateWrapper,
} from '../../../components/empty-State/EmptyStateWrapper';
import { CreateBatchButton } from './CreateBatchButton';
import { Path } from 'history';

interface IProps {
  batches: IApiBatch[];
  products: IApiProduct[];
  segmentBatchStatus: EBatchStatus;

  // UI controls for SnackBar:
  handleModal: ILayoutUiControlFuncs['handleModal'];
  hideSnackBar: () => void;

  routeComponentProps: RouteComponentProps<{
    [ERouterUrlParamKey.batch]: Uuid;
  }>;
}

interface IState {
  uuid: Uuid;
  actualYield: number;

  // Show actualYield input modal:
  shouldShowActualYieldModal: boolean;
  uom: EUom;
}

export const handleSingleBatchClick = (
  routerHistoryPush: (path: Path) => void
) => {
  return (e: SyntheticEvent, batchUuid: number | Uuid) => {
    if (typeof batchUuid === 'string') {
      routerHistoryPush(
        `${ERouterUrlPath.incompleteSingleBatch}${batchUuid}`
      );
    } else {
      throw new Error(
        `Expected batchUuid to be typeof string, got ${typeof batchUuid}`);
    }
  }
};

export default class BatchTable extends React.Component<IProps, IState> {
  readonly state: IState = {
    uuid: '',
    actualYield: 0,
    // Show actualYield input modal:
    shouldShowActualYieldModal: false,
    uom: EUom.unit,
  };

  public componentWillUnmount() {
    // When leaving batches table (This class), hide snackbar as the state would
    // still carry on after transition to another page (eg: single Batch page).
    this.props.hideSnackBar();
  }

  public render() {
    const batchesMatchingStatus = getValidBatchesByStatusAndSortByTime(
      this.props.batches,
      this.props.segmentBatchStatus,
      this.props.products
    );
    if (batchesMatchingStatus.length === 0) {
      const emptyStatePageName: EEmptyStateOperationName =
        this.props.segmentBatchStatus === EBatchStatus.inProgress
          ? EEmptyStateOperationName.inProgressBatches
          : EEmptyStateOperationName.plannedBatches;
      return (
        <Alignment vertical={'top'} horizontal={'center'}>
          <CenterEmptyStateButton>
            <EmptyStateWrapper pageName={emptyStatePageName} />
            {this.renderButtonUnderEmptyState(emptyStatePageName)}
          </CenterEmptyStateButton>
        </Alignment>
      );
    }
    return (
      <ProductionTableContainer>
        {this.renderBatchTable(batchesMatchingStatus)}
        {this.state.shouldShowActualYieldModal && (
          <ActualYieldModal
            initialValue={this.state.actualYield}
            handleActualYieldChange={this.handleActualYieldChange}
            hideFinishBatchYieldModal={this.getToggleActualYieldModalFunction(
              false
            )}
            handleCompleteBatchActionButton={
              this.handleCompleteBatchActionButton
            }
            uom={this.state.uom}
          />
        )}
      </ProductionTableContainer>
    );
  }

  private handleActualYieldChange = (value: Decimal) => {
    this.setState({
      actualYield: value.toNumber(),
    });
  };

  // Return a Function so that it can be passed as a eventHandler.
  // see:
  // hideFinishBatchYieldModal={this.getToggleActualYieldModalFunction(false)}
  private getToggleActualYieldModalFunction = (
    shouldShowActualYieldModal: boolean
  ): (() => void) => {
    const toggleShouldShowActualYieldModalState = () => {
      this.setState({
        shouldShowActualYieldModal,
      });
    };
    return toggleShouldShowActualYieldModalState;
  };

  private handleCompleteBatchActionButton = async (): Promise<void> => {
    const completeResult = await completeBatch(
      this.state.uuid,
      this.state.actualYield
    );

    if (completeResult.succeed) {
      const response = completeResult.responseObj;
      const completedBatchInfo = {
        batchUuid: response.uuid,
        productName: response.productName,
        // We know the `completedResult` definitely has `actualYield` defined, not null:
        actualYield: response.actualYield as number,
        uom: response.uom,
      };
      // We don't need to call `getBatches()`, the graphQL subscription in
      // Layout.tsx: `batchSubscriptionHandler()` will handle the refreshment
      // of the data.
      this.getToggleActualYieldModalFunction(false)();
      this.props.handleModal({
        completedBatchInfo,
        modalType: EModalType.SHEET,
      });
    }
    /**
     else {
        // Todo: show error flash message
        // console.log('something went wrong');
      }
     */
  };

  private showFinishBatchYieldModal = (uuid: Uuid, uom: EUom, actualYield: number) => {
    this.setState({
      uuid,
      uom,
      actualYield
    });
    this.getToggleActualYieldModalFunction(true)();
  };

  private renderBatchTable = (
    batchesMatchingStatus: IApiBatch[]
  ): JSX.Element => {
    return (
      <Table
        headers={productionHeaders}
        rows={this.generateTableRowForEachSegmentTab(
          batchesMatchingStatus,
          this.props.segmentBatchStatus
        )}
        onRowClick={handleSingleBatchClick(this.props.routeComponentProps.history.push)}
        showRowIndicator={false}
        showRowClick
      />
    );
  };

  private generateTableRowForEachSegmentTab = (
    batchesMatchingStatus: IApiBatch[],
    batchStatus: EBatchStatus
  ): TableRowType[] =>
    batchesMatchingStatus.map(
      (batch: IApiBatch): TableRowType => {
        const timestamp =
          batchStatus === EBatchStatus.planned
            ? batch.plannedTime
            : batch.startedTime;

        return {
          id: batch.uuid,
          content: [
            {
              id: 1,
              value: (
                <ItemWithTimeStamp
                  name={batch.productName}
                  timestamp={timestamp}
                  segmentBatchStatus={batchStatus}
                />
              ),
            },
            {
              id: 2,
              value: printQtyWithUom(batch.expectedYield, batch.uom),
            },
            {
              id: 3,
              value: (
                <BatchAction
                  segmentBatchStatus={batchStatus}
                  batchUuid={batch.uuid}
                  batchUom={batch.uom}
                  batchActualYield={batch.expectedYield}
                  // UI controls for SnackBar and Modal:
                  showFinishBatchYieldModal={this.showFinishBatchYieldModal}
                  handleModal={this.props.handleModal}
                />
              ),
            },
          ],
        };
      }
    );

  private renderButtonUnderEmptyState = (
    pageName: EEmptyStateOperationName
  ): JSX.Element | null => {
    if (pageName === EEmptyStateOperationName.plannedBatches) {
      return (
        <CreateBatchButton
          routeComponent={this.props.routeComponentProps}
          buttonStyle={SECONDARY_BUTTON}
          disabled={false}
        />
      );
    }
    return null;
  };
}

const productionHeaders: TableHeaderType[] = [
  {
    id: 1,
    name: 'Product',
    width: '43%',
  },
  {
    id: 2,
    name: 'Expected yield',
    width: '23%',
  },
  {
    id: 3,
    name: ' ',
    align: 'right',
    width: '33%',
  },
];

const ProductionTableContainer = styled.div`
  /* has to be scroll, not auto for smooth scrolling iOS to work */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  height: calc(100vh - 88px);
`;
