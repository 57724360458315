import React, { useEffect, useState } from 'react';
import { Loading } from '@kounta/chameleon';
import '../css/Layout.css';
import {
  EModalType,
  ICookieStrings,
  IHandleModalParams,
} from '../typings/Interface';
import { ELoadingTitle } from '../TextProvider';
import { IReduxStore } from '../redux/actions';
import PagesSwitch from './PagesSwitch';
import styled from 'styled-components';
import { LeftNavPanel } from './LeftNavPanel';
import { ProduceAppSwitcher } from '../components/app-switcher/AppSwitcher';
import { BrowserRouter } from 'react-router-dom';
import {
  IReduxFunctions
} from '../redux/typings/Interface';
import ModalBox from '../components/modal-box/ModalBox';
import { layoutUseEffectFunction } from '../utils/layout/LayoutUseEffectFunction';
import { getStaff } from '../graphql/StaffQL';
import {
  redirectToBackOfficeProduceLoginPage,
  redirectToBackOfficeProducePermissionDeniedPage,
} from '../utils/url/UrlRedirect';
import {
  addProductsByInventoryType,
  initialFetchOfProductsRecipesBatchesAndPlanRestrictions,
  replaceBatchesList,
  replaceRecipesList,
  updateBillingPlan,
} from '../redux/UpdateProductRecipeBatchLists';
import { batchSubscription } from '../graphql/BatchQL';
import { productSubscription } from '../graphql/ProductQL';
import { billingPlanSubscription } from '../graphql/BillingPlanQL';
import { recipeSubscription } from '../graphql/RecipeQL';
import { loadAllThirdParty } from '../third-party-script-loader/ScriptLoaderEntry';
import { getSiteInfo } from '../graphql/SitesQL';
import { loadingHandlerWithReturnValue } from '../utils/loader/LoadingHandler';

// We cannot use <ScrollArea> from Chameleon, because it auto enlarge the row
// height to full-fill the page.
const MajorSection = styled.div`
  overflow: hidden;
  flex: auto;
`;

interface IProps extends IReduxFunctions, IReduxStore, ICookieStrings {
}

const defaultSnackBar: IHandleModalParams = {
  shouldShowModalYn: false,
  snackBarMessage: '',
  modalType: EModalType.SNACK_BAR,
};

export interface ILoader {
  loadingText: ELoadingTitle;
  shouldShowLoading: boolean;
}

const defaultLoader: ILoader = {
  loadingText: ELoadingTitle.loading,
  shouldShowLoading: false,
};

export const Layout = React.memo((
  {
    cognitoObj,
    products,
    recipes,
    batches,
    isProductsFullyLoaded,
    isRecipesFullyLoaded,
    billingPlan,
    remainingBatchAllowance,
    staff,
    siteInfo,

    onLoadSiteInfo,
    onLoadingProducts,
    onLoadingBatches,
    onLoadingRecipes,
    onAddPartialProducts,
    onAddPartialRecipes,
    onAddPartialBatches,
    onSetProductsFullyLoaded,
    onSetRecipesFullyLoaded,
    onSetRemainingBatchAllowance,
    onLoadBillingPlan,
    onLoadStaff,
  }: IProps): JSX.Element => {
    const [modalBar, setModalBar] = useState<IHandleModalParams>(defaultSnackBar);

    const [loader, setLoader] = useState<ILoader>(defaultLoader);

    const [showAppSwitcher, setAppSwitcher] = useState(false);

    const [showLeftNavYn, setLeftNav] = useState(false);

    const handleModal = (params: IHandleModalParams) => {
      setModalBar({
        shouldShowModalYn: true, // as default value
        ...params,
      });
    };

    const hideModal = () => {
      handleModal(defaultSnackBar);
    };

    const showNav = () => {
      setLeftNav(true);
    };

    const closeAppSwitcher = () => {
      setAppSwitcher(false);
    };

    const openAppSwitcher = () => {
      setAppSwitcher(true);
    };

    const setLoading = (
      shouldShowLoading: boolean,
      loadingText: ELoadingTitle = defaultLoader.loadingText
    ) => {
      setLoader({
        shouldShowLoading: shouldShowLoading,
        loadingText: loadingText,
      });
    };

    // eslint-disable-next-line
    useEffect(
      layoutUseEffectFunction(
        loadingHandlerWithReturnValue,
        getSiteInfo,
        onLoadSiteInfo,
        onLoadStaff,
        onAddPartialRecipes,
        onAddPartialBatches,
        onAddPartialProducts,
        onLoadingBatches,
        onLoadingRecipes,
        onLoadingProducts,

        onLoadBillingPlan,
        onSetRemainingBatchAllowance,
        handleModal,
        setLoading,
        onSetProductsFullyLoaded,
        onSetRecipesFullyLoaded,
        getStaff,
        redirectToBackOfficeProducePermissionDeniedPage,
        redirectToBackOfficeProduceLoginPage,
        initialFetchOfProductsRecipesBatchesAndPlanRestrictions,
        replaceBatchesList,
        replaceRecipesList,
        addProductsByInventoryType,
        updateBillingPlan,
        batchSubscription,
        recipeSubscription,
        productSubscription,
        billingPlanSubscription,

        loadAllThirdParty,
      ),
      // If `siteInfo` or `cognitoObj` is changed, we
      // should refresh all lists of batch/recipe/products:
      [
        // These properties may change over time. Once changes happened, we should
        // trigger `useEffect` again.
        cognitoObj,
      ]);

    /**
     * <LeftNavPanel />  has `useRouter`, <BrowserRouter> need to wrap
     * `useRouter` inside, so <BrowserRouter> has to be at least one level up
     * than `usingRouter`.
     * Reference:
     * 'You should not use <withRouter() /> outside a <Router>'
     * https://stackoverflow.com/questions/47314541/you-should-not-use-route-or-withrouter-outside-a-router-when-using-react-route
     */
    return (
      <BrowserRouter>
        <div className="main">
          {showAppSwitcher && (
            <ProduceAppSwitcher
              siteName={siteInfo.siteName}
              siteHasImageUrl={siteInfo.siteHasImageUrl}
              siteImageUrl={siteInfo.siteImageUrl}
              onCloseCallback={closeAppSwitcher}
            />
          )}
          <LeftNavPanel
            staff={staff}
            onClickLogo={openAppSwitcher}
            siteInfo={siteInfo}
            showNavYn={showLeftNavYn}
            navHandler={setLeftNav}
          />
          <MajorSection>
            <PagesSwitch
              products={products}
              recipes={recipes}
              batches={batches}
              isProductsFullyLoaded={isProductsFullyLoaded}
              isRecipesFullyLoaded={isRecipesFullyLoaded}

              billingPlan={billingPlan}
              remainingBatchAllowance={remainingBatchAllowance}

              staff={staff}

              onLoadingProducts={onLoadingProducts}

              // UI controls for SnackBar and Modal:
              handleModal={handleModal}
              hideSnackBar={hideModal}
              setLoading={setLoading}

              showNav={showNav}
            />
            <ModalBox
              shouldShowModalYn={modalBar.shouldShowModalYn}
              snackBarMessage={modalBar.snackBarMessage}
              snackBarType={modalBar.snackBarType}
              hideModal={hideModal}
              completedBatchInfo={modalBar.completedBatchInfo}
              modalType={modalBar.modalType}
            />
            <Loading
              isLoading={loader.shouldShowLoading}
              title={loader.loadingText}
            />
          </MajorSection>
        </div>
      </BrowserRouter>
    );
  }
);
