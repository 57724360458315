import { AdvancedSelectOptionType, AutoComplete } from '@kounta/chameleon';
import { getProductNamesForAutoComplete } from '../../utils/product/GetProductNamesForAutoComplete';
import {
  INGREDIENT_NAME_SEARCH_PLACEHOLDER, UOM_EDITOR_TEXT_PROVIDER
} from '../../TextProvider';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import {
  EFormikIngredientsFieldName,
  EUom,
  IApiProduct,
  Uuid,
} from '../../typings/Interface';
import { getFormikFieldError } from '../../formik/FormikField';
import { emptyBatchIngredient } from '../../formik/batch/InitialDraftBatch';
import { emptyRecipeIngredient } from '../../formik/recipe/InitialDraftRecipe';
import { getProductByNameFromList } from "../../utils/product/GetProductByUuidFromList";
import { isUomTooltipNeverDisplayed } from "../../persister/Cookie";
import UomEditor from "../uom-editor/UomEditor";
import { createDefaultProduct } from "../../utils/product/CreateDefaultProduct";
import { createProductApiAdaptor } from "../../utils/product/CreateProductApiAdaptor";
import { ProductNameRowContainer } from "../../css/Styled";

interface IProps {
  products: IApiProduct[];
  fieldNamePrefix: EFormikIngredientsFieldName;
  arrayIndex: number;
  selectedProductName: string;
  enableToolTip: () => void;
  shouldProductNameAutoFocus: boolean;
  showSnackBar: () => void;
  addNewProductCallback: (product: IApiProduct) => void;
}

interface IState {
  showEditor: boolean;
  createProductName: string;
}

interface IProductRow {
  readonly id: Uuid;
  readonly uom: EUom;
  readonly name?: string;
}

const emptyBatchProductRow: IProductRow = {
  id: emptyBatchIngredient.productUuid,
  name: emptyBatchIngredient.productName,
  uom: emptyBatchIngredient.uom,
};

const emptyRecipeProductRow: IProductRow = {
  id: emptyRecipeIngredient.productUuid,
  uom: emptyRecipeIngredient.uom,
};

export const shouldFocus = (
  selectedValue: AdvancedSelectOptionType | null,
  shouldProductNameAutoFocus: boolean,
  arrayIndex: number
) => {
  // When we create a batch from scratch, it should auto focus the product
  // name When a product is selected and we click add ingredient, it will
  // focus the new ingredient field
  return !selectedValue && (shouldProductNameAutoFocus || arrayIndex > 0);
};

export default class ProductNameRow extends React.Component<IProps, IState> {

  readonly state: IState = {
    showEditor: false,
    createProductName: '',
  };

  public render = () => (
    <Field name={this.getFieldName()}>{this.renderAutoComplete}</Field>
  );

  private renderAutoComplete = ({ field, form }: FieldProps): JSX.Element => {
    const errorObj = getFormikFieldError(form, field);
    const selectedValue: AdvancedSelectOptionType | null = this.props
      .selectedProductName
      ? {
          value: this.props.selectedProductName,
          label: this.props.selectedProductName,
        }
      : null;

    const defaultProduct = createDefaultProduct(this.state.createProductName);
    return (
      <ProductNameRowContainer>
        <AutoComplete
          backspaceRemovesValue={true}
          allowCreate={true}
          itemList={getProductNamesForAutoComplete(this.props.products)}
          placeHolder={INGREDIENT_NAME_SEARCH_PLACEHOLDER}
          onBlur={this.handleBlur(form, field)}
          onChange={this.onProductNameChangeForFormField(form, field)}
          onCreateOption={this.popEditorModal}
          value={selectedValue}
          error={errorObj.errorYn}
          autoFocus={shouldFocus(
            selectedValue,
            this.props.shouldProductNameAutoFocus,
            this.props.arrayIndex
          )}
        /> { this.state.showEditor && defaultProduct &&
        <UomEditor
          product={defaultProduct}
          closeHandler={this.closeModalHandler}
          saveButtonHandler={this.editorSaveButtonHandler(form, field)}
          adaptor={createProductApiAdaptor}
          uomProvider={UOM_EDITOR_TEXT_PROVIDER.create}
        /> }
      </ProductNameRowContainer>
    );
  };

  private editorSaveButtonHandler = (form: FieldProps['form'], field: FieldProps['field']) => {
    return (product: IApiProduct) => {
      const result = getProductByNameFromList(product.name, this.props.products);

      if (!result) {
        this.props.addNewProductCallback(product);
      }

      this.setProductRowData(form, field, this.fromProductToProductRow(product));
      this.setState({
        ...this.state,
        createProductName: '',
      });
      this.props.showSnackBar();
    }
  };

  private popEditorModal = (name: string) => {
    this.setState({
      ...this.state,
      showEditor: true,
      createProductName: name,
    });
  };

  private closeModalHandler = () => {
    this.setState({
      ...this.state,
      showEditor: false,
    });
  };

  private handleBlur = (
    form: FieldProps['form'],
    field: FieldProps['field']
  ) => (option: AdvancedSelectOptionType) => {
    // if the blurred text is not the previous value
    // check if the value is valid, if it is not valid,
    // reset to empty;
    if (!option) return;

    const selectedName = option.label as string;
    if (
      this.props.selectedProductName !== selectedName &&
      !getProductByNameFromList(selectedName, this.props.products)
    ) {
      this.setProductRowData(form, field, this.getEmptyProductRow());
    }
  };

  private setProductRowData = (
    form: FieldProps['form'],
    field: FieldProps['field'],
    selectedProduct: IProductRow
  ) => {
    form.setFieldValue(
      `${this.props.fieldNamePrefix}.${this.props.arrayIndex}.productUuid`,
      selectedProduct.id
    );
    form.setFieldValue(
      `${this.props.fieldNamePrefix}.${this.props.arrayIndex}.uom`,
      selectedProduct.uom
    );

    if (this.forBatch()) {
      // Only batchIngredients needs to update `productName`,
      // recipeIngredients doesn't have it:
      form.setFieldValue(field.name, selectedProduct.name);
    }
  };

  private onProductNameChangeForFormField = (
    form: FieldProps['form'],
    field: FieldProps['field']
  ) => (selectedValue: AdvancedSelectOptionType) => {
    if (isUomTooltipNeverDisplayed()) {
      // Show UOM help section, which introduce users to redirect to backoffice
      // and edit UOM:
      this.props.enableToolTip();
    }

    /**
     * 1. When product names changed, firstly get the product from the list by
     * the new selected product name,
     * 2. if the product is valid, then put the product value in the form
     * 3. otherwise, set the empty product row in the form
     */
    const stringValue = selectedValue ? (selectedValue.label as string) : '';
    this.setProductRowData(form, field, this.getProductRowByName(stringValue));
  };

  private getProductRowByName = (productName: string): IProductRow => {
    const product = getProductByNameFromList(productName, this.props.products);
    if (!product) {
      return this.getEmptyProductRow();
    }
    return this.fromProductToProductRow(product);
  };

  private fromProductToProductRow = (product: IApiProduct): IProductRow => {
    const { id, uom, name } = product;
    return {
      id,
      uom,
      name,
    };
  };

  private getEmptyProductRow = (): IProductRow => {
    if (this.forBatch()) {
      return emptyBatchProductRow;
    } else if (this.forRecipeIngredients()) {
      return emptyRecipeProductRow;
    }
    throw new Error(`Invalid field name prefix ${this.props.fieldNamePrefix}.`);
  };

  private getFieldName = (): string => {
    const propertyName = this.forRecipeIngredients()
      ? 'productUuid'
      : 'productName';
    return `${this.props.fieldNamePrefix}.${this.props.arrayIndex}.${propertyName}`;
  };

  private forBatch = () =>
    this.props.fieldNamePrefix === EFormikIngredientsFieldName.batchIngredients;

  private forRecipeIngredients = () =>
    this.props.fieldNamePrefix ===
    EFormikIngredientsFieldName.recipeIngredients;
}
