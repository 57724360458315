import { IApiProduct, IApiRecipe } from '../../typings/Interface';
import { initialDraftFormikBatch } from './InitialDraftBatch';
import { getProductByNameFromList } from '../../utils/product/GetProductByUuidFromList';
import getRecipeFromRecipesListByProduct
  from '../../utils/recipe/GetRecipeFromRecipesListByProduct';
import { convertRecipeToBatch } from './ConvertRecipeToBatch';
import { IFormikResetForm } from '../../typings/Formik';
import { AdvancedSelectOptionType } from '@kounta/chameleon';

// Important place to update the default Batch with the selected recipe.
// This is how the `recipeUuid` is passed to the `Batch`.
export const handleMainProductNameOnChange = (
  resetForm: IFormikResetForm,
  products: IApiProduct[],
  recipes: IApiRecipe[],
) => {
  return (selectedValue: AdvancedSelectOptionType | null) => {
    if (selectedValue === null) {
      resetForm({ values: initialDraftFormikBatch });
    } else {
      const selectedProduct = getProductByNameFromList(
        selectedValue.label as string,
        products
      );
      if (selectedProduct === false) {
        // It will never come here, if the autoSuggest logic hasn't change:
        throw new Error(`Product doesn't exist`);
      }

      // Filter by selectedRecipe's Name
      const selectedRecipe = getRecipeFromRecipesListByProduct(
        selectedProduct.id,
        recipes
      );
      if (selectedRecipe === false) {
        throw new Error(`Recipe doesn't exist`);
      }

      const newBatchWithValidIngredient = convertRecipeToBatch(
        selectedRecipe,
        products,
        selectedProduct.name,
      );
      resetForm({ values: newBatchWithValidIngredient });
    }
  };
};
