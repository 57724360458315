import * as React from 'react';
import {
  Decimal,
  Group,
  Modal,
  NumberPadOnChangeFuncType,
  Paragraph,
  Portal,
} from '@kounta/chameleon';
import { EBatchFormButtonText, EUom } from '../../../typings/Interface';
import { ActualYieldNumberPad } from '../single-view/ActualYieldNumberPad';
import LoadingButton from '../../../components/loading/LoadingButton';
import { MODAL_LIST } from '../../../typings/Modal';

interface IProps {
  handleActualYieldChange: NumberPadOnChangeFuncType;
  handleCompleteBatchActionButton?: () => Promise<void>;
  hideFinishBatchYieldModal: () => void;
  uom: EUom;
  initialValue: number;
}

interface IState {
  disableFinishButton: boolean;
}

export class ActualYieldModal extends React.Component<IProps, IState> {
  readonly state = {
    disableFinishButton: false,
  };

  public render() {
    // Using a Portal to force the modal to render in the root dom
    return (
      <Portal>
        <Modal
          title={MODAL_LIST.batchActualYield.title}
          footer={this.modalFooter()}
          actionClose={this.hideModal}
        >{this.modalContent()}</Modal>
      </Portal>
    );
  }

  private hideModal = () => {
    this.props.hideFinishBatchYieldModal();
  };

  private handleYieldChanges = (value: Decimal) => {
    if (value.lte(0)) {
      this.setState({ disableFinishButton: true });
    } else {
      this.props.handleActualYieldChange(value);
      this.setState({ disableFinishButton: false });
    }
  };

  // Chameleon error expected:
  // https://kounta.atlassian.net/browse/CHAM-32
  private modalContent = () => (
    <Group spacing={'8px'}>
      <Paragraph>{MODAL_LIST.batchActualYield.text}</Paragraph>
      <ActualYieldNumberPad
        handleActualYieldChange={this.handleYieldChanges}
        initialValue={this.props.initialValue}
        uom={this.props.uom}
      />
    </Group>
  );

  private modalFooter = () => (
    <LoadingButton
      fullWidth={true}
      primary={true}
      onClick={this.props.handleCompleteBatchActionButton}
      disabled={this.state.disableFinishButton}
      buttonText={EBatchFormButtonText.finish}
    />
  );
}
