import { EUom, IUomDropDownOption } from '../typings/Interface';
import { acceptableUnits } from './AcceptableUnits';
import {
  displayDropdownOptionsSet,
  EUomDisplay,
} from './Display';

export default class Uom {
  // fields:
  public fromUom: EUom;
  public acceptableUnits: EUom[];
  public displayFromUom: EUomDisplay;
  public displayDropdownOptionsSet: IUomDropDownOption[];

  //constructor:
  constructor(fromUom: EUom) {
    this.fromUom = fromUom;
    // If `fromUom` is invalid, `acceptableUnits()` will throw exception:
    this.acceptableUnits = acceptableUnits(fromUom);
    this.displayFromUom = EUomDisplay[fromUom];
    this.displayDropdownOptionsSet = displayDropdownOptionsSet(
      this.acceptableUnits
    );
  }
}
