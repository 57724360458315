import {
  EUom,
  IBatchIngredientsArgs,
  IFormikBatchIngredient,
  IFormikIngredient,
  IRecipeIngredientsArgs,
} from '../typings/Interface';

export const removeQuotesFromKeys = (origin: string): string => {
  // https://stackoverflow.com/questions/3651294/remove-quotes-from-keys-in-a-json-string-using-jquery
  return origin.replace(/"(\w+)"\s*:/g, '$1:');
};

export const removeQuotesFromEnumUom = (origin: string): string => {
  // more info on why we need it:
  // https://stackoverflow.com/questions/47704615/graphql-passing-an-enum-value-directly-to-a-mutation-as-an-argument

  let updateQuery = origin;

  for (const uom of Object.values(EUom)) {
    // https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string-in-javascript
    updateQuery = updateQuery.replace(
      new RegExp(`uom:"${uom}"`, 'g'),
      `uom:${uom}`
    );
  }
  return updateQuery;
};

export const purifyUomQuery = (
  ingredients: (IBatchIngredientsArgs | IRecipeIngredientsArgs)[]
): string =>
  removeQuotesFromEnumUom(removeQuotesFromKeys(JSON.stringify(ingredients)));

export const purifyIngredientsQuery = (
  formikIngredients: (IFormikIngredient | IFormikBatchIngredient)[]
): string => purifyUomQuery(castingQtyToFloat(formikIngredients));

export const castingQtyToFloat = (
  ingredients: (IFormikIngredient | IFormikBatchIngredient)[]
) => {
  return ingredients.map(
    (
      ing: IFormikIngredient | IFormikBatchIngredient
    ): IBatchIngredientsArgs | IRecipeIngredientsArgs => {
      return {
        ...ing,
        componentQty: parseFloat(ing.componentQty),
      };
    }
  );
};

export const replaceLineBreakToBr = (notes: string): string =>
  replaceString(notes, '\n', '<br>');

export const replaceBrToLineBreak = (notes: string): string =>
  replaceString(notes, '<br>', '\n');

export const isNotesSame = (
  notesUsingLineBreak: string | null,
  notesUsingBr: string | null
): boolean => {
  const lineBreakNotes = notesUsingLineBreak || '';
  const brNotes = notesUsingBr || '';
  if (lineBreakNotes === brNotes) {
    return true;
  }
  return lineBreakNotes === replaceBrToLineBreak(brNotes);
};

export const replaceString = (
  notes: string,
  from: string,
  to: string
): string => notes.replace(new RegExp(`${from}`, 'g'), to);

// For AppSync GraphQL `query GetProducts`, the query name is always capitalized
export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeSpace = (originText: string): string => {
  const stingCopy = originText;
  return replaceString(stingCopy, ' ', '');
};
