import { TextInput } from '@kounta/chameleon';
import React from 'react';
import Uom from '../../../uom/Uom';
import { EUom, IFormikBatch } from '../../../typings/Interface';
import { CUSTOM_BATCH_SECTION } from '../TextProvider';
import { FormikErrors } from 'formik';

interface IProps {
  batchFormExpectedYield: number;
  onBatchExpectedYieldChange: (numberInString: string) => void;
  isCustomBatchYield: boolean;
  yieldUom: EUom;
  errors: FormikErrors<IFormikBatch>;
  isCustomBatchSectionReadOnly: boolean;
}

export const BatchMakes = React.memo((
  {
    isCustomBatchYield,
    onBatchExpectedYieldChange,
    batchFormExpectedYield,
    yieldUom,
    errors,
    isCustomBatchSectionReadOnly,
  }: IProps
): JSX.Element => {
  let error = false;

  if (errors && errors.expectedYield ) {
    error = true;
  }

  const uomDisplay = new Uom(yieldUom).displayFromUom;

  // First obey `isCustomBatchSectionReadOnly`, then obey (!isCustomBatchYield):
  // The first one makes sure `InProgress Batch` does not allow yield update.
  // The second one makes sure `Original Recipe` won't allow yield update.
  const readOnly = isCustomBatchSectionReadOnly? true: (!isCustomBatchYield);
  return (
    <TextInput
      readOnly={readOnly}
      value={batchFormExpectedYield}
      align={'right'}
      suffix={uomDisplay}

      onChange={onBatchExpectedYieldChange}

      // This is <NumberInput> props:
      // maxFractionLength={6}

      // To disable `mouseScroll` number edit, we have to NOT restrict inputHtmlType
      // inputHtmlType='number'

      placeholder={CUSTOM_BATCH_SECTION.batchMakes.default.placeHolderQty}

      error={error}
    />);
});
