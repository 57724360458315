import React from 'react';
import {
  Heading3,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
} from "@kounta/chameleon";
import { BATCH_DETAIL } from '../TextProvider';
import { printQtyWithUom } from '../../../utils/batch/PrintQtyWithUom';
import { IApiBatchIngredient } from '../../../typings/Interface';
import { printCost } from '../../../utils/batch/PrintCost';
import { SectionPaddingLeft } from '../../../css/Styled';

interface IProps {
  batchIngredients: IApiBatchIngredient[];
}

const headers: TableHeaderType[] = [
  {
    id: '1',
    name: BATCH_DETAIL.ingredientsSection.labels.ingredientsUsed,
    sortable: false,
  },
  {
    id: '2',
    name: BATCH_DETAIL.ingredientsSection.labels.totalQty,
    sortable: false,
  },
  {
    id: '3',
    name: BATCH_DETAIL.ingredientsSection.labels.cost,
    sortable: false,
  },
];

export const IngredientsSection = React.memo(({ batchIngredients }: IProps) => {
    const ingredientRows = batchIngredients.map(
      (ing: IApiBatchIngredient, index: number): TableRowType => ({
        id: index,
        content:
          [
            {
              id: '1',
              value: ing.productName,
            },
            {
              id: '2',
              value: printQtyWithUom(ing.componentQty, ing.uom),
            },
            {
              id: '3',
              value: printCost(ing.totalCost),
            },
          ]
      })
    );
    return (
      <React.Fragment>
        <SectionPaddingLeft>
          <Heading3>{BATCH_DETAIL.ingredientsSection.title}</Heading3>
        </SectionPaddingLeft>
        <Spacer spacing={'medium'}/>
        <Table
          headers={headers}
          rows={ingredientRows}
          showHover={false}
          showRowIndicator={false}
        />
      </React.Fragment>
    )
  }
);
