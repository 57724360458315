import {
  IApiProduct,
} from '../../typings/Interface';
import { EProductInventoryTypeOrAll } from "../../TextProvider";

export const filterProducts = (
  products: IApiProduct[],
  ...predicates: ((product: IApiProduct) => boolean)[]
): IApiProduct[] => {
  return products.filter(product => predicates.every(p => p(product)))
}

export const productMatchingSearchText = (
  searchText: string,
) => (product: IApiProduct): boolean => {
  // Filter search bar input text:
  return product.name
    .toLowerCase()
    .indexOf(searchText.toLowerCase()) !== -1;
}

export const productMatchingInventoryType = (
  inventoryType: EProductInventoryTypeOrAll,
) => (product: IApiProduct): boolean => {
  if (inventoryType === 'All') {
    return true;
  }

  return product.inventoryType === inventoryType;
}
