import { Provider } from 'react-redux';
import ReduxConnector from './ReduxConnector';
import * as React from 'react';
import { ICookieStrings } from '../typings/Interface';
import { ENABLE_REDUX_DEVTOOLS } from '../config/release';
import { storeCreator } from '../redux/StoreCreator';

const store = storeCreator(ENABLE_REDUX_DEVTOOLS);

export const ReduxWrapper = React.memo((
  {
    cognitoObj,
  }: ICookieStrings
  ): JSX.Element => (
    <Provider store={store}>
      <ReduxConnector
        cognitoObj={cognitoObj}
      />
    </Provider>
  )
);
