import { Segment, SegmentOptionType } from "@kounta/chameleon";
import React from 'react';
import { CUSTOM_BATCH_SECTION } from '../TextProvider';

interface IProps {
  isCustomBatchYield: boolean;
  onChange: (selectedOption: SegmentOptionType) => void;
  disabled: boolean;
}

export enum EBatchMakeOption {
  origin = 'origin',
  custom = 'custom',
}

const SEGMENT_ITEMS: SegmentOptionType[] = [
  {
    id: EBatchMakeOption.origin,
    name: CUSTOM_BATCH_SECTION.buttons.originalRecipe,
  },
  {
    id: EBatchMakeOption.custom,
    name: CUSTOM_BATCH_SECTION.buttons.customBatch,
  },
];

export const CustomBatchToggle = React.memo((
  {
    isCustomBatchYield,
    onChange,
    disabled,
  }: IProps
): JSX.Element => {
  const value = isCustomBatchYield
    ? EBatchMakeOption.custom
    : EBatchMakeOption.origin;

// In Chameleon <Segment>, never use `defaultValue`, there are bugs related to
// that. <Segment> does not honor defaultValue when component update.
// May related to the its life cycle function `componentWillReceiveProps()`.
  return (
    <Segment
      options={SEGMENT_ITEMS}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />)
});
