import React from 'react';
import {
  EBatchStatus,
  ERouteBatchComponentType,
  IApiBatch,
  IApiProduct,
  IApiRecipe,
  ILayoutUiControlFuncs, IProduceLeftNavControl,
  Uuid,
} from '../typings/Interface';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom';
import {History} from '../pages/completed-batches/History';
import {
  ELoadingTitle,
  ERecipeFilterDropdownIds,
  ERecipesListSearchEntity,
  ERouterUrlParamKey,
  REACT_ROUTER_URL_LIST,
} from '../TextProvider';
import { IReduxStore } from '../redux/actions';
import Batch, {IProps as IBatchProps} from '../pages/incomplete-batches/Batch';
import BatchesList from '../pages/incomplete-batches/BatchesList';
import {BatchDetail} from '../pages/completed-batches/BatchDetail';
import RecipesList from '../pages/recipes/RecipesList';
import Recipe from '../pages/recipes/Recipe';
import {getRecipesQueryParamFromUrl, goToRecipesListPageWithQuery,} from '../utils/recipe/RecipesQueryParam';
import getRecipeFromRecipesListByProduct from '../utils/recipe/GetRecipeFromRecipesListByProduct';
import {convertApiRecipeToFormikRecipe} from '../validation/DoesRecipeHaveUnsavedChanges';
import {SearchResultEmpty} from '../pages/recipes/components/SearchResultEmpty';
import { IApiBillingPlan } from '../graphql/BillingPlanQL';
import { IOnAddOrReplaceList } from '../redux/typings/Interface';
import { CreateRecipe } from "../pages/recipes/CreateRecipe";
import { getProductsWithoutRecipe } from "../utils/product/GetProductsWithoutRecipe";
import { Loading } from "@kounta/chameleon";

interface IState {
  segmentBatchStatus: EBatchStatus;
}

interface IProps extends Omit<IReduxStore, 'siteInfo'>, ILayoutUiControlFuncs, IProduceLeftNavControl {
  onLoadingProducts: IOnAddOrReplaceList<IApiProduct>;
}

interface IBatchComponentGeneratorParams {
  routeSourceType: ERouteBatchComponentType;
  routeComponentProps: RouteComponentProps<{
    [ERouterUrlParamKey.batch]: Uuid;
  }>;
  selectedBatchUuid?: Uuid;
  headerButtonText: string;
}

export default class PagesSwitch extends React.Component<IProps, any> {
  readonly state: IState = {
    segmentBatchStatus: EBatchStatus.inProgress,
  };

  public render() {
    if (
      this.props.products === null ||
      this.props.batches === null ||
      this.props.recipes === null ||
      this.props.billingPlan === null
    ) {
      // If store is not ready, will end up here.
      return null;
    }

    // Below can safely assume `products`,`batches` and `recipes` are NOT null.
    // Hence methods in this Class can safely use type assertion like:
    // <RecipesList
    //    products={this.props.products as IApiProduct[]}
    //    recipes={this.props.recipes as IApiRecipe[]}
    // />
    return (
      <Switch>
        <Redirect
          exact
          from={REACT_ROUTER_URL_LIST.default}
          to={REACT_ROUTER_URL_LIST.recipes}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.recipes}
          component={this.renderAllRecipes}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.singleRecipe}
          component={this.renderSingleRecipe}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.createRecipe}
          component={this.renderCreateRecipe}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.incompleteBatches}
          component={this.renderIncompleteBatches}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.incompleteSingleBatch}
          component={this.renderIncompleteSingleBatch}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.createBatch}
          component={this.renderCreatingSingleBatch}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.completedBatches}
          component={this.renderHistory}
        />
        <Route
          exact
          path={REACT_ROUTER_URL_LIST.completedSingleBatch}
          component={this.renderCompleteSingleBatch}
        />
        {/* when none of the above match, <NoMatch> will be rendered */}
        <Route component={NoMatch}/>
      </Switch>
    );
  }

  /**
   * Using <Connect> from amplify is throwing errors:
   * Cannot update during an existing state transition
   * (such as within render or another component's constructor).
   *
   * NOTE: all `<Route component={}>` will pass RouteComponentProps, please
   * check: https://reacttraining.com/react-router/web/api/Route/route-props
   */
  private renderAllRecipes = (
    routeComponentProps: RouteComponentProps
  ): JSX.Element | null => {
    const { searchTextFromUrl, filterCriteria, isInvalidQuery, entity } = getRecipesQueryParamFromUrl(
      routeComponentProps,
      REACT_ROUTER_URL_LIST.recipes,
    );
    if (isInvalidQuery) {
      return null;
    }

    // React Router may not support `async` component. It may be easier/cleaner
    // to load redux store inside the component itself.
    //
    // We cannot call below update here as it will call the GraphQl over and
    // over, Looks like the react-router has some loop:
    // this.getOrUpdateProductsAndRecipesInRedux();
    return (
      <RecipesList
        routeHistoryPush={routeComponentProps.history.push}
        products={this.props.products as IApiProduct[]}

        currentFilterCriteria={filterCriteria as ERecipeFilterDropdownIds}
        currentSearchEntity={entity as ERecipesListSearchEntity}

        // pre-existing searchText from URL:
        searchTextFromUrl={searchTextFromUrl}

        // Redux Lists
        recipes={this.props.recipes as IApiRecipe[]}

        // Redux flag:
        isProductsFullyLoaded={this.props.isProductsFullyLoaded}
        hasEditRecipePermission={this.props.staff.hasEditRecipePermission}

        // UI controls:
        setLoading={this.props.setLoading}
        handleModal={this.props.handleModal}
        showNav={this.props.showNav}
      />
    );
  };

  private renderSingleRecipe = (
    routeComponentProps: RouteComponentProps<{
      [ERouterUrlParamKey.recipe]: Uuid;
    }>
  ): JSX.Element | null => {
    // `this.props.products` will not be NULL here, because of the check at:
    // `public render() `
    // However, it can be empty array `[]`.
    // In that case, we should just show EmptyState component.
    if ((this.props.products as IApiProduct[]).length === 0) {
      return <SearchResultEmpty />;
    }

    if (!this.props.isRecipesFullyLoaded) {
      return  (
        <Loading
          isLoading
          title={ELoadingTitle.loading}
        />
      );
    }

    const { searchTextFromUrl, filterCriteria, isInvalidQuery, entity } = getRecipesQueryParamFromUrl(
      routeComponentProps,
      REACT_ROUTER_URL_LIST.recipes,
    );
    if (isInvalidQuery) {
      return null;
    }

    const productUuidFromUrl =
      routeComponentProps.match.params[ERouterUrlParamKey.recipe];

    const recipeByUrlProductUuid = getRecipeFromRecipesListByProduct(
      productUuidFromUrl,
      this.props.recipes
    );

    // Check if the recipe already exist in Produce DB with the URL product_uuid
    const formikRecipeByUrlProductUuid = recipeByUrlProductUuid
      ? convertApiRecipeToFormikRecipe(recipeByUrlProductUuid)
      : false;

    /**
     * If you’ve got a full URL that you’d like to parse query params from,
     * you can use location.search to pull those out:
     * // Working with the current URL
     * // URL: buy-shirts-here.com/filter?size=M&colour=red&sleeves=short
     * location.search //=> ?size=M&colour=red&sleeves=short
     *
     * https://javascriptplayground.com/url-search-params/
     */
    return <Recipe
      formikRecipeByUrlProductUuid={formikRecipeByUrlProductUuid}
      productUuidFromUrl={productUuidFromUrl}
      goToRecipesListPageWithQuery={goToRecipesListPageWithQuery(
        routeComponentProps.history.push,
        filterCriteria as ERecipeFilterDropdownIds,
        searchTextFromUrl,
        entity as ERecipesListSearchEntity,
      )}

      // Redux states:
      products={this.props.products as IApiProduct[]}
      recipes={this.props.recipes as IApiRecipe[]}
      isProductsFullyLoaded={this.props.isProductsFullyLoaded}
      hasEditRecipePermission={this.props.staff.hasEditRecipePermission}

      onLoadingProducts={this.props.onLoadingProducts}

      // UI controls:
      handleModal={this.props.handleModal}
      setLoading={this.props.setLoading}

      // Site plan restriction:
      billingPlan={this.props.billingPlan as IApiBillingPlan}
    />
  };

  private renderCreateRecipe = (
    routeComponentProps: RouteComponentProps
  ): JSX.Element | null => {
    const { searchTextFromUrl, filterCriteria, isInvalidQuery, entity } = getRecipesQueryParamFromUrl(
      routeComponentProps,
      REACT_ROUTER_URL_LIST.recipes,
    );
    if (isInvalidQuery) {
      return null;
    }

    const productsWithOutRecipe: IApiProduct[] = getProductsWithoutRecipe(
      this.props.products as IApiProduct[],
      this.props.recipes as IApiRecipe[]
    )

    return <CreateRecipe
      routeHistoryPush={routeComponentProps.history.push}
      productsWithOutRecipe={productsWithOutRecipe}
      goToRecipesListPageWithQuery={goToRecipesListPageWithQuery(
        routeComponentProps.history.push,
        filterCriteria as ERecipeFilterDropdownIds,
        searchTextFromUrl,
        entity as ERecipesListSearchEntity,
      )}
    />
  }

  private renderHistory = (
    routeComponentProps: RouteComponentProps
  ): JSX.Element => (
    <History
      routeComponentProps={routeComponentProps}
      batches={this.props.batches as IApiBatch[]}
      remainingBatchAllowance={this.props.remainingBatchAllowance as number}
      billingPlan={this.props.billingPlan as IApiBillingPlan}

      showNav={this.props.showNav}
    />
  );

  private renderCompleteSingleBatch = (
    routeComponentProps: RouteComponentProps<{
      [ERouterUrlParamKey.batch]: Uuid;
    }>
  ): JSX.Element => (
    <BatchDetail
      batches={this.props.batches as IApiBatch[]}
      routeComponentProps={routeComponentProps}
      remainingBatchAllowance={this.props.remainingBatchAllowance as number}
      billingPlan={this.props.billingPlan as IApiBillingPlan}
    />
  );

  // -------- Start of single batch view ---------- :
  private renderIncompleteSingleBatch = (
    routeComponentProps: RouteComponentProps<{
      [ERouterUrlParamKey.batch]: Uuid;
    }>
  ): JSX.Element => {

    const uuid = routeComponentProps.match.params[
      ERouterUrlParamKey.batch
      ];

    const props = this.generateBatchComponentProps({
      routeComponentProps,
      routeSourceType: ERouteBatchComponentType.INCOMPLETE,
      selectedBatchUuid: uuid,
      headerButtonText: 'Back',
    });

    return <Batch {...props}/>
  };

  private renderCreatingSingleBatch = (
    routeComponentProps: RouteComponentProps<{
      [ERouterUrlParamKey.batch]: Uuid;
    }>
  ): JSX.Element => {
    const props = this.generateBatchComponentProps({
      routeSourceType: ERouteBatchComponentType.CREATING,
      routeComponentProps,
      headerButtonText: 'Cancel',
    });

    return <Batch {...props} />
  };

  private generateBatchComponentProps = (
    params: IBatchComponentGeneratorParams,
  ): IBatchProps => (
    {
      products: this.props.products as IApiProduct[],
      recipes: this.props.recipes as IApiRecipe[],
      batches: this.props.batches as IApiBatch[],
      isProductsFullyLoaded: this.props.isProductsFullyLoaded,
      handleBatchStatusChange: this.handleBatchStatusChange,
      handleModal: this.props.handleModal,
      setLoading: this.props.setLoading,

      remainingBatchAllowance: this.props.remainingBatchAllowance as number,
      billingPlan: this.props.billingPlan as IApiBillingPlan,

      ...params,
    }
  );


  private handleBatchStatusChange = (batchStatus: { id: EBatchStatus }) => {
    // Don't change status tab which causes re-render unless it's different.
    if (batchStatus.id !== this.state.segmentBatchStatus) {
      this.setState({
        segmentBatchStatus: batchStatus.id,
      });
    }
  };
  // --------- End of single batch view ----------

  // -------- start batch list view -------
  private renderIncompleteBatches = (
    routeComponentProps: RouteComponentProps<{
       [ERouterUrlParamKey.batch]: Uuid;
    }>
  ): JSX.Element => (
    <BatchesList
      routeComponentProps={routeComponentProps}
      products={this.props.products as IApiProduct[]}
      recipes={this.props.recipes as IApiRecipe[]}
      batches={this.props.batches as IApiBatch[]}
      // UI controls:
      handleModal={this.props.handleModal}
      hideSnackBar={this.props.hideSnackBar}
      setLoading={this.props.setLoading}
      // segmentBatchStatus switcher:
      segmentBatchStatus={this.state.segmentBatchStatus}
      handleBatchStatusChange={this.handleBatchStatusChange}
      // Site plan batch restriction
      remainingBatchAllowance={this.props.remainingBatchAllowance as number}
      billingPlan={this.props.billingPlan as IApiBillingPlan}

      showNav={this.props.showNav}
    />
  );
}

const NoMatch = (): JSX.Element => (
  <h2> 404 Path Not found</h2>
);
