import React, { useEffect, useState } from 'react';
import { getInitials } from '@kounta/chameleon';
import {
  EBackofficeLinks,
  ELabelSiteSwitchMenuItem,
  LEFT_NAV_TITLE,
  PAGE_TOP_BAR_MENU_OPTIONS,
  REACT_ROUTER_URL_LIST,
} from '../TextProvider';
import { ISiteInfo, IApiSubscribedSiteInfo } from '../typings/Interface';
import { SiteList } from '../components/site-switcher/SiteList';
import { deleteAllCookies, ECookieValue } from '../persister/Cookie';
import {
  pageReload,
  redirectToBackOfficeProductsPage,
  redirectToBakOfficePage,
  redirectToFeedbackForum,
} from '../utils/url/UrlRedirect';
import { ISecondaryMenu } from '../typings/Chameleon';
import Auth from '@aws-amplify/auth';
import ProduceHelpPanel from "../components/help-panel/ProduceHelpPanel";
import { ProduceLeftNav } from "../components/produce-nav/ProduceLeftNav";
import { findMatchingUrlPath } from '../utils/url/FindMatchingUrlPath';
import { IReduxStaff } from '../redux/reducers/StaffReducer';
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProps extends RouteComponentProps {
  siteInfo: ISiteInfo;
  staff: IReduxStaff;
  onClickLogo: () => void;
  showNavYn: boolean;
  navHandler: (showNav: boolean) => void;
}

export const COLLAPSE_URL_LIST: string[] = [
  REACT_ROUTER_URL_LIST.recipes,
  REACT_ROUTER_URL_LIST.incompleteBatches,
  REACT_ROUTER_URL_LIST.completedBatches,
];

export const shouldExpandNavBar = (pathName: string): boolean => {
  const matchingPath = findMatchingUrlPath(pathName);
  return COLLAPSE_URL_LIST.includes(matchingPath);
};

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md
export const LeftNavPanel = withRouter(
  (
    {
      location,
      siteInfo,
      staff,
      onClickLogo,
      showNavYn,
      navHandler,
    }: IProps) => {
    const [showSitesYn, setShowSites] = useState(false);
    const { siteName, companyName } = siteInfo;

    const [expandedYn, setExpandedYn] = useState(true);

    const [showHelpPanelYn, setHelpPanelYn] = useState(false);

    useEffect(() => {
      // Always use `props.location` over `history.location`:
      // https://reacttraining.com/react-router/web/api/history/history-is-mutable
      setExpandedYn(shouldExpandNavBar(location.pathname));
    }, [location.pathname]);

    const secondaryMenu: ISecondaryMenu = {
      contexts: [
        {
          label: siteName,
          description: companyName,
          shortLabel: getInitials(siteName),
          onClick: showSecondaryMenu,
          isPerson: false,
        },
      ],
      items: [
        {
          label: ELabelSiteSwitchMenuItem.backOfficeProducts,
          onClick: redirectToBackOfficeProductsPage,
        },
        {
          label: ELabelSiteSwitchMenuItem.feedBackForum,
          onClick: redirectToFeedbackForum,
        },
        {
          label: ELabelSiteSwitchMenuItem.refreshPage,
          onClick: pageReload,
        },
        {
          label: ELabelSiteSwitchMenuItem.logout,
          onClick: logoutHandler,
        },
      ],
    };

    const closeHelpPanel = () => {
      setHelpPanelYn(false);
    };

    const showHelpPanel = () => {
      setHelpPanelYn(true);
    };

    return (
      <React.Fragment>
        {
          showHelpPanelYn && (
            <ProduceHelpPanel
              staff={staff}
              siteInfo={siteInfo}
              handleClose={closeHelpPanel}
              routerLocationPathName={location.pathname}
            />
          )
        }
        {showSitesYn && (
          <SiteList
            onSelect={onSiteChange}
            onClose={closeSecondaryMenu}
          />
        )}
        {!showSitesYn &&
        <ProduceLeftNav
            useRouter
            onClickLogo={onClickLogo}
            showHelp={true}
            onClickHelp={showHelpPanel}
            expanded={expandedYn}
            showToggle={expandedYn}
            title={LEFT_NAV_TITLE}
            items={PAGE_TOP_BAR_MENU_OPTIONS}
            contextLabel={expandedYn && siteName}
            shortLabel={getInitials(siteName)}
            contextDescription={staff.email}
            secondaryMenu={secondaryMenu}
            showNavYn={showNavYn}
            navHandler={navHandler}
        />}
      </React.Fragment>
    );

    function onSiteChange(site: IApiSubscribedSiteInfo) {
      closeSecondaryMenu();
      // go to back office to switch switch site;
      // after switching, back office will redirect back to produce;
      if (site.siteUuid === siteInfo.siteUuid) {
        // don't switch if both sites are the same
        return;
      }
      redirectToBakOfficePage(EBackofficeLinks.siteSwitchPage, site.siteUuid);
    }

    function closeSecondaryMenu() {
      setShowSites(false);
    }

    function showSecondaryMenu() {
      setShowSites(true);
    }

    async function logoutHandler() {
      await Auth.signOut();
      deleteAllCookies(ECookieValue.domain, ECookieValue.path);
      redirectToBakOfficePage(EBackofficeLinks.logoutPage);
    }
  }
);
