import { IApiProduct } from '../../typings/Interface';
import {
  EReduxActionType,
  IActionAddPartialProducts,
  IActionLoadProducts,
} from '../actions';
import produce from 'immer';

export const initialProducts = null;

export default function productsReducer(
  previousStateProducts: IApiProduct[] | null = initialProducts,
  action: IActionLoadProducts | IActionAddPartialProducts,
): IApiProduct[] | null {
  return produce(previousStateProducts, (draft: IApiProduct[] | null) => {
    switch (action.type) {
      case EReduxActionType.LOAD_PRODUCTS:
        return action.products;
      case EReduxActionType.ADD_PARTIAL_PRODUCTS:
        if (draft === initialProducts) {
          // null is not iterable, cannot use `...draft`;
          return action.products;
        }
        return [
          ...draft,
          ...action.products,
        ];
      default:
        return previousStateProducts;
    }
  });
}
