import {
  EBatchStatus, EModalType, ESnackBarType,
  IFormikBatch,
  ILayoutUiControlFuncs
} from '../../typings/Interface';
import { RouteComponentProps } from 'react-router';
import { completeBatch } from '../../graphql/BatchQL';
import { ESnackBarMessage } from '../../TextProvider';

export const handleCompleteBatchActionButton = (
  formValue: IFormikBatch,
  getToggleActualYieldModalFunction: (
    shouldShowActualYieldModal: boolean
  ) => (() => void),
  handleBatchStatusChange: (productionType: { id: EBatchStatus }) => void,
  handleModal: ILayoutUiControlFuncs['handleModal'],
  routerGoBack: RouteComponentProps['history']['goBack'],
) => {
  return async (): Promise<void> => {
    if (
      formValue.status !== EBatchStatus.inProgress ||
      formValue.actualYield === null
    ) {
      throw new Error(`Invalid Batch complete`);
    }

    const completeResult = await completeBatch(
      formValue.uuid,
      formValue.actualYield
    );

    if (completeResult.succeed) {
      const response = completeResult.responseObj;
      const completedBatchInfo = {
        batchUuid: response.uuid,
        productName: response.productName,
        actualYield: response.actualYield as number,
        uom: response.uom,
      };
      getToggleActualYieldModalFunction(false)();
      handleBatchStatusChange({ id: 1 });
      routerGoBack();
      handleModal({
        completedBatchInfo: completedBatchInfo,
        modalType: EModalType.SHEET,
      });
    } else {
      handleModal({
        snackBarMessage: ESnackBarMessage.FINISH_BATCH_FAILED,
        snackBarType: ESnackBarType.error,
        modalType: EModalType.SNACK_BAR,
      });
    }
  };
};
