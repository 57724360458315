import {
  EUom,
  IApiProduct,
} from "../../typings/Interface";
import { EUomCategory } from "../../uom/AcceptableUnits";
import { updateProduct } from "../../graphql/ProductQL";

export interface IUpdateProductAttributes {
  uom: EUom;
  measure: string;
  category: EUomCategory;
};

export const DEFAULT_MEASURE = 1;

export const updateProductApiAdaptor = async (product: IApiProduct, productInfos: IUpdateProductAttributes):
  Promise<IApiProduct> => {
    const measure = productInfos.uom === EUom.unit
      ? DEFAULT_MEASURE
      : parseFloat(productInfos.measure);
    
    const result = await updateProduct(product, productInfos.uom, measure);
    
    if (result.succeed) {
      return {
        ...product,
        uom: productInfos.uom,
        measure: measure,
      }
    }
    
    throw Error(result.errorMsg);
};
