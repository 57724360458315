import * as React from 'react';
import { Alert } from '@kounta/chameleon';
import { ALERT_LIST } from '../../TextProvider';
import { IAlertDetail } from '../../typings/Interface';
import styled from "styled-components";

interface IProps {
  alertTopic: string;
  onCloseCallBack: () => void;
  alertMessage: string;
}

// Copied from SMan:
// https://github.com/lightspeed-hospitality/inventory-stock-management/blob/21d41ece3ad093ad3b06a8fbf5e09ce56e9aa960/projects/frontend/src/components/Notifications/Notifications.style.tsx
export const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2500;
`;

export const AlertWrapper = React.memo((
  {
    alertTopic,
    onCloseCallBack,
    alertMessage,
  }: IProps
): JSX.Element => {
  const alertDetail: IAlertDetail = ALERT_LIST[alertTopic];
  return (
      <AlertContainer>
        <Alert
          alertType={alertDetail.alertType}
          alertMessage={alertMessage}
          alertTime={alertDetail.alertTime}
          autoClose={true}
          onClose={onCloseCallBack}
        />
      </AlertContainer>
  );
});
