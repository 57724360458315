import * as React from 'react';
import {EBatchStatus, ERouteBatchComponentType, IApiBatch, IFormikBatch, ILayoutUiControlFuncs, Uuid,} from '../../typings/Interface';
import { ERouterUrlParamKey, GO_BACK_TO_INVENTORY_DASH, INVENTORY_DASH_ORIGIN } from '../../TextProvider';
import {RouteComponentProps} from 'react-router-dom';
import {IReduxStoreListsAfterInitialised} from '../../redux/actions';
import {convertApiBatchToFormikBatch} from '../../validation/DoesBatchHaveUnsavedChanges';
import BatchForm from './BatchForm';
import {getBatchFromListByUuid} from '../../utils/batch/GetBatchFromList';
import {convertBatchToHaveValidIngredients} from '../../formik/RemoveDeletedProductsFromIngredients';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { batchesQueryParams } from "../../utils/batch/BatchesQueryParams";

export interface IProps extends IReduxStoreListsAfterInitialised {
  // Redux flag:
  isProductsFullyLoaded: boolean;

  handleBatchStatusChange: (productionType: { id: EBatchStatus }) => void;

  handleModal: ILayoutUiControlFuncs['handleModal'];
  setLoading: ILayoutUiControlFuncs['setLoading'];

  routeComponentProps: RouteComponentProps<{
    [ERouterUrlParamKey.batch]: Uuid;
  }>;
  selectedBatchUuid?: Uuid;
  routeSourceType: ERouteBatchComponentType;

  // Batch restriction based on the site plan
  remainingBatchAllowance: number;
  billingPlan: IApiBillingPlan;
  headerButtonText: string;
}

interface IState {
  /**
   * This `existingBatch` always shows the last saved (unless `false`) batch.
   * A successfully `saveBatch` or `createBatch` event will update this field.
   * We can use this as the base line when doing custom batch calculation.
   */
  existingBatch: IFormikBatch | false;
}

export default class Batch extends React.Component<IProps, IState> {
  readonly state: IState;

  public constructor (props: IProps) {
    super(props);

    if (this.props.routeSourceType === ERouteBatchComponentType.INCOMPLETE) {
      const currentBatchInfo = findExistingBatch(
        this.props.selectedBatchUuid as Uuid,
        this.props.batches,
      );

      this.state = {
        existingBatch: this.validateExistBatchAndPrefillYield(currentBatchInfo),
      }
    } else {
      this.state = { existingBatch: false };
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.routeSourceType === ERouteBatchComponentType.CREATING) return;
    // TODO: This is a workaround for lazying loading, this file will be rewritten in:
    // https://kounta.atlassian.net/browse/PRO-415
    if (prevProps.products.length !== this.props.products.length) {
      const batchInfo = findExistingBatch(
        this.props.selectedBatchUuid as Uuid,
        this.props.batches,
      );
      this.updateExistingBatch(batchInfo);
    }
  }

  public render() {
    if (
      this.props.routeSourceType !== ERouteBatchComponentType.CREATING &&
      !this.state.existingBatch
    ) {
      // If it goes into here, it means this is an existing batch (URL has UUID)
      // , however the batch detail is not fetched from API yet.
      return null;
    }

    const { originFrom } = batchesQueryParams(this.props.routeComponentProps);
    const buttonText = originFrom === INVENTORY_DASH_ORIGIN ? GO_BACK_TO_INVENTORY_DASH : this.props.headerButtonText;

    return (
      <BatchForm
        routeComponentProps={this.props.routeComponentProps}
        existingBatch={this.state.existingBatch}
        recipes={this.props.recipes}
        products={this.props.products}
        // existing batch:
        updateExistingBatch={this.updateExistingBatch}
        // Pre-set the chameleon segmentBatchStatus
        handleBatchStatusChange={this.props.handleBatchStatusChange}
        // UI controls for SnackBar:
        handleModal={this.props.handleModal}
        setLoading={this.props.setLoading}
        // Redux flag:
        isProductsFullyLoaded={this.props.isProductsFullyLoaded}
        // Batch Restriction based on the site plan
        billingPlan={this.props.billingPlan}
        remainingBatchAllowance={this.props.remainingBatchAllowance}
        originFrom={originFrom}
        headerButtonText={buttonText}
      />
    );
  }

  public updateExistingBatch = (existingBatch: IApiBatch): void => {
    this.setState({
      existingBatch: this.validateExistBatchAndPrefillYield(existingBatch),
    });
  };

  private validateExistBatchAndPrefillYield(existingBatch: IApiBatch): IFormikBatch | false {
    const result: IFormikBatch | false = existingBatch
      ? convertBatchToHaveValidIngredients(
        convertApiBatchToFormikBatch(existingBatch),
        this.props.products
      )
      : false;

    // PRO-622: Pre-populate actual yield field as expected yield
    if (result) {
      return { ...result, actualYield: result.expectedYield }
    }

    return result;
  }
}

export const findExistingBatch = (uuid: Uuid, batches: IApiBatch[]): IApiBatch=> {
  const result = getBatchFromListByUuid(uuid, batches);
  if (!result) throw new Error(`Invalid BatchUuid from URL`);
  return result;
}
