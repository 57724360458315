import React from 'react';
import { LeftNav, LeftNavMenuPropsType } from "@kounta/chameleon";
import Media from "react-media";
import { MobileLeftNav } from "./MobileLeftNav";
import { MEDIA_QUERY_BREAK_POINT } from '../../css/Styled';

/**
 * reference from
 * https://github.com/kounta/chameleon/blob/dev/src/components/LeftNav/LeftNav.js
 */
export interface ILeftNavProps extends LeftNavMenuPropsType {
  allowMultipleExpanded?: boolean;
  navHandler: (navState: boolean) => void;
  showNavYn: boolean;
}

export const ProduceLeftNav = React.memo((props: ILeftNavProps ) => {

  return (
    <Media query={MEDIA_QUERY_BREAK_POINT}>
      {(matches): JSX.Element | null => {
        if (matches) {
          if (!props.showNavYn) {
            return null;
          }

          return (<MobileLeftNav {...props} />);

        } else {
          if (!props.showNavYn) {
            props.navHandler(true)
          }

          return (<LeftNav {...props} />);
        }
      }}
    </Media>
  );
});
