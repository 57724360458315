import { AWS_CONFIG } from '../config/release';
import {
  IAuthResult,
  ICognitoTokenObject,
} from '../typings/Interface';
import moment from 'moment';
import { FederatedResponse, FederatedUser } from '@aws-amplify/auth/lib/types';
import { ICredentials } from '@aws-amplify/core/lib/types/types';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import PubSub from '@aws-amplify/pubsub';

const AWS_COGNITO_CUSTOM_DEVELOPER_PROVIDER = 'developer';

export const verifyCognitoTokenThenSignIn = async (
  cognitoObj: ICognitoTokenObject,
): Promise<IAuthResult> => {
  if (
    !cognitoObj ||
    !cognitoObj['@metadata'] ||
    !cognitoObj['@metadata'].headers ||
    !cognitoObj['@metadata'].headers.date
  ) {
    return {
      authSucceed: false,
    };
  }

  const succeedYn = await signIn(cognitoObj);

  if (succeedYn === false) {
    return {
      authSucceed: false,
    };
  }
  return {
    authSucceed: true,
  };
};

export const setFederatedResponse = (
  cognitoObj: ICognitoTokenObject
): FederatedResponse => {
  const tokenAssignedDateString = cognitoObj['@metadata'].headers.date;

  // We need to replace `+` with space in below string:
  //          Mon,+21+Jan+2019+01:03:26+GMT
  // This is to follow the moment acceptable format, check
  // `The RFC 2822 date time format` in below page:
  // http://momentjs.com/docs/#/parsing/string/
  // https://gist.github.com/AshKyd/8843581
  const validDateTimeFormattedString = tokenAssignedDateString.replace(
    new RegExp('\\+', 'g'),
    ' '
  );

  const tokenAssignedMoment = moment(validDateTimeFormattedString);
  const tokenAssignedDateInUnixTimeStampInMilliseconds = tokenAssignedMoment.valueOf();

  // server validates the user is authorized
  // the server calls getOpenIdTokenForDeveloperIdentity()
  // and sends the response back to the client.
  return {
    // The AWS Cognito token is created by backoffice:
    //    addons/cognito/lib/CognitoClient.php
    // It has 1 day Max expire:
    // https://docs.aws.amazon.com/cognitoidentity/latest/APIReference/API_GetOpenIdTokenForDeveloperIdentity.html
    // Hence we should also assign `FederatedResponse` to have the same 1 day
    // max expire. Be aware it is in Milliseconds UTC time: Ref:
    // https://github.com/aws-amplify/amplify-js/issues/2529
    expires_at:
      24 * 3600 * 1000 + tokenAssignedDateInUnixTimeStampInMilliseconds,
    identity_id: cognitoObj.IdentityId,
    token: cognitoObj.Token,
  };
};

export const setFederatedUser = (name: string): FederatedUser => {
  return {
    name,
  };
};

export const federatedSignInWrapper = async (
  cognitoObj: ICognitoTokenObject,
): Promise<ICredentials | false> => {
  const devAuthToken = setFederatedResponse(cognitoObj);

  const federatedUser = setFederatedUser(cognitoObj.IdentityId);

  try {
    // There is aws temp key inside
    // https://docs.amplify.aws/lib/auth/advanced/q/platform/js#identity-pool-federation
    const credentials = await Auth.federatedSignIn(
      AWS_COGNITO_CUSTOM_DEVELOPER_PROVIDER,
      devAuthToken,
      federatedUser,
    );
    // console.log('Printing Credentials:');
    // console.log(credentials);
    return credentials;
  } catch (e) {
    // console.log('captured exception Auth.federatedSignIn()');
    // console.log(e);
    return false;
  }
}

export const signIn = async (
  cognitoObj: ICognitoTokenObject,
): Promise<false | ICredentials> => {
  API.configure(AWS_CONFIG);
  Auth.configure(AWS_CONFIG);
  PubSub.configure(AWS_CONFIG);

  // Optionally add Debug Logging
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';

  // Before signIn, make sure there is no existing user Auth session:
  try {
    await Auth.signOut();
  } catch (e) {
    // console.log(e);
    // console.log('error when signOut()');
    return false;
  }

  let loginResult: false | ICredentials = false;
  try {
    loginResult = await federatedSignInWrapper(cognitoObj);
    // console.log('loginResult:', loginResult);
  } catch (e) {
    // console.log('captured exception federatedSignInWrapper()');
    // console.log(e);
    return false;
  }

  if (loginResult === false) {
    return false;
  }

  try {
    // AWS credentials are usually loaded lazily. As a result, you'll want to
    // `activate` it by explicitly calling `currentCredentials()`:
    return await Auth.currentCredentials();
  } catch (e) {
    // console.log('captured exception Auth.currentCredentials()');
    // console.log(e);
    return false;
  }
}
