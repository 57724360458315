import * as React from 'react';
import { theme } from '@kounta/chameleon';
import { ThemeProvider } from 'styled-components';
import {
  ICognitoTokenObject,
  ICookieStrings,
} from '../typings/Interface';
import { verifyCognitoTokenThenSignIn } from '../auth/Cognito';
import { useEffect, useState } from 'react';
import { getCookieObjFromString } from '../persister/Cookie';
import { ReduxWrapper } from './ReduxWrapper';
import {
  redirectToBackOfficeProduceLoginPage,
} from '../utils/url/UrlRedirect';

export const setAuthSucceedState = async (
  setAuthSucceedFunction: (authIsSucceed: boolean) => void,
  cognitoObj: ICognitoTokenObject
) => {
  // Attention: Make sure the SignIn won't triggered multiple times,
  // we have provided `useEffect()` with second argument [userInfo, siteInfo,
  // cognitoObj] to ensure it only get called when any of the three variables
  // changed.
  const authResult = await verifyCognitoTokenThenSignIn(cognitoObj);

  if (!authResult.authSucceed) {
    setAuthSucceedFunction(false);
    redirectToBackOfficeProduceLoginPage();
  } else {
    setAuthSucceedFunction(true);
  }
};

export const Main = React.memo((
  {
    cognitoObj,
  }: ICookieStrings
): JSX.Element => {
  const [authSucceed, setAuthSucceed] = useState<boolean>(false);

  // https://www.robinwieruch.de/react-hooks-fetch-data/
  // Consider using `Suspense`:
  // https://reactjs.org/docs/code-splitting.html#reactlazy
  /**
   * Attention: Make sure the SignIn won't trigger multiple times,
   * we have provided `useEffect()` with second argument [userInfo, siteInfo,
   * cognitoObj] to ensure it only get called when any of the three variables
   * changed.
   *
   * Second arguments are used here: [userInfo, siteInfo, cognitoObj]
   * to skip the effects when cookie strings doesn't change
   *
   * More read:
   * https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
   */
  useEffect(() => {
    setAuthSucceedState(
      setAuthSucceed,
      getCookieObjFromString(cognitoObj) as ICognitoTokenObject
    );
  }, [cognitoObj]);

  return (
    <ThemeProvider theme={theme}>
      {authSucceed && (
        // Redux only initialises if Auth is passed:
        <ReduxWrapper
          cognitoObj={cognitoObj}
        />
      )}
    </ThemeProvider>
  );
});
