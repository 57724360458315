import { IApiHandlerResult, Uuid } from './Interface';
import { EProductInventoryType } from '../graphql/ProductQL';

// PRO-606: Reduce page size
export const PAGINATION_LIMIT = 500;

/**
 * Reference: backend/appsync/src/pagination/Interface.ts
 */

// https://slack.engineering/evolving-api-pagination-at-slack-1c1f644f8e12
export interface IPaginationEncodedRequest {
  // Using a base64 encoded string to support different cursor object.
  // Cursor is optional. For the initial fetch, there won't be a cursor.
  cursor?: string;
  limit: number;
}

export interface IPaginationEncodedFeedback {
  // Using a base64 encoded string to support different cursor object
  newCursor?: string;
  // Indicate if there are further data
  hasNextPage: boolean;
}

// The Response object to indicate the nextCursor and show if it has nextPage.
export interface IPaginationResponse<T> {
  // Following the naming convention from official doc:
  // https://graphql.org/learn/pagination/
  edges: T[];
  pageInfo: IPaginationEncodedFeedback;
}

export interface IGraphQlRequestArgument {
  base64EncodedCursor?: string;
}

export interface IGraphQlGetProductsArgument extends IGraphQlRequestArgument {
  productUuids?: Uuid[];
  inventoryType?: EProductInventoryType;
}

// To write function signature with generic types:
// https://www.typescriptlang.org/docs/handbook/generics.html#generic-types
export interface IGraphQlPagingRequestFunction<T, U extends IGraphQlGetProductsArgument> {
  (
    { base64EncodedCursor, productUuids, inventoryType }: U
  ): Promise<IApiHandlerResult<IPaginationResponse<T>>>;
}
