import { IBatchFormikProps } from '../../typings/Formik';
import { IApiRecipe, IFormikBatchIngredient } from '../../typings/Interface';
import { EBatchMakeOption } from '../../pages/incomplete-batches/single-view/CustomBatchToggle';
import { SegmentOptionType } from '@kounta/chameleon';

export const handleCustomBatchToggle = (
  setFieldValue: IBatchFormikProps["setFieldValue"],
  selectedRecipe: IApiRecipe,
  benchmarkBatchIngs: IFormikBatchIngredient[]
) => {
  return (selectedOption: SegmentOptionType) => {
    if (selectedOption.id === EBatchMakeOption.origin) {
      // If `selectedRecipe===false`, the segment will be `disabled`, won't
      // allow edit/click, hence `onCustomBatchToggle()` won't be called,
      // so it is safe to assume  `selectedRecipe as IApiRecipe` and
      // `benchmarkBatchIngs` is not empty:
      setFieldValue('isCustomYield', false);

      // Validation only happens when submit the form, not earlier, hence
      // we provide third argument of `setFieldValue()` to false:
      setFieldValue('expectedYield',
        selectedRecipe.expectedYield, false);

      setFieldValue('batchIngredients', benchmarkBatchIngs, false);
    } else {
      // User just click `original recipe` button to `custom Batch`.
      // Custom batch will ALWAYS use the Recipe's expectedYield as Initial
      // value. Only need to update `isCustomYield` flag, all other fields
      // including batchIngredients Qty will just inherited from `Recipe`:
      setFieldValue('isCustomYield', true);
    }
  };
};
