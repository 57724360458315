import React from 'react';
import {
  AppHeader,
  AutoSizer,
  Heading, Spacer,
} from '@kounta/chameleon';
import {
  IApiBatch,
  EBatchFormHeaderTitle, Uuid,
} from '../../typings/Interface';
import {
  EReadOnlyNotesPlaceHolder,
  ERouterUrlParamKey,
  REACT_ROUTER_URL_LIST,
} from '../../TextProvider';
import { getBatchFromListByUuid } from '../../utils/batch/GetBatchFromList';
import { RouteComponentProps } from 'react-router-dom';
import {
  PageContainer,
  PageContent,
  PageLayout,
} from '../../components/page-container';
import { AppHeaderLeftContent } from '../../components/app-header-bar/AppHeaderLeftContent';
import { DatesSection } from './components/DatesSection';
import { CostsSection } from './components/CostsSection';
import { IngredientsSection } from './components/IngredientsSection';
import { NotesSection } from './components/NotesSection';
import { SectionPaddingLeft } from '../../css/Styled';
import { RemainingBatchAllowance } from '../../components/cards/RemainingBatchAllowance';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { BATCH_DETAIL } from './TextProvider';

interface IProps {
  batches: IApiBatch[];
  routeComponentProps: RouteComponentProps<{
    [ERouterUrlParamKey.batch]: Uuid;
  }>;
  remainingBatchAllowance: number;
  billingPlan: IApiBillingPlan;
}

export const BatchDetail = React.memo((
  {
    batches,
    routeComponentProps,
    remainingBatchAllowance,
    billingPlan
  }: IProps
  ): JSX.Element => {
    const batch = getBatchFromListByUuid(
      routeComponentProps.match.params.batchUuid,
      batches
    ) as IApiBatch;

    const goBackHandler = () => {
      routeComponentProps.history.push(REACT_ROUTER_URL_LIST.completedBatches);
    };

    return (
      <PageLayout>
        <AppHeader
          title={EBatchFormHeaderTitle.completeSingleBatch}
          leftContent={<AppHeaderLeftContent goBackHandler={goBackHandler}/>}
        />
        <AutoSizer>
          <PageContainer>
            <PageContent>
              <RemainingBatchAllowance
                billingPlan={billingPlan}
                remainingBatchAllowance={remainingBatchAllowance}
              />
              <SectionPaddingLeft>
                <Heading>{batch.productName}</Heading>
              </SectionPaddingLeft>
              <Spacer/>
              <DatesSection
                plannedDate={batch.plannedTime}
                productionDate={batch.completedTime}
              />
              <Spacer/>
              <Spacer/>
              <CostsSection
                batch={batch}
              />
              <Spacer/>
              <Spacer/>
              <IngredientsSection
                batchIngredients={batch.batchIngredients}
              />
              <Spacer/>
              <Spacer/>
              <NotesSection
                title={BATCH_DETAIL.noteSection.title}
                note={batch.note}
                placeHolder={EReadOnlyNotesPlaceHolder.forBatch}
              />
            </PageContent>
          </PageContainer>
        </AutoSizer>
      </PageLayout>
    );
  }
);
