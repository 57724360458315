export interface IOptionIdName {
  id: string | number;
  name: string;
}

export const findNameById = (
  options: IOptionIdName[],
  needleID: string | number
): string | false => {
  const result = options.find((e: IOptionIdName) => (e.id === needleID));

  if (result === undefined) {
    return false;
  }
  return result.name;
}
