import styled from 'styled-components';
import { Label, TextInputFilterType } from "@kounta/chameleon";

// I couldn't use `UOM_DROP_DOWN_WIDTH` from './IngredientsFormFields';
// It might be a problem with styled.component 3.1.6.
// However the chameleon issue is blocking use from upgrading.
export const DropDownWrapper = styled.div`
  width: 160px;
`;

export const NumberInputWrapper = styled.div`
  width: 96px;
`;

// Some CSS style object. Always use const for Style object, otherwise it cause
// unnecessary re-rendering .
export const FULL_HEIGHT = { height: '100vh' };

export const ImageWrapper = styled.img`
  height: 100%;
  width: 100%;
`;

export const SectionPaddingLeft = styled.div`
  padding-left: 8px;
`;

export const AccordionParagraphContainer = styled.div`
  margin-left: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
`;

export const CostList = styled.div`
	width: 94%;
`;

export const CostFieldDiv = styled.div`
  width: 112px;
`;

export const RemainBadge = styled.div`
  margin-right: 16px;
`;

export const EditorElement = styled.div`
  width: 100%;
`;

export const BoldDiv = styled.div`
  font-weight : bold;
`;

/**
 * as discuss with @mle
 * hack the z-index css in Editor Alert
 */
export const EditorAlertDiv = styled.div`
  div[class^="Error Alert__AlertBox"] {
    z-index: 2050;
  }
`;

export interface INumberInputProps {
  value: string;
  filter?: TextInputFilterType;
}

export const CostLabel = styled(Label)`
	width: 200px;
`;

export const ProductNameRowContainer = styled.div`
  width: 300px;
`;

export const MEDIA_QUERY_BREAK_POINT = { maxWidth: 769 };

export const Scrollable = styled.div`
  /* has to be scroll, not auto for smooth scrolling iOS to work */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  /* Height calculated by header and control plus 48px padding between elements */
  height: calc(100vh - 88px);
  padding-bottom: 120px;

  table {
    /* To fix issue when number of rows is not many */
    height: auto;
  }
`;
