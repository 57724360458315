import React from 'react';
import Moment from 'react-moment';
import { shortDateTimeFormat } from '../../TextProvider';

export const RELATIVE_TIME_TYPES = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  lastWeek: 'dddd LT',
};

export const relativeTimeStamp = (
  timestamp: string,
  currentDate = new Date(),
): JSX.Element => {
  const normalTimeStamp = new Date(timestamp);

  const difference = currentDate.getTime() - normalTimeStamp.getTime();
  const hourDifference = Math.floor(difference / 1000 / 60 / 60);

  // Timestamp between >4 and <36 hours:
  let formattedTimeStamp = (
    <Moment calendar={RELATIVE_TIME_TYPES}>{timestamp}</Moment>
  );

  // Timestamp between 0 and 4 hours
  if (hourDifference >= 0 && hourDifference <= 4) {
    formattedTimeStamp = <Moment fromNow={true}>{timestamp}</Moment>;
  }

  // If Timestamp is more than 36 hours, format it with date, month, hours
  // and minutes
  if (hourDifference >= 36) {
    formattedTimeStamp = (
      <Moment format={shortDateTimeFormat}>{timestamp}</Moment>
    );
  }
  return formattedTimeStamp;
};
