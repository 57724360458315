import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  EBatchStatus,
  IApiBatch,
  ILayoutUiControlFuncs, IProduceLeftNavControl,
  Uuid,
} from '../../typings/Interface';
import { IReduxStoreListsAfterInitialised } from '../../redux/actions';
import { EPageHeader, ERouterUrlParamKey } from '../../TextProvider';
import ProductionStatusSegment from './list-view/ProductionStatusSegment';
import BatchTable from './list-view/BatchTable';
import { CreateBatchButton } from './list-view/CreateBatchButton';
import { Container, Spacer } from '@kounta/chameleon';
import { RemainingBatchAllowance } from '../../components/cards/RemainingBatchAllowance';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { isBatchAllowanceExhausted } from '../../utils/plan-restriction/IsBatchAllowanceExhausted';
import { SLIM_PRIMARY_BUTTON } from '../../typings/Chameleon';
import { ProduceTitleHeader } from "../../components/produce-nav/ProduceTitleHeader";
import { Scrollable } from '../../css/Styled';

interface IProps
  extends IReduxStoreListsAfterInitialised,
    ILayoutUiControlFuncs,
    IProduceLeftNavControl {
  routeComponentProps: RouteComponentProps<{
    [ERouterUrlParamKey.batch]: Uuid;
  }>;
  segmentBatchStatus: EBatchStatus;
  handleBatchStatusChange: (batchStatus: { id: EBatchStatus }) => void;

  // Batch Restriction based on the site plan
  remainingBatchAllowance: number;
  billingPlan: IApiBillingPlan;
}

interface IState {
  existingBatch: IApiBatch | false;
  showFinishBatchYieldModal: boolean;
}

export default class BatchesList extends React.Component<IProps, IState> {
  readonly state: IState = {
    existingBatch: false,
    showFinishBatchYieldModal: false,
  };

  public render = () => {
    const isAllowanceExhausted = isBatchAllowanceExhausted(
      this.props.billingPlan.produce.newPlanId,
      this.props.remainingBatchAllowance,
    );
    return (
      <React.Fragment>
        <ProduceTitleHeader
          title={EPageHeader.incompleteBatches}
          showNavYn={this.props.showNav}
          content={(
            <CreateBatchButton
              routeComponent={this.props.routeComponentProps}
              buttonStyle={SLIM_PRIMARY_BUTTON}
              disabled={isAllowanceExhausted}
            />
          )}
        />
        <Container>
          <RemainingBatchAllowance
            billingPlan={this.props.billingPlan}
            remainingBatchAllowance={this.props.remainingBatchAllowance}
          />
          <ProductionStatusSegment
            segmentBatchStatus={this.props.segmentBatchStatus}
            handleBatchStatusChange={this.props.handleBatchStatusChange}
          />
          <Spacer/>
          <Scrollable>
            <BatchTable
              routeComponentProps={this.props.routeComponentProps}
              batches={this.props.batches}
              products={this.props.products}
              segmentBatchStatus={this.props.segmentBatchStatus}
              // UI controls for SnackBar and Modal:
              handleModal={this.props.handleModal}
              hideSnackBar={this.props.hideSnackBar}
            />
          </Scrollable>
        </Container>
      </React.Fragment>
    );
  };
}
