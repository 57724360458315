import {
  EConsumptionType,
  EProductInventoryType,
  EUomEditorType,
  IAlertDetail,
  IEmptyStateOption,
  IUomEditorTextProvider,
} from './typings/Interface';
import {EIllustrationName, IChameleonTooltipStep,} from './typings/Chameleon';
import {AdvancedSelectOptionType, SegmentOptionType, SelectOptionType, TableHeaderType,} from '@kounta/chameleon';

export enum EBackofficeLinks {
  assignCognitoPage = '/produce/index',
  siteSwitchPage = '/produce/SwitchSite?siteUuid=',
  productInventoryPage = '/product?ref=pro-inv-type',
  productPage = '/product',
  logoutPage = '/website/logout',
  producePlanPage = '/plans/produce',
  producePermissionDeniedPage = '/website/producepermissiondenied',
  inventoryDash = '/inventory',
}

export enum E3rdPartyLinks {
  produceFeedbackPage = 'https://kounta.customercase.com/forums/customer-ideas/ideas/create',
}

export const LEFT_NAV_TITLE = 'Produce';
export const MIN_SEARCH_LENGTH = 3;

// refer to https://momentjs.com/docs/#/displaying/ for detail display format
// like: 31 Mar
const shortDateFormat = 'DD MMM';
// like: 31 Mar 2019
export const longDateFormat = 'DD MMM YYYY';
// like 12:34 PM
const timeFormat = 'hh:mm A';
// like 31 Mar 12:34 PM
export const shortDateTimeFormat = `${shortDateFormat} ${timeFormat}`;
// like 31 Mar 2019 12:34 PM
export const longDateTimeFormat = `${longDateFormat} ${timeFormat}`;

// ------ For Recipes Filter -------
export enum ERecipeFilterDropdownIds {
  All = 'All',
  MadeToOrder = 'MadeToOrder',
  Premade = 'Premade',
}

export enum ERecipesListSearchEntity {
  recipes = 'recipes',
  ingredients = 'ingredients',
}

export const RECIPES_LIST_SEARCH_LENGTH_THRESHOLD = 3;

export const RECIPES_LIST_SEARCH_DROPDOWN: AdvancedSelectOptionType[] = [
  { value: ERecipesListSearchEntity.recipes, label: 'Recipes' },
  { value: ERecipesListSearchEntity.ingredients, label: 'Ingredients' },
];

export interface IRecipeFilterOption extends SelectOptionType {
  id: ERecipeFilterDropdownIds;
  name: string;
}

export const RECIPE_FILTER_OPTIONS: IRecipeFilterOption[] = [
  {
    id: ERecipeFilterDropdownIds.All,
    name: 'All',
  },
  {
    id: ERecipeFilterDropdownIds.MadeToOrder,
    name: 'Made to order recipes',
  },
  {
    id: ERecipeFilterDropdownIds.Premade,
    name: 'Batch recipes',
  },
];
// ------ End of Recipes Filter -------

// ------ For product filter -----
export type EProductInventoryTypeOrAll = EProductInventoryType | 'All';

export interface IProductFilterOption extends SelectOptionType {
  id: EProductInventoryTypeOrAll;
  name: string;
}

export const PRODUCT_FILTER_OPTIONS: IProductFilterOption[] = [
  {
    id: 'All',
    name: 'All products'
  },
  {
    id: EProductInventoryType.madeHere,
    name: 'Made here products'
  },
  {
    id: EProductInventoryType.purchase,
    name: 'Purchased products'
  }
]

// Dealing with React router URL settings:
// URL Parameters: https://reacttraining.com/react-router/web/example/url-params
export enum ERouterUrlParamKey {
  recipe = 'productId',
  batch = 'batchUuid',
}

// Query Parameters:
// https://tylermcginnis.com/react-router-query-strings/
// https://reacttraining.com/react-router/web/example/query-parameters
export enum ERouterQueryParamKey {
  recipesFilterCriteria = 'filterCriteria',
  recipesSearchText = 'searchText',
  recipesSearchEntity = 'entity',
}

export enum ERouterUrlPath {
  createRecipe = '/create-recipe',
  recipes = '/recipes',
  singleRecipe = '/recipe/',
  incompleteSingleBatch = '/incompletebatch/',
  completedSingleBatch = '/completedbatch/',
  // `createBatch` should put before `incompleteBatches` if we plan to use
  // `startWith()` to find the match of the URL path;
  createBatch = '/production/create-batch',
  incompleteBatches = '/production',
  completedBatches = '/history',

  // Order by length of the string DESC in order to make sure longest match can
  // be found first and then `break/return/exit`.
  default = '/',
}

export enum ELabelSiteSwitchMenuItem {
  backOfficeProducts = 'Back Office Products',
  feedBackForum = 'Give feedback',
  refreshPage = 'Refresh this page',
  logout = 'Logout',
}

export const REACT_ROUTER_URL_LIST = {
  default: ERouterUrlPath.default,
  recipes: ERouterUrlPath.recipes,
  createRecipe: ERouterUrlPath.createRecipe,

  singleRecipe: `${ERouterUrlPath.singleRecipe}:${ERouterUrlParamKey.recipe}`,
  incompleteSingleBatch: `${ERouterUrlPath.incompleteSingleBatch}:${
    ERouterUrlParamKey.batch
  }`,
  createBatch: ERouterUrlPath.createBatch,
  incompleteBatches: ERouterUrlPath.incompleteBatches,
  completedBatches: ERouterUrlPath.completedBatches,
  completedSingleBatch: `${ERouterUrlPath.completedSingleBatch}:${
    ERouterUrlParamKey.batch
  }`,
};

export enum EPageHeader {
  recipes = 'Recipes',
  incompleteBatches = 'Prep',
  completedBatches = 'Batch history',
}

export const PAGE_TOP_BAR_MENU_OPTIONS = [
  {
    label: EPageHeader.recipes,
    value: REACT_ROUTER_URL_LIST.recipes,
  },
  {
    label: EPageHeader.incompleteBatches,
    value: REACT_ROUTER_URL_LIST.incompleteBatches,
  },
  {
    label: EPageHeader.completedBatches,
    value: REACT_ROUTER_URL_LIST.completedBatches,
  },
];

// End

// Dealing with Table Headers:
const firstColumnWidth = '80px';
const lastColumnWidth = '180px';
export const RECIPES_LIST_TABLE_HEADER_OPTIONS: TableHeaderType[] = [
  {
    id: '0',
    // Use space to ensure a gap reserved for  <Avatar initials>:
    name: ' ',
    width: firstColumnWidth,
  },
  {
    id: '1',
    name: 'Product Name',
  },
  {
    id: '2',
    name: 'Last updated',
    width: lastColumnWidth,
  },
];

export const PRODUCTS_LIST_TABLE_HEADER_OPTIONS: TableHeaderType[] = [
  {
    id: '0',
    // Use space to ensure a gap reserved for  <Avatar initials>:
    name: ' ',
    width: firstColumnWidth,
  },
  {
    id: '1',
    name: ' ',
  },
  {
    id: '2',
    name: ' ',
    width: lastColumnWidth,
  },
];
// End

export const PRODUCT_COMSUMPTION_TYPES: SegmentOptionType[] = [
  { id: EConsumptionType.madeToOrder, name: 'Made to order' },
  { id: EConsumptionType.premade, name: 'Made in batches' },
];

export const UNSAVED_CHANGES_PROMPT_MESSAGE =
  'Are you sure you want to leave without saving changes?';

export const DELETE_RECIPE_MODAL_MESSAGE =
  'Are you sure you want to delete this recipe? Products without recipes cannot be produced.';

export const RECIPE_SAVE_ERROR_MESSAGE =
  "Sorry, we couldn't save your recipe. Please try again in a few minutes.";

export const RECIPE_DELETED_ERROR_MESSAGE =
  "Sorry, recipe couldn't be deleted. Please try again.";

const STANDARD_ALERT_TIME = 5000;

interface IAlertList {
  [key: string]: IAlertDetail;
}

export enum ELoadingTitle {
  loading = 'Loading',
  saveRecipe = 'Saving recipe',
  saveBatch = 'Saving batch',
  startBatch = 'Starting batch',
  retrievingSites = 'Retrieving sites',
}

export const ALERT_LIST: IAlertList = {
  saveRecipeError: {
    topic: 'saveRecipeError',
    alertType: 'Error',
    alertTime: STANDARD_ALERT_TIME,
    message: RECIPE_SAVE_ERROR_MESSAGE,
  },
  deleteRecipeError: {
    topic: 'deleteRecipeError',
    alertType: 'Error',
    alertTime: STANDARD_ALERT_TIME,
    message: RECIPE_DELETED_ERROR_MESSAGE,
  },
};

// Create recipe page
export const CREATE_RECIPE_HEADER = 'Select an item';

// Batch Pages:
export const GO_BACK_TEXT = 'Back';
export const GO_BACK_TO_INVENTORY_DASH = 'Inventory Dashboard';
export const INVENTORY_DASH_ORIGIN = 'inventory-dash';

export enum EBatchButtonText {
  create = 'Create new batch',
  plan = 'Plan batch',
  cancel = 'Cancel batch',
}

export const PRODUCT_NAME_SEARCH_LABEL = 'Product name';

export const PRODUCT_NAME_SEARCH_PLACEHOLDER =
  'Find the product you want to make';

export const INGREDIENT_NAME_SEARCH_PLACEHOLDER =
  'Search for a product/ingredient';

export const INGREDIENT_QTY_PLACEHOLDER = '0.0';

export enum ERecipeAppHeaderTitle {
  update = 'View recipe',
  create = 'Create recipe',
}

export enum ERecipeFormLabel {
  recipeType = 'Recipe type',
  expectedYield = 'Expected yield',
}

export enum ERecipeFormButtonText {
  save = 'Save recipe',
  delete = 'Delete recipe',
}

export enum ESnackBarMessage {
  START_BATCH_SUCCEED = 'Started batch',
  FINISH_BATCH_SUCCEED = 'Finished batch successfully',
  FINISH_BATCH_FAILED = 'Unable to finish this batch',
  CANCEL_BATCH_SUCCEED = 'Batch cancelled',
  PLAN_BATCH_SNACKBAR_MSG_PREFIX = 'Batch planned for',
  SAVE_RECIPE_SUCCEED = 'Recipe saved',
  DELETE_RECIPE_SUCCEED = 'Recipe deleted',
}

export enum EEmptyStateOperationName {
  inProgressBatches = 'inProgressBatches',
  plannedBatches = 'plannedBatches',
  historyFilter = 'historyFilter',
  noProductionHistory = 'noProductionHistory',
  productsList = 'productsList',
  productsSearch = 'productsSearch',
}

interface IEmptyStateDescription {
  [name: string]: IEmptyStateOption;
}

const CDN_HOST = 'https://cdn.kounta.com/produce/';
export const EMPTY_STATE_DESCRIPTION: IEmptyStateDescription = {
  [EEmptyStateOperationName.inProgressBatches]: {
    heading: 'Never miss a beat',
    subheading: `You'll see a list of all your in progress batches here.`,
    isUrl: false,
    illustrationName: EIllustrationName.foodScale,
  },
  [EEmptyStateOperationName.plannedBatches]: {
    heading: 'Stay ahead of the curve',
    subheading: `You'll see a list of all your planned batches here.`,
    isUrl: false,
    illustrationName: EIllustrationName.reminders,
  },
  [EEmptyStateOperationName.historyFilter]: {
    heading: 'Try expanding your search',
    subheading: `You don't have any matches to the filter criteria.`,
    isUrl: false,
    illustrationName: EIllustrationName.noMatches,
  },
  [EEmptyStateOperationName.noProductionHistory]: {
    heading: 'Production history in one place',
    subheading: `You'll see a list of all your completed batches here.`,
    isUrl: false,
    illustrationName: EIllustrationName.foodScale,
  },
  [EEmptyStateOperationName.productsSearch]: {
    heading: 'Try expanding your search',
    subheading: "You don't have any matches to the filter criteria",
    isUrl: false,
    illustrationName: EIllustrationName.noMatches,
  },
  [EEmptyStateOperationName.productsList]: {
    heading: 'Create your first recipe',
    subheading:
      `Search from all your Back Office products to create a recipe.
      All your recipes will be listed here.`,
    isUrl: true,
    imageUrl:
      `${CDN_HOST}img/empty-state/no-recipes-2020-02-19.svg`,
  },
};

export const UOM_TOOLTIP_TARGET_CLASS = 'uom-dropdown';
export const UOM_TOOLTIP: IChameleonTooltipStep[] = [
  {
    target: '.uom-dropdown',
    title: 'You can now use volume and weight',
    content:
      'Set volume (mL/L) or weight (g/kg) as your new default in Back Office.',
    locale: { last: 'Got it' },
    disableBeacon: true,
    showSkipButton: false,
    styles: {
      options: {
        width: 400,
      },
    },
  },
];

export const EMPTY_PRODUCTS_POPUP_BUTTON_TEXT = 'Create a recipe';

export enum EReadOnlyNotesPlaceHolder {
  forRecipe = 'Recipe instructions have not been added',
  forBatch = 'No notes were entered',
}

export const COST_SECTION_LABELS = {
  profit: 'Gross Profit: ',
  sellsPrice: {
    original: 'Sell Price: ',
    amended: 'Suggested Sell Price: ',
  },
  unitCost: 'Unit Cost: ',
  totalCost: 'Total Cost: ',
};

export const sellPriceLabelProvider = (isOriginalSellPrice: boolean) => {
  return isOriginalSellPrice ?
    COST_SECTION_LABELS.sellsPrice.original :
    COST_SECTION_LABELS.sellsPrice.amended;
};

export const CURRENCY_SYMBOL = '$';

export const UOM_EDITOR_TEXT_PROVIDER: IUomEditorTextProvider = {
  create: {
    actionType: EUomEditorType.create,
    header: 'Add new product',
    body: 'Find settings for the sell and cost price, category and inventory type in Back Office for this product.',
    button: 'Add new product',
  },

  update: {
    actionType: EUomEditorType.update,
    header: 'Edit default unit and size',
    body: 'These are the default settings for this product across the Lightspeed Platform.',
    button: 'Set new default',
  }
};
