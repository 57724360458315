import * as React from 'react';
import { Button } from '@kounta/chameleon';
import { EBatchButtonText, REACT_ROUTER_URL_LIST } from '../../../TextProvider';
import { RouteComponentProps } from 'react-router-dom';
import { PRIMARY_BUTTON, IButtonStyle } from '../../../typings/Chameleon';

interface CreateBatchButtonProps {
  buttonStyle?: IButtonStyle;
  routeComponent: RouteComponentProps;
  disabled: boolean;
}

export const CreateBatchButton = React.memo(
  (props: CreateBatchButtonProps): JSX.Element => {
    const goToCreateBatch = () => {
      props.routeComponent.history.push(REACT_ROUTER_URL_LIST.createBatch);
    };

    const buttonStyle = props.buttonStyle
      ? props.buttonStyle
      : PRIMARY_BUTTON;
    return (
      <Button
        slim={buttonStyle.slim}
        primary={buttonStyle.primary}
        secondary={buttonStyle.secondary}
        destructive={buttonStyle.destructive}
        onClick={goToCreateBatch}
        disabled={props.disabled}
      >
        {EBatchButtonText.create}
      </Button>
    );
  });
