import * as React from 'react';
import styled from 'styled-components';
import {
  IconBackspace,
  NumberPad,
  NumberPadOnChangeFuncType,
} from '@kounta/chameleon';
import { EUom } from '../../../typings/Interface';
import Uom from '../../../uom/Uom';

const StyledNumPad = styled.div`
  width: 348px;
  height: 348px;
  max-width: 384px;
  max-height: 384px;
`;

interface IProps {
  handleActualYieldChange: NumberPadOnChangeFuncType;

  uom: EUom;
  initialValue: number;
}

export const ActualYieldNumberPad = (props: IProps) => {
  const uomClass = new Uom(props.uom);
  return (
    <StyledNumPad>
      <NumberPad
        fill={true}
        showTitle={false}
        showActionButtons={false}
        initialValue={props.initialValue}
        overlay={false}
        onChange={props.handleActualYieldChange}
        numberPadButtons={numberPadButtons}
        maxLength={17}
        decimalDigits={4}
        useSuffix={uomClass.displayFromUom}
      />
    </StyledNumPad>
  );
};

/**
 * InputTypes are not exposed in chameleon so have to be defined
 * For more info on numberpad component usage checkout
 * https://chameleon.dev.kounta.com/3.23.0-91f60b5/index.html#/Numberpad?id=numberpad-1
 */

const inputTypes = {
  Numeric: '^[0-9.]$',
  Enter: 'Enter',
  Backspace: 'Delete',
  Clear: 'Clear',
  Escape: 'Esc',
  PlusMinus: '^[+-]$',
  DollarPercentage: '^[$%]$',
};

const numberPadButtons = [
  {
    id: '7',
    name: '7',
    className: '',
  },
  {
    id: '8',
    name: '8',
    className: '',
  },
  {
    id: '9',
    name: '9',
    className: '',
  },
  {
    id: '4',
    name: '4',
    className: '',
  },
  {
    id: '5',
    name: '5',
    className: '',
  },
  {
    id: '6',
    name: '6',
    className: '',
  },
  {
    id: '1',
    name: '1',
    className: '',
  },
  {
    id: '2',
    name: '2',
    className: '',
  },
  {
    id: '3',
    name: '3',
    className: '',
  },
  {
    id: 'backspace',
    name: '',
    content: <IconBackspace />,
    className: 'tdNumPadBk',
    inputType: inputTypes.Clear,
  },
  {
    id: '0',
    name: '0',
    className: '',
  },
  {
    id: '.',
    name: '.',
    className: '',
  },
];
