export const BATCH_DETAIL = {
  datesSection: {
    title: 'Dates',
    labels: {
      plannedDate: 'PLANNED DATE',
      productionDate: 'PRODUCTION DATE',
    },
  },
  costSection: {
    title: 'Batch yields and costs',
    labels: {
      expectedYield: 'EXPECTED YIELD',
      actualYield: 'ACTUAL YIELD',
      batchCost: 'BATCH COST',
      unitCost: 'UNIT COST',
    },
  },
  ingredientsSection: {
    title: 'Ingredients',
    labels: {
      ingredientsUsed: 'INGREDIENTS USED',
      totalQty: 'TOTAL QTY',
      cost: 'COST',
    },
  },
  noteSection: {
    title: 'Notes',
  },
};
