import { IApiRecipe } from '../../typings/Interface';
import {
  IActionLoadRecipes,
  EReduxActionType,
  IActionAddPartialRecipes,
} from '../actions';
import produce from 'immer';

// We choose `null` to indicate the Redux store is just initialising.
// If it is [], it indicates the Redux store is initialised, just the site has
// no recipe.
// Hence we can distinguish between `null --> store initialise value` and
// `[] --> api response already pushed to the store, but it is empty`.
export const initialRecipes = null;

export default function recipesReducer(
  previousStateRecipes: IApiRecipe[] | null = initialRecipes,
  action: IActionLoadRecipes | IActionAddPartialRecipes,
): IApiRecipe[] | null {
  return produce(previousStateRecipes, (draft: IApiRecipe[] | null) => {
    switch (action.type) {
      case EReduxActionType.LOAD_RECIPES:
        return action.recipes;
      case EReduxActionType.ADD_PARTIAL_RECIPES:
        if (draft === initialRecipes) {
          // null is not iterable, cannot use `...draft`;
          return action.recipes;
        }
        return [
          ...draft,
          ...action.recipes,
        ];
      default:
        return previousStateRecipes;
    }
  });
}
