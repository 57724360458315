import {
  EConsumptionType,
  IApiProduct,
  IApiRecipe,
  Uuid,
} from '../../typings/Interface';
import {
  ERecipeFilterDropdownIds,
  ERecipesListSearchEntity,
  RECIPES_LIST_SEARCH_LENGTH_THRESHOLD,
} from '../../TextProvider';

export const getMatchedProductsUuids = (
  products: IApiProduct[],
  productNameSearchText: string,
): Uuid[] => {
  const matchedProductsUuidCollection: Uuid[] = [];
  for (const e of products) {
    let isMatchingProductNameSearchText = false;
    if (productNameSearchText.length < RECIPES_LIST_SEARCH_LENGTH_THRESHOLD) {
      // Reset Recipes list to show all recipes, when input < 3 chars
      // https://kounta.atlassian.net/browse/PRO-549
      isMatchingProductNameSearchText = true;
    } else {
      // ONLY start search, if the input has at least 3 characters:
      // https://kounta.atlassian.net/browse/PRO-549
      isMatchingProductNameSearchText = e.name.toLowerCase()
        // index of an empty string will always return 0, eg:
        // console.log("sxyz".indexOf('')) printing 0;
        // console.log("sxyz".indexOf('z')); is printing 3;
        .indexOf(productNameSearchText.toLowerCase()) !== -1;
    }
    if (isMatchingProductNameSearchText) {
      matchedProductsUuidCollection.push(e.id);
    }
  }
  return matchedProductsUuidCollection;
}

function verifyRecipeAgainstConditions(
  selectedSearchDropdown: ERecipesListSearchEntity,
  matchedProductsUuidCollection: Uuid[],
  selectedFilterOptionCriteria: ERecipeFilterDropdownIds,
) {
  return (e: IApiRecipe): boolean => {
    if (selectedSearchDropdown === ERecipesListSearchEntity.recipes) {
      const isMainProductMatch: boolean =
        matchedProductsUuidCollection.includes(e.productUuid);
      if (!isMainProductMatch) {
        return false;
      }
    } else if (selectedSearchDropdown === ERecipesListSearchEntity.ingredients) {
      let isIngMatch: boolean = false;

      for (const i of e.recipeIngredients) {
        if (matchedProductsUuidCollection.includes(i.productUuid)) {
          isIngMatch = true;
          // No need to loop further inside recipeIngredients, when one of the
          // ingredient already has a match.
          break;
        }
      }
      if (!isIngMatch) {
        return false;
      }
    }
    // After the above check, at least mainProduct or ing has a match

    switch (selectedFilterOptionCriteria) {
      case ERecipeFilterDropdownIds.MadeToOrder:
        return e.consumptionType === EConsumptionType.madeToOrder;
      case ERecipeFilterDropdownIds.Premade:
        return e.consumptionType === EConsumptionType.premade;
      default: // this default includes case of: ERecipeFilterDropdownIds.All
        return true;
    }
  };
}

export const searchByRecipesOrIngredients = (
  products: IApiProduct[],
  recipes: IApiRecipe[],
  productNameSearchText: string,
  selectedFilterOptionCriteria: ERecipeFilterDropdownIds,
  selectedSearchDropdown: ERecipesListSearchEntity,
): IApiRecipe[] => {
  // search `recipe name` is the same as search `product's name`, because
  // recipe name is main product's name.

  const matchedProductsUuidCollection = getMatchedProductsUuids(
    products,
    productNameSearchText,
  );

  // NOTE: never do `[] === []`, as it is always `false`.
  // https://stackoverflow.com/questions/40313263/why-is-in-javascript
  if (matchedProductsUuidCollection.length === 0) {
    // early exit:
    return [];
  }

  return recipes.filter(
    verifyRecipeAgainstConditions(
      selectedSearchDropdown,
      matchedProductsUuidCollection,
      selectedFilterOptionCriteria,
    )
  );
};
