import {
  IApiProduct,
  IApiRecipe,
  Uuid,
} from '../../typings/Interface';

export const getProductsWithoutRecipe = (
  products: IApiProduct[],
  recipes: IApiRecipe[],
): IApiProduct[] => {
  const recipesProductUuids: Uuid[] = recipes.map(
    (recipe: IApiRecipe) => recipe.productUuid
  );

  return products.filter(
    (product: IApiProduct) => !recipesProductUuids.includes(product.id)
  );
};
