import { RouteComponentProps } from "react-router-dom";

export interface IBatchesParams {
  originFrom: string|null;
}

export const batchesQueryParams = (
  routeComponentProps: RouteComponentProps,
): IBatchesParams => {
  const searchParams = new URLSearchParams(routeComponentProps.location.search);
  const origin = searchParams.get('origin');

  return {
    originFrom: origin,
  }
}
