import React from "react";
import { LeftNav, Overlay } from "@kounta/chameleon";
import styled from "styled-components";
import { ILeftNavProps } from "./ProduceLeftNav";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80%;
`;

export const MobileLeftNav = React.memo((props: ILeftNavProps): JSX.Element => {
  
  const hideNav = (): void => {
    props.navHandler(false);
  };
  
  const showHelpPanel = () => {
    hideNav();
    if(props.onClickHelp) props.onClickHelp();
  };
  
  const showSecondaryMenu = () => {
    hideNav();
    /**
     * this is the only context that using to control
     * the secondaryMenu show / hide
     */
    if (props.secondaryMenu?.contexts?.[0].onClick) props.secondaryMenu.contexts[0].onClick();
  };
  
  const secondaryMenu = {
    ...props.secondaryMenu,
    contexts: [
      {
        ...props.secondaryMenu?.contexts?.[0],
        onClick: showSecondaryMenu,
      },
    ]
  }
  
  return (
    <Overlay
      onClick={hideNav}
    >
      <MenuContainer>
        <LeftNav
          {...props}
          onClickHelp={showHelpPanel}
          secondaryMenu={secondaryMenu}
        />
      </MenuContainer>
    </Overlay>
  );
});
