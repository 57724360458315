import React from 'react';
import { Group, IconArrowLeft, IconButton } from '@kounta/chameleon';
import { EButtonType } from '../../../typings/Chameleon';
import { IModalGoBackHandler } from '../../../typings/Modal';

interface IProps {
  goBackHandler: IModalGoBackHandler;
  goBackLabel: string;
}

export const TopLeftBackButton = ({
  goBackHandler,
  goBackLabel,
}: IProps): JSX.Element => (
  <Group horizontal spacing={'0px'}>
    <IconButton
      label={goBackLabel}
      onClick={goBackHandler}
      /*
          Note: the default type for IconButton is submit;
        * */
      type={EButtonType.button}
    >
      <IconArrowLeft width={16} height={16} />
    </IconButton>
  </Group>
);
