import {
  EConsumptionType,
  IApiProduct,
  IApiRecipe,
} from '../../typings/Interface';
import { IAutoSuggestOptions } from '../../typings/Chameleon';
import {
  checkIsSingleProductDeleted,
  getProductByUuidFromList,
} from '../product/GetProductByUuidFromList';
import {isProductMadeHere} from "../filter/FilterUtil";

export const getPremadeProductNamesForDropDown = (
  products: IApiProduct[],
  recipes: IApiRecipe[]
): IAutoSuggestOptions[] => {
  const results: IAutoSuggestOptions[] = [];
  
  recipes.forEach((recipe: IApiRecipe) => {
    // As agreed by the team, we are not showing `madeToOrder` recipes:
    if (recipe.consumptionType === EConsumptionType.madeToOrder) return;
    
    const product = getProductByUuidFromList(recipe.productUuid, products);
    if (!product) return;
    
    // PRO-276: Exclude deleted Recipes
    if (checkIsSingleProductDeleted(recipe.productUuid, product)) return;
    
    if (!isProductMadeHere(product)) return;
    
    results.push({
      value: product.name,
    })
  });
  
  return results;
};
