import * as React from 'react';
import {
  GO_BACK_TEXT,
  UNSAVED_CHANGES_PROMPT_MESSAGE,
} from '../../../TextProvider';
import {
  Button,
  Group,
  IconArrowLeft,
  IconButton,
  Paragraph,
} from '@kounta/chameleon';
import {
  EBatchStatus,
} from '../../../typings/Interface';

export const goBackToListView = (
  appHeaderTitle: string,
  goBack: () => void,
  showModal: (
    overlay: boolean,
    title: string,
    content: JSX.Element | string | null,
    footer: JSX.Element | null
  ) => void,
  hideModal: () => void,
  batchHasChanged: boolean,
  batchStatus: EBatchStatus,
  handleBatchStatusChange: (productionType: { id: number }) => void,
  customButtonText?: string|null,
): JSX.Element => {
  const changeListViewTab = () => {
    const id =
      batchStatus === EBatchStatus.planned
        ? EBatchStatus.planned
        : EBatchStatus.inProgress;
    handleBatchStatusChange({ id });
  };

  const goBackOrPrompt = () => {
    if (batchHasChanged) {
      showModal(true, 'Unsaved changes', modalContent(), modalFooter());
      return;
    }
    changeListViewTab();
    goBack();
    return;
  };

  const modalContent = () => (
    <Group align="center">
      <Paragraph>{UNSAVED_CHANGES_PROMPT_MESSAGE}</Paragraph>
    </Group>
  );

  const modalFooter = () => (
    <Group horizontal={true} full={true} even={true}>
      <Button fullWidth={true} primary={true} onClick={goBack}>
        Yes
      </Button>
      <Button fullWidth={true} secondary={true} onClick={hideModal}>
        Cancel
      </Button>
    </Group>
  );
  
  const buttonText = customButtonText ? customButtonText : GO_BACK_TEXT;

  return (
    <div>
      <Group horizontal={true} spacing="0px">
        <IconButton onClick={goBackOrPrompt} label={buttonText}>
          <IconArrowLeft width={16} height={16} />
        </IconButton>
      </Group>
    </div>
  );
};
