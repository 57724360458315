import {
  IApiProduct,
  IApiRecipe,
  IApiBatch, ISiteInfo,
} from '../../typings/Interface';
import { IApiBillingPlan } from '../../graphql/BillingPlanQL';
import { IReduxStaff } from '../reducers/StaffReducer';

/**
 * Store Structure example:
 */
// const exampleState = {
//   products: [
//     {
//       id: "20200002-0000-2000-8000-000000000001",
//       name: 'Food',
//     },
//     {
//       id: "20200002-0000-2000-8000-000000000002",
//       name: 'Drink',
//     },
//   ],
//   recipes: [
//     {
//       uuid: "20200002-0000-2000-8000-000000000001",
//       productId: "20200002-0000-2000-8000-000000000001",
//       note: "AppSync saveRecipe test",
//       consumptionType: 1,
//       expectedYield: 2.34,
//       ingredients: [
//         {
//           productUuid: "20200002-0000-2000-8000-000000000001",
//           componentQty: 2.22,
//           uom: "unit",
//         },
//         {
//           productUuid: "20200002-0000-2000-8000-000000000002",
//           componentQty: 1.14,
//           uom: "unit",
//         }
//       ],
//       uom: "unit",
//     },
//     {
//       uuid: "20200002-0000-2000-8000-000000000001",
//       productId: "20200002-0000-2000-8000-000000000001",
//       note: "AppSync saveRecipe test",
//       consumptionType: 1,
//       expectedYield: 2.34,
//       ingredients: [
//         {
//           productUuid: "20200002-0000-2000-8000-000000000001",
//           componentQty: 2.22,
//           uom: "unit",
//         },
//         {
//           productUuid: "20200002-0000-2000-8000-000000000002",
//           componentQty: 1.14,
//           uom: "unit",
//         }
//       ],
//       uom: "unit",
//     },
//   ],
//   batches: [],
//   isProductsFullyLoaded: true,
//
//   billingPlan: {
//      ....
//      produce: {
//        nextPlan: "Advanced Prep",
//        nextPlanId: 75,
//        ...
//      }
//   }
//   remainingBatchAllowance: 5,
//   staff: {
//      hasEditRecipePermission: true,
//      hasEditBatchPermission: true,
//   }
// };

/**
 * action types
 */
export enum EReduxActionType {
  // 1. Lists
  LOAD_PRODUCTS = 'LOAD_PRODUCTS',
  LOAD_RECIPES = 'LOAD_RECIPES',
  LOAD_BATCHES = 'LOAD_BATCHES',

  // 2. Load Partial list:
  ADD_PARTIAL_PRODUCTS = 'ADD_PARTIAL_PRODUCTS',
  ADD_PARTIAL_RECIPES = 'ADD_PARTIAL_RECIPES',
  ADD_PARTIAL_BATCHES = 'ADD_PARTIAL_BATCHES',

  // 3. Flag:
  SET_PRODUCTS_FULLY_LOADED = 'SET_PRODUCTS_FULLY_LOADED',
  SET_RECIPES_FULLY_LOADED = 'SET_RECIPES_FULLY_LOADED',

  // 4. Billing plan restrictions:
  SET_REMAINING_BATCH_ALLOWANCE = 'SET_REMAINING_BATCH_ALLOWANCE',
  LOAD_BILLING_PLAN = 'LOAD_BILLING_PLAN',

  // 5. Get Staff information:
  LOAD_STAFF = 'LOAD_STAFF',

  // 6. Get siteInfo:
  LOAD_SITE = 'LOAD_SITE',
}

/**
 * 1. Lists:
 */
export interface IActionLoadProducts {
  type: EReduxActionType.LOAD_PRODUCTS;
  products: IApiProduct[];
}

export interface IActionLoadRecipes {
  type: EReduxActionType.LOAD_RECIPES;
  recipes: IApiRecipe[];
}

export interface IActionLoadBatches {
  type: EReduxActionType.LOAD_BATCHES;
  batches: IApiBatch[];
}

/**
 * 2. Load Partial list:
 */
export interface IActionAddPartialProducts {
  type: EReduxActionType.ADD_PARTIAL_PRODUCTS;
  products: IApiProduct[];
}

export interface IActionAddPartialRecipes {
  type: EReduxActionType.ADD_PARTIAL_RECIPES;
  recipes: IApiRecipe[];
}

export interface IActionAddPartialBatches {
  type: EReduxActionType.ADD_PARTIAL_BATCHES;
  batches: IApiBatch[];
}

/**
 * 3. Flag:
 */
export interface IActionSetProductsFullyLoaded {
  type: EReduxActionType.SET_PRODUCTS_FULLY_LOADED;
  isProductsFullyLoaded: boolean;
}

export interface IActionSetRecipesFullyLoaded {
  type: EReduxActionType.SET_RECIPES_FULLY_LOADED;
  isRecipesFullyLoaded: boolean;
}

/**
 * 4. Billing plan restrictions:
 */
export interface IActionSetRemainingBatchAllowance {
  type: EReduxActionType.SET_REMAINING_BATCH_ALLOWANCE;
  remainingBatchAllowance: number;
}
export interface IActionLoadBillingPlan {
  type: EReduxActionType.LOAD_BILLING_PLAN;
  billingPlan: IApiBillingPlan;
}

/**
 * 5. Load staff
 */
export interface IActionLoadStaff {
  type: EReduxActionType.LOAD_STAFF;
  staff: IReduxStaff;
}

/**
 * 6. Load siteInfo
 */
export interface IActionLoadSite {
  type: EReduxActionType.LOAD_SITE;
  siteInfo: ISiteInfo;
}

type Actions =
  // 1. Lists
  | IActionLoadProducts
  | IActionLoadRecipes
  | IActionLoadBatches
  // 2. Load Partial Products/Recipes/Batches
  | IActionAddPartialProducts
  | IActionAddPartialRecipes
  | IActionAddPartialBatches
  // 3. Flag
  | IActionSetProductsFullyLoaded
  | IActionSetRecipesFullyLoaded
  // 4. batch restriction
  | IActionSetRemainingBatchAllowance
  | IActionLoadBillingPlan
  // 5. Load staff
  | IActionLoadStaff
  // 6. Load site
  | IActionLoadSite;


/**
 * action creators
 */
// In the actions, it should only accept array, even empty array [].
// but never `null`, `null` has another meaning: The redux state just
// initialised.
export const loadProducts = (products: IApiProduct[]): IActionLoadProducts => ({
  type: EReduxActionType.LOAD_PRODUCTS,
  products,
});

export const loadRecipes = (recipes: IApiRecipe[]): IActionLoadRecipes => ({
  type: EReduxActionType.LOAD_RECIPES,
  recipes,
});

export const loadBatches = (batches: IApiBatch[]): IActionLoadBatches => ({
  type: EReduxActionType.LOAD_BATCHES,
  batches,
});

export const addPartialProducts = (products: IApiProduct[]): IActionAddPartialProducts => ({
  type: EReduxActionType.ADD_PARTIAL_PRODUCTS,
  products,
});

export const addPartialRecipes = (recipes: IApiRecipe[]): IActionAddPartialRecipes => ({
  type: EReduxActionType.ADD_PARTIAL_RECIPES,
  recipes,
});

export const addPartialBatches = (batches: IApiBatch[]): IActionAddPartialBatches => ({
  type: EReduxActionType.ADD_PARTIAL_BATCHES,
  batches,
});

export const setProductsFullyLoaded = (isProductsFullyLoaded: boolean): IActionSetProductsFullyLoaded => ({
  type: EReduxActionType.SET_PRODUCTS_FULLY_LOADED,
  isProductsFullyLoaded,
});

export const setRecipesFullyLoaded = (isRecipesFullyLoaded: boolean): IActionSetRecipesFullyLoaded => ({
  type: EReduxActionType.SET_RECIPES_FULLY_LOADED,
  isRecipesFullyLoaded,
});

export const setRemainingBatchAllowance = (remainingBatchAllowance: number): IActionSetRemainingBatchAllowance => ({
  type: EReduxActionType.SET_REMAINING_BATCH_ALLOWANCE,
  remainingBatchAllowance,
});

export const loadBillingPlan = (billingPlan: IApiBillingPlan): IActionLoadBillingPlan => ({
  type: EReduxActionType.LOAD_BILLING_PLAN,
  billingPlan,
});

export const loadStaff = (staff: IReduxStaff): IActionLoadStaff => ({
  type: EReduxActionType.LOAD_STAFF,
  staff,
});

export const loadSite = (siteInfo: ISiteInfo): IActionLoadSite => ({
  type: EReduxActionType.LOAD_SITE,
  siteInfo,
});

/**
 * Redux store Interfaces:
 */
export interface IReduxStoreListsPart {
  products: IApiProduct[] | null;
  recipes: IApiRecipe[] | null;
  batches: IApiBatch[] | null;
}

export interface IReduxStoreListsAfterInitialised extends IReduxStoreListsPart {
  products: IApiProduct[];
  recipes: IApiRecipe[];
  batches: IApiBatch[];
}

// `null` is only valid, when the redux JUST initialised. All redux actions
// should always take not-null argument. Empty array `[]` is a valid argument.
// `[]` means the site has zero `recipes` or `products` etc.
export interface IReduxStore extends IReduxStoreListsPart {
  // 1. Lists
  products: IApiProduct[] | null;
  recipes: IApiRecipe[] | null;
  batches: IApiBatch[] | null;
  // 2. Load Partial Products will just use the same `products` above;
  // 3. Flag:
  isProductsFullyLoaded: boolean;
  isRecipesFullyLoaded: boolean;

  remainingBatchAllowance: number | false;
  billingPlan: IApiBillingPlan | null;
  staff: IReduxStaff;
  siteInfo: ISiteInfo;
}

export interface IReduxDispatchFunction {
  (action: Actions): any
}
