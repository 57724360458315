import { removeSpace } from '../graphql/QueryStringPurify';

export const maxNum = 999999999999.9999;
export const minNum = -999999999999.9999;

export const max12integer4decimal = (fieldValue: string | null | undefined): boolean => {
  if (fieldValue === null || fieldValue === undefined) {
    return false;
  }
  /**
   * validate input, only allowed max 12 integer place and 4 decimal point.
   */
  const regexp = /^-?\d{1,12}(\.\d{1,4})?$/;
  return regexp.test(fieldValue);
};

export const isNumericCompatible = (mixed: any): boolean => {
  if (mixed === undefined
    || mixed === null) {
    return false;
  }

  const stringValue = mixed.toString();
  const result = !(
    removeSpace(stringValue) === ''
    || isNaN(mixed as unknown as number)
  );

  // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
  return result;
};

export const isPositiveNumber = (stringValue: string | null | undefined): boolean => {
  if (stringValue === null || stringValue === undefined) {
    return false;
  }
  return parseFloat(stringValue) > 0;
};

export const isLessOrEqualThanMaxNum = (stringValue: string | null | undefined): boolean => {
  if (stringValue === null || stringValue === undefined) {
    return false;
  }
  return parseFloat(stringValue) <= maxNum;
};

export const lessThanMaxNum = (num: number): boolean => (num <= maxNum);
export const moreThanMinNum = (num: string | null | undefined): boolean => {
  if (num === null || num === undefined) {
    return false;
  }
  return (parseFloat(num) >= minNum);
}

export const MAX_NUMBER_OF_LETTERS_FOR_NOTE = 8000;

export const isValidNumber = (mixed: any): boolean => {
  return (max12integer4decimal(mixed) && isPositiveNumber(mixed) && isLessOrEqualThanMaxNum(mixed));
};
