import * as React from 'react';
import {
  Decimal,
  Group,
  Modal,
  NumberPadOnChangeFuncType,
  Paragraph,
  Portal,
} from '@kounta/chameleon';
import { EBatchFormButtonText, EUom } from '../../../typings/Interface';
import { ActualYieldNumberPad } from './ActualYieldNumberPad';
import {Field, FieldProps} from 'formik';
import { setFieldValueImperatively } from '../../../formik/SetFieldValueImperatively';
import LoadingButton from '../../../components/loading/LoadingButton';
import { MODAL_LIST } from '../../../typings/Modal';

interface IProps {
  handleCompleteBatchActionButton: () => Promise<void>;
  hideFinishBatchYieldModal: () => void;
  uom: EUom;
  initialValue: number;
}

interface IState {
  disableFinishButton: boolean;
}

export class ActualYieldInputModal extends React.Component<IProps, IState> {
  readonly state: IState = {
    disableFinishButton: false,
  };

  public render() {
    // Using a Portal to force the modal to render in the root dom
    return (
      <Portal>
        <Modal
          overlay
          hasCloseBtn
          title={MODAL_LIST.batchActualYield.title}
          footer={this.modalFooter()}
          actionClose={this.hideModal}
        >{this.modalContent()}</Modal>
      </Portal>
    );
  }

  private hideModal = () => {
    this.props.hideFinishBatchYieldModal();
  };

  private handleYieldChanges = (
    form: FieldProps['form'],
    field: FieldProps['field']
  ): NumberPadOnChangeFuncType => {
    return (value: Decimal) => {
      // We cannot use `getFormikFieldError(form, field)` here because the
      // `field.value` is not updated yet.
      // Alternatively, we can use yup validation function:
      // const isValid = await reach(BatchSchema, field.name).validate(value.toNumber());
      // However it requires us to change type INumberPadOnChange.
      //
      // Since Numberpad already have enough validation build-in, we are relaying
      // on it to restrict user from violate the rules.
      // We only need to take care the 0 here:
      this.setState({ disableFinishButton: value.lte(0) });
      setFieldValueImperatively(form, field)(value.toNumber());
    };
  };

  private modalContent = () => (
    <Group spacing={'8px'}>
      <Paragraph>{MODAL_LIST.batchActualYield.text}</Paragraph>
      <Field name={'actualYield'}>{this.renderNumberPad}</Field>
    </Group>
  );

  private modalFooter = () => (
    <LoadingButton
      fullWidth={true}
      primary={true}
      onClick={this.props.handleCompleteBatchActionButton}
      disabled={this.state.disableFinishButton}
      buttonText={EBatchFormButtonText.finish}
    />
  );

  private renderNumberPad = ({ field, form }: FieldProps): JSX.Element => (
    <ActualYieldNumberPad
      handleActualYieldChange={this.handleYieldChanges(form, field)}
      uom={this.props.uom}
      initialValue={this.props.initialValue}
    />
  );
}
